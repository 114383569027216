import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

import * as Models from '../../models/employee/DeleteEmployee'

interface LoadDeletePositionEmployee {
    type: 'LOAD_DELETE_POSITION'
}

interface DeletePositionEmployee {
    type: 'DELETEPOSITIONEMPLOYEE'
    response: Models.ResponseDeletePositionEmployee
    statusDelete: number
}

interface DeleteFail {
    type: 'DELETEFAIL'
    statusDelete: number
}

interface ClearDelete {
    type: 'CLEARDELETE'
}

type KnownAction = DeletePositionEmployee | DeleteFail | ClearDelete | LoadDeletePositionEmployee

export const actionCreators = {
    requestdeletePositionemployee: (p: boolean, method: String, position_code: string, app_id : string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        console.log('call actionCreators : requestdeletePositionemployee')
        const appState = getState();
        if (appState && appState.deleteEmployee && p && method == 'POST') {
            var appIdUri = encodeURIComponent(app_id)
            var positionCode = encodeURIComponent(position_code)
            await fetch(`/v2/Employee/delete?positionCode=` + positionCode + '&app_id=' + appIdUri, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('dispatch : DELETEFAIL')
                            dispatch({ type: 'DELETEFAIL', statusDelete: 204 });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            console.log('dispatch : DELETEPOSITIONEMPLOYEE')
                            dispatch({ type: 'DELETEPOSITIONEMPLOYEE', response: data as Models.ResponseDeletePositionEmployee, statusDelete: 200 });
                        })
                    }
                })

            dispatch({ type: 'LOAD_DELETE_POSITION' });

        } else if (method == 'CLEAR') {
            console.log('dispatch : CLEARDELETE')
            dispatch({ type: 'CLEARDELETE' });
        }
    }
}

const unloadedState: Models.DeletePositionEmployeeState = { isLoad: false, statusDelete: 400 };

export const reducer: Reducer<Models.DeletePositionEmployeeState> = (state: Models.DeletePositionEmployeeState | undefined, incomingAction: Action): Models.DeletePositionEmployeeState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'DELETEPOSITIONEMPLOYEE':
            return {
                isLoad: true,
                response: action.response,
                statusDelete: action.statusDelete

            }
        case 'DELETEFAIL':
            return {
                isLoad: true,
                statusDelete: action.statusDelete
            }

        case 'CLEARDELETE':
            return {
                isLoad: false,
                statusDelete: 400
            }

        case 'LOAD_DELETE_POSITION':
            return {
                isLoad: false,
                statusDelete: 400
            }
        default: return state;
    }
}