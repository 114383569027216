import React from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Card,
    CardBody,
} from 'reactstrap';
import { Button } from 'react-bootstrap';
import { IconType } from 'react-icons';
import {
    FaCheckCircle,
    FaClock,
    FaQuestionCircle,
    FaTimesCircle,
} from 'react-icons/fa';

import { toCapitalCase } from 'helpers/utils';
import { ApproveRejectWorkflowResponse } from 'models/workflow/ApproveRejectWorkflow';

const documentStatusIcons = new Map<
    string,
    [IconType, { size: number; color: string }]
>([
    ['SUCCESS', [FaCheckCircle, { size: 44, color: 'green' }]],
    ['ERROR', [FaTimesCircle, { size: 44, color: 'red' }]],
    ['wait_otp', [FaClock, { size: 44, color: 'orange' }]],
    ['wait_sign', [FaClock, { size: 44, color: 'orange' }]],
]);

type Props = {
    isOpen?: boolean;
    action: DocumentAction;
    message?: string;
    responseDocuments?: ApproveRejectWorkflowResponse[];
    toggle?: () => void;
    onClickApproveAnyway?: () => void;
};

function ApprovalResultModal(props: Props) {
    const {
        isOpen,
        action,
        message = '',
        responseDocuments,
        toggle,
        onClickApproveAnyway,
    } = props;

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            size="lg"
            backdrop="static"
            keyboard={false}
            className="max-w-7xl"
        >
            <ModalHeader className="text-lg font-semibold">
                {toCapitalCase(action)} Status
            </ModalHeader>
            <ModalBody className="space-y-4">
                <div>
                    <p className="font-semibold text-neutral-800">Message</p>
                    <p className="text-lg">{message}</p>
                </div>

                {responseDocuments &&
                    responseDocuments.map((document) => {
                        const [Icon, defaultProps] = documentStatusIcons.get(
                            document.status
                        ) ?? [FaQuestionCircle, { size: 44, color: 'yellow' }];

                        return (
                            <Card className="shadow-sm bg-white rounded">
                                <CardBody>
                                    <div className="flex items-center gap-4">
                                        {/* Status icon */}
                                        <div className="px-6">
                                            <Icon {...defaultProps} />
                                        </div>

                                        {/* Document status information */}
                                        <div className="flex-1 overflow-auto space-y-2">
                                            <div>
                                                <p className="font-semibold text-neutral-800">
                                                    Document No.
                                                </p>
                                                <p className="text-base">
                                                    {document.docNo}
                                                </p>
                                            </div>
                                            <div>
                                                <p className="font-semibold text-neutral-800">
                                                    Status
                                                </p>
                                                <p className="text-base">
                                                    {document.status}
                                                </p>
                                            </div>
                                            {document.status === 'ERROR' && (
                                                <div>
                                                    <p className="font-semibold text-neutral-800">
                                                        Error Message
                                                    </p>
                                                    <p className="text-base">
                                                        {document.errorMessage}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        );
                    })}
            </ModalBody>
            <ModalFooter>
                <Button
                    variant="secondary"
                    onClick={toggle}
                    className="bg-[#6c757d]"
                >
                    Close
                </Button>
                {action === 'approve' &&
                    responseDocuments?.some(
                        (document) => document.status === 'ESIG0010'
                    ) && (
                        <Button
                            variant="success"
                            onClick={onClickApproveAnyway}
                            className="bg-[#3085d6]"
                        >
                            Approve anyway
                        </Button>
                    )}
            </ModalFooter>
        </Modal>
    );
}

export default ApprovalResultModal;
