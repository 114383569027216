import * as React from "react";
import { connect } from "react-redux";
import Select from 'react-dropdown-select';
import { ApplicationState } from "../../store";

import * as AutocompleteGroupStore from '../../store/autocomplete/GroupSearch'
import * as Models from '../../models/autocomplete/GroupSearch'

type autocompletegroupProps = Models.GetGroupState & typeof AutocompleteGroupStore.actionCreators

export interface inititem {
    sid: string,
    code: string,
    name: string,
    display: string,
};

class AutoCompleteGroup extends React.Component<any, { response: any, p: boolean, dataResult: Array<inititem>, dataSearch: {}, checkValueComing: boolean, roleChange: Array<inititem>, company?: string }, autocompletegroupProps> {
    constructor(props: any) {
        super(props);
        this.state = {
            response: "",
            p: true,
            dataResult: [],
            dataSearch: {},
            checkValueComing: true,
            roleChange: [],
            company: undefined
        }
    }

    componentDidMount() {
        var company = this.props.company

        if (company) {
            this.props.requestGroupSearch(this.state.p, "POST", this.props.company)
        }

        this.setState({
            company: company,
            p: false
        });
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { valueData, roleChange } = this.props
        /*if (valueData != undefined && this.state.checkValueComing) {
            this.setState({
                dataSearch: valueData,
                checkValueComing: false
            })
        } else*/
        if (this.props.status == 200 && this.state.p == false && this.props.response['result_list'] != null) {
            //console.log('data :');
            //this.state.dataResult = this.props.response['result_list']
            this.setState({
                dataResult: this.props.response['result_list'],
                p: true
            })
            //console.log(this.state.dataResult)
        }

        //if (roleChange.length > 0 && this.state.checkValueComing) {
        //    console.log(this.props.roleChange)
        //    var checkAuto: Array<inititem> = []
        //    checkAuto = this.state.dataResult
        //    for (var i = 0; i < this.props.roleChange.legnth; i++) {
        //        checkAuto = checkAuto.splice(this.state.dataResult.findIndex(({ code }) => code === this.props.roleChange[i]['code']), 1)
        //    }
        //    this.setState({
        //        dataResult: checkAuto
        //    })
        //    setTimeout(() => {
        //        console.log(this.state.dataResult)
        //    }, 500);
        //}
    }
    render() {
        console.log(this.state.dataResult);
        /*  if (this.props.status == 200 && this.state.p == false && this.props.response['result_list'] != null) {
              this.state.dataResult = this.props.response['result_list']
          }*/
        return (
            <Select
                options={this.state.dataResult}
                searchBy="code"
                labelField="display"
                valueField="code"
                values={[this.props.valueData]}
                dropdownGap={5}
                required={true}
                onChange={(value) => this.props.handlechageAutocomplete(value)}
            />
        )
    }
}
export default connect((state: ApplicationState) => ({ ...state.autoCompleteGroup, ...state.appState }), AutocompleteGroupStore.actionCreators)(AutoCompleteGroup)