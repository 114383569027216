import * as React from "react";
import { Button, Container, Row, Col, Table, FormControl, Form } from "react-bootstrap";
import { FaPlusCircle, FaTrashAlt } from 'react-icons/fa';
import AutcoCompleteWorkflowGroup from '../autocomplete/AutcoCompleteWorkflowGroup'
import AutcoCompleteConditionID from '../autocomplete/AutoCompleteConditionID'
import * as Models from '../../models/group/CreateGroup'

export interface SubPosi {
    obj_id: string,
    wf_id: string,
    position_group_id: string,
    parameter_code: string,
    parameter_name: string,
    parameter_value: string,
    parameter_operator: string
}

export interface Position {
    wf_id: string,
    task_type_id: string,
    task_id: string,
    position_group_id: string,
    position_group_name: string,
    child: string,
    y_next_task: string,
    n_next_task: string,
    chg_status_to_table: string,
    chg_status_to_value: string,
    chg_status_where: string,
    condition_list: Array<SubPosi>,
}

export interface Header {
    code: string,
    name: string,
    display: string
}

export interface PositionSelect {
    condition_list_select: Array<SubPosiSelect>,
}

export interface SubPosiSelect {
    conditionid: string,
    conditionname: string,
}

class TaskComponent extends React.Component<any, {fixBugPage:boolean, obj: Array<Position>, objSelect: Array<Models.PositionSelect>, objHeader: Array<Header>, CheckresponseSearch: boolean }> {
    constructor(props: any) {
        super(props);
        this.handlechageAutocomplete = this.handlechageAutocomplete.bind(this)
        this.handlechageCondition = this.handlechageCondition.bind(this)
    }
    state = {
        obj: [],
        objSelect: [],
        objHeader:[],
        CheckresponseSearch: true,
        fixBugPage:false
    };

    handleChangePosition = (idx: number, mode: String) => (e: { target: { name: any; value: any; }; }) => {
        var rows: Array<Position> = [];
        rows = this.state.obj;
        console.log(rows)
    };

    handleChangeSub = (idPosition: number, idSub: number, mode: String) => (e: { target: { name: any; value: any; }; }) => {
        console.log('rowsub')
        var obj_id: any = idSub + 1
        var rowsub: Array<Position> = [];
        rowsub = this.state.obj
        if (mode == 'Code') {
            rowsub[idPosition].condition_list[idSub]['parameter_code'] = e.target.value
        } else if (mode == 'Operator') {
            rowsub[idPosition].condition_list[idSub]['parameter_operator'] = e.target.value
        } else if (mode == 'Value') {
            rowsub[idPosition].condition_list[idSub]['parameter_value'] = e.target.value
        }
        rowsub[idPosition].condition_list[idSub]['obj_id'] = obj_id
        this.setState({ obj: rowsub })
        setTimeout(() => {
            console.log(this.state.obj)
        }, 500);
    };

    handleAddPosition = () => {

        var blindPosi: Array<Position> = [];
        blindPosi = this.state.obj;
        var blindPosiSelect: Array<Models.PositionSelect> = [];
        blindPosiSelect = this.state.objSelect;
        var blindPosiHeader: Array<Header> = [];
        blindPosiHeader = this.state.objHeader;

        var landingSub: SubPosi = {
            obj_id: "",
            wf_id: "",
            position_group_id: "",
            parameter_code: "",
            parameter_name: "",
            parameter_value: "",
            parameter_operator: ""
        }

        var HeaderSub: Header = {
            code: "",
            name:"",
            display: ""
        }

        var landibgPosition: Position = {
            wf_id: "",
            task_type_id: "",
            task_id: "",
            position_group_id: "",
            position_group_name: "",
            child: "",
            y_next_task: "",
            n_next_task: "",
            chg_status_to_table: "",
            chg_status_to_value: "",
            chg_status_where: "",
            condition_list: [landingSub],
        }

        var landingSubSelect: Models.SubPosiSelect = {
            conditionid: "",
            conditionname: ""
        }

        var landibgPositionSelect: Models.PositionSelect = {
            condition_list_select: [landingSubSelect],
        }

        blindPosi.push(landibgPosition);
        blindPosiSelect.push(landibgPositionSelect);
        blindPosiHeader.push(HeaderSub);

        this.setState({ objHeader: blindPosiHeader,obj: blindPosi, objSelect: blindPosiSelect });

        setTimeout(() => {
            console.log(this.state.obj)
        }, 500);
    };

    handleAddSub = (i: number) => {
        var blindPosi: Array<Position> = []
        blindPosi = this.state.obj
        var blindPosiSelect: Array<Models.PositionSelect> = []
        blindPosiSelect = this.state.objSelect

        var landingSub: SubPosi = {
            obj_id: "",
            wf_id: "",
            position_group_id: "",
            parameter_code: "",
            parameter_name: "",
            parameter_value: "",
            parameter_operator: ""
        }

        var landingSubSelect: Models.SubPosiSelect = {
            conditionid: "",
            conditionname: ""
        }

        blindPosi[i].condition_list.push(landingSub)
        blindPosiSelect[i].condition_list_select.push(landingSubSelect)
        this.setState({
            obj: blindPosi,
            objSelect: blindPosiSelect
        })
        setTimeout(() => {
            console.log(this.state.obj)
        }, 500);
    };

    handleRemoveSpecificPosition = (idPosition: number) => () => {
        console.log('rows')
        var rows: Array<Position> = []
        rows = this.state.obj
        var rowsSelect: Array<Models.PositionSelect> = []
        rowsSelect = this.state.objSelect

        var objHeader = [...this.state.objHeader];

        rows.splice(idPosition, 1)
        rowsSelect.splice(idPosition, 1)

        objHeader.splice(idPosition, 1)

        this.setState({ obj: rows, objSelect: rowsSelect, objHeader})
    }

    handleRemoveSpecificSub = (idPosition: number, idSub: number) => {
        console.log('rowsub')
        var rowsub: Array<Position> = [];
        rowsub = this.state.obj
        var rowsSelect: Array<Models.PositionSelect> = []
        rowsSelect = this.state.objSelect
        console.log(rowsub[idPosition].condition_list)
        rowsub[idPosition].condition_list.splice(idSub, 1)
        rowsSelect[idPosition].condition_list_select.splice(idSub, 1)
        console.log(rowsub)
        this.setState({ obj: rowsub, objSelect: rowsSelect })
        setTimeout(() => {
            console.log(this.state.obj)
        }, 500);
    }

    public handlechageAutocomplete(idx: number, value: any) {
        var rowsub: Array<Position> = [];
        rowsub = this.state.obj
        var blindPosiHeader: Array<Header> = [];
        blindPosiHeader = this.state.objHeader

            var HeaderSub: Header = {
                code: value[0].code,
                name: value[0].name,
                display: value[0].display
            }
            blindPosiHeader[idx] = HeaderSub
            this.setState({
            objHeader: blindPosiHeader
        })

        rowsub[idx].position_group_id = value[0].code
        rowsub[idx].position_group_name = value[0].name

        this.setState({ obj: rowsub })
    }

    public handlechageCondition(idx: number, idsub: any, value: any) {
        var obj_id: any = idsub + 1
        console.log(idx, idsub, value)
        var rowsub: Array<Position> = [];
        rowsub = this.state.obj
        var rowsSelect: Array<Models.PositionSelect> = []
        rowsSelect = this.state.objSelect

        rowsub[idx].condition_list[idsub]['parameter_code'] = value[0].conditionid
        rowsub[idx].condition_list[idsub]['parameter_name'] = value[0].conditionname
        rowsub[idx].condition_list[idsub]['obj_id'] = obj_id

        rowsSelect[idx].condition_list_select[idsub].conditionid = value[0].conditionid
        rowsSelect[idx].condition_list_select[idsub].conditionname = value[0].conditionname

        this.setState({ obj: rowsub, objSelect: rowsSelect })
        setTimeout(() => {
            console.log(this.state.obj)
        }, 500);
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { fixBugScreen, requestTask, responseSearch } = this.props
        console.log("requestTask :" + requestTask)
        if (requestTask == true) {
            this.props.blindTask(this.state.obj)
        } else if (responseSearch != undefined && this.state.CheckresponseSearch == true) {
            if (responseSearch['task_list']) {
                console.log(responseSearch['task_list'])
                var blindPosiSelect: Array<Models.PositionSelect> = [];
                blindPosiSelect = this.state.objSelect;
                var Header: Array<Header> = [];
                Header = this.state.objHeader;
                for (var a = 0; a < responseSearch['task_list'].length; a++) {
                   
                    var blindPosiSelectHost:any = [];
                    for (var s = 0; s < responseSearch['task_list'][a].condition_list.length; s++) {

                        var landingSubSelect: Models.SubPosiSelect = {
                            conditionid: responseSearch['task_list'][a].condition_list[s].parameter_code,
                            conditionname: responseSearch['task_list'][a].condition_list[s].parameter_name
                        }

                       blindPosiSelectHost.push(landingSubSelect)
                    }
                    var landibgPositionSelect: Models.PositionSelect = {
                        condition_list_select: blindPosiSelectHost as any
                    }

                    blindPosiSelect.push(landibgPositionSelect)      
                        var HeaderValue: Header = {
                            code: responseSearch['task_list'][a].position_group_id,
                            name: responseSearch['task_list'][a].position_group_name,
                            display: responseSearch['task_list'][a].position_group_id + " : " + responseSearch['task_list'][a].position_group_name
                        }
                        Header.push(HeaderValue)
                }
                this.setState({
                    objHeader: Header,
                    objSelect: blindPosiSelect,
                    obj: responseSearch['task_list'],
                    CheckresponseSearch: false
                })
            }
        }
    }

    componentWillUnmount() {

        this.setState({
            obj: [],
            objSelect: [],
            objHeader:[]
        })
    }

    render() {
        const { fixBugScreen, requestTask, responseSearch } = this.props
        if (fixBugScreen == true && this.state.fixBugPage == false) {
            this.props.switchPage("Reset")
            this.setState({ fixBugPage: true })
        }
        console.log(fixBugScreen)
        return (
            <Container fluid>
                <Row className='mt-3'>
                    <Col>
                        <Table className="table-bordered align-middle" hover size="sm">
                            <thead>
                                <tr className='text-center thead-light' style={{ width: '100%' }}>
                                    <th style={{ width: '3%' }}>#</th>
                                    <th style={{ width: '5%' }}>No.</th>
                                    <th style={{ width: '82%' }}>Authority Group</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.obj.map((item: Position, idx) => (
                                    <tr id="addr0" key={idx}>
                                        <td className='text-center'><Button className="mx-2" variant="danger" size="sm" onClick={this.handleRemoveSpecificPosition(idx)}><FaTrashAlt /></Button></td>
                                        <td className='text-center' colSpan={2}>
                                            <Table>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: '5%' }}>{idx + 1}</td>
                                                        <td style={{ width: '95%' }}>
                                                            {/* <FormControl
                                                                placeholder="Position name"
                                                                onChange={this.handleChangePosition(idx, 'position')}
                                                            /> */}
                                                            <AutcoCompleteWorkflowGroup handlechageAutocomplete={this.handlechageAutocomplete.bind(this, idx)} valueDataworkflow={this.state.objHeader[idx]} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <Table className="table-bordered align-middle">
                                                <thead>
                                                    <tr className='text-center' style={{ width: '100%' }}>
                                                        <th style={{ width: '5%' }}><Button className="mx-2" color="primary" size="sm" onClick={() => this.handleAddSub(idx)} ><FaPlusCircle /></Button></th>
                                                        <th style={{ width: '30%' }}>Parameter ID</th>
                                                        <th style={{ width: '30%' }}>Parameter Operator</th>
                                                        <th style={{ width: '35%' }}>Parameter Value</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {item.condition_list.map((item2: SubPosi, subid) => (
                                                        <tr id="sub0" key={subid + 1}>
                                                            <th style={{ width: '5%' }}><Button className="mx-2" variant="danger" size="sm" onClick={() => this.handleRemoveSpecificSub(idx, subid)}><FaTrashAlt /></Button></th>
                                                            <th style={{ width: '30%' }}>
                                                                {/* <FormControl
                                                                    placeholder="Parameter Code"
                                                                    value={item.condition_list[subid].parameter_code}
                                                                    onChange={this.handleChangeSub(idx, subid, 'Code')}
                                                                /> */}
                                                                <AutcoCompleteConditionID handlechageCondition={this.handlechageCondition.bind(this, idx, subid)} valueData={this.state.objSelect[idx]['condition_list_select'][subid]} />
                                                            </th>
                                                            <th style={{ width: '30%' }}>
                                                                <Form.Group>
                                                                    <Form.Control as="select" onChange={this.handleChangeSub(idx, subid, 'Operator')} value={item.condition_list[subid].parameter_operator}>
                                                                        <option hidden={true}>Please Select..</option>
                                                                        <option>{"="}</option>
                                                                        <option>{">"}</option>
                                                                        <option>{">="}</option>
                                                                        <option>{"<"}</option>
                                                                        <option>{"<="}</option>
                                                                        <option>{"<>"}</option>
                                                                    </Form.Control>
                                                                </Form.Group>
                                                            </th>
                                                            <th style={{ width: '35%' }}>
                                                                <FormControl
                                                                    placeholder="Parameter Value"
                                                                    value={item.condition_list[subid].parameter_value}
                                                                    onChange={this.handleChangeSub(idx, subid, 'Value')}
                                                                />
                                                            </th>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td className='text-center'><Button className="mx-2" color="success" size="sm" onClick={this.handleAddPosition}><FaPlusCircle /></Button></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        )
    }
}
export default TaskComponent
