import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/type/TypeMaster'

interface LoadTypeMaster {
    type: 'LOADTYPEMASTER'
    response: Models.ResponseTypeMaster
    status: Number,
    statusMessage: String
}
interface FailLoadTYPEMaster {
    type: 'LOADFAIL'
    status: number,
    statusMessage: any
}


type KnownAction = LoadTypeMaster | FailLoadTYPEMaster

export const actionCreators = {
    requestTypeMaster: (p: boolean, method: String): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.positionGroup && p && method == 'POST') {
            console.log('call actionCreators : requestTypeMaster')
            fetch(`/v1/Type/Master`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok && data['total_count'] == 0) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('errorrrr')
                            dispatch({ type: 'LOADFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            dispatch({ type: 'LOADTYPEMASTER', response: data as Models.ResponseTypeMaster, status: 200, statusMessage: '' });
                        })
                    }
                })
        }
    }
}

const unloadedState: Models.GetTypeMasterState = { isLoadTypeMaster: false };

export const reducer: Reducer<Models.GetTypeMasterState> = (state: Models.GetTypeMasterState | undefined, incomingAction: Action): Models.GetTypeMasterState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOADTYPEMASTER':
            return {
                isLoadTypeMaster: true,
                responseMaster: action.response,
                statusMaster: action.status
            }

        case 'LOADFAIL':
            return {
                isLoadTypeMaster: true,
                message: action.statusMessage,
                statusMaster: action.status
            }
        default: return state;
    }
}