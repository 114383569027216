/*import React from 'react';*/
import * as React from "react";
import { connect } from 'react-redux';
import { Button, Row, Col, Table, Spinner } from 'react-bootstrap';
import { FaFileExport } from 'react-icons/fa';
import * as XLSX from 'xlsx';

/*import WorkflowLogSearchIdTab from '../log/WorkflowLogSearchIdTab';*/
import WorkflowConditionSearch from '../workflowreport/WorkflowConditionSearch';
import { ApplicationState } from '../../store';

import { WorkflowConditionStateStore } from '../../models/workflowreport/WorkflowCondition';
import { actionCreators } from '../../store/workflowreport/WorkflowConditionConfigReport';

interface WorkflowConditionProps {
    workflowCondition: WorkflowConditionStateStore;
}

type Props = WorkflowConditionProps & typeof actionCreators;

interface State { }

class WorkflowConditionConfigReport extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        
        this.handleSearch = this.handleSearch.bind(this);
    }

    public handleSearch(sids: string[], wfid: string, wf_condition: string, isCheckAll: boolean): void {
        const { requestWorkflowCondition } = this.props;
        console.log(sids);
        if (isCheckAll) {
            sids = ['']
        }

        requestWorkflowCondition({
            method: 'GET',
            params: {
                sids,
                wfid: wfid,
                wf_condition: wf_condition
            }
        });
    }

    ExportTemplate = (Mode) => {
        const { response } = this.props.workflowCondition;
        var date = new Date();
        var filename = 'WorkflowConditionReport_' + date.getTime().toString() + '.xlsx';
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        var formDataReq: any = [];
        if (response && response.result_list) {
            response.result_list.map((condition) => {
                let data = {}
                data["sidname"] = condition.sid_name;
                data["wfid"] = condition.wfid;
                data["wfname"] = condition.wfname;
                data["wfcondition"] = condition.wf_condition;
                data["taskid"] = condition.taskid;
                data["taskcondition"] = condition.task_condition;
                data["authgroup"] = condition.authgroup;
                formDataReq.push(data);
            })
            var ws_Company = XLSX.utils.json_to_sheet(formDataReq);
            var wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws_Company, "WorwflowCondition");
            XLSX.writeFile(wb, filename);
        }
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
    }

    public render(): React.ReactNode {
        const { isLoading, response, status } = this.props.workflowCondition;
        
        return (
            <div className="master-data-content">
                <Row>
                    <Col>
                        <div className='trapezoid-head' style={{ width: '330px' }}>
                            <span>
                                Workflow Condition Configuration Report
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <WorkflowConditionSearch
                            handleSearch={this.handleSearch}
                            isFetching={isLoading}
                            canNullDocNo={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{ textAlign: 'right', paddingTop: '10px' }}>
                        <Button className="mx-1" variant="outline-success" size="sm" style={{ display: '' }} onClick={() => this.ExportTemplate("exportEmployee")}><FaFileExport />&nbsp;Export Excel</Button>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <div style={{ overflowX: 'scroll' }}>
                            <Table className="table-bordered align-middle table-responsive-sm">
                                <thead >
                                    <tr className='text-center table-active thead-light text-nowrap' style={{ width: '100%' }}>
                                        <th>SID Name</th>
                                        <th>WF ID</th>
                                        <th>WF Name</th>
                                        <th>WF Condition</th>
                                        <th>Task ID</th>
                                        <th>Task Condition</th>
                                        <th>AuthGroup</th>
                                    </tr>
                                </thead>
                                {
                                    isLoading
                                    && (
                                        <tbody>
                                            <tr>
                                                <td
                                                    colSpan={11}
                                                    style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}
                                                >
                                                    <Spinner animation="border" size="sm" />
                                                    &nbsp;&nbsp;Loading...
                                                </td>
                                            </tr>
                                        </tbody>
                                    )
                                }

                                {
                                    !isLoading
                                    && (status === 200)
                                    && (response === null || response.result_list === null)
                                    && (
                                        <tbody>
                                            <tr>
                                                <td
                                                    colSpan={11}
                                                    style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}
                                                >
                                                    &nbsp;&nbsp;No Data...
                                                </td>
                                            </tr>
                                        </tbody>
                                    )
                                }

                                <tbody>
                                    {
                                        !isLoading
                                        && (status === 200)
                                        && (response !== null)
                                        && (response.result_list !== null)
                                        && (
                                            response.result_list.map((condition, index) => (
                                                <tr key={index}>
                                                    <td style={{ whiteSpace: 'normal' }}>
                                                        <div style={{ width: '80px' }}>
                                                            {condition.sid_name}
                                                        </div>
                                                    </td>
                                                    <td style={{ whiteSpace: 'normal' }}>
                                                        <div style={{ width: '100px' }}>
                                                            {condition.wfid}
                                                        </div>
                                                    </td>
                                                    <td style={{ whiteSpace: 'normal' }}>
                                                        <div style={{ width: '100px' }}>
                                                            {condition.wfname}
                                                        </div>
                                                    </td>
                                                    <td style={{ whiteSpace: 'normal' }}>
                                                        <div style={{ width: '200px' }}>
                                                            {condition.wf_condition}
                                                        </div>
                                                    </td>
                                                    <td style={{ whiteSpace: 'normal' }}>{condition.taskid}</td>
                                                    <td style={{ whiteSpace: 'normal' }}>
                                                        <div style={{ width: '100px' }}>
                                                            {condition.task_condition}
                                                        </div>
                                                    </td>
                                                    <td style={{ whiteSpace: 'normal' }}>
                                                        <div style={{ width: '100px' }}>
                                                            {condition.authgroup}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        )
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState, ownProps: Props) => ({ ...ownProps, workflowCondition: state.workflowCondition }),
    actionCreators
)(WorkflowConditionConfigReport as any);