import * as React from "react";
import { connect } from 'react-redux';
import { Row, Col, Table, Spinner, Button, Form } from 'react-bootstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import { FaPlusCircle, FaFileDownload } from 'react-icons/fa';
import Swal from 'sweetalert2';

import SimulateWorkflowSearch from '../simulateworkflow/SimulateWorkflowSearch';
import { ApplicationState } from '../../store';

import { SimulateWorkflowStateStore } from '../../models/simulateworkflow/SimulateWorkflow';
import { actionCreators } from '../../store/simulateworkflow/SimulateWorkflow';

import * as CallbackConfigMasterStore from '../../store/callbackconfig/CallbackMaster'
import * as ModelsCallbackConfigMaster from '../../models/callbackconfig/CallbackMaster'

import * as CallbackTokenStore from '../../store/callbackconfig/CallbackTokens'
import * as ModelsCallbackTokens from '../../models/callbackconfig/CallbackTokens'

import * as CreateCallbackStore from '../../store/callbackconfig/CreateCallback'
import * as ModelsCreateCallback from '../../models/callbackconfig/CreateCallback'

import * as AppState from '../../store/AppState'
import * as ModelsAppState from '../../models/AppState'

interface SimulateWorkflowProps {
    simulateWorkflow: SimulateWorkflowStateStore;
}

type CallbackConfigProps = ModelsCallbackConfigMaster.CallbackMasterState & typeof CallbackConfigMasterStore.actionCreators;
type CallbackTokenProps = ModelsCallbackTokens.CallbackTokenState & typeof CallbackTokenStore.actionCreators;
type CreateCallbackProps = ModelsCreateCallback.SaveCallbackState & typeof CreateCallbackStore.actionCreators;
type AppStateProps = ModelsAppState.AppState & typeof AppState.actionCreators
type Props = SimulateWorkflowProps & CallbackConfigProps & CallbackTokenProps & CreateCallbackProps & AppStateProps & typeof actionCreators;
// type Props = SimulateWorkflowProps & typeof actionCreators;

interface State {
    sid: string,
    token: string,
    company?: string
    checkRequest: boolean,
    checkResponse: boolean,
    callbackResponse: Array<any>
    selectedCriteria: string
    optionCriteria: Array<any>
    openModalSelectCriteria: boolean
    openModalChangeCriteriaName: boolean
    openModalAdd: boolean
    criteriaName: string
    criteriaParam: Array<any>
    importTemplateJson: Array<any>
    isImport: boolean
}

class SimulateWorkflow extends React.Component<Props & any, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            sid: "",
            token: "",
            company: undefined,
            checkRequest: true,
            checkResponse: true,
            callbackResponse: [],
            selectedCriteria: "",
            optionCriteria: [],
            openModalSelectCriteria: false,
            openModalChangeCriteriaName: false,
            openModalAdd: false,
            criteriaName: "",
            criteriaParam: [],
            importTemplateJson: [],
            isImport: false,
        }
        this.handleSearch = this.handleSearch.bind(this);
        this.updateParamsInput = this.updateParamsInput.bind(this);
    }

    public handleSearch(sids: string[], param1: string[], param2: string[]): void {
        this.props.requestSimulateWorkflow({
            method: 'GET',
            params: {
                sids: [this.state.sid],
                token: [this.state.token],
                param1,
                param2
            }
        })
    }

    componentDidMount() {
        this.props.updateUrl(this.props.location.pathname)
        const company = this.props.company

        if (company !== this.state.company && this.state.checkRequest) {
            if (company !== "") {
                this.props.requestCallbackMaster(true, "POST", company!)
            }

            this.setState({
                company: company,
                checkRequest: false,
            });
        }
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { CallbackisLoad, Callbackstatus, Callbackresponse } = this.props
        const company = this.props.company

        if (company !== this.state.company) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";

            this.props.requestCallbackMaster(true, "CLEAR", this.state.company!)

            this.setState({
                company: company,
                checkResponse: true,
                callbackResponse: []
            });

            setTimeout(() => {
                if (company) {
                    this.props.requestCallbackMaster(true, "POST", company)
                }
            }, 1000);
        }

        if (CallbackisLoad && Callbackstatus === 200 && this.state.checkResponse && Callbackresponse!['total_count'] !== 0) {
            console.log("------ SET DATATABLE --------")

            this.setState({
                checkResponse: false,
                callbackResponse: Callbackresponse!['result_list'],
            })

            //this.props.requestCallbackMaster(true, "CLEAR", this.state.company)

            const { result_list } = Callbackresponse
            const [{ app_id, token }] = result_list

            this.setState({ sid: app_id })
            this.setState({ token: token })

            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
        }

        if (nextState.sid !== this.state.sid && nextState.token !== this.state.token) {
            this.refreshCriteriaTemplate()
        }

        const { SimulateWorkflow, SimulateWorkflowCriteria, AddCriteria, EditCriteria, DeleteCriteria } = this.props.simulateWorkflow;

        if (!SimulateWorkflow.isLoading && SimulateWorkflow.error !== null && SimulateWorkflow.status === 400) {
            Swal.fire({
                title: 'เกิดข้อผิดพลาด',
                text: SimulateWorkflow.error.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestSimulateWorkflow({ method: 'CLEAR', params: [] })
                }
            })
        }

        if (!SimulateWorkflowCriteria.isLoading && SimulateWorkflowCriteria.error !== null && SimulateWorkflowCriteria.status === 400) {
            Swal.fire({
                title: 'เกิดข้อผิดพลาด',
                text: SimulateWorkflowCriteria.error.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestSimulateWorkflowCriteria({ method: 'CLEAR', params: [] })
                }
            })
        }

        if (AddCriteria.isLoadAdd && AddCriteria.statusAdd === 200) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";

            Swal.fire({
                title: 'สำเร็จ!',
                text: 'บันทึกข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.setState({ openModalAdd: false })

                    this.props.requestAddSimulateWorkflowCriteria({
                        method: 'CLEAR',
                        params: []
                    })

                    this.refreshCriteriaTemplate()
                }
            })
        }

        if (AddCriteria.isLoadAdd && AddCriteria.statusAdd === 400) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";

            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: AddCriteria.response,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestAddSimulateWorkflowCriteria({
                        method: 'CLEAR',
                        params: []
                    })
                }
            })
        }

        if (EditCriteria.isLoadEdit && EditCriteria.statusEdit === 200) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";

            Swal.fire({
                title: 'สำเร็จ!',
                text: 'บันทึกข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.setState({ openModalChangeCriteriaName: false })

                    this.props.requestEditSimulateWorkflowCriteria({
                        method: 'CLEAR',
                        params: []
                    })

                    this.refreshCriteriaTemplate()
                }
            })
        }

        if (EditCriteria.isLoadEdit && EditCriteria.statusEdit === 400) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";

            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: AddCriteria.response,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestEditSimulateWorkflowCriteria({
                        method: 'CLEAR',
                        params: []
                    })
                }
            })
        }

        if (DeleteCriteria.isLoadDelete && DeleteCriteria.statusDelete === 200) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";

            Swal.fire({
                title: 'สำเร็จ!',
                text: 'ลบข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestDeleteSimulateWorkflowCriteria({
                        method: 'CLEAR',
                        params: []
                    })

                    this.refreshCriteriaTemplate()
                }
            })
        }

        if (DeleteCriteria.isLoadDelete && DeleteCriteria.statusDelete === 400) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";

            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: DeleteCriteria.response,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestDeleteSimulateWorkflowCriteria({
                        method: 'CLEAR',
                        params: []
                    })
                }
            })
        }

        if (SimulateWorkflowCriteria.response && SimulateWorkflowCriteria.response.result_list?.length > 0) {
            if (nextState.optionCriteria !== SimulateWorkflowCriteria.response.result_list) {
                this.setState({ optionCriteria: SimulateWorkflowCriteria.response.result_list })
            }
        }
    }

    refreshCriteriaTemplate = () => {
        this.props.requestSimulateWorkflowCriteria({
            method: 'GET',
            params: {
                sid: this.state.sid,
                token: this.state.token,
            }
        })
    }

    updateParamsInput(inputParams: Array<Object>) {
        this.setState({ criteriaParam: inputParams });
    }

    onOpenModalSelectCriteria = () => {
        this.setState({ openModalSelectCriteria: !this.state.openModalSelectCriteria })
    }

    handleDisplayTemplate = () => {
        if (!this.state.selectedCriteria || !this.state.selectedCriteria.trim()) {
            return Swal.fire({
                title: 'โปรดเลือก Cirteria Template ให้ถูกต้อง',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false
            })
        }

        const optionResult = this.state.optionCriteria
            .filter((value) => value.criteria_code === this.state.selectedCriteria)
            .reduce((acc, obj) => acc.concat(JSON.parse(obj.criteria_param)), []);

        this.setState({ importTemplateJson: optionResult })
        this.setState({ isImport: true })
        this.setState({ openModalSelectCriteria: !this.state.openModalSelectCriteria })
        this.setState({ selectedCriteria: "" })
    }

    onOpenModalAdd = () => {
        if (this.state.openModalAdd === true) {
            this.setState({ openModalAdd: false })
        } else if (this.state.openModalAdd === false) {
            this.setState({ openModalAdd: true })
        }
        if (this.state.criteriaParam.length === 0) {
            this.setState({ criteriaParam: this.state.importTemplateJson })
        }
    }

    handleAddCriteria = () => {
        if (this.state.criteriaParam.length === 0 || !this.state.criteriaName || !this.state.criteriaName.trim()) {
            return Swal.fire({
                title: 'โปรดกรอก Param ให้ถูกต้อง',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false
            })
        }

        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";

        this.props.requestAddSimulateWorkflowCriteria({
            method: 'POST',
            params: {
                sid: this.state.sid,
                token: this.state.token,
                criteriaName: this.state.criteriaName,
                criteriaParam: this.state.criteriaParam
            }
        })

        this.setState({ criteriaName: "" })
    }

    handleChecked = (event: React.ChangeEvent<HTMLInputElement>, criteriaCode: string) => {
        this.setState({ selectedCriteria: criteriaCode })
    }

    onOpenModalChangeCriteriaName = () => {
        this.setState({ openModalChangeCriteriaName: !this.state.openModalChangeCriteriaName })
    }

    handleChangeCriteriaName = (criteriaCode: string) => {
        this.setState({ selectedCriteria: criteriaCode })
        this.setState({ openModalChangeCriteriaName: true })
    }

    handleSaveChangeCriteriaName = () => {
        if (!this.state.criteriaName || !this.state.criteriaName.trim()) {
            return Swal.fire({
                title: 'โปรดกรอก Criteria Name ให้ถูกต้อง',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false
            })
        }

        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";

        this.props.requestEditSimulateWorkflowCriteria({
            method: 'PUT',
            params: {
                sid: this.state.sid,
                token: this.state.token,
                criteriaCode: this.state.selectedCriteria,
                criteriaName: this.state.criteriaName,
            }
        })

        this.setState({ criteriaName: '' })
    }

    handleDeleteCriteriaTemplate = (criteriaCode: string) => {
        Swal.fire({
            title: 'ลบข้อมูล ?',
            text: 'คุณต้องการลบหรือไม่ ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ไม่'
        }).then((result) => {
            if (result.isConfirmed) {
                document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";

                this.props.requestDeleteSimulateWorkflowCriteria({
                    method: 'DELETE',
                    params: {
                        sid: this.state.sid,
                        token: this.state.token,
                        criteriaCode,
                    }
                })
            }
        })
    }

    public render(): React.ReactNode {
        const { SimulateWorkflow } = this.props.simulateWorkflow;
        return (
            <div className="master-data-content">
                <Row>
                    <Col className="import-export-actions">
                        <div className='trapezoid-head' style={{ width: '180px' }}>
                            <span>
                                Simulate Workflow
                            </span>
                        </div>
                        <div className="d-none d-lg-block">
                            <Button className="mx-1" variant="outline-success" size="sm" style={{ display: '' }} onClick={() => this.onOpenModalSelectCriteria()}><FaFileDownload />&nbsp;Criteria Template</Button>
                            <Button className="mx-1" variant="outline-info" size="sm" onClick={() => this.onOpenModalAdd()}><FaPlusCircle />&nbsp;Create Criteria Template</Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <SimulateWorkflowSearch
                            simulatehandleSearch={this.handleSearch}
                            isFetching={SimulateWorkflow.isLoading}
                            canNullDocNo={true}
                            updateParamsInput={this.updateParamsInput}
                            importTemplateJson={this.state.importTemplateJson}
                            isImport={this.state.isImport}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col style={{ overflow: 'hidden' }}>
                        <div style={{ overflowX: 'scroll' }}>
                            <Table className="table-bordered table-hover align-middle table-responsive-sm">
                                <thead >
                                    <tr className='text-center table-active thead-light text-nowrap' style={{ width: '100%' }}>
                                        <th>WF ID</th>
                                        <th>Task ID</th>
                                        <th>DocNo</th>
                                        <th>Positiongroup ID</th>
                                        <th>Emp ID</th>
                                        <th>Emp Name</th>
                                    </tr>
                                </thead>
                                {
                                    SimulateWorkflow.isLoading
                                    && (
                                        <tbody>
                                            <tr>
                                                <td colSpan={11} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}>
                                                    <Spinner animation="border" size="sm" />
                                                    &nbsp;&nbsp;Loading...
                                                </td>
                                            </tr>
                                        </tbody>
                                    )
                                }
                                {
                                    !SimulateWorkflow.isLoading
                                    && (SimulateWorkflow.status === 0)
                                    && (SimulateWorkflow.response === null || SimulateWorkflow.response.result_list === null)
                                    && (
                                        <tbody>
                                            <tr>
                                                <td
                                                    colSpan={11}
                                                    style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}
                                                >
                                                    &nbsp;&nbsp;No Data...
                                                </td>
                                            </tr>
                                        </tbody>
                                    )
                                }
                                <tbody>
                                    {
                                        !SimulateWorkflow.isLoading
                                        && (SimulateWorkflow.status === 200)
                                        && (SimulateWorkflow.response !== null)
                                        && (SimulateWorkflow.response.result_list !== null)
                                        && (
                                            SimulateWorkflow.response.result_list.map((simulate, index) => (
                                                <tr key={index}>
                                                    <td>{simulate.wfid}</td>
                                                    <td>{simulate.taskid}</td>
                                                    <td>{simulate.docno}</td>
                                                    <td>{simulate.positiongroupid}</td>
                                                    <td>{simulate.empid}</td>
                                                    <td>{simulate.empname}</td>
                                                </tr>
                                            ))
                                        )
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>

                <Modal isOpen={this.state.openModalSelectCriteria} toggle={() => this.onOpenModalSelectCriteria()} size="md">
                    <ModalHeader toggle={() => this.onOpenModalSelectCriteria()}>Select Criteria Template</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <Table className="table-bordered table-responsive-md">
                                    <thead>
                                        <tr>
                                            <th className="text-center" scope="col">
                                                Name Criteria Template
                                            </th>
                                            <th className="text-center" scope="col">Manage Criteria</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.optionCriteria
                                            && this.state.optionCriteria.map((data, index) => (
                                                <tr key={index}>
                                                    <td className="align-middle">
                                                        <Form.Check
                                                            label={data.criteria_name}
                                                            type="checkbox"
                                                            className="align-center"
                                                            checked={this.state.selectedCriteria === data.criteria_code}
                                                            onChange={(event) => this.handleChecked(event, data.criteria_code)}
                                                        />
                                                    </td>
                                                    <td className="text-center">
                                                        <div>
                                                            <Button className="mx-1" variant="outline-warning" onClick={() => this.handleChangeCriteriaName(data.criteria_code)}>Change</Button>
                                                            <Button className="mx-1" variant="outline-danger" onClick={() => this.handleDeleteCriteriaTemplate(data.criteria_code)}>Delete</Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="outline-primary" onClick={() => this.handleDisplayTemplate()}>Save</Button>
                        <Button variant="outline-danger" onClick={() => this.onOpenModalSelectCriteria()}>Cancel</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.openModalChangeCriteriaName} toggle={() => this.onOpenModalChangeCriteriaName()} size="md">
                    <ModalHeader toggle={() => this.onOpenModalChangeCriteriaName()}>Update Name : Criteria Template</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col sm={11}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    {/* <Form.Label>Criteria Name :</Form.Label> */}
                                    <Form.Control type="text" size='sm' onChange={(e) => this.setState({ criteriaName: e.target.value })} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="outline-primary" onClick={() => this.handleSaveChangeCriteriaName()}>Save</Button>
                        <Button variant="outline-danger" onClick={() => this.onOpenModalChangeCriteriaName()}>Cancel</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.openModalAdd} toggle={() => this.onOpenModalAdd()} size="md">
                    <ModalHeader toggle={() => this.onOpenModalAdd()}>Criteria Template</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control type="text" size='sm' placeholder="ตั้งชื่อ Criteria Template Name" onChange={(e) => this.setState({ criteriaName: e.target.value })} />
                                    <div className="create-criteria text-white mt-2 mx-3 py-1">
                                        รายการที่ต้องการสร้าง Criteria
                                    </div>
                                    <div className="mt-2 text-center" id="create_criteria_select">
                                        {this.state.criteriaParam.map((item, index) => (
                                            <div key={index}>
                                                <span className="text-primary">{item.param1}</span>
                                                <span> : </span>
                                                <span>{item.param2}</span>
                                            </div>
                                        ))}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="outline-primary" onClick={() => this.handleAddCriteria()}>Save</Button>
                        <Button variant="outline-danger" onClick={() => this.onOpenModalAdd()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState, ownProps: Props) => ({ ...ownProps, simulateWorkflow: state.simulateWorkflow, ...state.appState, ...state.callbackConfig, ...state.callbackTokens, ...state.createCallback }),
    { ...actionCreators, ...CallbackConfigMasterStore.actionCreators, ...CallbackTokenStore.actionCreators, ...CreateCallbackStore.actionCreators, ...AppState.actionCreators }
)(SimulateWorkflow as any);