import { Action, Reducer, combineReducers } from 'redux';
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/callbackconfig/MasterEmployee'

// #region interface GetMasterEmployee
interface BeforeLoadMasterEmployee {
    type: 'LOAD_MASTEREMPLOYEE'
}
interface LoadMasterEmployee {
    type: 'LOADEDMASTEREMPLOYEE'
    response: Models.resMasterEmployee
    status: number,
    statusMessage: String
}
interface FailMasterEmployee {
    type: 'LOADMASTEREMPLOYEEFAIL'
    status: number,
    statusMessage: any
}
interface ClearLoadMasterEmployee {
    type: 'CLEARLOADMASTEREMPLOYEE'
}
// #endregion

// #region interface DeleteMasterEmployee
interface BeforeDeleteMasterEmployee {
    type: 'DELETE_MASTEREMPLOYEE'
}
interface DeleteMasterEmployee {
    type: 'DELETEDMASTEREMPLOYEE'
    response: string
    status: number,
    statusMessage: String
}
interface DeleteFailMasterEmployee {
    type: 'DELETEMASTEREMPLOYEEFAIL'
    status: number,
    statusMessage: any
}
interface ClearDeleteMasterEmployee {
    type: 'CLEARDELETEMASTEREMPLOYEE'
}
// #endregion

// #region interface EditMasterEmployee
interface BeforeEditMasterEmployee {
    type: 'EDIT_MASTEREMPLOYEE'
}
interface EditMasterEmployee {
    type: 'EDITDMASTEREMPLOYEE'
    response: string
    status: number,
    statusMessage: String
}
interface EditFailMasterEmployee {
    type: 'EDITMASTEREMPLOYEEFAIL'
    status: number,
    statusMessage: any
}
interface ClearEditMasterEmployee {
    type: 'CLEAREDITMASTEREMPLOYEE'
}
// #endregion

// #region interface SearchMasterEmployee
interface BeforeSearchMasterEmployee {
    type: 'SEARCH_MASTEREMPLOYEE'
}
interface SearchMasterEmployee {
    type: 'SEARCHDMASTEREMPLOYEE'
    response: Models.resMasterEmployee
    status: number,
    statusMessage: String
}
interface SearchFailMasterEmployee {
    type: 'SEARCHMASTEREMPLOYEEFAIL'
    status: number,
    statusMessage: any
}
interface ClearSearchMasterEmployee {
    type: 'CLEARSEARCHMASTEREMPLOYEE'
}
// #endregion

// #region interface AddMasterEmployee
interface BeforeAddMasterEmployee {
    type: 'ADD_MASTEREMPLOYEE'
}
interface AddMasterEmployee {
    type: 'ADDDMASTEREMPLOYEE'
    response: string
    status: number,
    statusMessage: String
}
interface AddFailMasterEmployee {
    type: 'ADDMASTEREMPLOYEEFAIL'
    status: number,
    statusMessage: any
}
interface ClearAddMasterEmployee {
    type: 'CLEARADDMASTEREMPLOYEE'
}
// #endregion

// #region interface AddImportMasterEmployee
interface BeforeAddImportMasterEmployee {
    type: 'ADD_IMPORTMASTEREMPLOYEE'
}
interface AddImportMasterEmployee {
    type: 'ADDIMPORTMASTEREMPLOYEE'
    response: string
    status: number,
    statusMessage: String
}
interface AddFailImportMasterEmployee {
    type: 'ADDIMPORTMASTEREMPLOYEEFAIL'
    status: number,
    statusMessage: any
}
interface ClearAddImportMasterEmployee {
    type: 'CLEARADDIMPORTMASTEREMPLOYEE'
}
// #endregion

// #region interface getListEmployeeFromFile
interface BeforeListExcelMasterEmployee {
    type: 'BEFORE_LISTEXCELMASTEREMPLOYEE'
}
interface ListExcelMasterEmployee {
    type: 'LISTEXCELMASTEREMPLOYEE'
    response: Models.ExcelListemployee,
    status: number,
    statusMessage: String
}
interface FailListExcelMasterEmployee {
    type: 'LISTEXCELMASTEREMPLOYEEFAIL'
    status: number,
    statusMessage: any
}
interface ClearListExcelMasterEmployee {
    type: 'CLEARLISTEXCELMASTEREMPLOYEE'
}
// #endregion

type KnownAction = BeforeListExcelMasterEmployee | ListExcelMasterEmployee | FailListExcelMasterEmployee | ClearListExcelMasterEmployee| BeforeAddImportMasterEmployee | AddImportMasterEmployee | AddFailImportMasterEmployee | ClearAddImportMasterEmployee |  LoadMasterEmployee | FailMasterEmployee | BeforeLoadMasterEmployee | ClearLoadMasterEmployee | BeforeDeleteMasterEmployee | DeleteMasterEmployee | DeleteFailMasterEmployee | ClearDeleteMasterEmployee | BeforeEditMasterEmployee | EditMasterEmployee | EditFailMasterEmployee | ClearEditMasterEmployee | BeforeSearchMasterEmployee | SearchMasterEmployee | SearchFailMasterEmployee | ClearSearchMasterEmployee | BeforeAddMasterEmployee | AddMasterEmployee | AddFailMasterEmployee | ClearAddMasterEmployee

export const actionCreators = {
    requestGetMasterEmployee: (p: boolean, method: string, empid: string, sid: string, empname: string, page: string, page_size: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestGetMasterEmployee')
        if (appState && appState.positionEmployee && p && method == 'GET') {
            await fetch(`/v1/configuration/MasterEmployee?page=` + page + '&page_size=' + page_size + '&empid=' + empid + '&sid=' + sid + '&empname=' + empname, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('dispatch : LOADMASTEREMPLOYEEFAIL')
                            dispatch({ type: 'LOADMASTEREMPLOYEEFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            console.log('dispatch : LOADEDMASTEREMPLOYEE')
                            dispatch({ type: 'LOADEDMASTEREMPLOYEE', response: data as Models.resMasterEmployee, status: 200, statusMessage: '' });
                        })
                    }
                })
            dispatch({ type: 'LOAD_MASTEREMPLOYEE' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARLOADMASTEREMPLOYEE' });
        }
    },

    requestDeleteMasterEmployee: (p: boolean, method: string, empid: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestDeleteMasterEmployee')
        if (appState && appState.positionEmployee && p && method == 'DELETE') {
            await fetch(`/v1/configuration/MasterEmployee?empid=` + empid, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    console.log(data.ok)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('dispatch : DELETEMASTEREMPLOYEEFAIL')
                            dispatch({ type: 'DELETEMASTEREMPLOYEEFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        //data.json().then(data => {
                        //console.log(data)
                        console.log('dispatch : DELETEDMASTEREMPLOYEE')
                        dispatch({ type: 'DELETEDMASTEREMPLOYEE', response: 'success', status: 200, statusMessage: '' });
                        //})
                    }
                })
            dispatch({ type: 'DELETE_MASTEREMPLOYEE' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARDELETEMASTEREMPLOYEE' });
        }
    },

    requestEditMasterEmployee: (p: boolean, method: string, user_id: string, empid: string, formData: Models.listMasterEmployee): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestEditMasterEmployee')
        if (appState && appState.positionEmployee && p && method == 'PUT') {
            await fetch(`/v1/configuration/MasterEmployee?empid=` + empid + '&user_id=' + user_id, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: JSON.stringify(formData)
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('dispatch : EDITMASTEREMPLOYEEFAIL')
                            dispatch({ type: 'EDITMASTEREMPLOYEEFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        //data.json().then(data => {
                        //console.log(data)
                        console.log('dispatch : EDITDMASTEREMPLOYEE')
                        dispatch({ type: 'EDITDMASTEREMPLOYEE', response: 'success', status: 200, statusMessage: '' });
                        //})
                    }
                })
            dispatch({ type: 'EDIT_MASTEREMPLOYEE' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEAREDITMASTEREMPLOYEE' });
        }
    },

    requestSearchMasterEmployee: (p: boolean, method: string, empid: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestSearchMasterEmployee')
        if (appState && appState.positionEmployee && p && method == 'GET') {
            await fetch(`/v1/configuration/MasterEmployeeEdit?empid=` + empid, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('dispatch : SEARCHMASTEREMPLOYEEFAIL')
                            dispatch({ type: 'SEARCHMASTEREMPLOYEEFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            console.log('dispatch : SEARCHDMASTEREMPLOYEE')
                            dispatch({ type: 'SEARCHDMASTEREMPLOYEE', response: data as Models.resMasterEmployee, status: 200, statusMessage: '' });
                        })
                    }
                })
            dispatch({ type: 'SEARCH_MASTEREMPLOYEE' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARSEARCHMASTEREMPLOYEE' });
        }
    },

    requestAddMasterEmployee: (p: boolean, method: string, user_id: string, formData: Models.listMasterEmployee): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestAddMasterEmployee')
        if (appState && appState.positionEmployee && p && method == 'POST') {
            await fetch(`/v1/configuration/MasterEmployee?user_id=` + user_id, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: JSON.stringify(formData)
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('dispatch : EDITMASTEREMPLOYEEFAIL')
                            dispatch({ type: 'ADDMASTEREMPLOYEEFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        //data.json().then(data => {
                        //console.log(data)
                        console.log('dispatch : EDITDMASTEREMPLOYEE')
                        dispatch({ type: 'ADDDMASTEREMPLOYEE', response: 'success', status: 200, statusMessage: '' });
                        //})
                    }
                })
            dispatch({ type: 'ADD_MASTEREMPLOYEE' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARADDMASTEREMPLOYEE' });
        }
    },

    requestAddImportMasterEmployee: (p: boolean, method: string, formData: Models.employeeListAdd): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.positionEmployee && p && method == 'POST') {
            await fetch(`/v1/configuration/ImportEmployee`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: JSON.stringify(formData)
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            dispatch({ type: 'ADDIMPORTMASTEREMPLOYEEFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        dispatch({ type: 'ADDIMPORTMASTEREMPLOYEE', response: 'success', status: 200, statusMessage: '' });
                    }
                })
            dispatch({ type: 'ADD_IMPORTMASTEREMPLOYEE' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARADDIMPORTMASTEREMPLOYEE' });
        }
    },

    requestListExcelMasterEmployee: (p: boolean, method: string, File : any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.positionEmployee && p && method == 'POST') {

            var formData = new FormData();
            formData.append('file', File);
            await fetch("/v1/configuration/upload-excell",
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION')
                    },
                    body: formData
                }
            )
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            dispatch({ type: 'LISTEXCELMASTEREMPLOYEEFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            dispatch({ type: 'LISTEXCELMASTEREMPLOYEE', response: data as Models.ExcelListemployee, status: 200, statusMessage: '' });
                        })
                    }
                })
            dispatch({ type: 'BEFORE_LISTEXCELMASTEREMPLOYEE' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARLISTEXCELMASTEREMPLOYEE' });
        }
    },
}

const unloadedState: Models.GetMasterEmployeeState = { isLaoadGetMasterEmployee: false };
const unloadedDeleteState: Models.DeleteMasterEmployeeState = { isLaoadDeleteMasterEmployee: false };
const unloadedEditState: Models.EditMasterEmployeeState = { isLaoadEditMasterEmployee: false };
const unloadedSearchState: Models.SearchMasterEmployeeState = { isLaoadSearchMasterEmployee: false };
const unloadedAddState: Models.AddMasterEmployeeState = { isLaoadAddMasterEmployee: false };
const unloadedAddImportState: Models.AddImportMasterEmployeeState = { isLaoadAddImportMasterEmployee: false };
const unloadedListExcelState: Models.ListExcelMasterEmployeeState = { isLaoadListExcelMasterEmployee: false };

const reducerGet: Reducer<Models.GetMasterEmployeeState> = (state: Models.GetMasterEmployeeState | undefined, incomingAction: Action): Models.GetMasterEmployeeState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_MASTEREMPLOYEE':
            return {
                isLaoadGetMasterEmployee: false,
            }

        case 'LOADEDMASTEREMPLOYEE':
            return {
                isLaoadGetMasterEmployee: true,
                responseGetMasterEmployee: action.response,
                statusGetMasterEmployee: action.status
            }

        case 'LOADMASTEREMPLOYEEFAIL':
            return {
                isLaoadGetMasterEmployee: true,
                messageGetMasterEmployee: action.statusMessage,
                statusGetMasterEmployee: action.status
            }

        case 'CLEARLOADMASTEREMPLOYEE':
            return {
                isLaoadGetMasterEmployee: false,
                messageGetMasterEmployee: '',
                statusGetMasterEmployee: 400
            }
        default: return state;
    }
}

const reducerDelete: Reducer<Models.DeleteMasterEmployeeState> = (state: Models.DeleteMasterEmployeeState | undefined, incomingAction: Action): Models.DeleteMasterEmployeeState => {
    if (state == undefined) {
        return unloadedDeleteState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'DELETE_MASTEREMPLOYEE':
            return {
                isLaoadDeleteMasterEmployee: false,
            }

        case 'DELETEDMASTEREMPLOYEE':
            return {
                isLaoadDeleteMasterEmployee: true,
                //responseDeleteMasterEmployee: action.response,
                statusDeleteMasterEmployee: action.status
            }

        case 'DELETEMASTEREMPLOYEEFAIL':
            return {
                isLaoadDeleteMasterEmployee: true,
                messageDeleteMasterEmployee: action.statusMessage,
                statusDeleteMasterEmployee: action.status
            }

        case 'CLEARDELETEMASTEREMPLOYEE':
            return {
                isLaoadDeleteMasterEmployee: false,
                messageDeleteMasterEmployee: '',
                statusDeleteMasterEmployee: 400
            }
        default: return state;
    }
}

const reducerEdit: Reducer<Models.EditMasterEmployeeState> = (state: Models.EditMasterEmployeeState | undefined, incomingAction: Action): Models.EditMasterEmployeeState => {
    if (state == undefined) {
        return unloadedEditState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'EDIT_MASTEREMPLOYEE':
            return {
                isLaoadEditMasterEmployee: false,
            }

        case 'EDITDMASTEREMPLOYEE':
            return {
                isLaoadEditMasterEmployee: true,
                //responseDeleteMasterEmployee: action.response,
                statusEditMasterEmployee: action.status
            }

        case 'EDITMASTEREMPLOYEEFAIL':
            return {
                isLaoadEditMasterEmployee: true,
                messageEditMasterEmployee: action.statusMessage,
                statusEditMasterEmployee: action.status
            }

        case 'CLEAREDITMASTEREMPLOYEE':
            return {
                isLaoadEditMasterEmployee: false,
                messageEditMasterEmployee: '',
                statusEditMasterEmployee: 400
            }
        default: return state;
    }
}

const reducerSearch: Reducer<Models.SearchMasterEmployeeState> = (state: Models.SearchMasterEmployeeState | undefined, incomingAction: Action): Models.SearchMasterEmployeeState => {
    if (state == undefined) {
        return unloadedSearchState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SEARCH_MASTEREMPLOYEE':
            return {
                isLaoadSearchMasterEmployee: false,
            }

        case 'SEARCHDMASTEREMPLOYEE':
            return {
                isLaoadSearchMasterEmployee: true,
                responseSearchMasterEmployee: action.response,
                statusSearchMasterEmployee: action.status
            }

        case 'SEARCHMASTEREMPLOYEEFAIL':
            return {
                isLaoadSearchMasterEmployee: true,
                messageSearchMasterEmployee: action.statusMessage,
                statusSearchMasterEmployee: action.status
            }

        case 'CLEARSEARCHMASTEREMPLOYEE':
            return {
                isLaoadSearchMasterEmployee: false,
                messageSearchMasterEmployee: '',
                statusSearchMasterEmployee: 400
            }
        default: return state;
    }
}

const reducerAdd: Reducer<Models.AddMasterEmployeeState> = (state: Models.AddMasterEmployeeState | undefined, incomingAction: Action): Models.AddMasterEmployeeState => {
    if (state == undefined) {
        return unloadedAddState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'ADD_MASTEREMPLOYEE':
            return {
                isLaoadAddMasterEmployee: false,
            }

        case 'ADDDMASTEREMPLOYEE':
            return {
                isLaoadAddMasterEmployee: true,
                //responseDeleteMasterEmployee: action.response,
                statusAddMasterEmployee: action.status
            }

        case 'ADDMASTEREMPLOYEEFAIL':
            return {
                isLaoadAddMasterEmployee: true,
                messageAddMasterEmployee: action.statusMessage,
                statusAddMasterEmployee: action.status
            }

        case 'CLEARADDMASTEREMPLOYEE':
            return {
                isLaoadAddMasterEmployee: false,
                messageAddMasterEmployee: '',
                statusAddMasterEmployee: 400
            }
        default: return state;
    }
}

const reducerImportAdd: Reducer<Models.AddImportMasterEmployeeState> = (state: Models.AddImportMasterEmployeeState | undefined, incomingAction: Action): Models.AddImportMasterEmployeeState => {
    if (state == undefined) {
        return unloadedAddImportState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'ADD_IMPORTMASTEREMPLOYEE':
            return {
                isLaoadAddImportMasterEmployee: false,
            }

        case 'ADDIMPORTMASTEREMPLOYEE':
            return {
                isLaoadAddImportMasterEmployee: true,
                statusAddImportMasterEmployee: action.status
            }

        case 'ADDIMPORTMASTEREMPLOYEEFAIL':
            return {
                isLaoadAddImportMasterEmployee: true,
                messageAddImportMasterEmployee: action.statusMessage,
                statusAddImportMasterEmployee: action.status
            }

        case 'CLEARADDIMPORTMASTEREMPLOYEE':
            return {
                isLaoadAddImportMasterEmployee: false,
                messageAddImportMasterEmployee: '',
                statusAddImportMasterEmployee: 400
            }
        default: return state;
    }
}

const reducerListExcel: Reducer<Models.ListExcelMasterEmployeeState> = (state: Models.ListExcelMasterEmployeeState | undefined, incomingAction: Action): Models.ListExcelMasterEmployeeState => {
    if (state == undefined) {
        return unloadedListExcelState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'BEFORE_LISTEXCELMASTEREMPLOYEE':
            return {
                isLaoadListExcelMasterEmployee: false,
            }

        case 'LISTEXCELMASTEREMPLOYEE':
            return {
                isLaoadListExcelMasterEmployee: true,
                responseListExcelMasterEmployee: action.response,
                statusListExcelMasterEmployee: action.status
            }

        case 'LISTEXCELMASTEREMPLOYEEFAIL':
            return {
                isLaoadListExcelMasterEmployee: true,
                messageListExcelMasterEmployee: action.statusMessage,
                statusListExcelMasterEmployee: action.status
            }

        case 'CLEARLISTEXCELMASTEREMPLOYEE':
            return {
                isLaoadListExcelMasterEmployee: false,
                messageListExcelMasterEmployee: '',
                statusListExcelMasterEmployee: 400
            }
        default: return state;
    }
}

export const rootReducer = combineReducers({ GetMasterEmployee: reducerGet, DeleteMasterEmployee: reducerDelete, EditMasterEmployee: reducerEdit, SearchMasterEmployee: reducerSearch, AddMasterEmployee: reducerAdd, AddImportMasterEmployee: reducerImportAdd,  ListExcelMasterEmployee: reducerListExcel });