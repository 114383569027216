import { ResponseList } from 'models/Response';
import {
    putCallbackLog,
    retrieveCallbackStart,
    retrieveCallbackWaiting,
    retrieveWorkflowCallbackLog
} from '../datasources/CallbackLog.datasource';
import {
    LogCallBackInfo,
    LogCallbackStartResponse,
    UpdateLogCallbackBody,
    WorkflowCallBackLogParams,
    WorkflowWaitingCallback
} from '../schemas/CallbackLog.schema';
import {
    DatasourceReturnType,
    ResponseError
} from 'models/common/respone.model';

export async function getCallbackWaiting(): Promise<
    DatasourceReturnType<ResponseError, ResponseList<WorkflowWaitingCallback>>
> {
    try {
        const data = (await retrieveCallbackWaiting()).data;

        return [null!, data];
    } catch (error) {
        throw error;
    }
}

export async function getCallbackStart(): Promise<
    DatasourceReturnType<ResponseError, ResponseList<LogCallbackStartResponse>>
> {
    try {
        const data = (await retrieveCallbackStart()).data;

        return [null!, data];
    } catch (error) {
        throw error;
    }
}

export async function getWorkflowCallbackLog(
    params: WorkflowCallBackLogParams
): Promise<DatasourceReturnType<ResponseError, ResponseList<LogCallBackInfo>>> {
    try {
        const data = (await retrieveWorkflowCallbackLog(params)).data;

        return [null!, data];
    } catch (error) {
        throw error;
    }
}

export async function updateWorkflowCallbackLog(
    body: UpdateLogCallbackBody
): Promise<DatasourceReturnType<ResponseError, string>> {
    try {
        const data = (await putCallbackLog(body)).data;

        return [null!, data];
    } catch (error) {
        throw error;
    }
}
