import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

import * as Models from '../../models/autocomplete/ParameterID'

interface LoadParameterSearch {
    type: 'LOAD_PARAMETER_SEARCH'
}
interface LoadedeParameterSearch {
    type: 'LOADEDPARAMETERSEARCH'
    response: Models.ResponseParameter
    status: Number,
    statusMessage: String
}
interface FailLoadedeParameterSearch {
    type: 'LOADFAIL'
    status: number,
    statusMessage: any
}

type KnownAction = LoadedeParameterSearch | FailLoadedeParameterSearch | LoadParameterSearch


export const actionCreators = {
    requestParameterSearch: (p: boolean, method: string , company : string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestConditionSearch')
        if (appState && appState.conditionSearch && p && method == 'POST') {
            fetch(`/v2/Condition/Master?app_id=` + company, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then(data => {
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('dispatch : LOADFAIL')
                            dispatch({ type: 'LOADFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            console.log('dispatch : LOADEDPARAMETERSEARCH')
                            dispatch({ type: 'LOADEDPARAMETERSEARCH', response: data as Models.ResponseParameter, status: 200, statusMessage: '' });
                        })
                    }
                })
            dispatch({ type: 'LOAD_PARAMETER_SEARCH' });
        }
    }
}

const unloadedState: Models.GetParameterState = { isLoad: false, };

export const reducer: Reducer<Models.GetParameterState> = (state: Models.GetParameterState | undefined, incomingAction: Action): Models.GetParameterState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_PARAMETER_SEARCH':
            return {
                isLoad: false,
            }

        case 'LOADEDPARAMETERSEARCH':
            return {
                isLoad: true,
                response: action.response,
                status: action.status
            }

        case 'LOADFAIL':
            return {
                isLoad: true,
                message: action.statusMessage,
                status: action.status
            }
        default: return state;
    }
}