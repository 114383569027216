import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

import * as Models from '../../models/workflow/ApproveRejectWorkflow'

interface ApproveWorkflow {
    type: 'APPROVE_WORKFLOW'
    response: Models.ResponseWorkflowApprove
    statusResponse: number,
    statusMessage: String
}

interface ApproveWorkflowFail {
    type: 'APPROVE_WORKFLOW_FAIL'
    statusResponse: number,
    statusMessage: any
}

interface ClearApproveWorkflow {
    type: 'CLEAR_APPROVE_WORKFLOW'
    statusResponse: number,
    statusMessage: any
}

interface RejectWorkflow {
    type: 'REJECT_WORKFLOW'
    response: Models.ResponseWorkflowApprove
    statusResponse: number,
    statusMessage: String
}

interface RejectWorkflowFail {
    type: 'REJECT_WORKFLOW_FAIL'
    statusResponse: number,
    statusMessage: any
}

interface ClearRejectWorkflow {
    type: 'CLEAR_REJECT_WORKFLOW'
    statusResponse: number,
    statusMessage: any
}

type KnownAction = ApproveWorkflow | ApproveWorkflowFail | RejectWorkflow | RejectWorkflowFail | ClearApproveWorkflow | ClearRejectWorkflow

export const actionCreators = {
    requestApproveWorkflow: (p: boolean, method: String, userId: String, docNo: string, message: string, wf_type: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.approveRejectWorkflow && p && method == 'PUT') {
            var body = {
                'app_id': 'abcd',
                'user_id': userId,
                'doc_no': docNo,
                'message': message,
                'wf_type': wf_type
            }
            await fetch(`workflow/approve`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
                body: JSON.stringify(body),
            })
                .then(response => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            dispatch({ type: 'APPROVE_WORKFLOW_FAIL', statusResponse: 300, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            dispatch({ type: 'APPROVE_WORKFLOW', response: data as Models.ResponseWorkflowApprove, statusResponse: 200, statusMessage: '' });
                        })
                    }
                })
        } else if (method == "CLEAR") {
            dispatch({ type: 'CLEAR_APPROVE_WORKFLOW', statusResponse: 400, statusMessage: "" });
        }
    },
    requestRejectWorkflow: (p: boolean, method: String, userId: String, docNo: string, message: string, wf_type: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.approveRejectWorkflow && p && method == 'PUT') {
            var body = {
                'app_id': 'abc',
                'user_id': userId,
                'doc_no': docNo,
                'message': message,
                'wf_type': wf_type
            }
            await fetch(`/workflow/reject`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
                body: JSON.stringify(body),
            })
                .then(response => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            dispatch({ type: 'REJECT_WORKFLOW_FAIL', statusResponse: 400, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            dispatch({ type: 'REJECT_WORKFLOW', response: data as Models.ResponseWorkflowApprove, statusResponse: 200, statusMessage: '' });
                        })
                    }
                })
        } else if (method == "CLEAR") {
            dispatch({ type: 'CLEAR_REJECT_WORKFLOW', statusResponse: 400, statusMessage: "" });
        }
    },
    requestApproveWorkflowList: (p: boolean, method: String, wfList: any, message: string, accessToken?: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.approveRejectWorkflow && p && method == 'PUT') {
            var tasks: any = [];
            var finalResult: any;

            var body = wfList

            const bearer = `Bearer ${accessToken || localStorage.getItem('WF_APPLICATION')}`;

            fetch(`/v3/workflow/approve`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': bearer,
                },
                body: JSON.stringify(body),
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            dispatch({ type: 'APPROVE_WORKFLOW_FAIL', statusResponse: 204, statusMessage: err.message });
                        })
                            .catch((error) => {
                                dispatch({ type: 'APPROVE_WORKFLOW_FAIL', statusResponse: 204, statusMessage: 'เกิดข้อผิดพลาด <br> กรุณาปิดหน้าต่าง Workflow แล้วทำรายการใหม่อีกครั้ง' });
                            });
                    } else {
                        data.json().then(data => {
                            dispatch({ type: 'APPROVE_WORKFLOW', response: data, statusResponse: 200, statusMessage: '' });
                        })
                    }
                })
        } else if (method == "CLEAR") {
            dispatch({ type: 'CLEAR_APPROVE_WORKFLOW', statusResponse: 400, statusMessage: "" });
        }
    },
    requestRejectWorkflowList: (p: boolean, method: String, wfList: any, message: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.approveRejectWorkflow && p && method == 'PUT') {
            var tasks: any = [];
            var finalResult: any;

            var body = wfList

            fetch(`/v3/workflow/reject`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
                body: JSON.stringify(body),
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            dispatch({ type: 'REJECT_WORKFLOW_FAIL', statusResponse: 204, statusMessage: err.message });
                        })
                            .catch((error) => {
                                dispatch({ type: 'REJECT_WORKFLOW_FAIL', statusResponse: 204, statusMessage: 'เกิดข้อผิดพลาด <br> กรุณาปิดหน้าต่าง Workflow แล้วทำรายการใหม่อีกครั้ง' });
                            });
                    } else {
                        data.json().then(data => {
                            dispatch({ type: 'REJECT_WORKFLOW', response: data, statusResponse: 200, statusMessage: '' });
                        })
                    }
                });
        } else if (method == "CLEAR") {
            dispatch({ type: 'CLEAR_REJECT_WORKFLOW', statusResponse: 400, statusMessage: "" });
        }
    }
}

const unloadedState: Models.ApproveRejectWorkflowState = { isLoadApproveReject: false };

export const reducer: Reducer<Models.ApproveRejectWorkflowState> = (state: Models.ApproveRejectWorkflowState | undefined, incomingAction: Action): Models.ApproveRejectWorkflowState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'APPROVE_WORKFLOW':
            return {
                isLoadApproveReject: true,
                responseApproveReject: action.response,
                statusResponseApproveReject: action.statusResponse

            }
        case 'APPROVE_WORKFLOW_FAIL':
            return {
                isLoadApproveReject: true,
                messageResponseApproveReject: action.statusMessage,
                statusResponseApproveReject: 400
            }
        case 'REJECT_WORKFLOW':
            return {
                isLoadApproveReject: true,
                responseApproveReject: action.response,
                statusResponseApproveReject: action.statusResponse
            }
        case 'REJECT_WORKFLOW_FAIL':
            return {
                isLoadApproveReject: true,
                messageResponseApproveReject: action.statusMessage,
                statusResponseApproveReject: 400
            }
        case 'CLEAR_APPROVE_WORKFLOW':
            return {
                isLoadApproveReject: false,
                messageResponseApproveReject: "",
                statusResponseApproveReject: 0
            }
        case 'CLEAR_REJECT_WORKFLOW':
            return {
                isLoadApproveReject: false,
                messageResponseApproveReject: "",
                statusResponseApproveReject: 0
            }
        default: return state;
    }
}