import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/callbackconfig/CallbackTokens'

interface BeforeLoadCallbackToken {
    type: 'LOAD_CALLBACKTOKENS'
}
interface LoadCallbackToken {
    type: 'LOADCALLBACKTOKENS'
    response: Models.TokensResponse
    status: Number,
    statusMessage: String
    request: Models.RequestTokens
}
interface FailCallbackTOken {
    type: 'LOADCALLBACKTOKENFAIL'
    status: number,
    statusMessage: any
}

interface ClearCallbackTOken {
    type: 'CLEARCALLBACKTOKEN'
    status: number,
    statusMessage: any
    request: Models.RequestTokens
}

type KnownAction = LoadCallbackToken | FailCallbackTOken | ClearCallbackTOken | BeforeLoadCallbackToken

export const actionCreators = {
    requestCallbackToken: (p: boolean, method: String, id : string, idx : number, appId: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestPositionemmethodployee')
        if (appState && appState.positionEmployee && p && method == 'POST') {
            //var appId = '';
            //var ls = localStorage.getItem('WF_APPLICATION');
            //if (ls) {
            //    var j = JSON.parse(ls);
            //    var jat = parseJwt(j.access_token);
            //    appId = jat.app;
            //}
            await fetch(`/v1/Callbackconfig/Token?id=` + id + `&app_id=` + appId, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('dispatch : LOADFAIL')
                            dispatch({ type: 'LOADCALLBACKTOKENFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            console.log('dispatch : LOADCALLBACKTOKENS')
                            dispatch({ type: 'LOADCALLBACKTOKENS', response: data as Models.TokensResponse, status: 200, statusMessage: '', request: { id: id, idx: idx } });
                        })
                    }
                })

            dispatch({ type: 'LOAD_CALLBACKTOKENS' });

        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARCALLBACKTOKEN', status: 400, statusMessage: "", request: { id: "", idx: 0} });
        }
    }
}

const unloadedState: Models.CallbackTokenState = { CallbackTokenisLoad: false };

export const reducer: Reducer<Models.CallbackTokenState> = (state: Models.CallbackTokenState | undefined, incomingAction: Action): Models.CallbackTokenState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_CALLBACKTOKENS':
            return {
                CallbackTokenisLoad: false,
            }

        case 'LOADCALLBACKTOKENS':
            return {
                CallbackTokenisLoad: true,
                CallbackTokenresponse: action.response,
                CallbackTokenstatus: action.status,
                CallbackTokenrequest: action.request
            }

        case 'LOADCALLBACKTOKENFAIL':
            return {
                CallbackTokenisLoad: true,
                CallbackTokenmessage: action.statusMessage,
                CallbackTokenstatus: action.status
            }
        case 'CLEARCALLBACKTOKEN':
            return {
                CallbackTokenisLoad: false,
                CallbackTokenmessage: "",
                CallbackTokenstatus: 400,
                CallbackTokenrequest: action.request
            }
        default: return state;
    }
}