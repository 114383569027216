import React, { useState } from 'react'
import { Row, Col, Button } from "react-bootstrap";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as ModelsWorkflowInbox from '../../models/workflow/WorkflowInbox';
import BorderGrid from '../../common/BorderGrid';

type align_ = "left" | "center" | "right";

interface Props {
    isLoading: boolean,
    listDataItem: ModelsWorkflowInbox.DataItem[],
}

interface ModalData {
    label: string,
    value: string
}

interface ReportInfoModalState {
    isToggleModal: boolean,
    tableData?: Array<ModalData>
}

const ReportInformationMobileTable = (props: Props) => {
    const [reportInfoModalData, setReportInfoModalData] = useState<ReportInfoModalState>({ isToggleModal: false });

    const { isLoading, listDataItem } = props;

    const copiedListDataItem = [...listDataItem];
    //console.log("copy list data =", copiedListDataItem)

    const displayTableHeader = copiedListDataItem[0]?.tableheader.slice(0, 3);
    const displayTableData = copiedListDataItem[0]?.tabledata.map(data => ({ row: data.row.slice(0, 3) }) as ModelsWorkflowInbox.tabledata);
    //console.log("display data tb =", displayTableData)

    const tableFooter = {
        //width: '100%',
        backgroundColor: '#1473E6',
        color: '#FFFFFF'
    };

    //console.log("header =", listDataItem[0].tableheader)
    //console.log("data =", listDataItem[0].tabledata.map(data => data.row))

    function findTableData(index: number): ModalData[] {
        const labelArr = listDataItem[0]?.tableheader.map(header => header.label);
        //console.log("find tb header =", labelArr);

        const tableData = listDataItem[0]?.tabledata[index];
        //console.log("find tb data =", tableData);

        const modalDataArr: Array<ModalData> = [];

        tableData.row.map((row, rowIndex) => {
            modalDataArr.push({
                label: labelArr[rowIndex],
                value: row
            })
        })

        //console.log("renew =", modalDataArr);

        return modalDataArr;
    }

    return (
        <React.Fragment>
            {!isLoading &&
                listDataItem !== undefined &&
                listDataItem.length !== 0 &&
                listDataItem.map((ListItem: ModelsWorkflowInbox.DataItem, idList) => {
                    return (
                        <Row className="mt-3" key={idList}>
                            <Col>
                                <div>
                                    <table className="table table-bordered w-100">
                                        <thead>
                                            <tr style={tableFooter}>
                                                {displayTableHeader.map((data: ModelsWorkflowInbox.tableheader, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <th
                                                                style={{ textAlign: data.align as align_ }}
                                                                align={data.align as align_}
                                                            >
                                                                {data.label}
                                                            </th>
                                                            {index === (displayTableHeader.length - 1) && (<th></th>)}
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {displayTableData.map((data: ModelsWorkflowInbox.tabledata, index) => {
                                                return (
                                                    <tr key={index}>
                                                        {data.row.map((item: string, index2) => {
                                                            return (
                                                                <React.Fragment key={index2}>
                                                                    <td style={{ textAlign: ListItem.tableheader[index2].align as align_ }}>
                                                                        {item}
                                                                    </td>
                                                                    {index2 === (data.row.length - 1) && (
                                                                        <td
                                                                            onClick={() => setReportInfoModalData({ isToggleModal: true, tableData: findTableData(index) })}
                                                                        >
                                                                            ...
                                                                        </td>)}
                                                                </React.Fragment>
                                                            )
                                                        })}
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                        </Row>
                    )
                })
            }

            {/*More detail modal*/}
            <Modal isOpen={reportInfoModalData.isToggleModal} toggle={() => setReportInfoModalData({ isToggleModal: false })} size="lg" backdrop="static" keyboard={false}>
                <ModalHeader toggle={() => setReportInfoModalData({ isToggleModal: false })}>More Detail</ModalHeader>
                <ModalBody>
                    {reportInfoModalData.tableData !== undefined &&
                        reportInfoModalData.tableData.length !== 0 &&
                        reportInfoModalData.tableData.map((data: ModalData, index) => {
                            return (
                                <BorderGrid key={index}>
                                    <BorderGrid.Row>
                                        <BorderGrid.Col>
                                            <div>
                                                <label>{data.label}</label>
                                            </div>
                                            <div>
                                                <b>{data.value}</b>
                                            </div>
                                        </BorderGrid.Col>
                                    </BorderGrid.Row>
                                </BorderGrid>
                            )
                        })
                    }
                </ModalBody>
                <ModalFooter>
                    <Button variant="secondary" onClick={() => setReportInfoModalData({ isToggleModal: false })}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )
}

//ReportInformationMobileTable.defaultProps = {
//    isLoading: false,
//    listDataItem: []
//}

export default ReportInformationMobileTable