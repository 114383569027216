import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { RequestOptions } from '../../models/Common';
import { ResponseList } from '../../models/Response';
import { WorkflowCondition, WorkflowConditionStateStore } from '../../models/workflowreport/WorkflowCondition';

interface RequestWorkflowCondition {
    type: 'REQUEST_WORKFLOW_CONDITION';
}

interface ReceiveWorkflowCondition {
    type: 'RECEIVE_WORKFLOW_CONDITION';
    data: ResponseList<WorkflowCondition>;
}

interface FailureWorkflowCondition {
    type: 'FAILURE_WORKFLOW_CONDITION';
    error: any
}

interface ClearWorkflowCondition {
    type: 'CLEAR_WORKFLOW_CONDITION';
}

type KnownAction =
    | RequestWorkflowCondition
    | ReceiveWorkflowCondition
    | FailureWorkflowCondition
    | ClearWorkflowCondition;

export const actionCreators = {
    requestWorkflowCondition: (options: RequestOptions<null, { sids?: string[], wfid?: string, wf_condition?: string }>): AppThunkAction<KnownAction> => (dispatch, _getState) => {
        const { method, params } = options;
        const searchSids = new URLSearchParams(params?.sids!.map(sid => ['sid', sid])).toString().replace('&', '?');
        delete params?.sids;
        const searchOptional = new URLSearchParams(params as any).toString();

        dispatch({ type: 'REQUEST_WORKFLOW_CONDITION' });

        fetch('/v1/workflow-condition?' + searchSids + '&' + searchOptional, {
            method,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION')
            }
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return Promise.reject(response.json());
                }
            })
            .then(data => {
                console.log('ngame', data)
                dispatch({ type: 'RECEIVE_WORKFLOW_CONDITION', data });
            })
            .catch(error => dispatch({ type: 'FAILURE_WORKFLOW_CONDITION', error }));
    }
}

const initialState: WorkflowConditionStateStore = {
    isLoading: false,
    response: null,
    status: 0,
    error: null
};

export const reducer: Reducer<WorkflowConditionStateStore> = (state = initialState, action: KnownAction) => {
    switch (action.type) {
        case 'REQUEST_WORKFLOW_CONDITION': {
            return {
                ...state,
                isLoading: true
            };
        }
        case 'RECEIVE_WORKFLOW_CONDITION': {
            return {
                ...state,
                isLoading: false,
                response: action.data,
                status: 200
            };
        }
        case 'FAILURE_WORKFLOW_CONDITION': {
            return {
                ...state,
                isLoading: false,
                status: 400,
                error: action.error
            };
        }
        case 'CLEAR_WORKFLOW_CONDITION': {
            return initialState;
        }
        default: return state;
    }
}