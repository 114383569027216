import React, { useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { twMerge } from 'tailwind-merge';
import { useEventListener } from 'usehooks-ts';
import {
    TbChevronLeft,
    TbChevronLeftPipe,
    TbChevronRight,
    TbChevronRightPipe,
    TbReload
} from 'react-icons/tb';
import { FaThLarge, FaThList } from 'react-icons/fa';
import {
    IoCloseCircleOutline,
    IoCheckmarkCircleOutline
} from 'react-icons/io5';

import { Icons } from 'assets';
import { ApprovalConfirmationModal, Tabs, Button } from 'common/components';
import { usePagination } from 'common/hooks';
import { debounce } from 'helpers/utils';

import {
    ApprovalResultModal,
    OnlineFormInformationModal,
    OnlineFormThumbnail
} from './components';
import useWorkflowOnlineFormViewModel from './WorkflowOnlineForm.viewmodel';

const tabs = ['Inbox', 'Report'] as const;

function WorkflowOnlineForm() {
    const {
        auth,
        accessToken,
        mode,
        displayStyle,
        selectedDocuments,
        selectedDocument,
        prepareAction,
        message,
        isOpenConfirmModal,
        isOpenInformationModal,
        reportsReponse,
        responseWorkflowInbox,
        responseApproveReject,
        fetchWorkflowInbox,
        handleChangeTab,
        handleChangeDisplayStyle,
        handleChangeSelectAllDocuments,
        handleSelectForm,
        handleCloseApprovalStatusModal,
        handleNavigateToESignature,
        confirmAction,
        toggleConfirmModal,
        toggleInformationModal
    } = useWorkflowOnlineFormViewModel();

    const documentCounts = useMemo(
        () => [
            `(${responseWorkflowInbox?.total_count ?? 0} รายการ)`,
            `(${reportsReponse?.total_count ?? 0} รายการ)`
        ],
        [responseWorkflowInbox, reportsReponse]
    );
    const documents =
        (mode === 'Inbox' ? responseWorkflowInbox : reportsReponse)
            ?.result_list ?? [];

    useEventListener(
        'resize',
        debounce(_event => {
            if (window.innerWidth < 1024) {
                handleChangeDisplayStyle('list');
            }
        }, 500)
    );

    const {
        collections: slicedDocuments,
        pageSize,
        numberOfPages,
        currentPage,
        handleClickFirstPage,
        handleClickPreviousPage,
        handleClickNextPage,
        handleClickLastPage
    } = usePagination(documents, { pageSize: 10 });

    const disabledButton =
        !responseWorkflowInbox || responseWorkflowInbox.total_count === 0;
    const disabledActionButton = disabledButton || selectedDocuments.size == 0;

    return (
        <React.Fragment>
            <Container
                fluid
                className="bg-[#F7F7F7] p-2"
            >
                <div className="rounded-md bg-white p-3">
                    <Tabs
                        activeTab={mode}
                        icons={[<Icons.INBOX_TAB />, <Icons.REPORT_TAB />]}
                        tabs={tabs}
                        trailingLabel={documentCounts}
                        onChangeTab={handleChangeTab}
                    />

                    {mode === 'Inbox' && (
                        <div className="mt-4 grid grid-cols-4 grid-rows-2 gap-3 px-4 md:flex">
                            {/* Select all */}
                            <div className="col-span-1 h-fit self-center whitespace-nowrap">
                                <input
                                    disabled={disabledButton}
                                    id="select-all-checkbox"
                                    type="checkbox"
                                    className="relative top-[3px] h-4 w-4 cursor-pointer border-rose-400 accent-[#3085d6]"
                                    onChange={handleChangeSelectAllDocuments}
                                />
                                <label
                                    htmlFor="select-all-checkbox"
                                    className="m-0 ml-2.5"
                                >
                                    Select All
                                </label>
                            </div>

                            <div className="col-span-3 ml-6 flex justify-end">
                                <Button
                                    icon={TbReload}
                                    onClick={fetchWorkflowInbox}
                                >
                                    Refresh
                                </Button>

                                <div className="mx-4 hidden w-[1px] bg-zinc-300 lg:block" />

                                <div className="hidden rounded-md border border-zinc-300 lg:inline-flex [&_>_div:first-child]:border-r">
                                    <button
                                        className={twMerge(
                                            'flex flex-1 items-center justify-center px-3 text-stone-300 transition-colors',
                                            displayStyle === 'grid' &&
                                                'bg-zinc-300 text-[#777777]'
                                        )}
                                        onClick={() =>
                                            handleChangeDisplayStyle('grid')
                                        }
                                    >
                                        <FaThLarge />
                                    </button>
                                    <button
                                        className={twMerge(
                                            'flex flex-1 items-center justify-center px-3 text-stone-300 transition-colors',
                                            displayStyle === 'list' &&
                                                'bg-zinc-300 text-[#777777]'
                                        )}
                                        onClick={() =>
                                            handleChangeDisplayStyle('list')
                                        }
                                    >
                                        <FaThList />
                                    </button>
                                </div>
                            </div>

                            <div className="col-span-full flex justify-end gap-2 md:ml-auto">
                                <Button
                                    disabled={disabledActionButton}
                                    className="w-[110px] border-0 bg-[#269E41] text-white"
                                    onClick={() =>
                                        toggleConfirmModal('approve')
                                    }
                                >
                                    <IoCheckmarkCircleOutline size={18} />
                                    Approve
                                </Button>
                                <Button
                                    disabled={disabledActionButton}
                                    className="w-[110px]"
                                    onClick={() => toggleConfirmModal('reject')}
                                >
                                    <IoCloseCircleOutline
                                        size={18}
                                        color="#B3261E"
                                    />
                                    Reject
                                </Button>
                            </div>
                        </div>
                    )}

                    {/* Document list */}
                    <Row className="mt-4">
                        <Col
                            // style={{ columns: displayStyle === 'list' ? 1 : 2 }}
                            className={twMerge(
                                'grid gap-x-3 gap-y-3',
                                displayStyle === 'list'
                                    ? 'grid-cols-1'
                                    : 'grid-cols-2'
                            )}
                        >
                            {slicedDocuments.length > 0 &&
                                slicedDocuments.map((document, index) => (
                                    <OnlineFormThumbnail
                                        key={document.docNo}
                                        mode={mode}
                                        displayStyle={displayStyle}
                                        document={document}
                                        isSelected={selectedDocuments.has(
                                            document
                                        )}
                                        onSelectForm={handleSelectForm}
                                        onClickViewDetails={
                                            toggleInformationModal
                                        }
                                        // Additional styles
                                        style={
                                            displayStyle === 'list'
                                                ? undefined
                                                : {
                                                      gridRowStart:
                                                          (index + 1) %
                                                          (pageSize / 2),
                                                      gridColumnStart:
                                                          index < pageSize / 2
                                                              ? 1
                                                              : 2
                                                  }
                                        }
                                    />
                                ))}
                        </Col>
                    </Row>

                    {/* Paginations */}
                    <Row>
                        <Col>
                            <div className="my-4 flex items-center justify-end px-4">
                                <button
                                    className="h-7 w-7 rounded-full transition-colors hover:bg-zinc-200"
                                    onClick={handleClickFirstPage}
                                >
                                    <TbChevronLeftPipe size={18} />
                                </button>
                                <button
                                    className="h-7 w-7 rounded-full transition-colors hover:bg-zinc-200"
                                    onClick={handleClickPreviousPage}
                                >
                                    <TbChevronLeft size={18} />
                                </button>
                                <span className="mx-2">
                                    Page {currentPage} of {numberOfPages || 1}
                                </span>
                                <button
                                    className="h-7 w-7 rounded-full transition-colors hover:bg-zinc-200"
                                    onClick={handleClickNextPage}
                                >
                                    <TbChevronRight size={18} />
                                </button>
                                <button
                                    className="h-7 w-7 rounded-full transition-colors hover:bg-zinc-200"
                                    onClick={handleClickLastPage}
                                >
                                    <TbChevronRightPipe size={18} />
                                </button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>

            <ApprovalConfirmationModal
                isOpen={isOpenConfirmModal}
                toggle={() => toggleConfirmModal('')}
                onClickConfirm={confirmMessage =>
                    confirmAction(
                        confirmMessage,
                        selectedDocument // If selected document use their instead
                            ? [selectedDocument]
                            : Array.from(selectedDocuments)
                    )
                }
            />

            <ApprovalResultModal
                isOpen={
                    !!responseApproveReject &&
                    !responseApproveReject.result_list?.every(
                        document => document.status === 'ESIG0010'
                    )
                }
                toggle={handleCloseApprovalStatusModal}
                action={prepareAction}
                message={message}
                responseDocuments={responseApproveReject?.result_list}
                onClickApproveAnyway={() =>
                    responseApproveReject?.result_list &&
                    handleNavigateToESignature(
                        responseApproveReject.result_list
                    )
                }
            />

            {auth && accessToken && (
                <OnlineFormInformationModal
                    accessToken={accessToken}
                    auth={auth}
                    isOpen={isOpenInformationModal}
                    toggle={() => toggleInformationModal(false)}
                    selectedDocument={selectedDocument}
                    toggleConfirmModal={toggleConfirmModal}
                />
            )}
        </React.Fragment>
    );
}

export default WorkflowOnlineForm;
