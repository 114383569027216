import { MasterEmployeeRequest } from 'data/MasterEmployee/schemas/MasterEmployee.shema';
import { MasterCompanyList } from 'models/company/MasterCompany';
import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { Input } from 'reactstrap';
import Swal from 'sweetalert2';

type Props = {
    userMasterEmployee: MasterEmployeeRequest[];
    companyMapping: MasterCompanyList[];
    handleAddUser: (sid: string, role: string, employee: string) => void;
};

function AddWorkflowConfigAdmin(props: Props) {
    const { userMasterEmployee, companyMapping, handleAddUser } = props;

    const [condition, setCondition] = useState<{
        sid: string;
        role: string;
        employee: string;
    }>({ sid: '', role: '', employee: '' });

    const handleAddUserAdmin = () => {
        if (condition.sid === '' || condition.role === '' || condition.employee === '') {
            Swal.fire({
                text: "กรุณากรอกข้อมูลให้ครบ",
                icon: "warning"
            });

            return
        }
        handleAddUser(condition.sid, condition.role, condition.employee);
    }

    return (
        <Container>
            <Row>
                <Col className="import-export-actions">
                    <div
                        className="trapezoid-head"
                        style={{ width: '200px' }}
                    >
                        <span>Admin Master Employee</span>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="mat-box">
                        <div
                            className="trapezoid trapezoid-purple"
                            style={{ width: '200px', display: 'inline-block' }}
                        >
                            <span>
                                <i
                                    className="fa fa-search"
                                    aria-hidden="true"
                                ></i>
                                Add Admin Master Employee
                                <div
                                    style={{
                                        display: 'inline-block',
                                        float: 'right'
                                    }}
                                ></div>
                            </span>
                        </div>
                        <div className="mat-box mb-5 rounded bg-white p-3 shadow">
                            <div className="mt-2">
                                <Row>
                                    <Col
                                        xs={12}
                                        md={5}
                                    >
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Company :</Form.Label>
                                            <Input
                                                type="select"
                                                onChange={e => {
                                                    e.persist();
                                                    setCondition(prev => ({
                                                        ...prev,
                                                        sid: e.target.value!
                                                    }));
                                                }}
                                            >
                                                <option selected>
                                                    Please choose
                                                </option>
                                                {companyMapping && companyMapping.map(
                                                    (value, index) => (
                                                        <option
                                                            key={index}
                                                            value={value.sid}
                                                        >
                                                            {value.company_name}
                                                        </option>
                                                    )
                                                )}
                                            </Input>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col
                                        xs={12}
                                        md={5}
                                    >
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>
                                                Role Access:
                                            </Form.Label>
                                            <Input
                                                type="select"
                                                onChange={e => {
                                                    e.persist();
                                                    setCondition(prev => ({
                                                        ...prev,
                                                        role: e.target.value!
                                                    }));
                                                }}
                                            >
                                                <option selected>
                                                    Please choose
                                                </option>
                                                <option value={'admin'}>
                                                    Admin
                                                </option>
                                                <option value={'super_admin'}>
                                                    Super Admin
                                                </option>
                                            </Input>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col
                                        xs={12}
                                        md={5}
                                    >
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Employee :</Form.Label>
                                            <Input
                                                type="select"
                                                onChange={e => {
                                                    e.persist();
                                                    setCondition(prev => ({
                                                        ...prev,
                                                        employee:
                                                            e.target.value!
                                                    }));
                                                }}
                                            >
                                                <option selected>
                                                    Please choose
                                                </option>
                                                {userMasterEmployee && userMasterEmployee.map(
                                                    (value, index) => (
                                                        <option key={index} value={value.id}>
                                                            {value.id}: (
                                                            {value.empname})
                                                        </option>
                                                    )
                                                )}
                                            </Input>
                                        </Form.Group>
                                    </Col>
                                    <Col
                                        xs={12}
                                        md={5}
                                    >
                                        <Button
                                            variant="outline-success"
                                            size="sm"
                                            onClick={handleAddUserAdmin}
                                        >
                                            <FaPlus /> Add Admin
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default AddWorkflowConfigAdmin;
