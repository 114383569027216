import React, { Component } from "react";
import Switch from "react-switch";

class SwitchBtnWorkflow extends Component<any, { checked: boolean, checkvalueSwitch: boolean }>{
    constructor(props: any) {
        super(props);
        this.state = {
            checked: true,
            checkvalueSwitch: true
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(checked) {
        this.setState({ checked });
        this.props.hadlechangeSwitchBtn(checked)
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { valueSwitch } = this.props
        if (valueSwitch != undefined && this.state.checkvalueSwitch) {
            console.log("valueSwitch :" + valueSwitch)
            var rs = true;
            if (String(valueSwitch) == "false") (
                rs = false
            )
            console.log("rs :" + rs)
            this.setState({
                checked: rs,
                checkvalueSwitch: false
            })
        }
    }

    render() {
        return (
            <Switch
                checked={this.state.checked}
                onChange={this.handleChange}
                onColor="#86d3ff"
                onHandleColor="#2693e6"
                handleDiameter={30}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={20}
                width={48}
                className="react-switch"
                id="material-switch"
            />
        )
    }
}
export default SwitchBtnWorkflow;