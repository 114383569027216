import React, { createContext, useContext, useState, useEffect, PropsWithChildren } from 'react';

export type ViewportContextType = {
    width: number;
    height: number;
};

export const ViewportContext = createContext<ViewportContextType | null>(null);

export function useViewportContext() {
    return useContext(ViewportContext);
}

function ViewportProvider(props: PropsWithChildren<{}>) {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const handleResize = (_event: UIEvent) => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <ViewportContext.Provider value={{ width, height }}>
            { props.children }
        </ViewportContext.Provider>
    )
}

export default ViewportProvider;