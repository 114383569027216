import { AssignmentRoutingResponse } from 'data/AssignmentRouting/schemas/AssignmentRouting.schema';
import { CreateEmployeeBodyAndResponse } from 'data/PositionEmployee/schemas/PositionEmployee.schema';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FaRegEdit } from 'react-icons/fa';
import { ImCancelCircle } from 'react-icons/im';
import { Table } from 'reactstrap';

type Props = {
    filteredReports: AssignmentRoutingResponse[] | undefined;
    handleChangeConfigAssignmentRouting: (
        assignmentToUpdate: AssignmentRoutingResponse,
        field: 'validfrom' | 'validto',
        value: string
    ) => void;
    assignmentRoutingMapping: AssignmentRoutingResponse[] | undefined;
};

function AssignmentRoutingTable(props: Props) {
    const {
        filteredReports,
        handleChangeConfigAssignmentRouting,
        assignmentRoutingMapping
    } = props;

    const [assignmentEdit, setAssignmentEdit] =
        useState<AssignmentRoutingResponse>();

    const handleEdit = (assignment: AssignmentRoutingResponse) => {
        setAssignmentEdit(assignment);
    };

    return (
        <div style={{ overflowX: 'scroll' }}>
            <Table className="table-bordered table-hover">
                <thead>
                    <tr
                        className="table-active thead-light text-nowrap text-center"
                        style={{ width: '100%' }}
                    >
                        <th>SID Name</th>
                        <th>AuthGroup</th>
                        <th>AuthGroup Name</th>
                        <th>PositionCode</th>
                        <th>Position Condition</th>
                        <th>Emp ID</th>
                        <th>Emp Name</th>
                        <th>Email Address</th>
                        <th>Valid From (yyyy/mm/dd)</th>
                        <th>Valid To (yyyy/mm/dd)</th>
                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredReports &&
                        filteredReports.map((assignment, index) => (
                            <tr key={index}>
                                <td>{assignment.sid_name}</td>
                                <td>{assignment.authgroup}</td>
                                <td>{assignment.authgroup_name}</td>
                                <td>{assignment.positioncode}</td>
                                <td>{assignment.position_condition}</td>
                                <td>{assignment.empid}</td>
                                <td>{assignment.emp_name}</td>
                                <td>{assignment.email_address}</td>
                                <td>
                                    {assignment.validfrom}
                                    {/* <input
                                        defaultValue={assignment.validfrom}
                                        onChange={e => {
                                            handleChangeConfigAssignmentRouting(
                                                assignment,
                                                'validfrom',
                                                e.target.value
                                            );
                                        }}
                                    /> */}
                                </td>
                                <td>
                                    {assignmentEdit &&
                                    assignment.positioncode ===
                                        assignmentEdit.positioncode &&
                                    assignment.emp_name ===
                                        assignmentEdit.emp_name ? (
                                        <div className="flex justify-around">
                                            <input
                                                defaultValue={
                                                    assignment.validto
                                                }
                                                className="w-1/3"
                                                onChange={e => {
                                                    handleChangeConfigAssignmentRouting(
                                                        assignment,
                                                        'validto',
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <button
                                                onClick={e => {
                                                    handleChangeConfigAssignmentRouting(
                                                        assignment,
                                                        'validto',
                                                        '99991231'
                                                    );
                                                    setAssignmentEdit(
                                                        undefined
                                                    );
                                                }}
                                                className="rounded border border-green-600 p-0.5 text-green-700"
                                            >
                                                Active
                                            </button>
                                            <button
                                                onClick={e => {
                                                    const date = new Date();
                                                    const year =
                                                        date.getFullYear();
                                                    const month = String(
                                                        date.getMonth() + 1
                                                    ).padStart(2, '0');
                                                    const day = String(
                                                        date.getDate()
                                                    ).padStart(2, '0');

                                                    handleChangeConfigAssignmentRouting(
                                                        assignment,
                                                        'validto',
                                                        `${year}${month}${day}`
                                                    );
                                                    setAssignmentEdit(
                                                        undefined
                                                    );
                                                }}
                                                className="rounded border border-red-600 p-0.5 text-red-700"
                                            >
                                                InActive
                                            </button>
                                        </div>
                                    ) : (
                                        assignmentRoutingMapping?.find(
                                            value =>
                                                assignment.positioncode ===
                                                    value.positioncode &&
                                                assignment.emp_name ===
                                                    value.emp_name
                                        )?.validto ?? (
                                            <span>{assignment.validto}</span>
                                        )
                                    )}
                                </td>
                                <td className="gap-2 text-center">
                                    <FaRegEdit
                                        className="text-yellow-500"
                                        onClick={() => {
                                            handleEdit(assignment);
                                        }}
                                    />

                                    {assignmentEdit &&
                                        assignment.positioncode ===
                                            assignmentEdit.positioncode &&
                                        assignment.emp_name ===
                                            assignmentEdit.emp_name &&
                                        assignment.emp_name ===
                                            assignmentEdit.emp_name && (
                                            <ImCancelCircle
                                                className="text-red-500"
                                                onClick={() => {
                                                    setAssignmentEdit(
                                                        undefined
                                                    );
                                                }}
                                            />
                                        )}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>
        </div>
    );
}

export default AssignmentRoutingTable;
