import * as React from "react";
import { Button, Container, Row, Col, Form, Table, FormControl } from "react-bootstrap";
import { FaPlusCircle, FaTrashAlt } from "react-icons/fa";
import SwitchBtnWorkflow from '../other/SwitchBtnWorkflow'
import AutcoCompleteConditionID from '../autocomplete/AutoCompleteConditionID'

export interface inititem_ {
    wf_id: string,
    characteristic: string,
    characteristic_name: string,
    object_id: string,
    rel_code: string,
    x_value_name: string,
    x_operator: string,
    x_value: string
};

export interface itemSelect {
    conditionid: string,
    conditionname: string
};

const inititem = {
    wf_id: "",
    characteristic: "WF_TYPE",
    characteristic_name: "WF_TYPE",
    object_id: "",
    rel_code: "",
    x_value_name: "",
    x_operator: "",
    x_value: ""
};

const itemSelect = {
    conditionid: "WF_TYPE",
    conditionname: "WF_TYPE"
};

const formdata = {
    wf_id: "",
    wf_name: "",
    created_by: "",
    created_on: "",
    updated_by: "",
    updated_on: "",
    active: "true",
}

export interface auto {
    code: string,
    name: string,
    display: string,
};

class WorkflowComponent extends React.Component<any, {}> {
    constructor(props: any) {
        super(props);
        this.hadlechangeSwitchBtn = this.hadlechangeSwitchBtn.bind(this)
        this.handlechageCondition = this.handlechageCondition.bind(this)
        this.handlechageAutoType = this.handlechageAutoType.bind(this)
    }

    state = {
        rows: [inititem],
        rowsSelect: [itemSelect],
        wf_id: "",
        wf_name: "",
        created_by: "",
        created_on: "",
        updated_by: "",
        updated_on: "",
        active: "true",
        formData: {},
        CheckresponseSearch: true,
        Checkreadonly: false
    };


    handleChange = (idx: number, mode: String) => (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        const rows = [...this.state.rows];
        if (mode == 'Relcode') {
            const item = {
                wf_id: this.state.wf_id,
                characteristic: rows[idx].characteristic,
                characteristic_name: rows[idx].characteristic_name,
                object_id: String(idx + 1),
                rel_code: value,
                x_value_name: rows[idx].x_value_name,
                x_operator: rows[idx].x_operator,
                x_value: rows[idx].x_value
            };
            rows[idx] = item;
            this.setState({
                rows
            });
        }
        // else if (mode == 'ConditionID') {
        //     const item = {
        //         Relcode: rows[idx].Relcode,
        //         ConditionID: value,
        //         Operator: rows[idx].Operator,
        //         Value: rows[idx].Value
        //     };
        //     rows[idx] = item;
        //     this.setState({
        //         rows
        //     });
        // } 
        else if (mode == 'Operator') {
            const item = {
                wf_id: this.state.wf_id,
                characteristic: rows[idx].characteristic,
                characteristic_name: rows[idx].characteristic_name,
                object_id: String(idx + 1),
                rel_code: rows[idx].rel_code,
                x_value_name: rows[idx].x_value_name,
                x_operator: value,
                x_value: rows[idx].x_value
            };
            rows[idx] = item;
            this.setState({
                rows
            });
        } else if (mode == 'Value') {
            const item = {
                wf_id: this.state.wf_id,
                characteristic: rows[idx].characteristic,
                characteristic_name: rows[idx].characteristic_name,
                object_id: String(idx + 1),
                rel_code: rows[idx].rel_code,
                x_value_name: rows[idx].x_value_name,
                x_operator: rows[idx].x_operator,
                x_value: value
            };
            rows[idx] = item;
            this.setState({
                rows
            });
        }
    };

    handleChangehead = (mode: String) => (e: { target: { name: any; value: any; }; }) => {
        if (mode == "WorkflowID") {
            this.setState({
                wf_id: e.target.value
            })
        } else if (mode == "CreateBy") {
            this.setState({
                created_by: e.target.value
            })
        } else if (mode == "CreateOn") {
            this.setState({
                created_on: e.target.value
            })
        } else if (mode == "WorkflowName") {
            this.setState({
                wf_name: e.target.value
            })
        } else if (mode == "UpdateBy") {
            this.setState({
                updated_by: e.target.value
            })
        } else if (mode == "UpdateOn") {
            this.setState({
                updated_on: e.target.value
            })
        }
    }

    handleAddRow = () => {
        const item = {
            wf_id: "",
            characteristic: "",
            characteristic_name: "",
            object_id: "",
            rel_code: "",
            x_value_name: "",
            x_operator: "",
            x_value: ""
        };

        const itemSelect = {
            conditionid: "",
            conditionname: ""
        };

        this.setState({
            rows: [...this.state.rows, item],
            rowsSelect: [...this.state.rowsSelect, itemSelect]
        });
    };

    handleRemoveRow = () => {
        this.setState({
            rows: this.state.rows.slice(0, -1)
        });
    };

    handleRemoveSpecificRow = (idx: number) => () => {
        const rows = [...this.state.rows]
        const rowsSelect = [...this.state.rowsSelect]
        rows.splice(idx, 1)
        rowsSelect.splice(idx, 1)
        this.setState({ rows, rowsSelect })
    }

    hadlechangeSwitchBtn = (value: any) => {
        if (value == true) {
            this.setState({
                active: "true"
            })
        } else (
            this.setState({
                active: "false"
            })
        )
    }

    handlechageCondition = (idx: any, value: any) => {
        var rowsub: Array<inititem_> = [];
        var rowsubSelect: Array<itemSelect> = [];
        rowsub = this.state.rows
        rowsubSelect = this.state.rowsSelect
        rowsub[idx].characteristic = value[0].conditionid
        rowsub[idx].characteristic_name = value[0].conditionname

        rowsubSelect[idx].conditionid = value[0].conditionid
        rowsubSelect[idx].conditionname = value[0].conditionname

        this.setState({ rows: rowsub, rowsSelect: rowsubSelect })
        setTimeout(() => {
            console.log(this.state.rows)
        }, 1000);
    }

    handlechageAutoType = (idx: any, value: any) => {
        console.log(value)
    }

    formatDMY = (dmy: string) => {

        if (dmy != undefined || dmy != null || dmy != " ") {
            var year = dmy.substring(0, 4);
            var month = dmy.substring(4, 6);
            var day = dmy.substring(6, 8);
            var Datevalue = "";
            if (dmy.length < 9) {
                Datevalue = year + '-' + month + '-' + day
            } else {
                var hour = dmy.substring(8, 10);
                var second = dmy.substring(10, 12);
                var millssecond = dmy.substring(12, 14);
                Datevalue = year + '-' + month + '-' + day + ' ' + hour + ':' + second + ':' + millssecond
            }

            return Datevalue;
        }
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { requestConfig, responseSearch } = this.props
        if (requestConfig == true) {
            console.log("this.state.active")
            console.log(this.state.active)
            let formData = { ...this.state.formData }
            const item = {
                wf_id: this.state.wf_id,
                wf_name: this.state.wf_name,
                created_by: this.state.created_by,
                created_on: this.state.created_on,
                updated_by: this.state.updated_by,
                updated_on: this.state.updated_on,
                active: this.state.active,
                wf_conditoin_list: this.state.rows,
                task_list: []
            };
            formData = item;
            this.setState(prevState => ({
                formData
            }));
            this.props.blindConfig(this.state.rows, formData)
        } else if (responseSearch != undefined && this.state.CheckresponseSearch == true) {

            if (responseSearch['wf_conditoin_list']) {
                var rowsSelect = this.state.rowsSelect
                var arraySelect: any = []
                for (let i = 0; i < responseSearch['wf_conditoin_list'].length; i++) {

                    if (responseSearch['wf_conditoin_list'].characteristic != "") {
                        let data = {}
                        data['conditionid'] = responseSearch['wf_conditoin_list'][i].characteristic
                        data['conditionname'] = responseSearch['wf_conditoin_list'][i].characteristic
                        arraySelect.push(data)
                    }
                }

                this.setState({
                    rowsSelect: arraySelect,
                    CheckresponseSearch: false,
                    wf_id: responseSearch['wf_id'],
                    rows: responseSearch['wf_conditoin_list'],
                    wf_name: responseSearch['wf_name'],
                    created_by: responseSearch['created_by'],
                    created_on: responseSearch['created_on'],
                    updated_by: responseSearch['updated_by'],
                    updated_on: responseSearch['updated_on'],
                    active: responseSearch['active'],
                    Checkreadonly: true
                })
            }
        }
    }

    componentWillUnmount() {
        this.setState({
            rows: [],
            rowsSelect: [],
            formData: {},
            wf_id: "",
            wf_name: "",
            created_by: "",
            created_on: "",
            updated_by: "",
            updated_on: "",
            active: "true",
        })
    }

    render() {
        const { CheckresponseSearch } = this.state
        return (
            <Container fluid={true}>
                <Row className='mt-3'>
                    <Col sm={6} md={4}>
                        <Form.Group>
                            <Form.Label>Workflow ID :</Form.Label>
                            <Form.Control type="text" placeholder="" name='' value={this.state.wf_id} onChange={this.handleChangehead('WorkflowID')} readOnly={this.state.Checkreadonly ? true : false} />
                        </Form.Group>
                    </Col>
                    <Col sm={6} md={4}>
                        <Form.Group>
                            <Form.Label>Create By :</Form.Label>
                            <Form.Control type="text" placeholder="" name='' value={this.state.created_by} onChange={this.handleChangehead('CreateBy')} readOnly={true} />
                        </Form.Group>
                    </Col>
                    <Col sm={6} md={4}>
                        <Form.Group>
                            <Form.Label>Create On :</Form.Label>
                            <Form.Control type="text" placeholder="" name='' value={this.formatDMY(String(this.state.created_on))} onChange={this.handleChangehead('CreateOn')} readOnly={true} />
                        </Form.Group>
                    </Col>
                    {/* <Col sm={6} md={4}>
                        <Form.Group>
                            <Form.Label>Workflow Type :</Form.Label>
                            <AutcoCompleteType handlechageAutoType={this.handlechageAutoType.bind(this)} />
                        </Form.Group>
                    </Col> */}
                    <Col sm={6} md={4}>
                        <Form.Group>
                            <Form.Label>Workflow Name :</Form.Label>
                            <Form.Control type="text" placeholder="" name='' value={this.state.wf_name} onChange={this.handleChangehead('WorkflowName')} />
                        </Form.Group>
                    </Col>
                    <Col sm={6} md={4}>
                        <Form.Group>
                            <Form.Label>Update By :</Form.Label>
                            <Form.Control type="text" placeholder="" name='' value={this.state.updated_by} onChange={this.handleChangehead('UpdateBy')} readOnly={true} />
                        </Form.Group>
                    </Col>
                    <Col sm={6} md={4}>
                        <Form.Group>
                            <Form.Label>Update On :</Form.Label>
                            <Form.Control type="text" placeholder="" name='' value={this.formatDMY(String(this.state.updated_on))} onChange={this.handleChangehead('UpdateOn')} readOnly={true} />
                        </Form.Group>
                    </Col>
                    <Col sm={6} md={4}>
                        {/* <SwitchBtnWorkflow hadlechangeSwitchBtn={this.hadlechangeSwitchBtn.bind(this)} valueSwitch={this.state.active} /> */}
                        {this.state.CheckresponseSearch ?
                            <SwitchBtnWorkflow hadlechangeSwitchBtn={this.hadlechangeSwitchBtn.bind(this)} /> :
                            <SwitchBtnWorkflow hadlechangeSwitchBtn={this.hadlechangeSwitchBtn.bind(this)} valueSwitch={this.state.active} />
                        }
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <Table className="table-bordered align-middle" hover size="sm">
                            <thead className='text-center thead-light'>
                                <tr className='text-center' style={{ width: '100%' }}>
                                    <th style={{ width: '3%' }}>#</th>
                                    {/* <th style={{ width: '25%' }}>Relation code</th> */}
                                    <th style={{ width: '27%' }}>Parameter ID</th>
                                    <th style={{ width: '20%' }}>Operator</th>
                                    <th style={{ width: '25%' }}>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.rows.map((item, idx) => (
                                    <tr id="addr0" key={idx}>
                                        <td className='text-center'><Button className="mx-2" variant="danger" size="sm" onClick={this.handleRemoveSpecificRow(idx)}><FaTrashAlt /></Button></td>
                                        {/* <td>
                                                <Form.Control as="select" onChange={this.handleChange(idx, 'Relcode')} value={this.state.rows[idx].rel_code}>
                                                    <option value="" hidden={true}>Please Select..</option>
                                                    <option value="AND">AND</option>
                                                    <option value="OR">OR</option>
                                                </Form.Control>
                                        </td> */}
                                        <td>
                                            <AutcoCompleteConditionID handlechageCondition={this.handlechageCondition.bind(this, idx)} valueData={this.state.rowsSelect[idx]} valueWorflow={this.state.rows[idx].characteristic} />
                                            {/* <FormControl
                                                placeholder=""
                                                // value={this.state.rows[idx].c}
                                                onChange={this.handleChange(idx, 'Condition')}
                                            /> */}
                                            {/* <AutcoCompleteConditionID /> */}
                                        </td>
                                        <td>
                                            <Form.Control as="select" onChange={this.handleChange(idx, 'Operator')} value={this.state.rows[idx].x_operator}>
                                                <option value="" hidden={true}>Please Select..</option>
                                                <option value="=">{"="}</option>
                                                <option value=">">{">"}</option>
                                                <option value=">=">{">="}</option>
                                                <option value="<">{"<"}</option>
                                                <option value="<=">{"<="}</option>
                                                <option value="<>">{"<>"}</option>
                                            </Form.Control>
                                        </td>
                                        <td>
                                            <FormControl
                                                placeholder=""
                                                value={this.state.rows[idx].x_value}
                                                onChange={this.handleChange(idx, 'Value')}
                                            />
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td className='text-center'><Button className="mx-2" color="primary" size="sm" onClick={this.handleAddRow}><FaPlusCircle /></Button></td>
                                    {/* <td></td> */}
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        )
    }
}
export default WorkflowComponent
