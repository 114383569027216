import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

import * as Models from '../../models/log/Log'

interface BeforeLoadLog {
    type: 'LOAD_Log'
}
interface LoadLog {
    type: 'LOADLog'
    response: Models.ResponseLog
    status: Number,
    statusMessage: String
}
interface FailLoadLog {
    type: 'LOADLogFAIL'
    status: number,
    statusMessage: any
}

interface ClearLoadLog {
    type: 'CLEARLOADLog'
    status: number,
    statusMessage: any
}

type KnownAction = LoadLog | FailLoadLog | ClearLoadLog | BeforeLoadLog

export const actionCreators = {
    requestLog: (p: boolean, method: String, sids: string[], type: any, page: any, page_size: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestLogInbox')
        if (appState && appState.workflowInbox && p && method == 'POST') {
            //console.log("post")
            //console.log(wf_id)
            //console.log(sid)
            var sid = ""
            sids.forEach(element => {
                sid += "?sid=" + element
            });
            fetch('/v1/Log/Get' + sid + '&type=' + type + '&page=' + page + '&page_size=' + page_size, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
            })
                .then((response) => {
                    //console.log("requestLogInbox")
                    //console.log(response)
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log(err)
                            dispatch({ type: 'LOADLogFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            dispatch({ type: 'LOADLog', response: data as Models.ResponseLog, status: 200, statusMessage: '' });
                        })
                    }
                })

            dispatch({ type: 'LOAD_Log' });

        } else if (method == "CLEAR") {
            console.log("dispatch : CLEARLOADLog")
            dispatch({ type: 'CLEARLOADLog', status: 400, statusMessage: "" });
        }
    },
}

const unloadedState: Models.GetLogState = { isLoadLog: false };

export const reducer: Reducer<Models.GetLogState> = (state: Models.GetLogState | undefined, incomingAction: Action): Models.GetLogState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_Log':
            console.log("RESET_LOADLOGINBOX")
            return {
                isLoadLog: false,
            }

        case 'LOADLog':
            console.log("LOADLOGINBOX")
            return {
                isLoadLog: true,
                responseLog: action.response,
                statusLog: action.status
            }

        case 'LOADLogFAIL':
            return {
                isLoadLog: true,
                messageLog: action.statusMessage,
                statusLog: action.status
            }
        case 'CLEARLOADLog':
            return {
                isLoadLog: false,
                messageLog: "",
                statusLog: 400
            }
        default: return state;
    }
}