import * as React from "react";
import { connect } from "react-redux";
import Select from 'react-dropdown-select';
import { ApplicationState } from "../../store";

import * as AutocompleteWorkflowGroupStore from '../../store/autocomplete/WorkflowGroup'
import * as Models from '../../models/autocomplete/WorkflowGroup'

type autocompleteWorkflowGroupProps = Models.GetGroupState & typeof AutocompleteWorkflowGroupStore.actionCreators

export interface inititem {
    code: string,
    name: string,
    display: string,
};

class AutcoCompleteWorkflowGroup extends React.Component<any, { response: any, p: boolean, dataResult: Array<inititem>, dataSearch: {}, checkSearch: boolean, checkResult: boolean, company? : string }, autocompleteWorkflowGroupProps> {
    constructor(props: any) {
        super(props);

        this.state = {
            response: "",
            p: true,
            dataResult: [],
            dataSearch: {},
            checkSearch: true,
            checkResult: true,
            company : undefined
        }
    }

    componentDidMount() {
        var company = this.props.company
        if (company != this.state.company) {
            if (company) {
                this.props.requestWorkflowgroup(this.state.p, "POST" , company)
            }

            this.setState({
                company: company,
                p: false
            });
        }
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { status, valueDataworkflow, response } = this.props
        if (status == 200 && this.state.p == false && this.state.checkResult && response['result_list'] != null) {
            this.setState({
                dataResult: response['result_list'],
                checkResult: false
            })
        }
        if (valueDataworkflow['position_group_id'] != "" && this.state.checkSearch == true) {
            var blindAuto = this.state.dataSearch
            var landingauto: inititem = {
                code: valueDataworkflow['position_group_id'],
                name: valueDataworkflow['position_group_name'],
                display: valueDataworkflow['position_group_id'] + " : " + valueDataworkflow['position_group_name']
            }
            blindAuto = landingauto
            this.setState({
                dataSearch: blindAuto,
                checkSearch: false
            })

            setTimeout(() => {
                //console.log(this.state.dataSearch)
                // var searchinit = this.state.dataResult
                // console.log("searchinit")
                // console.log(searchinit.find((opt: inititem) => opt.code === valueData['position_group_id']))
            }, 1000);
        }
    }


    render() {
        const { status, valueDataworkflow, response } = this.props
      /*  if (status == 200 && this.state.p == false && this.state.checkResult && response['result_list'] != null) {
            this.setState({
                dataResult: response['result_list'],
                checkResult: false
            })
        }*/
        return (
            <Select
                options={this.state.dataResult}
                searchBy="code"
                labelField="display"
                valueField="code"
                //values={[this.state.dataSearch]}
                values={[this.props.valueDataworkflow]}
                dropdownGap={5}
                required={true}
                onChange={(value) => this.props.handlechageAutocomplete(value)}
            />
        )
    }
}
export default connect((state: ApplicationState) => ({ ...state.appState, ...state.autocompleteWorkflowGroup }), AutocompleteWorkflowGroupStore.actionCreators)(AutcoCompleteWorkflowGroup)