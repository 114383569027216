import axios from 'axios';
import {
    BufferLogRequest,
    BufferLogResponse,
    BufferLogResponseList,
    BufferStatResponse
} from '../schemas/LogBuffer.shema';
import { ResponseList } from 'models/Response';

export function retrieveLogBuffer(params: BufferLogRequest) {
    return axios.get<BufferLogResponse>('/v1/log/buffer', {
        params,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('WF_APPLICATION')
        }
    });
}

export function retrieveExportLogBuffer(params: BufferLogRequest) {
    return axios.get<BufferLogResponseList[]>('/v1/log/export/buffer', {
        params,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('WF_APPLICATION')
        }
    });
}

export function retrieveBufferStat() {
    return axios.get<ResponseList<BufferStatResponse>>('/v1/buffer/stat', {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('WF_APPLICATION')
        }
    });
}
