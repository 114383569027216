import {
    DatasourceReturnType,
    ResponseError
} from 'models/common/respone.model';
import {
    AdminMasterEmployeeResponse,
    CreateAdminMasterEmployeeParams,
    ActionAdminMasterEmployeeResponse,
    MasterEmployeeResponse,
    RoleMasterEmployeeResponse,
    UpdateRoleMasterEmployeeRequest,
    DeleteRoleMasterEmployeeRequest,
    WorkflowAdminReqeust
} from '../schemas/MasterEmployee.shema';
import {
    callCreateRoleMasterEmployee,
    callDeleteAdminMasterEmployee,
    callDeleteRoleMasterEmployee,
    callUpdateRoleMasterEmployee,
    createAdminMasterEmployee,
    retrieveAdminMasterEmployee,
    retrieveMasterEmployee,
    retrieveRoleMasterEmployee
} from '../datasources/MasterEmployee.datasource';

export async function getAdminMasterEmployee(
    params: WorkflowAdminReqeust
): Promise<DatasourceReturnType<ResponseError, AdminMasterEmployeeResponse[]>> {
    try {
        const data = (await retrieveAdminMasterEmployee(params)).data;

        return [null!, data];
    } catch (error) {
        throw error;
    }
}

export async function AddAdminMasterEmployee(
    params: CreateAdminMasterEmployeeParams
): Promise<
    DatasourceReturnType<ResponseError, ActionAdminMasterEmployeeResponse>
> {
    try {
        const data = (await createAdminMasterEmployee(params)).data;

        return [null!, data];
    } catch (error) {
        throw error;
    }
}

export async function getMasterEmployee(): Promise<
    DatasourceReturnType<ResponseError, MasterEmployeeResponse>
> {
    try {
        const data = (await retrieveMasterEmployee()).data;

        return [null!, data];
    } catch (error) {
        throw error;
    }
}

export async function deleteAdminMasterEmployee(params: {
    userId: string;
}): Promise<
    DatasourceReturnType<ResponseError, ActionAdminMasterEmployeeResponse>
> {
    try {
        const data = (await callDeleteAdminMasterEmployee(params)).data;

        return [null!, data];
    } catch (error) {
        throw error;
    }
}

export async function getRoleMasterEmployee(): Promise<
    DatasourceReturnType<ResponseError, RoleMasterEmployeeResponse[]>
> {
    try {
        const data = (await retrieveRoleMasterEmployee()).data;

        return [null!, data];
    } catch (error) {
        throw error;
    }
}

export async function createRoleMasterEmployee(
    body: UpdateRoleMasterEmployeeRequest
): Promise<DatasourceReturnType<ResponseError, string>> {
    try {
        const data = (await callCreateRoleMasterEmployee(body)).data;

        return [null!, data];
    } catch (error) {
        throw error;
    }
}

export async function updateRoleMasterEmployee(
    body: UpdateRoleMasterEmployeeRequest
): Promise<DatasourceReturnType<ResponseError, string>> {
    try {
        const data = (await callUpdateRoleMasterEmployee(body)).data;

        return [null!, data];
    } catch (error) {
        throw error;
    }
}

export async function deleteRoleMasterEmployee(
    params: DeleteRoleMasterEmployeeRequest
): Promise<DatasourceReturnType<ResponseError, string>> {
    try {
        const data = (await callDeleteRoleMasterEmployee(params)).data;

        return [null!, data];
    } catch (error) {
        throw error;
    }
}
