import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

import * as Models from '../../models/workflow/WorkflowTypeReportAdmin'

interface BeforeLoadWorkflowType {
    type: 'LOAD_WORKFLOWTYPE'
}
interface LoadWorkflowType {
    type: 'LOADWORKFLOWTYPE'
    response: Models.ResponseWorkflowTypeReportAdmin
    status: Number,
    statusMessage: String
}
interface FailLoadWorkflowType {
    type: 'LOADWORKFLOWTYPEFAIL'
    status: number,
    statusMessage: any
}

interface ClearLoadWorkflowType {
    type: 'CLEARLOADWORKFLOWTYPE'
    status: number,
    statusMessage: any
}

type KnownAction = LoadWorkflowType | FailLoadWorkflowType | ClearLoadWorkflowType | BeforeLoadWorkflowType

export const actionCreators = {
    requestWorkflowTypeReportAdmin: (p: boolean, method: string, user_id: string, app_id: string[], searchReq: Models.searchValue, wfStatus: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestWorkflowTypeReportAdmin')
        if (appState && appState.workflowInbox && p && method == 'POST') {
            var app_ids = ""
            app_id.forEach(element => {
                app_ids += "&app_id=" + element
            });

            var wfstatus = ""
            wfStatus.forEach(element => {
                wfstatus += "&wf_status=" + element
            });
            var createdOnTo = searchReq.createByTo
            //if (createdOnTo) {
            //    createdOnTo += '235900';
            //}
            var updatedOnTo = searchReq.updatedTo
            if (updatedOnTo) {
                updatedOnTo += '235900';
            }

            var createdfrom = searchReq.createBy
            //if (createdfrom) {
            //    createdfrom += '000000'
            //}

            var updatefrom = searchReq.updatedFrom
            if (updatefrom) {
                updatefrom += '000000'
            }
            console.log(searchReq)
            if (searchReq.userName != "") {
                if (searchReq.userName == "*") {
                    user_id = "";
                } else {
                    user_id = searchReq.userName.toString()
                }
            }
            fetch('/v4/workflow/admin/report/type?user_id=' + user_id + app_ids + wfstatus + '&doc_no=' + searchReq.wfNo + '&doc_year=' + searchReq.fiscalYear + '&doc_year=' + searchReq.fiscalYear + '&doc_date_from=' + createdfrom + '&doc_date_to=' + createdOnTo + '&updated_from=' + updatefrom + '&updated_to=' + updatedOnTo + '&requester_id=' + searchReq.requester_id + '&amount_from=' + searchReq.docAmount + '&amount_to=' + searchReq.docAmountTo, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            //console.log(err)
                            dispatch({ type: 'LOADWORKFLOWTYPEFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            dispatch({ type: 'LOADWORKFLOWTYPE', response: data as Models.ResponseWorkflowTypeReportAdmin, status: 200, statusMessage: '' });
                        })
                    }
                })

            dispatch({ type: 'LOAD_WORKFLOWTYPE' });

        } else if (method == "CLEAR") {
            dispatch({ type: 'CLEARLOADWORKFLOWTYPE', status: 400, statusMessage: "" });
        }
    },
}

const unloadedState: Models.GetWorkflowTypeReportAdminState = { isLoadWorkflowTypeReport: false };

export const reducer: Reducer<Models.GetWorkflowTypeReportAdminState> = (state: Models.GetWorkflowTypeReportAdminState | undefined, incomingAction: Action): Models.GetWorkflowTypeReportAdminState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_WORKFLOWTYPE':
            return {
                isLoadWorkflowTypeReport: false,
            }

        case 'LOADWORKFLOWTYPE':
            return {
                isLoadWorkflowTypeReport: true,
                responseWorkflowTypeReport: action.response,
                statusWorkflowTypeReport: action.status
            }

        case 'LOADWORKFLOWTYPEFAIL':
            return {
                isLoadWorkflowTypeReport: true,
                messageWorkflowTypeReport: action.statusMessage,
                statusWorkflowTypeReport: action.status
            }
        case 'CLEARLOADWORKFLOWTYPE':
            return {
                isLoadWorkflowTypeReport: false,
                messageWorkflowTypeReport: "",
                statusWorkflowTypeReport: 400
            }
        default: return state;
    }
}