import {
    RoleMasterEmployeeResponse,
    UpdateRoleMasterEmployeeRequest
} from 'data/MasterEmployee/schemas/MasterEmployee.shema';
import React, { useState } from 'react';
import { Button, Row, Col, Table, InputGroup, Form } from 'react-bootstrap';
import { FaArrowCircleLeft, FaPlusCircle } from 'react-icons/fa';
import { InputGroupAddon, InputGroupText, Input } from 'reactstrap';

type Props = {
    action: string;
    handleChangeAction: (action: string) => void;
    roleMasterEmployee?: RoleMasterEmployeeResponse;
    handleSaveChange: (
        request: UpdateRoleMasterEmployeeRequest,
        mode: string
    ) => void;
};

function AddRoleMasterEmployee(props: Props) {
    const { action, roleMasterEmployee, handleChangeAction, handleSaveChange } =
        props;

    const [positionName, setPositionName] = useState(
        action === 'edit' && roleMasterEmployee
            ? roleMasterEmployee.positionname
            : ''
    );

    const [positionCode, setPositionCode] = useState(
        action === 'edit' && roleMasterEmployee
            ? roleMasterEmployee.positioncode
            : ''
    );

    const handleSave = () => {
        const request: UpdateRoleMasterEmployeeRequest = { Sid: '*', PositionCode: positionCode, PositionName: positionName, ActionBy: '' }

        handleSaveChange(request, action)
    };

    return (
        <div>
            <div className="master-data-content">
                <div
                    className="trapezoid-head"
                    style={{ width: '200px' }}
                >
                    <span>Role Master Employee</span>
                </div>
                <div className="mat-box">
                    <Row>
                        <Col>
                            <div className="container">
                                <div>
                                    <div
                                        className="trapezoid trapezoid-purple"
                                        style={{
                                            width: 'auto',
                                            display: 'inline-block'
                                        }}
                                    >
                                        <span>Role Master Employee</span>
                                    </div>
                                    <div
                                        style={{
                                            display: 'inline',
                                            float: 'right'
                                        }}
                                    >
                                        <Button
                                            variant="success"
                                            size="sm"
                                            onClick={() => {
                                                handleChangeAction('main');
                                            }}
                                        >
                                            <FaArrowCircleLeft />
                                            &nbsp;Back
                                        </Button>
                                    </div>
                                </div>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            Position Code
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        value={positionCode}
                                        disabled={
                                            action === 'edit' &&
                                            roleMasterEmployee !== undefined
                                        }
                                        placeholder="Position Code"
                                        type="text"
                                        onChange={e => {
                                            setPositionCode(e.target.value);
                                        }}
                                    />
                                </InputGroup>
                                <br />
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            Position Name
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        value={positionName}
                                        placeholder="Position Name"
                                        type="text"
                                        onChange={e => {
                                            setPositionName(e.target.value);
                                        }}
                                    />
                                </InputGroup>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div
                                style={{
                                    display: 'inline',
                                    float: 'right',
                                    paddingTop: '10px'
                                }}
                            >
                                <Button
                                    className="mx-2"
                                    color="primary"
                                    size="sm"
                                    onClick={handleSave}
                                >
                                    <FaPlusCircle />
                                    &nbsp;Save
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default AddRoleMasterEmployee;
