import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

import * as Models from '../../models/autocomplete/ConditionSearch'

interface LoadConditionSearch {
    type: 'LOAD_CONDITION_SEARCH'
}

interface LoadedConditionSearch {
    type: 'LOADEDCONDITIONSEARCG'
    response: Models.ResponseCondition
    status: Number,
    statusMessage: String
}
interface FailLoadedConditionSearch {
    type: 'LOADFAIL'
    status: number,
    statusMessage: any
}
interface ClearConditionSearch {
    type: 'CLEAR_CONDITION_SEARCH'
}

type KnownAction = LoadedConditionSearch | FailLoadedConditionSearch | LoadConditionSearch | ClearConditionSearch


export const actionCreators = {
    requestConditionSearch: (p: boolean, method: String, app_id: Array<string>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.conditionSearch && p && method == 'POST') {
            var condition: string = "";
            if (app_id != null && app_id.length != 0) {
                for (var i = 0; i < app_id.length; i++) {
                    if (i == 0) {
                        condition += "?app_id=" + app_id[i]
                    } else {
                        condition += "&app_id=" + app_id[i]
                    }
                }
            }
            fetch(`/v1/Autocomplete/Condition` + condition, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then(data => {
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('dispatch : LOADFAIL')
                            dispatch({ type: 'LOADFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            console.log('dispatch : LOADEDCONDITIONSEARCG')
                            dispatch({ type: 'LOADEDCONDITIONSEARCG', response: data as Models.ResponseCondition, status: 200, statusMessage: '' });
                        })
                    }
                })
            dispatch({ type: 'LOAD_CONDITION_SEARCH' });
        } else if (method == "CLEAR") {
            dispatch({ type: 'CLEAR_CONDITION_SEARCH' });
        }
    }
}

const unloadedState: Models.GetConditionState = { isLoad: false, };

export const reducer: Reducer<Models.GetConditionState> = (state: Models.GetConditionState | undefined, incomingAction: Action): Models.GetConditionState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_CONDITION_SEARCH':
            return {
                isLoad: false,
            }
        case 'LOADEDCONDITIONSEARCG':
            return {
                isLoad: true,
                response: action.response,
                status: action.status
            }

        case 'LOADFAIL':
            return {
                isLoad: true,
                message: action.statusMessage,
                status: action.status
            }

        case 'CLEAR_CONDITION_SEARCH':
            return {
                isLoad: false,
                message: '',
                status: 0
            }
        default: return state;
    }
}