import './custom.css'

import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import Login from './components/auth/SignInComponent';
import PositionEmployee from './components/positionemployee/PositionEmployee';
import AddPositionEmployee from './components/positionemployee/AddPositionEmployee'
import PositionGroup from './components/positiongroup/PositionGroup'
import AddPositionGroup from './components/positiongroup/AddPositionGroup'
import Workflow from './components/workflow/Workflow'
import AddWorkflow from './components/workflow/AddWorkflow'
import SignOut from './components/auth/SignOutComponent'

import { Modal, Spinner } from "react-bootstrap";
import Loader from 'react-loader-advanced';

import ViewportProvider from './context/ViewportProvider';
import LoadingProvider from 'context/LoaderProvider';

export default () => (
    <LoadingProvider>
        <Loader show={true} message={<Spinner animation="border" />}>
            <ViewportProvider>
                <Layout>
                    {/* <Route exact path='/' component={Home} />
            <Route path='/counter' component={Counter} />
            <Route path='/roleemployee' component={PositionEmployee} />
            <Route path='/addpositionemployee' component={AddPositionEmployee} />
            <Route path='/authoritygroup' component={PositionGroup} />
            <Route path='/addauthoritygroup' component={AddPositionGroup} />
            <Route path='/workflow' component={WorkFlow} />
            <Route path='/addworkflow' component={AddWorkFlow} />
            <Route path='/login' component={Login} />
        <Route path='/logout' component={SignOut} /> */}
                </Layout>
            </ViewportProvider>
        </Loader>
    </LoadingProvider>
);
