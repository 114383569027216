import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { parseJwt } from '../../modules/Common';
// models
import * as Models from '../../models/workflow/CreateCustom'

interface LoadCreateCustom {
    type: 'LOADCREATECUSTOM'
    response: Models.CreateCustomRes
    status: Number,
    statusMessage: String
}
interface FailLoadCreateCustom {
    type: 'LOADFAILCREATECUSTOM'
    status: number,
    statusMessage: any
}
interface ClearCreateCustom {
    type: 'CLEARCREATECUSTOM'
    response: Models.CreateCustomRes
    status: Number,
    statusMessage: String
}

type KnownAction = LoadCreateCustom | FailLoadCreateCustom | ClearCreateCustom

export const actionCreators = {
    requestCreateCustom: (p: boolean, method: string, wf_approve_list: Array<Models.wf_approve_list>, wf_header: string, wf_detail: string,list_file: any , app_id : string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestCreateworkflow')
        if (appState && appState.createCustom && wf_approve_list && p && method == 'POST') {
            var list: any = []
            for (var i = 0; i < wf_approve_list.length; i++) {
                var list_id: any = []
                for (var a = 0; a < wf_approve_list[i]['wf_approve_list'].length; a++) {
                    list_id.push(wf_approve_list[i]['wf_approve_list'][a].wf_id)
                }
                list.push(list_id)
            }
            console.log("list :")
            //var list: any = []
            //list.push(listInner);
            console.log(list)

            var localUserId = ""
            if (localStorage.getItem("WF_APPLICATION") != undefined) {

                var ls: any = localStorage.getItem("WF_APPLICATION");
                var jls: any = JSON.parse(ls)
                var access_token = parseJwt(jls.access_token)
                var extra = JSON.parse(access_token.extra)
                console.log(extra)

                if (extra.citizen_id) {
                    console.log('Azure Login ID: ' + extra.citizen_id)
                    localUserId = extra.citizen_id
                } else if (extra.username) {
                    console.log('Local Login ID: ' + extra.sid)
                    localUserId = extra.username
                }
            }

            var body = {
                'doc_subject': wf_header,
                'requestor_message': wf_detail,
                'requestor_id': localUserId,
                'wf_approve_list': list,
                'user_login_id': localUserId,
                //'sid': "",
                //'companycode': "",
                'list_dms': JSON.stringify(list_file),
                'app_id': app_id
            }
            console.log(JSON.stringify(body))
            await fetch(`/v4/Workflow/CreateCustom`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
                body: JSON.stringify(body),
            })
                .then(response => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('dispatch : LOADFAILCREATECUSTOM')
                            dispatch({ type: 'LOADFAILCREATECUSTOM', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            dispatch({ type: 'LOADCREATECUSTOM', response: data as Models.CreateCustomRes, status: 200, statusMessage: '' });
                        })
                    }
                })
        } else if (method == "CLEAR") {
            dispatch({ type: 'CLEARCREATECUSTOM', response: [] as any, status: 400, statusMessage: '' });
        }
    }
}

const unloadedState: Models.CreateCustomState = { isLoadCreateCustom: false, responseCreateCustom: [] as any };

export const reducer: Reducer<Models.CreateCustomState> = (state: Models.CreateCustomState | undefined, incomingAction: Action): Models.CreateCustomState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOADCREATECUSTOM':
            return {
                isLoadCreateCustom: true,
                responseCreateCustom: action.response,
                statusCreateCustom: action.status
            }

        case 'LOADFAILCREATECUSTOM':
            return {
                isLoadCreateCustom: true,
                messageCreateCustom: action.statusMessage,
                responseCreateCustom: [] as any,
                statusCreateCustom: action.status
            }
        case 'CLEARCREATECUSTOM':
            return {
                isLoadCreateCustom: false,
                responseCreateCustom: action.response,
                statusCreateCustom: action.status
            }
        default: return state;
    }
}