
// employee
import * as PositionEmployee from './employee/PositionEmployee'
import * as ModelsEmployee from '../models/employee/PositionEmployee'

import * as SearchEmployee from './employee/SearchEmployee'
import * as ModelsSearchEmployee from '../models/employee/SearchEmployee'

import * as CreateEmployee from './employee/CreateEmployee'
import * as ModelsCreateEmployee from '../models/employee/CreateEmployee'

import * as DeleteEmployee from './employee/DeleteEmployee'
import * as ModelsDeleteEmployee from '../models/employee/DeleteEmployee'

// Group
import * as PositionGroup from './group/PositionGroup'
import * as ModelsGroup from '../models/group/PositionGroup'

import * as CreateGroup from './group/CreateGroup'
import * as ModelsCreateGroup from '../models/group/CreateGroup'

import * as DeleteGroup from './group/DeleteGroup'
import * as ModelsDeleteGroup from '../models/group/DeleteGroup'

import * as SearchGroup from './group/SearchGroup'
import * as ModelsSearchGroup from '../models/group/SearchGroup'

// Workflow
import * as WorkflowConfig from './workflow/WorkflowConfig'
import * as ModelsWorkfow from '../models/workflow/WorkflowConfig'

import * as CreateWorkflow from './workflow/CreateWorkflow'
import * as ModelsCreateWorkflow from '../models/workflow/CreateWorkflow'

import * as DeleteWorkflow from './workflow/DeleteWorkflow'
import * as ModelsDeleteWorkflow from '../models/workflow/DeleteWorkflow'

import * as searchWorkflow from './workflow/SearchWorkflow'
import * as ModelsearchWorkflows from '../models/workflow/SearchWorkflow'

import * as WorkflowDashboard from './workflow/WorkflowDashboard'
import * as ModelWorkflowDashboard from '../models/workflow/WorkflowInbox'

import * as SearchWorkflowDashboard from './workflow/SearchWorkflowDashboard'
import * as ModelssearchworkflowDashboard from '../models/workflow/SearchWorkflowInbox'

import * as WorkflowTypeInbox from './workflow/WorkflowType'
import * as ModelsWorkflowTypeInbox from '../models/workflow/WorkflowType'

import * as WorkflowTypeReportAdmin from './workflow/WorkflowTypeReportAdmin'
import * as ModelsWorkflowTypeReportAdmin from '../models/workflow/WorkflowTypeReportAdmin'

import * as WorkflowReportAdmin from './workflow/WorkflowReportAdmin'
import * as ModelsWorkflowReportAdmin from '../models/workflow/WorkflowInbox'

// etc
import * as ConditionSearch from './autocomplete/ConditionSearch'
import * as ModelsConditionSearch from '../models/autocomplete/ConditionSearch'

import * as AutocompleteGroup from './autocomplete/GroupSearch'
import * as ModelsAutocompleteGroup from '../models/autocomplete/GroupSearch'

import * as autoCompleteWorkflowGroup from './autocomplete/WorkflowGroup'
import * as ModelsWorkflowGroup from '../models/autocomplete/WorkflowGroup'

import * as signInWorkflow from './auth/SignIn'
import * as ModelsSignIn from '../models/auth/SignIn'

import * as CreateConditionID from './condition/CreateConditionID'
import * as ModelsCreateConditionID from '../models/condition/CreateConditionID'

import * as ConditionMaster from './condition/ConditionMaster'
import * as ModelsCreateConditionMaster from '../models/condition/ConditionMaster'

import * as DeleteConditionMaster from './condition/DeleteConditionMaster'
import * as ModelsDeleteConditionMaster from '../models/condition/DeleteConditionMaster'

import * as AutocompleteParameterID from './autocomplete/ParameterID'
import * as ModelsAutocompleteParam from '../models/autocomplete/ParameterID'

import * as TypeMaster from './type/TypeMaster'
import * as ModelsTypeMaster from '../models/type/TypeMaster'

import * as CreateTypeMaster from './type/CreateTypeMaster'
import * as ModelsCreateTypeMaster from '../models/type/CreateTypeMaster'

import * as DeleteTypeMaster from './type/DeleteTypeMaster'
import * as ModelsDeleteTypeMaster from '../models/type/DeleteTypeMaster'

import * as WorkflowInbox from './workflow/WorkflowInbox'
import * as Modelsworkflowinbox from '../models/workflow/WorkflowInbox'

import * as SearchWorkflowInbox from './workflow/SearchWorkflowInbox'
import * as ModelssearchworkflowInbox from '../models/workflow/SearchWorkflowInbox'

import * as ApproveRejectWorkflow from './workflow/ApproveRejectWorkflow'
import * as ModelsApproveRejectWorkflow from '../models/workflow/ApproveRejectWorkflow'

import * as CallbackConfigMaster from './callbackconfig/CallbackMaster'
import * as ModelsCallbackConfigMaster from '../models/callbackconfig/CallbackMaster'

import * as CallbackTokens from './callbackconfig/CallbackTokens'
import * as ModelsCallbackTokens from '../models/callbackconfig/CallbackTokens'

import * as CreateCallback from './callbackconfig/CreateCallback'
import * as ModelsCreateCallback from '../models/callbackconfig/CreateCallback'

import * as CreateCustom from './workflow/CreateCustom'
import * as ModelsCreateCustom from '../models/workflow/CreateCustom'

import * as MasterEmployeeStore from './callbackconfig/MasterEmployee'
import * as MasterEmployeeModels from '../models/callbackconfig/MasterEmployee'

import * as RemarkModels from '../models/remark/Remark'
import * as RemarkStore from './remark/Remark'

import * as MasterCompanyModels from '../models/company/MasterCompany'
import * as MasterCompanyStore from './company/MasterCompany'

import * as BatchDetailModels from '../models/workflow/BatchDetail'
import * as BatchDetailStore from './workflow/BatchDetail'

import * as ApproveRejectWorkflowDashboard from './workflow/ApproveRejectWorkflowDashboard'
import * as ModelsApproveRejectWorkflowDashboard from '../models/workflow/ApproveRejectWorkflowDashboard'

import * as ApproveRejectWorkflowAdmin from './workflow/ApproveRejectWorkflowAdmin'
import * as ModelsApproveRejectWorkflowAdmin from '../models/workflow/ApproveRejectWorkflowAdmin'

import * as ApproveRejectWorkflowInformation from './workflow/ApproveRejectWorkflowInformation'
import * as ModelsApproveRejectWorkflowInformation from '../models/workflow/ApproveRejectWorkflowInformation'

import * as AdminDetailModels from '../models/admin/MasterAdmin'
import * as AdminDetailStore from './admin/AdminDetail'

// Log
import * as LogType from './log/LogType'
import * as ModelsLogType from '../models/log/LogType'

import * as Log from './log/Log'
import * as ModelsLog from '../models/log/Log'

// App State
import * as AppState from './AppState'
import * as ModelsAppState from '../models/AppState'

// WfWithwfType
import * as WfWithWfTypeStore from './workflow/CreateWorkflowWithWfType'
import * as  ModelsWfWithWfType from '../models/workflow/CreateWorkflowWithWfType'

import * as EmailLogStore from './log/EmailLog';
import * as EmailLog from '../models/log/EmailLog';

import * as WorkflowConditionStore from './workflowreport/WorkflowConditionConfigReport';
import * as WorkflowCondition from '../models/workflowreport/WorkflowCondition';

import * as WorkflowAssignmentStore from './workflowreport/WorkflowAssignmentRoutingReport';
import * as WorkflowAssignment from '../models/workflowreport/WorkflowAssignment';

import * as SimulateWorkflowStore from './simulateworkflow/SimulateWorkflow';
import * as SimulateWorkflow from '../models/simulateworkflow/SimulateWorkflow';

// The top-level state object
export interface ApplicationState {
    positionEmployee: ModelsEmployee.GetPositionEmployeeState | undefined;
    positionGroup: ModelsGroup.GetPositionGroupState | undefined;
    workflowConfig: ModelsWorkfow.GetWorkflowState | undefined;
    searchEmployee: ModelsSearchEmployee.SearchPositionEmployeeState | undefined;
    conditionSearch: ModelsConditionSearch.GetConditionState | undefined;
    createEmployee: ModelsCreateEmployee.CreatePositionEmployeeState | undefined;
    resCreateEmployee: ModelsCreateEmployee.ResCreatePositionEmployeeState | undefined;
    deleteEmployee: ModelsDeleteEmployee.DeletePositionEmployeeState | undefined;
    autoCompleteGroup: ModelsAutocompleteGroup.GetGroupState | undefined;
    createGroup: ModelsCreateGroup.CreatePositionGroupState | undefined;
    deleteGroup: ModelsDeleteGroup.DeleteGroupState | undefined;
    searchGroup: ModelsSearchGroup.SearchGrouptate | undefined;
    autocompleteWorkflowGroup: ModelsWorkflowGroup.GetGroupState | undefined;
    createWorkflow: ModelsCreateWorkflow.CreateWorkflowState | undefined;
    deleteWorkflow: ModelsDeleteWorkflow.DeleteWorkflowState | undefined;
    searchWorkflow: ModelsearchWorkflows.SearchWorkflowState | undefined;
    signIn: ModelsSignIn.SignInState | undefined;
    createCondtiotionID: ModelsCreateConditionID.CreateConditionIDState | undefined;
    conditionMaster: ModelsCreateConditionMaster.GetConditionMasterState | undefined;
    deleteconditionMaster: ModelsDeleteConditionMaster.DeleteConditionIDState | undefined;
    autocompleteParam: ModelsAutocompleteParam.GetParameterState | undefined;
    typeMaster: ModelsTypeMaster.GetTypeMasterState | undefined;
    createTypeMaster: ModelsCreateTypeMaster.CreateTypeState | undefined;
    deleteTypeMaster: ModelsDeleteTypeMaster.DeleteTypeState | undefined;
    workflowInbox: Modelsworkflowinbox.GetWorkflowInboxState | undefined;
    workflowDashboard: ModelWorkflowDashboard.GetWorkflowInboxState | undefined;
    searchworkflowInbox: ModelssearchworkflowInbox.GetWorkflowInboxValueState | undefined;
    searchworkflowDashboard: ModelssearchworkflowDashboard.GetWorkflowInboxValueState | undefined;
    approveRejectWorkflow: ModelsApproveRejectWorkflow.ApproveRejectWorkflowState | undefined;
    callbackConfig: ModelsCallbackConfigMaster.CallbackMasterState | undefined;
    callbackTokens: ModelsCallbackTokens.CallbackTokenState | undefined;
    createCallback: ModelsCreateCallback.SaveCallbackState | undefined;
    createCustom: ModelsCreateCustom.CreateCustomState | undefined;
    WorkflowTypeInbox: ModelsWorkflowTypeInbox.GetWorkflowTypeState | undefined;
    LogType: ModelsLogType.GetLogTypeState | undefined;
    Log: ModelsLog.GetLogState | undefined;
    appState: ModelsAppState.AppState | undefined;
    WorkflowTypeReportAdmin: ModelsWorkflowTypeReportAdmin.GetWorkflowTypeReportAdminState | undefined,
    workflowReportAdmin: ModelsWorkflowReportAdmin.GetWorkflowInboxState | undefined;
    WfWithWfType: ModelsWfWithWfType.CreateWfWithWfTypeState | undefined;
    MasterEmployee: MasterEmployeeModels.GetMasterEmployeeState | undefined;
    Remark: RemarkModels.GetRemarkState | undefined;
    MasterCompany: MasterCompanyModels.GetMasterCompanyState | undefined;
    BatchDetail: BatchDetailModels.GetBatchDetailState | undefined;
    approveRejectWorkflowDashboard: ModelsApproveRejectWorkflowDashboard.ApproveRejectWorkflowDashboardState | undefined;
    approveRejectWorkflowAdmin: ModelsApproveRejectWorkflowAdmin.ApproveRejectWorkflowAdminState | undefined;
    approveRejectWorkflowInformation: ModelsApproveRejectWorkflowInformation.ApproveRejectWorkflowInformationState | undefined;
    adminDetail: AdminDetailModels.GetAdminDetailState | undefined;
    emailLog: EmailLog.EmailLog | undefined;
    workflowCondition: WorkflowCondition.WorkflowCondition | undefined;
    workflowAssignment: WorkflowAssignment.WorkflowAssignmentStateStore | undefined;
    simulateWorkflow: SimulateWorkflow.SimulateWorkflow | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    positionEmployee: PositionEmployee.reducer,
    positionGroup: PositionGroup.reducer,
    workflowConfig: WorkflowConfig.reducer,
    searchEmployee: SearchEmployee.reducer,
    conditionSearch: ConditionSearch.reducer,
    createEmployee: CreateEmployee.reducer,
    resCreateEmployee: CreateEmployee.reducer,
    deleteEmployee: DeleteEmployee.reducer,
    autoCompleteGroup: AutocompleteGroup.reducer,
    createGroup: CreateGroup.reducer,
    deleteGroup: DeleteGroup.reducer,
    searchGroup: SearchGroup.reducer,
    autocompleteWorkflowGroup: autoCompleteWorkflowGroup.reducer,
    createWorkflow: CreateWorkflow.reducer,
    deleteWorkflow: DeleteWorkflow.reducer,
    searchWorkflow: searchWorkflow.reducer,
    signIn: signInWorkflow.reducer,
    createCondtiotionID: CreateConditionID.reducer,
    conditionMaster: ConditionMaster.reducer,
    deleteconditionMaster: DeleteConditionMaster.reducer,
    autocompleteParam: AutocompleteParameterID.reducer,
    typeMaster: TypeMaster.reducer,
    createTypeMaster: CreateTypeMaster.reducer,
    deleteTypeMaster: DeleteTypeMaster.reducer,
    workflowInbox: WorkflowInbox.reducer,
    workflowDashboard: WorkflowDashboard.reducer,
    searchworkflowInbox: SearchWorkflowInbox.reducer,
    searchworkflowDashboard: SearchWorkflowDashboard.reducer,
    approveRejectWorkflow: ApproveRejectWorkflow.reducer,
    callbackConfig: CallbackConfigMaster.reducer,
    callbackTokens: CallbackTokens.reducer,
    createCallback: CreateCallback.reducer,
    createCustom: CreateCustom.reducer,
    WorkflowTypeInbox: WorkflowTypeInbox.reducer,
    LogType: LogType.reducer,
    Log: Log.reducer,
    appState: AppState.reducer,
    WorkflowTypeReportAdmin: WorkflowTypeReportAdmin.reducer,
    workflowReportAdmin: WorkflowReportAdmin.reducer,
    WfWithWfType: WfWithWfTypeStore.rootReducer,
    MasterEmployee: MasterEmployeeStore.rootReducer,
    Remark: RemarkStore.rootReducer,
    MasterCompany: MasterCompanyStore.rootReducer,
    BatchDetail: BatchDetailStore.reducer,
    approveRejectWorkflowDashboard: ApproveRejectWorkflowDashboard.reducer,
    approveRejectWorkflowAdmin: ApproveRejectWorkflowAdmin.reducer,
    approveRejectWorkflowInformation: ApproveRejectWorkflowInformation.reducer,
    adminDetail: AdminDetailStore.reducer,
    emailLog: EmailLogStore.reducer,
    workflowCondition: WorkflowConditionStore.reducer,
    workflowAssignment: WorkflowAssignmentStore.reducer,
    simulateWorkflow: SimulateWorkflowStore.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
