import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/group/PositionGroup'

interface LoadPositionGroup {
    type: 'LOAD_POSITIONGROUP'
}

interface LoadedPositionGroup {
    type: 'LOADEDPOSITIONGROUP'
    response: Models.ResponsePositionGroup
    status: Number,
    statusMessage: String
}
interface FailLoadedPositionGroup {
    type: 'LOADFAIL'
    status: number,
    statusMessage: any
}


type KnownAction = LoadedPositionGroup | FailLoadedPositionGroup | LoadPositionGroup

export const actionCreators = {
    requestPositiongroup: (p: boolean, method: string, company: string, role_id: string, emp_id: string, authority_group: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.positionGroup && p && method == 'POST') {
            console.log('call actionCreators : requestPositiongroup')
            fetch(`/v2/Group/Get?app_id=` + company + `&role_id=` + role_id + `&emp_id=` + emp_id + `&authority_group=` + authority_group, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('errorrrr')
                            dispatch({ type: 'LOADFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            dispatch({ type: 'LOADEDPOSITIONGROUP', response: data as Models.ResponsePositionGroup, status: 200, statusMessage: '' });
                        })
                    }
                })
            dispatch({ type: 'LOAD_POSITIONGROUP' });
        }
    }
}

const unloadedState: Models.GetPositionGroupState = { isLoadPositionGroup: false };

export const reducer: Reducer<Models.GetPositionGroupState> = (state: Models.GetPositionGroupState | undefined, incomingAction: Action): Models.GetPositionGroupState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_POSITIONGROUP':
            return {
                isLoadPositionGroup: false,
            }

        case 'LOADEDPOSITIONGROUP':
            return {
                isLoadPositionGroup: true,
                response: action.response,
                status: action.status
            }

        case 'LOADFAIL':
            return {
                isLoadPositionGroup: true,
                message: action.statusMessage,
                status: action.status
            }
        default: return state;
    }
}