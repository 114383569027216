import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

import * as Models from '../../models/autocomplete/GroupSearch'

interface LoadGroupSearch {
    type: 'LOAD_GROUP_SEARCH'
}

interface LoadedGroupSearch {
    type: 'LOADEDGROUPSEARCH'
    response: Models.ResponseGroup
    status: Number,
    statusMessage: String
}

interface FailLoadedGroupSearch {
    type: 'LOADFAIL'
    status: number,
    statusMessage: any
}

interface ClearGroupSearch {
    type: 'CLEARFILE'
    response?: Models.ResponseGroup
    status: Number,
    statusMessage: String
}

type KnownAction = LoadedGroupSearch | FailLoadedGroupSearch | ClearGroupSearch | LoadGroupSearch

export const actionCreators = {
    requestGroupSearch: (status: boolean, method: string , company : string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestGroupSearch')
        if (appState && appState.autoCompleteGroup && status && method == 'POST') {
            fetch(`/v2/Autocomplete/Group?app_id=` + company, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then(data => {
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('dispatch : LOADFAIL')
                            dispatch({ type: 'LOADFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            console.log('dispatch : LOADEDGROUPSEARCH')
                            dispatch({ type: 'LOADEDGROUPSEARCH', response: data as Models.ResponseGroup, status: 200, statusMessage: '' });
                        })
                    }
                })
            dispatch({ type: 'LOAD_GROUP_SEARCH' });

        } else if (method == 'CLEAR') {
            console.log("CLEAR")
            dispatch({ type: 'CLEARFILE', response: undefined, status: 400, statusMessage: '' });
        }
    }
}

const unloadedState: Models.GetGroupState = { isLoad: false, };

export const reducer: Reducer<Models.GetGroupState> = (state: Models.GetGroupState | undefined, incomingAction: Action): Models.GetGroupState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_GROUP_SEARCH':
            return {
                isLoad: false,
            }

        case 'LOADEDGROUPSEARCH':
            return {
                isLoad: true,
                response: action.response,
                status: action.status
            }

        case 'LOADFAIL':
            return {
                isLoad: true,
                message: action.statusMessage,
                status: action.status
            }
        case 'CLEARFILE':
            return {
                isLoad: true,
                response: action.response,
                status: action.status
            }
        default: return state;
    }
}