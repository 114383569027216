import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Col, Table, Spinner, ButtonGroup } from 'react-bootstrap';
import { FaFileExport } from 'react-icons/fa';
import * as XLSX from 'xlsx';
/*import WorkflowLogSearchIdTab from '../log/WorkflowLogSearchIdTab';*/
/*import WorkflowConditionSearch from '../workflowreport/WorkflowConditionSearch';*/
import WorkflowAssignmentSearch from '../workflowreport/WorkflowAssignmentSearch';
import { ApplicationState } from '../../store';

import { actionCreators } from '../../store/workflowreport/WorkflowAssignmentRoutingReport';
import { ResponseList } from 'models/Response';
import { WorkflowAssignment } from 'models/workflowreport/WorkflowAssignment';

function WorkflowAssignmentRoutingReport() {
    const dispatch = useDispatch();
    const { workflowAssignment } = useSelector<
        Defined<ApplicationState>,
        Defined<Pick<ApplicationState, 'workflowAssignment'>>
    >(state => ({
        workflowAssignment: state.workflowAssignment
    }));

    const [filter, setFilter] = useState<'All' | 'Active' | 'InActive'>('All');

    const { isLoading, status } = workflowAssignment;

    const filteredReports = useMemo(() => {
        const { response } = workflowAssignment;

        if (!response) {
            return undefined;
        }

        if (filter === 'All') {
            return response.result_list;
        }

        const condition =
            filter === 'Active'
                ? (validToDate: Date) => validToDate >= new Date()
                : (validToDate: Date) => validToDate < new Date();

        return response.result_list.filter(report => {
            const validto = report.validto;
            const year = Number(validto.slice(0, 4));
            const month = Number(validto.slice(4, 6)) - 1;
            const day = Number(validto.slice(6, 8));

            const validtoDate = new Date(year, month, day);

            return condition(validtoDate);
        });
    }, [filter, workflowAssignment]);

    const handleSearch = (
        sids: string[],
        authgroup: string,
        positioncode: string,
        empid: string,
        isCheckAll: boolean
    ) => {
        if (isCheckAll) {
            sids = [''];
        }

        dispatch(
            actionCreators.requestWorkflowAssignment({
                method: 'GET',
                params: {
                    sids,
                    authgroup: authgroup,
                    positioncode: positioncode,
                    empid: empid
                }
            })
        );
    };

    const exportTemplate = () => {
        const response = filteredReports;

        const date = new Date();
        const filename =
            'WorkflowAssignmentReport_' + date.getTime().toString() + '.xlsx';

        document.getElementsByClassName('Loader__background')[0].className =
            'Loader__background show';

        const formDataReq: any = [];
        if (response && response) {
            response.map(assignment => {
                let data = {};
                data['sidname'] = assignment.sid_name;
                data['authgroup'] = assignment.authgroup;
                data['authgroupname'] = assignment.authgroup_name;
                data['positioncode'] = assignment.positioncode;
                data['positioncondition'] = assignment.position_condition;
                data['empid'] = assignment.empid;
                data['empname'] = assignment.emp_name;
                data['email_address'] = assignment.email_address;
                data['validfrom'] = assignment.validfrom;
                data['validto'] = assignment.validto;
                formDataReq.push(data);
            });
            const ws_Company = XLSX.utils.json_to_sheet(formDataReq);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws_Company, 'WorwflowAssignment');
            XLSX.writeFile(wb, filename);
        }

        document.getElementsByClassName('Loader__background')[0].className =
            'Loader__background hide';
    };

    const filterButtons = useMemo(
        () => [
            {
                label: 'All',
                value: 'All',
                onClick: () => setFilter('All')
            },
            {
                label: 'Active',
                value: 'Active',
                onClick: () => setFilter('Active')
            },
            {
                label: 'In-Active',
                value: 'InActive',
                onClick: () => setFilter('InActive')
            }
        ],
        []
    );

    return (
        <div className="master-data-content">
            <Row>
                <Col>
                    <div
                        className="trapezoid-head"
                        style={{ width: '290px' }}
                    >
                        <span>Workflow Assignment Routing Report</span>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <WorkflowAssignmentSearch
                        handleSearch={handleSearch}
                        isFetching={isLoading}
                        canNullDocNo={true}
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <ButtonGroup size="sm">
                        {filterButtons.map(filterButton => (
                            <Button
                                variant={
                                    filterButton.value !== filter
                                        ? 'outline-primary'
                                        : undefined
                                }
                                onClick={filterButton.onClick}
                            >
                                {filterButton.label}
                            </Button>
                        ))}
                    </ButtonGroup>
                </Col>
                <Col style={{ textAlign: 'right' }}>
                    <Button
                        className="mx-1"
                        variant="outline-success"
                        size="sm"
                        onClick={() => exportTemplate()}
                    >
                        <FaFileExport />
                        &nbsp;Export Excel
                    </Button>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col style={{ overflow: 'hidden' }}>
                    <div style={{ overflowX: 'scroll' }}>
                        <Table className="table-bordered table-hover table-responsive-sm align-middle">
                            <thead>
                                <tr
                                    className="table-active thead-light text-nowrap text-center"
                                    style={{ width: '100%' }}
                                >
                                    <th>SID Name</th>
                                    <th>AuthGroup</th>
                                    <th>AuthGroup Name</th>
                                    <th>PositionCode</th>
                                    <th>Position Condition</th>
                                    <th>Emp ID</th>
                                    <th>Emp Name</th>
                                    <th>Email Address</th>
                                    <th>Valid From</th>
                                    <th>Valid To</th>
                                </tr>
                            </thead>
                            {isLoading && (
                                <tbody>
                                    <tr>
                                        <td
                                            colSpan={11}
                                            style={{
                                                background:
                                                    'rgba(209, 250, 221, 1)',
                                                color: 'black',
                                                fontSize: '10pt',
                                                textAlign: 'center'
                                            }}
                                        >
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                            />
                                            &nbsp;&nbsp;Loading...
                                        </td>
                                    </tr>
                                </tbody>
                            )}

                            {!isLoading &&
                                status === 200 &&
                                !filteredReports && (
                                    <tbody>
                                        <tr>
                                            <td
                                                colSpan={11}
                                                style={{
                                                    background: '#b7b7a4',
                                                    color: 'black',
                                                    fontSize: '10pt',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                &nbsp;&nbsp;No Data...
                                            </td>
                                        </tr>
                                    </tbody>
                                )}

                            <tbody>
                                {!isLoading &&
                                    status === 200 &&
                                    filteredReports &&
                                    filteredReports.map((assignment, index) => (
                                        <tr key={index}>
                                            <td>{assignment.sid_name}</td>
                                            <td>{assignment.authgroup}</td>
                                            <td>{assignment.authgroup_name}</td>
                                            <td>{assignment.positioncode}</td>
                                            <td>
                                                {assignment.position_condition}
                                            </td>
                                            <td>{assignment.empid}</td>
                                            <td>{assignment.emp_name}</td>
                                            <td>{assignment.email_address}</td>
                                            <td>{assignment.validfrom}</td>
                                            <td>{assignment.validto}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default WorkflowAssignmentRoutingReport;
