import * as React from 'react';
import { Router, Route, RouteProps, Redirect } from 'react-router';
import { getRedirectURL } from '../modules/Common'

export const PrivateRoute = ({ component: Component, ...rest }: any) => (

    <Route {...rest} render={props => (
        localStorage.getItem('WF_APPLICATION')
            ? <Component {...props} />
            : getRedirectURL()
    )} />
)
