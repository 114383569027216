import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

import * as Models from '../../models/type/DeleteTypeMaster'

interface DeleteTypeMaster {
    type: 'DELETETYPEMASTER'
    response: Models.ResDeleteType
    statusDelete: number
}

interface DeleteFail {
    type: 'DELETEFAIL'
    statusDelete: number
}

interface ClearDelete {
    type: 'CLEARDELETE'
}

type KnownAction = DeleteTypeMaster | DeleteFail | ClearDelete

export const actionCreators = {
    requestdeleteTypeMaster: (p: boolean, method: String, typeid: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        console.log('call actionCreators : requestdeleteConditionMaster')
        const appState = getState();
        if (appState && appState.deleteTypeMaster && p && method == 'POST') {
            await fetch(`/v1/Type/delete?typeid=` + typeid, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('dispatch : DELETEFAIL')
                            dispatch({ type: 'DELETEFAIL', statusDelete: 204 });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            console.log('dispatch : DELETETYPEMASTER')
                            dispatch({ type: 'DELETETYPEMASTER', response: data as Models.ResDeleteType, statusDelete: 200 });
                        })
                    }
                })
        } else if (method == 'CLEAR') {
            console.log('dispatch : CLEARDELETE')
            dispatch({ type: 'CLEARDELETE' });
        }
    }
}

const unloadedState: Models.DeleteTypeState = { isLoad: false, statusDelete: 400 };

export const reducer: Reducer<Models.DeleteTypeState> = (state: Models.DeleteTypeState | undefined, incomingAction: Action): Models.DeleteTypeState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'DELETETYPEMASTER':
            return {
                isLoad: true,
                response: action.response,
                statusDelete: action.statusDelete

            }
        case 'DELETEFAIL':
            return {
                isLoad: true,
                statusDelete: action.statusDelete
            }

        case 'CLEARDELETE':
            return {
                isLoad: false,
                statusDelete: 400
            }
        default: return state;
    }
}