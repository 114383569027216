import * as React from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";

class WorkflowConfigSearchTap extends React.Component<any, { rolecode: string, empid: string, authority_group: string, workflowid: string, workflowName: string, SearchMode: string, keyResetSearchTap: boolean }> {

    constructor(props: any) {
        super(props)
        this.state = {
            rolecode: "",
            empid: "",
            authority_group: "",
            workflowid: "",
            workflowName: "",
            SearchMode: "",
            keyResetSearchTap: false
        }
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        if (this.state.keyResetSearchTap != this.props.keyResetSearchTap) {
            if (this.props.keyResetSearchTap) {
                this.props.callbackResetSearchTap()
                this.setState({
                    rolecode: "",
                    empid: "",
                    authority_group: "",
                    workflowid: "",
                    workflowName: "",
                })
            }
        }

        if (this.state.SearchMode != this.props.SearchMode) {
            this.setState({
                SearchMode: this.props.SearchMode
            })
        }
        console.log("this.props.SearchMode")
        console.log(this.props.SearchMode)
    }
    render() {
        return (
            <Row>
                <Col>
                    <div className='trapezoid trapezoid-purple' style={{ width: '200px', display: 'inline-block' }}>
                        <span>
                            <i className="fa fa-search" aria-hidden="true"></i>
                            Search menu
                        </span>
                    </div>
                    <div className="mat-box shadow p-3 mb-5 bg-white rounded">
                        <Row>
                            <Col>
                                <div className="mt-2">
                                    <Row>
                                        <Col md={2}>
                                            <Form.Group className="mb-3" controlId="formBasicRolecode">
                                                <Form.Label>Role Code :</Form.Label>
                                                <Form.Control type="text" size='sm' value={this.state.rolecode} onChange={(e) => this.setState({ rolecode: e.target.value })} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group className="mb-3" controlId="formBasicEmpid">
                                                <Form.Label>Employee ID :</Form.Label>
                                                <Form.Control type="text" size='sm' value={this.state.empid} onChange={(e) => this.setState({ empid: e.target.value })} />
                                            </Form.Group>
                                        </Col>
                                        {(this.props.SearchMode == "Authority" || this.props.SearchMode == "Workflow") &&
                                            <Col md={2}>
                                                <Form.Group className="mb-3" controlId="formBasicAuthorityGroup">
                                                    <Form.Label>Authority Group ID :</Form.Label>
                                                    <Form.Control type="text" size='sm' value={this.state.authority_group} onChange={(e) => this.setState({ authority_group: e.target.value })} />
                                                </Form.Group>
                                            </Col>
                                        }
                                        {this.props.SearchMode == "Workflow" &&
                                            <>
                                                <Col md={2}>
                                                    <Form.Group className="mb-3" controlId="formBasicWorkflowid">
                                                        <Form.Label>Workflow ID :</Form.Label>
                                                        <Form.Control type="text" size='sm' value={this.state.workflowid} onChange={(e) => this.setState({ workflowid: e.target.value })} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={2}>
                                                    <Form.Group className="mb-3" controlId="formBasicWorkflowName">
                                                        <Form.Label>Workflow Name :</Form.Label>
                                                        <Form.Control type="text" size='sm' value={this.state.workflowName} onChange={(e) => this.setState({ workflowName: e.target.value })} />
                                                    </Form.Group>
                                                </Col>
                                            </>
                                        }
                                        {/* {this.props.SearchMode == "Authority" &&
                                            <Col lg={1}></Col>
                                        }
                                        {(this.props.SearchMode != "Workflow" && this.props.SearchMode != "Authority") &&
                                            <Col lg={2}></Col>
                                        } */}
                                        
                                        <Col md={2} >
                                            <div style={{ marginTop: "1.75rem", textAlign: "right" }}>
                                                <Button variant="outline-info" size="sm" onClick={() => this.props.handleSearch(this.state.rolecode, this.state.empid, this.state.authority_group, this.state.workflowid, this.state.workflowName)}><FaSearch /> Search</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default WorkflowConfigSearchTap;