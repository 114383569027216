import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

import * as Models from '../../models/workflow/SearchWorkflowInbox'

interface LoadSearchWorkflowInbox {
    type: 'LOADSEARCHWORKFLOWINBOX'
    response: Models.ResponseWorkflowInboxValue
    status: Number
    statusMessage: String
}

interface FailSearchWorkflowInbox {
    type: 'FAILSEARCHWORKFLOWINBOX'
    status: number,
    statusMessage: any
}

interface ClearSearchWorkflowInbox {
    type: 'CLEARSEARCHWORKFLOWINBOX'
    response: Models.ResponseWorkflowInboxValue
    status: Number
    statusMessage: String
}

interface CloseSearchWorkflowInbox {
    type: 'CLOSESEARCHWORKFLOWINBOX'
    response: Models.ResponseWorkflowInboxValue
    status: Number
    statusMessage: String
}

type KnownAction = LoadSearchWorkflowInbox | FailSearchWorkflowInbox | ClearSearchWorkflowInbox | CloseSearchWorkflowInbox

export const actionCreators = {
    requestsearchworkflowInbox: (p: boolean, method: String, wf_id: string, doc_no: string, user_id: string, wf_no: string, app_id: string, accessToken?: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestsearchworkflowInbox')
        if (appState && appState.searchWorkflow && p && method == 'POST' && wf_id) {
            var wfIdUri = encodeURIComponent(wf_id)
            var docNoUri = encodeURIComponent(doc_no)
            var userIdUri = encodeURIComponent(user_id)
            var wfNoUri = encodeURIComponent(wf_no)
            var appIdUri = encodeURIComponent(app_id)

            const bearer = `Bearer ${accessToken || localStorage.getItem('WF_APPLICATION')}`;

            fetch('/v4/WorkflowInbox/Search?wf_id=' + wfIdUri + '&doc_no=' + docNoUri + '&user_id=' + userIdUri + '&wf_no=' + wfNoUri + '&app_id=' + appIdUri, {
                method: 'GET',
                headers: {
                    'Authorization': bearer,
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('errorrrr')
                            dispatch({ type: 'FAILSEARCHWORKFLOWINBOX', status: 204, statusMessage: err.message });
                        })
                    } else if (data.ok) {
                        console.log(data)
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'LOADSEARCHWORKFLOWINBOX', status: 200, statusMessage: '', response: data });
                        })
                    }
                })
            dispatch({ type: 'CLEARSEARCHWORKFLOWINBOX', status: 0, statusMessage: "", response: [] as any });
        } else if (method == "CLEAR") {
            dispatch({ type: 'CLEARSEARCHWORKFLOWINBOX', status: 0, statusMessage: "", response: [] as any });
        } else if (method == "CLOSE") {
            dispatch({ type: 'CLOSESEARCHWORKFLOWINBOX', status: 400, statusMessage: "", response: [] as any });
        }
    }
}

const unloadedState: Models.GetWorkflowInboxValueState = { isLoadWorkflowInboxValue: false };

export const reducer: Reducer<Models.GetWorkflowInboxValueState> = (state: Models.GetWorkflowInboxValueState | undefined, incomingAction: Action): Models.GetWorkflowInboxValueState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOADSEARCHWORKFLOWINBOX':
            return {
                isLoadWorkflowInboxValue: true,
                responseWorkflowInboxValue: action.response,
                statusWorkflowInboxValue: action.status
            }

        case 'FAILSEARCHWORKFLOWINBOX':
            return {
                isLoadWorkflowInboxValue: true,
                messageWorkflowInboxValue: action.statusMessage,
                statusWorkflowInboxValue: action.status
            }

        case 'CLEARSEARCHWORKFLOWINBOX':
            return {
                isLoadWorkflowInboxValue: false,
                responseWorkflowInboxValue: action.response,
                statusWorkflowInboxValue: 0
            }
        case 'CLOSESEARCHWORKFLOWINBOX':
            return {
                isLoadWorkflowInboxValue: false,
                responseWorkflowInboxValue: action.response,
                statusWorkflowInboxValue: 0
            }
        default: return state;
    }
}