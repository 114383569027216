import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

import * as Models from '../../models/employee/SearchEmployee'

interface LoadSearchEmployee {
    type: 'LOAD_SEARCH_EMPLOYEE'
}

interface LoadedSearchEmployee {
    type: 'LOADEDSEARCHEMPLOYEE'
    response: Models.ResponseSearchPositionEmployee
    status: Number
    statusMessage: String
}

interface FailSearchEmployee {
    type: 'LOADFAIL'
    status: number,
    statusMessage: any
}

interface ClearSearchEmployee {
    type: 'CLEARSEARCHEMPLOYEE'
    response: Models.ResponseSearchPositionEmployee
    status: Number
    statusMessage: String
}

type KnownAction = LoadSearchEmployee | LoadedSearchEmployee | FailSearchEmployee | ClearSearchEmployee

export const actionCreators = {
    requestsearchemployee: (p: boolean, method: string, positionCode: string, company: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestsearchemployee')
        if (appState && appState.searchEmployee && p && method == 'POST') {
            if (company == "*") {
                company = "";
            }
            await fetch(`/v2/Employee/Search?positionCode=` + positionCode + `&sid=` + company, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                    'Accept': 'application/json'
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('dispatch : LOADFAIL')
                            dispatch({ type: 'LOADFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            console.log('dispatch : LOADEDSEARCHEMPLOYEE')
                            dispatch({ type: 'LOADEDSEARCHEMPLOYEE', response: data as Models.ResponseSearchPositionEmployee, status: 200, statusMessage: '' });
                        })
                    }
                })

            dispatch({ type: 'LOAD_SEARCH_EMPLOYEE' });

        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARSEARCHEMPLOYEE', response: [] as any, status: 204, statusMessage: '' });
        }
    }
}

const unloadedState: Models.SearchPositionEmployeeState = { isLoad: false };

export const reducer: Reducer<Models.SearchPositionEmployeeState> = (state: Models.SearchPositionEmployeeState | undefined, incomingAction: Action): Models.SearchPositionEmployeeState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_SEARCH_EMPLOYEE':
            return {
                isLoad: false
            }
        case 'LOADEDSEARCHEMPLOYEE':
            return {
                isLoad: true,
                responseSearch: action.response,
                statusSearch: action.status
            }

        case 'LOADFAIL':
            return {
                isLoad: true,
                message: action.statusMessage,
                statusSearch: action.status
            }

        case 'CLEARSEARCHEMPLOYEE':
            return {
                isLoad: false,
                responseSearch: action.response,
                statusSearch: 0
            }

        default: return state;
    }
}