import { LogCallbackStartResponse } from "data/CallbackLog/schemas/CallbackLog.schema"
import { formatYMDToDMY } from "modules/Common"
import React from "react"
import { Col, Container, Row, Spinner, Table } from "react-bootstrap"

type Props = {
    bufferStat: LogCallbackStartResponse[]
    mappingCompany: Map<string, string>;
}

function BufferStatTable(props: Props) {
    const { bufferStat, mappingCompany } = props

    return (
        <Container>
            <Row className="mt-3">
                <Col>
                    <Table
                        className="table-bordered align-middle"
                        size="sm"
                    >
                        <thead>
                            <tr
                                style={{ width: '100%' }}
                                className="table-active thead-light text-center"
                            >
                                <th style={{ width: '15%' }}>Company</th>
                                <th style={{ width: '10%' }}>Last Trigger</th>
                                <th style={{ width: '10%' }}>Last Number of Callback</th>
                                <th style={{ width: '10%' }}>Current Trigger</th>
                                <th style={{ width: '10%' }}>Current Number of Callback</th>
                                <th style={{ width: '10%' }}>Process Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!bufferStat ? (
                                <tr>
                                    <td
                                        colSpan={7}
                                        style={{
                                            background: 'rgba(209, 250, 221, 1)',
                                            color: 'black',
                                            fontSize: '10pt',
                                            textAlign: 'center'
                                        }}
                                    >
                                        <Spinner
                                            animation="border"
                                            size="sm"
                                        />
                                        &nbsp;&nbsp;Loading...
                                    </td>
                                </tr>
                            ) : (
                                bufferStat.map((value, index) => (
                                    <tr key={index}>
                                        <td className="pl-5">{mappingCompany.get(value.sid)}</td>
                                        <td className="text-center">{formatYMDToDMY(value.last_trigger)}</td>
                                        <td className="text-center">{value.last_num_callback}</td>
                                        <td className="text-center">{formatYMDToDMY(value.current_trigger)}</td>
                                        <td className="text-center">{value.current_num_callback}</td>
                                        <td className="text-center">{value.last_process_time_sec}</td>
                                    </tr>
                                ))
                            )}

                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container >
    )
}

export default BufferStatTable