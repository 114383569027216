import * as React from "react";
import { connect } from "react-redux";
import { Card, Button, Row, Col, Accordion, Form } from "react-bootstrap";
import { FaAngleDown, FaSearch } from "react-icons/fa";
import CreatableSelect from 'react-select/creatable';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from 'sweetalert2';
import * as ModelsLog from '../../models/log/Log'

import { ApplicationState } from "../../store";

import * as MasterCompanyStore from '../../store/company/MasterCompany'
import * as MasterCompanyModels from '../../models/company/MasterCompany'
type store = MasterCompanyModels.GetMasterCompanyState & typeof MasterCompanyStore.actionCreators

export interface optionValue {
    value: string
    label: string
}

export interface searchValue {
    wfNo: string,
    docAmount: string,
    docAmountTo: string,
    requestor: string,
    createBy: string,
    createByTo: string,
}

const customStyles = {
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none', zIndex: 'auto' } : base;
    },
    menu: (provided, state) => ({
        ...provided,
        zIndex: 9999,
    }),
}


class WorkflowConditionSearch extends React.Component<any, { dashboardStatus: any, checkedALL: boolean, checkedSLSN: boolean, checkedSLTS: boolean, checkedSRIC: boolean, sids: string[], option: Array<MasterCompanyModels.MasterCompanyList>, checkCompanyOption: boolean, valueSelect: any, wfnoSearch: ModelsLog.searchValue, blindCreateBy: string, blindCreateByTo: string, blindApproveBy: string, blindApproveByTo: string, wfID: string, wfCondition: string }, store> {

    constructor(props: any) {
        super(props)

        this.state = {
            checkedALL: true,
            checkedSLSN: false,
            checkedSLTS: false,
            checkedSRIC: false,
            sids: ["*"],
            option: [],
            checkCompanyOption: true,
            valueSelect: [],
            wfnoSearch: {
                wfNo: "",
                wfLogType:"",
                docAmount: "",
                docAmountTo: "",
                requester_id: "",
                createBy: "",
                createByTo: "",
                updatedFrom: "",
                updatedTo: "",
            },
            dashboardStatus: {
                APPROVED: "",
                WAITING: "",
                CANCEL: "",
            },
            blindCreateBy: "",
            blindCreateByTo: "",
            blindApproveBy: "",
            blindApproveByTo: "",
            wfID: "",
            wfCondition: ""
        }
    }

    componentDidMount() {
        // this.props.blindSidArr(this.state.option)
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        if (this.props.GetMasterCompany.isLoadGetMasterCompany && this.props.GetMasterCompany.statusGetMasterCompany == 200 && this.state.checkCompanyOption) {
            this.setState({
                checkCompanyOption: false
            })
            var dataSelect: any = []
            for (let i = 0; i < this.props.GetMasterCompany.responseGetMasterCompany['result_list'].length; i++) {
                var data = {}
                data['value'] = this.props.GetMasterCompany.responseGetMasterCompany['result_list'][i]['sid']
                data['label'] = this.props.GetMasterCompany.responseGetMasterCompany['result_list'][i]['company_name']
                dataSelect.push(data)
            }
            this.setState({
                option: dataSelect
            })
        }
    }

    handleInputChange = (newValue: any, action: any) => {
    };

    handleChangeSelect = (newValue: any, actionMeta: any) => {
        if (newValue.length != 0) {
            var sidArrValue: any = []
            if (newValue != '') {
                for (let i = 0; i < newValue.length; i++) {
                    sidArrValue.push(newValue[i].value)
                }
            } else {
                sidArrValue = []
            }
            this.setState({ valueSelect: sidArrValue })
        } else {
            this.setState({ valueSelect: [] })
        }
    };

    handleChange = (event) => {
        this.setState({ ...this.state, [event.target.name]: event.target.checked });
    };

    handleChangeSearch = (e: any, key: any) => {
        var value = e.target.value
        if(key == "wfID"){
            this.setState({
            wfID: value
            })
        }
        else if(key == "wfCondition"){
            this.setState({
            wfCondition: value
            })
        }
    }

    dateFormat(date) {
        if (date != "" && date != null && date != undefined) {
            var dates = new Date(date),
                mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                day = ("0" + date.getDate()).slice(-2);
            var dataRs = dates.getFullYear() + mnth + day + '000000'
            return dataRs
        } else {
            return ""
        }
    }

    handleChangeDateTo = (date, event) => {
        var dateDateTo = ""
        if (date != null) {
            dateDateTo = this.dateFormat(date);
        }
        var dataReq = this.state.wfnoSearch
        dataReq['createByTo'] = dateDateTo
        this.setState({
            blindCreateByTo: date,
            wfnoSearch: dataReq
        })
    }
    handleChangeApproveTo = (date, event) => {
        var dateApproveTo = ""
        if (date != null) {
            dateApproveTo = this.dateFormat(date);
        }
        var dataReq = this.state.wfnoSearch
        dataReq['updatedTo'] = dateApproveTo
        this.setState({
            blindApproveByTo: date,
            wfnoSearch: dataReq
        })
    }

    handleChangeDateBy = (date, event) => {
        var dateDateBy = ""
        if (date != null) {
            dateDateBy = this.dateFormat(date);
        }
        var dataReq = this.state.wfnoSearch
        dataReq['createBy'] = dateDateBy
        this.setState({
            blindCreateBy: date,
            wfnoSearch: dataReq
        })
    }
    handleChangeApproveeBy = (date, event) => {
        var dateApproveeBy = ""
        if (date != null) {
            dateApproveeBy = this.dateFormat(date);
        }
        var dataReq = this.state.wfnoSearch
        dataReq['updatedFrom'] = dateApproveeBy
        this.setState({
            blindApproveBy: date,
            wfnoSearch: dataReq
        })
    }

    handlecheckStatus(e: any, checkbox) {
        var Status = this.state.dashboardStatus;
        if (checkbox == "APPROVED") {
            if (e.target.checked == true) {
                Status.APPROVED = "APPROVED";
            } else {
                Status.APPROVED = "";
            }
        }
        else if (checkbox == "WAITING") {
            if (e.target.checked == true) {
                Status.WAITING = "WAITING";
            }
            else {
                Status.WAITING = "";
            }
        }
        else if (checkbox == "CANCEL") {
            if (e.target.checked == true) {
                Status.CANCEL = "CANCEL";
            }
            else {
                Status.CANCEL = "";
            }
        }
        this.setState({ dashboardStatus: Status })
    }

    beforeHandleSearch() {
        if ((this.state.wfnoSearch.wfNo == "" || this.state.wfnoSearch.wfNo == undefined) && !this.props.canNullDocNo) {
            Swal.fire({
                title: 'โปรดกรอกข้อมูลให้ครบถ้วน',
                text: 'โปรดกรอกเลขที่เอกสารก่อนค้นหาข้อมูล',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'ตกลง'
            })
        } else {
            this.props.handleSearch(this.state.valueSelect, this.state.wfID, this.state.wfCondition, this.state.checkedALL)
        }
    }

    render() {
        console.log("wfID:"+this.state.wfID);
        console.log("wfCondition:"+this.state.wfCondition);
        return (
            <div style={{ width: '100%' }} className='h-50 d-inline-block'>
                <Card>
                    <Card.Header className='h-50 d-inline-block' style={{ marginBottom: '-12px' }}>
                        <Row>
                            <div className='col-lg-6'>
                                <div className='d-flex justify-content-start my-2 my-lg-0'>
                                    <Form.Label column sm="3" className="mt-2">ค้นหาข้อมูล</Form.Label>
                                </div>
                            </div>
                        </Row>
                    </Card.Header>
                    <Card.Body style={{ overflow: 'none' }}>
                        <Row className='mt-1'>
                            <Col>
                                <Form.Group controlId="formBasicCheckbox">
                                    <Form.Check type="checkbox" label="เลือกทั้งหมด" checked={this.state.checkedALL} name="checkedALL" onChange={this.handleChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='mt-1'>
                            <Col sm={10}>
                                <div style={{ zIndex: 2000 }}>
                                    <CreatableSelect
                                        isMulti
                                        isClearable
                                        onChange={(value, action) => this.handleChangeSelect(value, action)}
                                        //onInputChange={(value, action) => this.handleInputChange(value, action)}
                                        options={this.state.option}
                                        isDisabled={this.state.checkedALL || this.props.isFetching}
                                        styles={customStyles}
                                        menuPosition={'fixed'}
                                    />
                                </div>
                            </Col>
                            <Col sm={2}>
                                <Button
                                    style={{ marginBottom: '10px', float: 'right' }}
                                    variant="outline-success"
                                    size="sm"
                                    onClick={() => this.beforeHandleSearch()}
                                    disabled={this.props.isFetching}
                                >
                                    <FaSearch />
                                    &nbsp;ค้นหา
                                </Button>
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col>
                                <Form.Group className="mb-3" >
                                    <Form.Label>WF ID :</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="WF ID"
                                        size="sm"
                                        onChange={(e) => this.handleChangeSearch(e, 'wfID')}
                                        disabled={this.props.isFetching}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" >
                                    <Form.Label>WF Condition :</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="WF Condition"
                                        size="sm"
                                        onChange={(e) => this.handleChangeSearch(e, 'wfCondition')}
                                        disabled={this.props.isFetching}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {this.props.modeSearchApproveDate == true &&
                            <Row className='mt-2'>
                                <Col md={{ span: 4, offset: 4 }}>
                                    <Form.Label>สถานะ :</Form.Label>
                                    <div className="form-inline">
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                            <Form.Check type="checkbox" id="checkActive" label="APPROVED" onChange={(e) => this.handlecheckStatus(e, "APPROVED")} />
                                            <Form.Check type="checkbox" className="mx-5" id="checkError" label="WAITING" onChange={(e) => this.handlecheckStatus(e, "WAITING")} />
                                            <Form.Check type="checkbox" className="" id="checkError" label="CANCEL" onChange={(e) => this.handlecheckStatus(e, "CANCEL")} />
                                        </Form.Group>
                                    </div>
                                </Col>
                                <Col >
                                    <Form.Label>วันที่ปรับปรุงล่าสุด :</Form.Label>
                                    <div className="form-inline">
                                        <DatePicker
                                            selected={this.state.blindApproveBy}
                                            onChange={this.handleChangeApproveeBy}
                                            customInput={<Form.Control className="menu-form custom-datepicker" size="sm" placeholder="dd/mm/yyyy" />}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="dd/mm/yyyy"
                                            isClearable
                                        />
                                        <Form.Label className="mx-1 text-center"> -</Form.Label>
                                        <DatePicker
                                            selected={this.state.blindApproveByTo}
                                            onChange={this.handleChangeApproveTo}
                                            customInput={<Form.Control className="menu-form custom-datepicker" size="sm" placeholder="dd/mm/yyyy" />}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="dd/mm/yyyy"
                                            isClearable
                                        />
                                    </div>
                                </Col>
                            </Row>
                        }
                    </Card.Body>
                </Card >
            </div>
        )
    }

}
export default connect((state: ApplicationState) => ({ ...state.MasterCompany }))(WorkflowConditionSearch)