import React from 'react';
import { Button, Container, Row, Col, Form } from 'react-bootstrap';
import { Input, Label } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { BsCalendar2Date } from 'react-icons/bs';

import LogBufferViewModel from './WorkflowLogBuffer.viewmodel';
import { FaPlusCircle, FaSearch } from 'react-icons/fa';
import LogBufferTable from './components/WorkflowLogBufferTable';
import { convertToYYYYMMDD } from 'modules/Common';
import WorkflowLogBufferDetail from './components/WorkflowLogBufferDetail';
import Pagination from '@material-ui/lab/Pagination';

function LogBuffer() {
    const {
        dateTo,
        dateForm,
        searchHelp,
        companyMaster,
        explorerDetail,
        logBufferDetail,
        selectdAllCompany,
        logBufferResponse,

        setDateTo,
        setDateForm,
        handleSearchLog,
        ExportTemplate,
        mappingCompany,
        handleCheckboxChange,
        setSelectdAllCompany,
        handleExplorerDetail,
        handleChangeSearchHelp
    } = LogBufferViewModel();

    return (
        <Container>
            <Row>
                <Col className="import-export-actions">
                    <div
                        className="trapezoid-head"
                        style={{ width: '200px' }}
                    >
                        <span>Log Buffer</span>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="mat-box">
                        <div
                            className="trapezoid trapezoid-purple"
                            style={{
                                width: '200px',
                                display: 'inline-block'
                            }}
                        >
                            <span>
                                <i
                                    className="fa fa-search"
                                    aria-hidden="true"
                                ></i>
                                Search menu
                                <div
                                    style={{
                                        display: 'inline-block',
                                        float: 'right'
                                    }}
                                ></div>
                            </span>
                        </div>
                        <div className="mat-box mb-5 rounded bg-white p-3 shadow">
                            <Row>
                                <Col>
                                    <div className="mt-2">
                                        <Row>
                                            <Col
                                                xs={12}
                                                md={3}
                                            >
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="formBasicEmail"
                                                >
                                                    <Form.Label>
                                                        Company Name :
                                                    </Form.Label>
                                                    <div>
                                                        <input
                                                            type="checkbox"
                                                            checked={
                                                                selectdAllCompany
                                                            }
                                                            onChange={e => {
                                                                e.persist();
                                                                setSelectdAllCompany(
                                                                    prev =>
                                                                        !prev
                                                                );
                                                                handleChangeSearchHelp(
                                                                    'SID',
                                                                    ''
                                                                );
                                                            }}
                                                        />
                                                        <span className="ml-2">
                                                            เลือกทั้งหมด
                                                        </span>
                                                    </div>
                                                    <Input
                                                        type="select"
                                                        onChange={e => {
                                                            e.persist();
                                                            handleChangeSearchHelp(
                                                                'SID',
                                                                e.target.value
                                                            );
                                                        }}
                                                        disabled={selectdAllCompany}
                                                    >
                                                        <option selected>
                                                            Please choose
                                                        </option>
                                                        {companyMaster &&
                                                            companyMaster.map(
                                                                (
                                                                    value,
                                                                    index
                                                                ) => (
                                                                    <option
                                                                        key={
                                                                            index
                                                                        }
                                                                        value={
                                                                            value.sid
                                                                        }
                                                                    >
                                                                        {
                                                                            value.company_name
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                    </Input>
                                                </Form.Group>
                                            </Col>
                                            <Col
                                                xs={12}
                                                md={2}
                                                className="flex items-end justify-center"
                                            >
                                                <div className="text-center">
                                                    <Button
                                                        variant="outline-info"
                                                        size="sm"
                                                        onClick={
                                                            handleSearchLog
                                                        }
                                                    >
                                                        <FaSearch /> Search
                                                    </Button>
                                                    <Button
                                                        variant="outline-success"
                                                        size="sm"
                                                        className="ml-3"
                                                        onClick={ExportTemplate}
                                                    >
                                                        <FaPlusCircle />
                                                        &nbsp;Export Excel
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col
                                                xs={12}
                                                md={2}
                                            >
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="formBasicEmail"
                                                >
                                                    <Form.Label>
                                                        Date From :{' '}
                                                        <BsCalendar2Date />
                                                    </Form.Label>
                                                    <DatePicker
                                                        wrapperClassName="w-full"
                                                        className="w-full"
                                                        customInput={
                                                            <Form.Control className="menu-form custom-datepicker" />
                                                        }
                                                        selected={dateForm}
                                                        dateFormat="dd/MM/yyyy"
                                                        onChange={date => {
                                                            setDateForm(date!);
                                                            handleChangeSearchHelp(
                                                                'CreatedOn',
                                                                convertToYYYYMMDD(
                                                                    date?.toISOString()!
                                                                )
                                                            );
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col
                                                xs={12}
                                                md={2}
                                            >
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="formBasicEmail"
                                                >
                                                    <Form.Label>
                                                        Date To :{' '}
                                                        <BsCalendar2Date />
                                                    </Form.Label>
                                                    <DatePicker
                                                        wrapperClassName="w-full"
                                                        className="w-full"
                                                        customInput={
                                                            <Form.Control className="menu-form custom-datepicker" />
                                                        }
                                                        selected={dateTo}
                                                        dateFormat="dd/MM/yyyy"
                                                        onChange={date => {
                                                            setDateTo(date!);
                                                            handleChangeSearchHelp(
                                                                'CreatedTo',
                                                                convertToYYYYMMDD(
                                                                    date?.toISOString()!
                                                                )
                                                            );
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col
                                                xs={5}
                                                md={2}
                                            >
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="formBasicEmail"
                                                >
                                                    <Form.Label>
                                                        Buffer ID :
                                                    </Form.Label>
                                                    <Input
                                                        bsSize="sm"
                                                        onChange={e =>
                                                            handleChangeSearchHelp(
                                                                'BufferId',
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col
                                                xs={5}
                                                md={2}
                                            >
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="formBasicEmail"
                                                >
                                                    <Form.Label>
                                                        เลขที่เอกสาร :
                                                    </Form.Label>
                                                    <Input
                                                        bsSize="sm"
                                                        onChange={e =>
                                                            handleChangeSearchHelp(
                                                                'DocumentNumber',
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col
                                                xs={5}
                                                md={1}
                                            >
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="formBasicEmail"
                                                >
                                                    <Form.Label>
                                                        ปีเอกสาร :
                                                    </Form.Label>
                                                    <Input
                                                        bsSize="sm"
                                                        onChange={e =>
                                                            handleChangeSearchHelp(
                                                                'DocumentYear',
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col
                                                xs={5}
                                                md={1}
                                            >
                                                <Form.Label>สถานะ :</Form.Label>
                                                <div
                                                    className="form-inline"
                                                    style={{
                                                        paddingRight: '90px'
                                                    }}
                                                >
                                                    <Form.Group
                                                        className="mb-3"
                                                        controlId="formBasicCheckbox"
                                                    >
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="checkActive"
                                                            label="SUCCESS"
                                                            onChange={e =>
                                                                handleCheckboxChange(
                                                                    'S'
                                                                )
                                                            }
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            className="mx-5"
                                                            id="checkError"
                                                            label="WAITING"
                                                            onChange={e =>
                                                                handleCheckboxChange(
                                                                    'W'
                                                                )
                                                            }
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            className=""
                                                            id="checkError"
                                                            label="ERROR"
                                                            onChange={e =>
                                                                handleCheckboxChange(
                                                                    'E'
                                                                )
                                                            }
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col>
                                {!explorerDetail ? (
                                    <React.Fragment>
                                        <div className="flex w-[10%] flex-row items-center">
                                            <Form.Label
                                                className="my-1 mr-2"
                                                htmlFor="inlineFormCustomSelectPref"
                                            >
                                                Show
                                            </Form.Label>
                                            <Form.Control
                                                as="select"
                                                className="mr-sm-2 my-1"
                                                id="inlineFormCustomSelectPref"
                                                custom
                                                size="sm"
                                                value={searchHelp.PageSize}
                                                onChange={e =>
                                                    handleChangeSearchHelp(
                                                        'PageSize',
                                                        parseInt(e.target.value)
                                                    )
                                                }
                                            >
                                                <option value="10">20</option>
                                                <option value="25">40</option>
                                                <option value="50">60</option>
                                                <option value="100">100</option>
                                            </Form.Control>
                                            <Form.Label
                                                className="my-1 mr-2"
                                                htmlFor="inlineFormCustomSelectPref"
                                            >
                                                entries
                                            </Form.Label>
                                        </div>
                                        <LogBufferTable
                                            logBuffer={logBufferResponse!}
                                            mappingCompany={mappingCompany}
                                            explorerDetail={
                                                handleExplorerDetail
                                            }
                                        />
                                        <div className="flex items-end justify-end">
                                            <Pagination
                                                count={Math.ceil(
                                                    logBufferResponse?.totalCount! /
                                                    searchHelp.PageSize
                                                )}
                                                shape="rounded"
                                                onChange={(_, page) => {
                                                    handleChangeSearchHelp(
                                                        'Page',
                                                        page
                                                    );
                                                }}
                                                defaultPage={1}
                                                page={searchHelp.Page}
                                                showFirstButton
                                                showLastButton
                                            />
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <WorkflowLogBufferDetail
                                        mappingCompany={mappingCompany}
                                        explorerDetail={handleExplorerDetail}
                                        logBufferDetail={logBufferDetail!}
                                    />
                                )}
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default LogBuffer;
