import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { IconType } from 'react-icons';
import { BsThreeDots } from 'react-icons/bs';
import {
    FaCheck,
    FaCheckCircle,
    FaClock,
    FaFileAlt,
    FaQuestionCircle,
    FaShare,
    FaTimes,
    FaTimesCircle
} from 'react-icons/fa';
import { FaListUl, FaTableCells } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import {
    CardBody,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from 'reactstrap';
import { twMerge } from 'tailwind-merge';

import { Button, Stepper, Tabs } from 'common/components';
import BorderGrid from 'common/BorderGrid';
import { SpinnerLoader } from 'helpers/dom-utils';
import { Reference, UserInformation } from 'models/auth/SignIn';
import * as ModelSearchWorkflowInbox from 'models/workflow/SearchWorkflowInbox';
import { WorkflowInboxValue } from 'models/workflow/SearchWorkflowInbox';
import { WorkflowInbox } from 'models/workflow/WorkflowInbox';
import {
    formatDMY,
    onClickFileShow,
    onClickFileShowIframe,
    parseJwt
} from 'modules/Common';
import { ApplicationState } from 'store';
import * as SearchWorkflowDashboardStore from 'store/workflow/SearchWorkflowDashboard';
import * as SearchWorkflowInboxStore from 'store/workflow/SearchWorkflowInbox';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { extractAccessToken } from 'helpers/decodeLocalStorage';
import * as ApproveRejectWorkflowDashboardStore from 'store/workflow/ApproveRejectWorkflowDashboard';
import * as ApproveRejectWorkflowStore from 'store/workflow/ApproveRejectWorkflow';
import {
    ApproveRejectWorkflowRequest,
    ApproveRejectWorkflowResponse
} from 'models/workflow/ApproveRejectWorkflow';
import Swal from 'sweetalert2';
import { toCapitalCase } from 'helpers/utils';
import { ApprovalConfirmationModal } from 'common/components';
import {
    IoCheckmarkCircleOutline,
    IoCloseCircleOutline,
    IoCheckmarkSharp
} from 'react-icons/io5';

type Props = {
    noCheckParams?: boolean;
    accessToken: string;
    auth: UserInformation;
    document?: WorkflowInbox;
    toggleConfirmModal?: (action: DocumentAction) => void;
};

const documentStatusIcons = new Map<
    string,
    [IconType, { size: number; color: string }]
>([
    ['SUCCESS', [FaCheckCircle, { size: 44, color: 'green' }]],
    ['ERROR', [FaTimesCircle, { size: 44, color: 'red' }]],
    ['wait_otp', [FaClock, { size: 44, color: 'orange' }]],
    ['wait_sign', [FaClock, { size: 44, color: 'orange' }]]
]);

const TABS = ['Information', 'Online Form'] as const;

function OnlineFormInformation(props: Props) {
    const {
        noCheckParams,
        accessToken: accessTokenProp,
        auth: authProp,
        document: documentProp,
        toggleConfirmModal: toggleConfirmModalProp
    } = props;

    const location = useLocation();

    const [haveInbox, setHaveInbox] = useState(true);
    const [document, setDocument] = useState(documentProp);
    const [auth, setAuth] = useState(authProp);
    const [accessToken, setAccessToken] = useState(accessTokenProp);

    // These will not use when `WorkflowOnlineForm` is parent
    const [message, setMessage] = useState('');
    const [prepareAction, setPrepareAction] = useState<DocumentAction>('');
    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);

    const [activeTab, setActiveTab] = useState<'Information' | 'Online Form'>(
        'Information'
    );

    const [displayApproveInChargeMode, setDisplayApproveInChargeMode] =
        useState<'step' | 'table'>('table');

    // Redux
    const dispatch = useDispatch();
    const {
        searchworkflowInbox,
        searchworkflowDashboard,
        approveRejectWorkflow,
        approveRejectWorkflowDashboard
    } = useSelector<
        Defined<ApplicationState>,
        Defined<
            Pick<
                ApplicationState,
                | 'searchworkflowInbox'
                | 'searchworkflowDashboard'
                | 'approveRejectWorkflow'
                | 'approveRejectWorkflowDashboard'
            >
        >
    >(state => ({
        searchworkflowInbox: state.searchworkflowInbox,
        searchworkflowDashboard: state.searchworkflowDashboard,
        approveRejectWorkflow: state.approveRejectWorkflow,
        approveRejectWorkflowDashboard: state.approveRejectWorkflowDashboard
    }));

    const {
        isLoadWorkflowInboxValue,
        statusWorkflowInboxValue,
        responseWorkflowInboxValue
    } = searchworkflowInbox;
    const {
        isLoadWorkflowInboxValue: isLoadWorkflowDashboardValue,
        statusWorkflowInboxValue: statusWorkflowDashboardValue,
        responseWorkflowInboxValue: responseWorkflowDashboardValue
    } = searchworkflowDashboard;
    const {
        isLoadApproveReject: isLoadApproveRejectInbox,
        statusResponseApproveReject: statusResponseApproveRejectInbox,
        responseApproveReject: responseApproveRejectInbox
    } = approveRejectWorkflow;
    const {
        isLoadApproveReject: isLoadApproveRejectDashboard,
        statusResponseApproveReject: statusResponseApproveRejectDashboard,
        responseApproveReject: responseApproveRejectDashboard
    } = approveRejectWorkflowDashboard;

    // Watch props change and set to local state
    useEffect(() => {
        setDocument(documentProp);
        setAuth(authProp);
        setAccessToken(accessTokenProp);
    }, [documentProp, authProp, accessTokenProp]);

    // Validate authentication and initial fetch document
    useEffect(() => {
        if (noCheckParams) {
            return;
        }

        const { docNo, wfId, wfInstanceNo, app_id, token } = queryString.parse(
            location.search
        ) as Record<string, string>;
        const auth = extractAccessToken(token, { replaceOldToken: true });
        const accessToken = parseJwt(token) as Reference;

        if (Date.now() > accessToken.exp * 1000) {
            Swal.fire('เซสซันหมดอายุ', 'กรุณาเข้าสู่ระบบ', 'error').then(() =>
                window.location.replace('/login')
            );

            return;
        }

        setAccessToken(JSON.stringify({ access_token: token }));
        setAuth(auth);
        setDocument({
            docNo,
            wfId,
            wfInstanceno: wfInstanceNo,
            app_id
        } as WorkflowInbox);
    }, [noCheckParams]);

    // Observe open and document state
    useEffect(() => {
        if (!document) {
            dispatch(
                SearchWorkflowInboxStore.actionCreators.requestsearchworkflowInbox(
                    true,
                    'CLEAR',
                    '',
                    '',
                    '',
                    '',
                    '',
                    accessToken
                )
            );
            dispatch(
                SearchWorkflowDashboardStore.actionCreators.requestsearchworkflowDashboard(
                    true,
                    'CLEAR',
                    '',
                    '',
                    '',
                    '',
                    '',
                    ''
                )
            );

            return;
        }

        SpinnerLoader.show();

        dispatch(
            SearchWorkflowInboxStore.actionCreators.requestsearchworkflowInbox(
                true,
                'POST',
                document.wfId,
                document.docNo,
                auth.citizen_id ?? auth.username,
                document.wfInstanceno,
                document.app_id,
                accessToken
            )
        );
        dispatch(
            SearchWorkflowDashboardStore.actionCreators.requestsearchworkflowDashboard(
                true,
                'POST',
                document.wfId,
                document.docNo,
                auth.citizen_id ?? auth.username,
                document.wfInstanceno,
                '',
                document.app_id,
                accessToken
            )
        );
    }, [document]);

    // Response from server after calling search workflow
    useEffect(() => {
        const { ref_id, token } = queryString.parse(location.search) as Record<
            string,
            string
        >;

        if (isLoadWorkflowInboxValue && statusWorkflowInboxValue === 204) {
            setHaveInbox(false);
        }

        //if (isLoadWorkflowInboxValue && statusWorkflowInboxValue === 204) {
        //    if (ref_id) {
        //        const params = new URLSearchParams({ ref_id });

        //        history.push({
        //            pathname: '/workflow-report-detail',
        //            search: '?' + params.toString(),
        //        });
        //    } else if (token) {
        //        const { app_id, docNo, wfId, wfInstanceNo } = queryString.parse(
        //            location.search
        //        ) as Record<string, string>;
        //        const params = new URLSearchParams({
        //            token,
        //            app_id,
        //            docNo,
        //            wfId,
        //            wfInstanceNo,
        //        });

        //        history.push({
        //            pathname: '/workflow-report-detail',
        //            search: '?' + params.toString(),
        //        });
        //    }

        //    return;
        //}

        // Clean up state
        if (
            (isLoadWorkflowInboxValue && statusWorkflowInboxValue === 200) ||
            (isLoadWorkflowDashboardValue &&
                statusWorkflowDashboardValue === 200)
        ) {
            SpinnerLoader.hide();
        }
    }, [searchworkflowInbox, searchworkflowDashboard]);

    // This will not use when `WorkflowOnlineForm` is parent
    // Clean up things
    useEffect(() => {
        if (toggleConfirmModalProp) {
            return;
        }

        const {
            isLoadApproveReject,
            statusResponseApproveReject,
            responseApproveReject,
            messageResponseApproveReject
        } = approveRejectWorkflow;

        if (
            isLoadApproveReject &&
            statusResponseApproveReject === 200 &&
            responseApproveReject
        ) {
            if (
                responseApproveReject.result_list?.every(
                    document =>
                        document.status !== 'ERROR' &&
                        document.status !== 'ESIG0010'
                )
            ) {
                handleNavigateToESignature(responseApproveReject.result_list);
            } else if (
                responseApproveReject.result_list?.every(
                    document => document.status === 'ESIG0010'
                )
            ) {
                handleCloseApprovalStatusModal();
                Swal.fire({
                    title: 'Failed CA',
                    html: 'Fail CA คุณระบุ Mode การเซ็นต์เอกสารอิเล็คทรอนิกส์ แบบ Level 3 โดยยังไม่มีการนำเข้า Personal Certificate โปรดตรวจสอบให้แน่ใจในข้อมูลส่วนตัวของคุณ หากยังต้องการใช้การเซ็นต์เอกสารอิเล็คทรอนิกส์แบบ Level 3 โดยการเข้าไปที่ ข้อมูลส่วนตัว > นำเข้า CA และบันทึกข้อมูล หากคุณยังไม่มี CA คุณสามารถปรับ Mode การเซ็นเอกสารอิเล็คทรอนิกส์เป็น Level 2 หรือ Level 1',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        } else if (
            isLoadApproveReject &&
            (statusResponseApproveReject ?? 400) > 200
        ) {
            Swal.fire('Error', messageResponseApproveReject, 'error');
        }

        if (isLoadApproveRejectInbox || isLoadApproveRejectDashboard) {
            SpinnerLoader.hide();
        }
    }, [approveRejectWorkflow, approveRejectWorkflowDashboard]);

    // This will not use when `WorkflowOnlineForm` is parent
    const localToggleConfirmModal = (action: DocumentAction) => {
        setMessage('');
        setPrepareAction(action);
        setIsOpenConfirmModal(prevState => !prevState);
    };

    // This will not use when `WorkflowOnlineForm` is parent
    const confirmAction = (
        confirmMessage: string,
        documents: WorkflowInbox[]
    ) => {
        if (!auth) {
            return;
        }

        SpinnerLoader.show();

        const prepareDocuments: ApproveRejectWorkflowRequest = {
            app_id: 'e5mPFn1JEi76UvyyPkOf',
            user_id: auth.citizen_id ?? auth.username,
            wf_type: 'SC_CT_ONLINE_FORM',
            doc_no_list: documents.map(document => document.docNo),
            instance_no_list: documents.map(document => document.wfInstanceno),
            message: confirmMessage
        };

        const functionName: keyof typeof ApproveRejectWorkflowStore.actionCreators =
            prepareAction === 'approve'
                ? 'requestApproveWorkflowList'
                : 'requestRejectWorkflowList';

        const store = haveInbox
            ? ApproveRejectWorkflowStore
            : ApproveRejectWorkflowDashboardStore;

        dispatch(
            store.actionCreators[functionName](
                true,
                'PUT',
                [prepareDocuments],
                confirmMessage,
                accessToken
            )
        );
    };

    // This will not use when `WorkflowOnlineForm` is parent
    const handleCloseApprovalStatusModal = () => {
        dispatch(
            ApproveRejectWorkflowStore.actionCreators.requestApproveWorkflowList(
                false,
                'CLEAR',
                '',
                '',
                accessToken
            )
        );
        dispatch(
            ApproveRejectWorkflowDashboardStore.actionCreators.requestRejectWorkflowList(
                false,
                'CLEAR',
                '',
                '',
                accessToken
            )
        );

        toggleConfirmModal('');
    };

    // This will not use when `WorkflowOnlineForm` is parent
    const handleNavigateToESignature = (
        documents: ApproveRejectWorkflowResponse[]
    ) => {
        const foundWaitOtpDocument = documents.find(
            document =>
                document.status === 'wait_otp' ||
                document.status === 'wait_sign'
        );

        foundWaitOtpDocument &&
            window.location.replace(foundWaitOtpDocument.redirectLink);
    };

    // Preparing render
    const toggleConfirmModal =
        toggleConfirmModalProp ?? localToggleConfirmModal;

    const fetchedDocument = haveInbox
        ? responseWorkflowInboxValue
        : responseWorkflowDashboardValue;
    const [docDate, docTime] = fetchedDocument?.createdOnFormatDateTime?.split(
        ' '
    ) ?? ['', ''];

    const responseApproveReject = isLoadApproveRejectInbox
        ? responseApproveRejectInbox
        : responseApproveRejectDashboard;

    return (
        <React.Fragment>
            <Col className="flex justify-end gap-2">
                {haveInbox ? (
                    <>
                        <Button
                            disabled={!document}
                            className="w-[110px] border-0 bg-[#269E41] text-white"
                            onClick={() => toggleConfirmModal('approve')}
                        >
                            <IoCheckmarkCircleOutline size={18} />
                            Approve
                        </Button>
                        <Button
                            disabled={!document}
                            className="w-[110px]"
                            onClick={() => toggleConfirmModal('reject')}
                        >
                            <IoCloseCircleOutline
                                size={18}
                                color="#B3261E"
                            />
                            Reject
                        </Button>
                    </>
                ) : (
                    <>
                        {/* <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => toggleConfirmModal('reject')}
                            disabled={!document}
                        >
                            <FaTimesCircle />
                            &nbsp;Reject
                        </Button> */}
                    </>
                )}
            </Col>

            <Tabs
                activeTab={activeTab}
                tabs={TABS}
                onChangeTab={setActiveTab}
            />
            <Container
                fluid
                className="mt-3"
            >
                {activeTab === 'Information' ? (
                    <React.Fragment>
                        {document &&
                            fetchedDocument &&
                            !Array.isArray(fetchedDocument) && (
                                <React.Fragment>
                                    <Row>
                                        <Col>
                                            <div className="grid flex-1 grid-cols-4 gap-4">
                                                <div className="col-span-4 space-y-2">
                                                    <p className="font-semibold">
                                                        Subject
                                                    </p>
                                                    <p className="rounded-md border-[2px] border-zinc-200 px-4 py-2">
                                                        {document.subject ??
                                                            fetchedDocument.subject}
                                                    </p>
                                                </div>
                                                <div className="col-span-4 space-y-2 md:col-span-2">
                                                    <p className="font-semibold">
                                                        Online Form ID
                                                    </p>
                                                    <p className="rounded-md border-[2px] border-zinc-200 px-4 py-2">
                                                        {document.docNo ??
                                                            fetchedDocument.docNo}
                                                    </p>
                                                </div>
                                                <div className="col-span-4 space-y-2 md:col-span-2">
                                                    <p className="font-semibold">
                                                        Requestor
                                                    </p>
                                                    <p className="rounded-md border-[2px] border-zinc-200 px-4 py-2">
                                                        {document.requestorName ??
                                                            fetchedDocument.requestorName}
                                                    </p>
                                                </div>
                                                <div className="col-span-2 space-y-2">
                                                    <p className="font-semibold">
                                                        Document Date
                                                    </p>
                                                    <p className="rounded-md border-[2px] border-zinc-200 px-4 py-2">
                                                        {docDate
                                                            .split('.')
                                                            .join('/')}
                                                    </p>
                                                </div>
                                                <div className="col-span-2 space-y-2">
                                                    <p className="font-semibold">
                                                        Document Time
                                                    </p>
                                                    <p className="rounded-md border-[2px] border-zinc-200 px-4 py-2">
                                                        {docTime} น.
                                                    </p>
                                                </div>
                                                <div className="col-span-4 flex items-center gap-4">
                                                    <p className="font-semibold">
                                                        Approve in Charge
                                                    </p>
                                                    <div className="flex w-fit divide-x overflow-hidden rounded-md border">
                                                        <button
                                                            onClick={() =>
                                                                setDisplayApproveInChargeMode(
                                                                    'table'
                                                                )
                                                            }
                                                            className={twMerge(
                                                                'flex items-center justify-center px-2 py-1.5 transition-colors',
                                                                displayApproveInChargeMode ===
                                                                    'table' &&
                                                                    'bg-[#3085d6] text-white'
                                                            )}
                                                        >
                                                            <FaTableCells />
                                                        </button>
                                                        <button
                                                            onClick={() =>
                                                                setDisplayApproveInChargeMode(
                                                                    'step'
                                                                )
                                                            }
                                                            className={twMerge(
                                                                'flex items-center justify-center px-2 py-1.5 transition-colors',
                                                                displayApproveInChargeMode ===
                                                                    'step' &&
                                                                    'bg-[#3085d6] text-white'
                                                            )}
                                                        >
                                                            <FaListUl />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {displayApproveInChargeMode ===
                                            'table' ? (
                                                <ApproveInChargeTable
                                                    document={fetchedDocument}
                                                />
                                            ) : (
                                                <ApprovalInChargeStepper
                                                    document={fetchedDocument}
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            )}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {fetchedDocument && !Array.isArray(fetchedDocument) && (
                            <React.Fragment>
                                <Row>
                                    <Col>
                                        <p className="font-semibold">
                                            Document Online Form
                                        </p>
                                    </Col>
                                </Row>
                                <OnlineForms
                                    files={JSON.parse(
                                        fetchedDocument.listSignFileUrl ||
                                            fetchedDocument.listFileUrl ||
                                            '[]'
                                    )}
                                />
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}

                {/* This will not use when `WorkflowOnlineForm` is parent */}
                {!toggleConfirmModalProp && document && (
                    <>
                        <ApprovalConfirmationModal
                            isOpen={isOpenConfirmModal}
                            toggle={() => toggleConfirmModal('')}
                            onClickConfirm={confirmMessage =>
                                confirmAction(confirmMessage, [document])
                            }
                        />

                        {/* Approval reesult modal */}
                        <Modal
                            isOpen={
                                !!responseApproveReject &&
                                !responseApproveReject.result_list?.every(
                                    document => document.status === 'ESIG0010'
                                )
                            }
                            toggle={handleCloseApprovalStatusModal}
                            size="lg"
                            backdrop="static"
                            keyboard={false}
                            className="max-w-7xl"
                        >
                            <ModalHeader className="text-lg font-semibold">
                                {toCapitalCase(prepareAction)} Status
                            </ModalHeader>
                            <ModalBody className="space-y-4">
                                <div>
                                    <p className="font-semibold text-neutral-800">
                                        Message
                                    </p>
                                    <p className="text-lg">{message}</p>
                                </div>

                                {responseApproveReject?.result_list &&
                                    (
                                        responseApproveReject as any
                                    ).result_list.map(document => {
                                        const [Icon, defaultProps] =
                                            documentStatusIcons.get(
                                                document.status
                                            ) ?? [
                                                FaQuestionCircle,
                                                { size: 44, color: 'yellow' }
                                            ];

                                        return (
                                            <Card className="rounded bg-white shadow-sm">
                                                <CardBody>
                                                    <div className="flex items-center gap-4">
                                                        {/* Status icon */}
                                                        <div className="px-6">
                                                            <Icon
                                                                {...defaultProps}
                                                            />
                                                        </div>

                                                        {/* Document status information */}
                                                        <div className="flex-1 space-y-2 overflow-auto">
                                                            <div>
                                                                <p className="font-semibold text-neutral-800">
                                                                    Document No.
                                                                </p>
                                                                <p className="text-base">
                                                                    {
                                                                        document.docNo
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p className="font-semibold text-neutral-800">
                                                                    Status
                                                                </p>
                                                                <p className="text-base">
                                                                    {
                                                                        document.status
                                                                    }
                                                                </p>
                                                            </div>
                                                            {document.status ===
                                                                'ERROR' && (
                                                                <div>
                                                                    <p className="font-semibold text-neutral-800">
                                                                        Error
                                                                        Message
                                                                    </p>
                                                                    <p className="text-base">
                                                                        {
                                                                            document.errorMessage
                                                                        }
                                                                    </p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        );
                                    })}
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    onClick={handleCloseApprovalStatusModal}
                                    className="w-[110px] justify-center"
                                >
                                    Close
                                </Button>
                                {prepareAction === 'approve' &&
                                    responseApproveReject?.result_list?.some(
                                        document =>
                                            document.status === 'ESIG0010'
                                    ) && (
                                        <Button
                                            className="w-[110px] justify-center border-0 bg-[#269E41] text-white"
                                            onClick={() =>
                                                responseApproveReject?.result_list &&
                                                handleNavigateToESignature(
                                                    responseApproveReject.result_list as any
                                                )
                                            }
                                        >
                                            Approve anyway
                                        </Button>
                                    )}
                            </ModalFooter>
                        </Modal>
                    </>
                )}
            </Container>
        </React.Fragment>
    );
}

const STATUS_COLOR = {
    APPROVED: 'success',
    CANCEL: 'danger',
    WAITING: 'warning'
};

type ApprovalInChargeStepperProps = {
    document: ModelSearchWorkflowInbox.ResponseWorkflowInboxValue;
};

function ApprovalInChargeStepper(props: ApprovalInChargeStepperProps) {
    const { document } = props;

    const isSpecialMode =
        document.specialMode && document.specialMode === 'guarantee';
    const approvalDetail = document.listApprovalDetail;

    return (
        <Stepper.Container className="mt-3">
            {approvalDetail
                .slice(0, isSpecialMode ? 1 : undefined)
                .map((approval, index) => {
                    const alreadyApprovedOrRejected =
                        approval.status === 'APPROVED' ||
                        approval.status === 'CANCEL';
                    const Icon =
                        statusIcons.get(approval.status) ?? FaQuestionCircle;

                    return (
                        <Stepper
                            icon={Icon}
                            status={STATUS_COLOR[approval.status]}
                        >
                            <div className="flex gap-4">
                                <div>
                                    <p>
                                        {!isSpecialMode &&
                                            alreadyApprovedOrRejected &&
                                            formatDMY(
                                                String(
                                                    approval.updatedOnFormatDateTime
                                                )
                                            )}
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        สถานะ: {approval.status || ' '} จาก{' '}
                                        {approval.listEmployeeInPositionGroup
                                            .map(employee => employee.empname)
                                            .join(', ')}
                                    </p>
                                    <p>
                                        {(approval.reason &&
                                            `รายละเอียด: ${approval.reason}`) ||
                                            ' '}
                                    </p>
                                </div>
                            </div>
                        </Stepper>
                    );
                })}
        </Stepper.Container>
    );
}

// Approve In Charge Table subcomponent
type ApproveInChargeTableProps = {
    document: ModelSearchWorkflowInbox.ResponseWorkflowInboxValue;
};

const statusIcons = new Map<string, IconType>([
    ['APPROVED', FaCheck],
    ['CANCEL', FaTimes],
    ['WAITING', FaClock]
]);

const rowTwClasses = new Map<string, string>([
    ['APPROVED', 'bg-[#90BE6D]'],
    ['CANCEL', 'bg-red-400'],
    ['WAITING', 'bg-orange-400']
]);

const subRowTwClasses = new Map<string, string>([
    ['APPROVED', 'bg-[#90BE6D]/60'],
    ['CANCEL', 'bg-red-400/60'],
    ['WAITING', 'bg-orange-400/60']
]);

function ApproveInChargeTable(props: ApproveInChargeTableProps) {
    const { document } = props;

    const isSpecialMode =
        document.specialMode && document.specialMode === 'guarantee';
    const approvalDetail = document.listApprovalDetail;

    const [selectedDocument, setSelectedDocument] =
        useState<WorkflowInboxValue>();

    return (
        <React.Fragment>
            <table className="mt-3 w-full table-auto border-collapse border">
                <thead className="hidden md:table-header-group">
                    <tr className="table-active text-center [&>th]:p-3">
                        <th></th>
                        <th>Approver Code</th>
                        <th>Approver Name</th>
                        <th>สถานะ</th>
                        <th>Description</th>
                        <th>Approve Date/Time</th>
                    </tr>
                </thead>

                {approvalDetail
                    .slice(0, isSpecialMode ? 1 : undefined)
                    .map((approval, index) => {
                        const alreadyApprovedOrRejected =
                            approval.status === 'APPROVED' ||
                            approval.status === 'CANCEL';
                        const Icon =
                            statusIcons.get(approval.status) ??
                            FaQuestionCircle;

                        // Check overall status if special mode
                        let overallStatus = 'APPROVED';
                        if (isSpecialMode) {
                            for (const approval of approvalDetail) {
                                if (approval.status === 'CANCEL') {
                                    overallStatus = 'CANCEL';
                                    break;
                                } else if (approval.status === 'WAITING') {
                                    overallStatus = 'WAITING';
                                }
                            }
                        }

                        return (
                            <tbody
                                key={approval.instanceno}
                                className="[&_td]:p-3"
                            >
                                <tr
                                    className={twMerge(
                                        'border md:[&>td]:border',
                                        rowTwClasses.get(
                                            isSpecialMode
                                                ? overallStatus
                                                : approval.status
                                        )
                                    )}
                                >
                                    <td>{index + 1}</td>
                                    <td>{approval.positiongroupname}</td>
                                    <td colSpan={1}>
                                        <p className="flex items-center justify-end gap-2 md:hidden">
                                            <Icon size={16} />
                                            <span>{approval.status}</span>
                                            <button
                                                className="ml-4 inline-flex h-7 w-7 items-center justify-center rounded-md border border-neutral-600"
                                                onClick={() =>
                                                    setSelectedDocument(
                                                        approval
                                                    )
                                                }
                                            >
                                                <BsThreeDots
                                                    size={16}
                                                    color="#333"
                                                />
                                            </button>
                                        </p>
                                        <p className="hidden md:block">
                                            {!isSpecialMode &&
                                                alreadyApprovedOrRejected &&
                                                approval.updated_byname}
                                        </p>
                                    </td>
                                    <td className="hidden text-center md:table-cell">
                                        <Icon />
                                    </td>
                                    <td className="hidden md:table-cell">
                                        {!isSpecialMode &&
                                            alreadyApprovedOrRejected &&
                                            approval.reason}
                                    </td>
                                    <td className="hidden md:table-cell">
                                        {!isSpecialMode &&
                                            alreadyApprovedOrRejected &&
                                            formatDMY(
                                                String(
                                                    approval.updatedOnFormatDateTime
                                                )
                                            )}
                                    </td>
                                </tr>

                                {approval.listEmployeeInPositionGroup.map(
                                    (employee, index) => {
                                        const approval = approvalDetail.find(
                                            approval =>
                                                approval.updated_by ===
                                                employee.empid
                                        );

                                        const Icon = statusIcons.get(
                                            approval?.status ?? 'WAITING'
                                        )!;

                                        return (
                                            <tr
                                                key={`emp-${index}`}
                                                className={twMerge(
                                                    isSpecialMode
                                                        ? `${subRowTwClasses.get(
                                                              approval?.status ??
                                                                  'WAITING'
                                                          )!} border [&_>_td]:border`
                                                        : ''
                                                )}
                                            >
                                                <td></td>
                                                <td className="font-semibold">
                                                    {employee.empid}
                                                </td>
                                                <td className="text-right md:text-left">
                                                    {employee.empname}
                                                </td>
                                                {isSpecialMode && (
                                                    <React.Fragment>
                                                        <td className="text-center">
                                                            <Icon />
                                                        </td>
                                                        <td>
                                                            {approval?.reason}
                                                        </td>
                                                        <td>
                                                            {formatDMY(
                                                                String(
                                                                    approval?.updatedOnFormatDateTime ??
                                                                        ''
                                                                )
                                                            )}
                                                        </td>
                                                    </React.Fragment>
                                                )}
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        );
                    })}
            </table>

            <Modal
                isOpen={!!selectedDocument}
                toggle={() => setSelectedDocument(undefined)}
                size="lg"
                backdrop="static"
                keyboard={false}
            >
                <ModalHeader toggle={() => setSelectedDocument(undefined)}>
                    More Detail
                </ModalHeader>
                <ModalBody>
                    {selectedDocument && (
                        <BorderGrid>
                            <BorderGrid.Row>
                                <BorderGrid.Col>
                                    <div style={{ color: '#1473E6' }}>
                                        <b>
                                            {selectedDocument.positiongroupname}
                                        </b>
                                    </div>
                                    <div>
                                        {(() => {
                                            const Icon = statusIcons.get(
                                                selectedDocument.status
                                            )!;

                                            return <Icon size={14} />;
                                        })()}
                                        <span style={{ marginLeft: '5px' }}>
                                            {selectedDocument.status}
                                        </span>
                                    </div>
                                </BorderGrid.Col>
                            </BorderGrid.Row>
                            <BorderGrid.Row>
                                <BorderGrid.Col>
                                    <div>
                                        <label style={{ color: '#4B4B4B' }}>
                                            Description
                                        </label>
                                    </div>
                                    <div>
                                        <span>{selectedDocument.reason}</span>
                                    </div>
                                </BorderGrid.Col>
                            </BorderGrid.Row>
                            <BorderGrid.Row>
                                <BorderGrid.Col>
                                    <div>
                                        <label style={{ color: '#4B4B4B' }}>
                                            Approve Date
                                        </label>
                                    </div>
                                    <div>
                                        <span>
                                            {formatDMY(
                                                String(
                                                    selectedDocument.updatedOnFormatDateTime
                                                )
                                            )}
                                        </span>
                                    </div>
                                </BorderGrid.Col>
                            </BorderGrid.Row>
                        </BorderGrid>
                    )}
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

// Online form subcomponent
type OnlineFormsProps = {
    files: ModelSearchWorkflowInbox.FileUrl[];
};

const DEFAULT_SELECTED_FILE_STATE = {
    src: '',
    fileName: '',
    mode: ''
};

function OnlineForms(props: OnlineFormsProps) {
    const { files } = props;

    const isAlreadyDefaultClickFile = useRef(false);

    const [selectedFile, setSelectedFile] = useState(
        DEFAULT_SELECTED_FILE_STATE
    );

    const handleOpenFileOnNewTab = (
        event: any,
        fileName: string,
        filepath: string,
        mode: string
    ) => {
        event.target.dataset.filepath = filepath;
        event.target.dataset.filename = fileName;
        event.target.dataset.mode = mode;

        onClickFileShow(event, '');
    };

    const handleOpenFileOnIframe = async (
        event: any,
        fileName: string,
        mode: string
    ) => {
        const src = await onClickFileShowIframe(event, mode, '');

        setSelectedFile({
            src: src ?? '',
            fileName: fileName,
            mode: mode
        });
    };

    const handleCloseIframe = () => {
        setSelectedFile(DEFAULT_SELECTED_FILE_STATE);
    };

    return (
        <React.Fragment>
            <Row className="mt-3">
                <Col>
                    <p className="rounded-md border-[2px] border-zinc-200 px-4 py-2">
                        {files.map((file, index) => (
                            <React.Fragment key={file.url}>
                                {file.file_name && file.url && (
                                    <>
                                        <span
                                            data-filename={file.file_name}
                                            data-filepath={file.url}
                                            onClick={event =>
                                                handleOpenFileOnNewTab(
                                                    event,
                                                    file.file_name,
                                                    file.url,
                                                    's3'
                                                )
                                            }
                                            className="cursor-pointer"
                                        >
                                            <FaShare
                                                style={{
                                                    transform: 'rotate(270deg)',
                                                    color: 'green'
                                                }}
                                            />
                                        </span>{' '}
                                        <span
                                            ref={ref => {
                                                !isAlreadyDefaultClickFile.current &&
                                                    index === 0 &&
                                                    ref?.click();
                                                isAlreadyDefaultClickFile.current =
                                                    true;
                                            }}
                                            data-filename={file.file_name}
                                            data-filepath={file.url}
                                            onClick={event =>
                                                handleOpenFileOnIframe(
                                                    event,
                                                    file.file_name,
                                                    's3'
                                                )
                                            }
                                            className="cursor-pointer text-[#0366d6] hover:underline"
                                        >
                                            <FaFileAlt /> {file.file_name}
                                        </span>
                                    </>
                                )}
                            </React.Fragment>
                        ))}
                    </p>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    {selectedFile.src && (
                        <Card>
                            <Card.Header>
                                {selectedFile.fileName}
                                <span className="float-right">
                                    <Button
                                        className="w-[110px] justify-center"
                                        onClick={handleCloseIframe}
                                    >
                                        <FaTimes
                                            size={15}
                                            color="red"
                                        />
                                        &nbsp;ปิด
                                    </Button>
                                </span>
                            </Card.Header>
                            <Card.Body>
                                <iframe
                                    style={{ minHeight: '1025px' }}
                                    src={selectedFile.src}
                                    height="100%"
                                    width="100%"
                                    frameBorder="0"
                                />
                            </Card.Body>
                        </Card>
                    )}
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default OnlineFormInformation;
