import { DatasourceReturnType, ResponseError, ResponseList } from "models/common/respone.model";
import { CreateEmployeeBodyAndResponse } from "../schemas/PositionEmployee.schema";
import { putPositionEmployee, putUpdatePositionEmployee } from "../datasources/PositionEmployee.datasource";

export async function CreateAndUpdateAssignmentRouting(username: string, app_id: string, positionEmployeeList: CreateEmployeeBodyAndResponse[]): Promise<
    DatasourceReturnType<ResponseError, ResponseList<CreateEmployeeBodyAndResponse>>
> {
    try {
        const data = (await putPositionEmployee(username, app_id, positionEmployeeList)).data;

        return [null!, data];
    } catch (error) {
        throw error;
    }
}

export async function UpdateAssignmentRouting(app_id: string, positionEmployeeList: CreateEmployeeBodyAndResponse[]): Promise<
    DatasourceReturnType<ResponseError, string>
> {
    try {
        const data = (await putUpdatePositionEmployee(app_id, positionEmployeeList)).data;

        return [null!, data];
    } catch (error) {
        throw error;
    }
}