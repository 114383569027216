import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

import * as Models from '../../models/group/SearchGroup'

interface LoadSearchGroup {
    type: 'LOAD_SEARCH_GROUP'
}

interface LoadedSearchGroup {
    type: 'LOADEDSEARCHGROUP'
    response: Models.ResponseSearchGroup
    status: Number
    statusMessage: String
}

interface FailSearchGroup {
    type: 'LOADFAIL'
    status: number,
    statusMessage: any
}

interface ClearSearchGroup {
    type: 'CLEARSEARCHGROUP'
    response: Models.ResponseSearchGroup
    status: Number
    statusMessage: String
}

type KnownAction = LoadedSearchGroup | FailSearchGroup | ClearSearchGroup | LoadSearchGroup

export const actionCreators = {
    requestsearchgroup: (p: boolean, method: String, position_group_id: string , company : string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.searchGroup && p && method == 'POST' && position_group_id) {
            await fetch(`/v2/Group/Search?GroupID=` + position_group_id + '&app_id='+ company, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('errorrrr')
                            dispatch({ type: 'LOADFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            dispatch({ type: 'LOADEDSEARCHGROUP', response: data as Models.ResponseSearchGroup, status: 200, statusMessage: '' });
                        })
                    }
                })

            dispatch({ type: 'LOAD_SEARCH_GROUP' });

        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARSEARCHGROUP', response: [] as any, status: 204, statusMessage: '' });
        }
    }
}

const unloadedState: Models.SearchGrouptate = { isLoad: false };

export const reducer: Reducer<Models.SearchGrouptate> = (state: Models.SearchGrouptate | undefined, incomingAction: Action): Models.SearchGrouptate => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_SEARCH_GROUP':
            return {
                isLoad: false,
            }

        case 'LOADEDSEARCHGROUP':
            return {
                isLoad: true,
                responseSearch: action.response,
                statusSearch: action.status
            }

        case 'LOADFAIL':
            return {
                isLoad: true,
                message: action.statusMessage,
                statusSearch: action.status
            }

        case 'CLEARSEARCHGROUP':
            return {
                isLoad: true,
                responseSearch: action.response,
                statusSearch: action.status
            }
        default: return state;
    }
}