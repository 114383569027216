import * as React from 'react';
import { Button, Col, Form, Row, Spinner, Tab, Table, Tabs, Card, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as ModelsWorkflowInbox from '../../models/workflow/WorkflowInbox';
import { changeSidToName, formatDMY, numberWithCommas, parseJwt } from '../../modules/Common';
import Swal from 'sweetalert2'
import QueryString from "query-string";
import { Redirect } from "react-router";
import { ApplicationState } from "../../store";

import WorkflowInboxInformation from './WorkflowInboxInformation';
import TransactionInformation from './TransactionInformation';
import ReportInformation from './ReportInformation';
import RemarkWorkflowMaster from '../remark/RemarkWorkflowMaster';
import * as ModelSearchWorkflowInbox from '../../models/workflow/SearchWorkflowInbox';
import * as SearchWorkflowInboxStore from '../../store/workflow/SearchWorkflowInbox';

import * as ModelsRef from '../../models/reference/Reference';
import WorkflowInboxInformationMobile from './WorkflowInboxInformationMobile';
import { debounce } from '../../helpers/utils';

type SearchworkflowInboxProps = ModelSearchWorkflowInbox.GetWorkflowInboxValueState & typeof SearchWorkflowInboxStore.actionCreators

class WorkflowInboxOverview extends React.Component<any, {
    workflowValue: ModelsWorkflowInbox.WorkflowValue, showModal: boolean, srcHistory: string, userId: string, token: string, srcLogin: string, loginModal: boolean, checkurl: string, controlRedirect: boolean, callBackReport: boolean
    JSONReportList: ModelsWorkflowInbox.JSONReportList, innerWidth: number
}, SearchworkflowInboxProps>{

    constructor(props: any) {
        super(props);
        this.state = {
            workflowValue: {
                wfId: "",
                docNo: "",
                wfInstanceNo: "",
                userId: "",
                token: "",
                appId: "",
                wfType: "",
                fiscalYear: "",
                docType: "",
            },
            showModal: false,
            srcHistory: "",
            userId: "",
            token: "",
            srcLogin: "",
            loginModal: false,
            checkurl: "",
            controlRedirect: false,
            callBackReport: false,
            JSONReportList: {
                DataHeader: {
                    column: []
                },
                ListDataItem: []
            },
            innerWidth: window.innerWidth
        }
    }

    componentDidMount() {
        this.setState({
            srcHistory: window.document.location.href
        })

        if (this.props.location != undefined) {
            //window.history.pushState({}, "Workflow Information", "/workflow-information");

            var { wfId, docNo, wfInstanceNo, userId, token, ref_id, sid } = QueryString.parse(this.props.location.search)
            if (ref_id) {
                fetch('/workflow/reference?id=' + ref_id, {
                    method: 'GET',
                })
                    .then((response) => {
                        if (response.status != 200) {
                            throw new Error(response.json() as any)
                        } else {
                            return response.json() as Promise<ModelsRef.Response>
                        }
                    })
                    .then(data => {
                        console.log(data)
                        let params = new URLSearchParams(data.parameter);
                        console.log(params.get("app_id"))
                        wfId = params.get("wfId");
                        docNo = params.get("docNo");
                        wfInstanceNo = params.get("wfInstanceNo");
                        userId = params.get("userId");
                        token = params.get("token");
                        if (params.get("app_id") != null || params.get("app_id") != undefined) {
                            sid = params.get("app_id");
                        } else {
                            sid = data.sid;
                        }
                        var localUserId: string = ""

                        var access_token = parseJwt(token!)
                        if (Date.now() < access_token.exp * 1000) {
                            var extra = JSON.parse(access_token.extra)
                            console.log(extra)

                            if (extra.citizen_id) {
                                var tokenData = {
                                    access_token: token,
                                    /*            user_id: extra.user_id*/
                                }

                                localStorage.removeItem('WF_APPLICATION');
                                localStorage.setItem('WF_APPLICATION', JSON.stringify(tokenData));

                                console.log('Azure Login ID: ' + extra.citizen_id)
                                localUserId = extra.citizen_id
                                this.setState({ userId: extra.citizen_id, token: token! })
                            } else if (extra.username) {
                                var tokenData = {
                                    access_token: token,
                                    /*            user_id: extra.user_id*/
                                }

                                localStorage.removeItem('WF_APPLICATION');
                                localStorage.setItem('WF_APPLICATION', JSON.stringify(tokenData));

                                console.log('Local Login ID: ' + extra.sid)
                                localUserId = extra.username
                                this.setState({ userId: extra.username, token: jls.access_token })
                            }
                        }

                        console.log(localStorage.getItem("WF_APPLICATION"))
                        if (!localUserId) {
                            var ls: any = localStorage.getItem("WF_APPLICATION");
                            var jls: any = JSON.parse(ls)
                            var access_token = parseJwt(jls.access_token)
                            if (Date.now() < access_token.exp * 1000) {
                                var extra = JSON.parse(access_token.extra)
                                console.log(extra)

                                if (extra.citizen_id) {
                                    console.log('Azure Login ID: ' + extra.citizen_id)
                                    localUserId = extra.citizen_id
                                    this.setState({ userId: extra.citizen_id, token: jls.access_token })
                                } else if (extra.username) {
                                    console.log('Local Login ID: ' + extra.sid)
                                    localUserId = extra.username
                                    this.setState({ userId: extra.username, token: jls.access_token })
                                }
                            }
                        }

                        if (localUserId.toLocaleLowerCase() != userId!.toLocaleLowerCase()) {
                            var srcc = window.location.protocol + '//' + window.location.host + '/auth/signin'
                            //this.setState({
                            //    srcLogin: srcc
                            //})
                            this.props.requestsearchworkflowInbox(true, "CLOSE", wfId, docNo, userId, wfInstanceNo)
                            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                            //this.setState({
                            //    loginModal: true,
                            //    //srcLogin: srcc
                            //})
                            if (localUserId) {
                                Swal.fire({
                                    title: 'ไม่สำเร็จ!',
                                    text: 'คุณ ' + localUserId + ' ไม่ได้รับอนุญาตให้ Approve เอกสาร\nกรุณากดยืนยัน เพื่อล็อคอินใหม่',
                                    icon: 'error',
                                    showCancelButton: false,
                                    confirmButtonColor: '#3085d6',
                                    confirmButtonText: 'ยืนยัน!',
                                    allowOutsideClick: false,
                                }).then((result) => {
                                    if (result.value) {
                                        console.log(this.state)
                                        this.setState({
                                            loginModal: true,
                                            srcLogin: srcc
                                        })
                                    }
                                })
                            }
                            else {
                                this.setState({
                                    loginModal: true,
                                    srcLogin: srcc
                                })
                            }
                        } else {
                            this.setState({
                                workflowValue: {
                                    refId: ref_id as string,
                                    wfId: wfId!,
                                    docNo: docNo!,
                                    wfInstanceNo: wfInstanceNo!,
                                    userId: userId!,
                                    token: token!,
                                    sid: sid as any,
                                    appId: sid as any
                                },
                                checkurl: "URL",
                                controlRedirect: true
                            })
                            console.log(this.state.workflowValue)
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    });
            }
        } else {
            const { workflowValue } = this.props
            this.setState({
                workflowValue: {
                    wfId: workflowValue.wfId,
                    docNo: workflowValue.docNo,
                    wfInstanceNo: workflowValue.wfInstanceNo,
                    userId: workflowValue.userId,
                    token: workflowValue.token,
                    sid: workflowValue.appId,
                    appId: workflowValue.appId
                },
                checkurl: "No_URL",
            })
        }

        const onResizeDebounce = debounce(this.onResize, 200);
        window.addEventListener('resize', onResizeDebounce);
    }

    onResize = (_event: UIEvent) => {
        this.setState({ innerWidth: window.innerWidth });
    };

    blindopenModal = (value: any) => {
        ////document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
        //if (value == "reload") {
        //    //window.location.reload();
        //    this.props.requestWorkflowInbox(true, "POST", this.state.userId, this.props.sids, 1, this.state.pageSize, '', this.props.searchReq)
        //}
        //this.setState({
        //    showModal: false,
        //    wfId: "",
        //    docNo: "",
        //    wfInstanceno: ""
        //})
    }

    public refreshPageChild() {
        this.props.refreshPage && this.props.refreshPage()
    }

    jsonReportCallback = (value: string) => {
        console.log(value)
        this.setState({
            callBackReport: true,
        })
        try {
            if (value != null) {
                this.setState({
                    JSONReportList: JSON.parse(value)
                })
            }
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        const breakpoint = 768;

        if (this.state.loginModal) {
            console.log('redirect to logout')
            return <Redirect to={{ pathname: '/auth/signin', state: { from: this.state.srcHistory } }} />;
        }

        return (
            <Container>
                <Row className="mt-3">
                    <Col>
                        {this.state.workflowValue.wfId != "" && this.state.workflowValue.wfInstanceNo != "" &&
                            <Tabs defaultActiveKey="WorkflowInboxInformation" id="tab">
                                <Tab eventKey="WorkflowInboxInformation" title="Workflow Information" >
                                    {/* <WorkflowInboxInformation wfId={this.state.wfId} docNo={this.state.docNo} wfInstanceno={this.state.wfInstanceno} userId={this.state.userId} showmodal={this.state.showModal} blindopenModal={this.blindopenModal.bind(this)} />*/}
                                    {
                                        innerWidth > breakpoint ? (
                                            <WorkflowInboxInformation
                                                workflowValue={this.state.workflowValue}
                                                showmodal={true}
                                                blindopenModal={this.blindopenModal.bind(this)}
                                                refreshPageChild={this.refreshPageChild.bind(this)}
                                                jsonReportCallback={this.jsonReportCallback.bind(this)}
                                            />
                                        ) : (
                                            <WorkflowInboxInformationMobile
                                                workflowValue={this.state.workflowValue}
                                                showmodal={true}
                                                blindopenModal={this.blindopenModal.bind(this)}
                                                refreshPageChild={this.refreshPageChild.bind(this)}
                                                jsonReportCallback={this.jsonReportCallback.bind(this)}
                                            />
                                        )
                                    }
                                    {/* <iframe src={this.state.src} name="myIframe" height="100%" width="100%" title="Iframe Example"  frameBorder="0" ref="iframe"></iframe>*/}
                                </Tab>
                                {(this.state.workflowValue.appId != "e5mPFn1JEi76UvyyPkOf" && this.state.callBackReport) && <Tab eventKey="ReportInformation" title="Report information">
                                    <ReportInformation JSONReportList={this.state.JSONReportList} />
                                </Tab>}
                                {(this.state.workflowValue.wfType == "PR" || this.state.workflowValue.wfType == "PO") && <Tab eventKey="Transaction" title="Transaction Information">
                                    <TransactionInformation workflowValue={this.state.workflowValue} />
                                </Tab>}
                                {(this.state.workflowValue.appId != "e5mPFn1JEi76UvyyPkOf") && <Tab eventKey="Remark" title="Work Chat">
                                    <RemarkWorkflowMaster workflowValue={this.state.workflowValue} />
                                </Tab>}
                            </Tabs>
                        }
                    </Col>
                </Row>
            </Container>
        )
    }
}
export default connect((state: ApplicationState) => ({ ...state.searchworkflowInbox }), ({ ...SearchWorkflowInboxStore.actionCreators }))(WorkflowInboxOverview)