import * as React from "react";
import { connect } from "react-redux";
import { Button, Table, Spinner } from "react-bootstrap";
import { FaEdit, FaTimesCircle } from 'react-icons/fa';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
import '../../style/table/table.css'

// redux
import * as EmployeeStore from '../../store/employee/PositionEmployee'
import * as Models from '../../models/employee/PositionEmployee'

import * as DeleteStore from '../../store/employee/DeleteEmployee'
import * as DeleteModels from '../../models/employee/DeleteEmployee'

import { ApplicationState } from "../../store";
import { getRoleMasterEmployee } from "data/MasterEmployee/repositories/MasterEmployee.repository";
import { RoleMasterEmployeeResponse } from "data/MasterEmployee/schemas/MasterEmployee.shema";

type employeeProps = Models.GetPositionEmployeeState & typeof EmployeeStore.actionCreators;
type deleteProps = DeleteModels.DeletePositionEmployeeState & typeof DeleteStore.actionCreators;

class PositionEmployeeTable extends React.Component<any, { response: any, delete: boolean, dataResult: any, company?: string, keyReset: boolean, roleid: string, empid: string, roleMasterEmployee: RoleMasterEmployeeResponse[], missingPositionCode: string[], presentPositionCode: string[] }, employeeProps & deleteProps> {

    constructor(props: any) {
        super(props);
        this.state = {
            response: "",
            delete: false,
            dataResult: [],
            company: undefined,
            keyReset: false,
            roleid: "",
            empid: "",
            roleMasterEmployee: [],
            missingPositionCode: [],
            presentPositionCode: []
        }
        this.mappingRoleMasterEmployee = this.mappingRoleMasterEmployee.bind(this);
    }

    loading() {
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
    }

    componentDidMount() {
        this.fetchRoleMasterEmployee();
    }

    fetchRoleMasterEmployee = async () => {
        const [, response] = await getRoleMasterEmployee();

        if (response) {
            this.setState({ roleMasterEmployee: response });
        }
    };

    mappingRoleMasterEmployee = async () => {
        if (this.props.responseEmployee && this.props.responseEmployee['result_list'] && this.state.roleMasterEmployee.length > 0) {
            const roleMasterEmployee = this.state.roleMasterEmployee.map(item => item.positioncode);
            const roleEmployee = this.props.responseEmployee['result_list'].map(item => item.positioncode);
            const missingPositionCode = roleMasterEmployee.filter(code => !roleEmployee.includes(code));
            const presentPositionCode = roleEmployee.filter(code => roleMasterEmployee.includes(code));
            this.setState({ missingPositionCode: missingPositionCode, presentPositionCode: presentPositionCode })
        } else {
            this.setState({ missingPositionCode: [], presentPositionCode: [] })
        }
    }

    ondelete = (idEmployee: string) => {
        Swal.fire({
            title: 'ลบข้อมูล ?',
            text: 'คุณต้องการลบหรือไม่ ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ไม่'
        }).then((result) => {
            if (result.value) {
                //  this.props.submitUser(this.state)
                this.props.requestdeletePositionemployee(true, "POST", idEmployee, this.state.company)
                document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
                this.setState({
                    delete: true
                })
            }
        })
    }

    formatDMY = (dmy: string) => {
        var year = dmy.substring(0, 4);
        var month = dmy.substring(4, 6);
        var day = dmy.substring(6, 8);
        var Datevalue = "";
        if (dmy.length < 9) {
            Datevalue = year + '-' + month + '-' + day
        } else {
            var hour = dmy.substring(8, 10);
            var second = dmy.substring(10, 12);
            var millssecond = dmy.substring(12, 14);
            Datevalue = year + '-' + month + '-' + day + ' ' + hour + ':' + second + ':' + millssecond
        }

        return Datevalue;
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        if (this.state.keyReset != this.props.keyReset) {
            if (this.props.keyReset) {
                this.props.callbackReset()
                this.setState({
                    roleid: this.props.roleid,
                    empid: this.props.empid,
                    company: this.props.company
                })
                this.props.requestPositionemployee(this.props.company, this.props.roleid, this.props.empid);
            }
        } else {
            var company = this.props.company
            if (company != this.state.company) {
                if (company) {
                    this.props.requestPositionemployee(this.props.company, "", "");
                }
                this.props.callbackResetSearch()
                this.setState({
                    company: company
                });
            }
        }

        if (this.props.statusDelete == 200 && this.state.delete == true) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            this.setState({
                delete: false
            })
            this.props.requestdeletePositionemployee(true, "CLEAR", '')
            Swal.fire({
                title: 'สำเร็จ!',
                text: 'ลบข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {
                if (result.value) {
                    //this.props.requestPositionemployee(true, "POST");
                    this.props.requestPositionemployee(this.props.company, this.state.roleid, this.state.empid);
                }
            })
        } else if (this.props.statusDelete == 204 && this.state.delete == true) {
            this.props.requestdeletePositionemployee(true, "CLEAR", '')
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: 'ลบข้อมูลไม่สำเร็จ',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {
                this.props.requestPositionemployee(this.props.company, this.state.roleid, this.state.empid);
            })
        }

        if (this.props.responseEmployee !== nextProps.responseEmployee) {
            this.mappingRoleMasterEmployee();
        }
    }

    render() {
        // Find the difference between centerCodes and inputCodes
        return (
            <div>
                <div className="flex gap-2">
                    <span className="text-green-600">
                        มี Role สำคัญทั้งหมด {this.state.presentPositionCode.length} /
                    </span>
                    <span className="text-red-600">
                        ขาด Role สำคัญทั้งหมด {this.state.missingPositionCode.length}
                    </span>
                </div>
                <Table className="table-bordered" size="sm">
                    <thead>
                        <tr style={{ width: '100%' }} className="text-center table-active thead-light">
                            <th style={{ width: '10%' }}></th>
                            <th style={{ width: '30%' }}>Role Code</th>
                            <th style={{ width: '20%' }}>Field Description</th>
                            <th style={{ width: '20%' }}>Created By</th>
                            <th style={{ width: '20%' }}>Created On</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!this.props.isLoadPositionEmployee ? <tr><td colSpan={5} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr> : <tr></tr>}
                        {this.props.status == 200 && this.props.isLoadPositionEmployee && this.props.responseEmployee['result_list'] == null ? <tr><td colSpan={5} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr> : <tr></tr>}
                        {this.props.status == 200 && this.props.responseEmployee['result_list'] != null && this.props.responseEmployee['result_list'].map((item: Models.PositionEmployee) =>
                            <tr key={item.positioncode}>
                                <td className="text-center">
                                    <div>
                                        <Link to={{ pathname: '/addpositionemployee', state: { positioncode: item.positioncode, positionname: item.positionname } }}>
                                            <Button variant="outline-info" onClick={() => this.loading()} size="sm"><FaEdit /></Button>
                                        </Link>
                                        <Button className="mx-1" variant="outline-danger" size="sm" onClick={() => this.ondelete(item.positioncode)}><FaTimesCircle /></Button>
                                    </div>
                                </td>
                                <td>{item.positioncode}</td>
                                <td>{item.positionname}</td>
                                <td>{item.created_by}</td>
                                <td>{this.formatDMY(String(item.created_on))}</td>
                            </tr>
                        )}

                    </tbody>
                </Table>
            </div>
        );
    }
}
export default connect((state: ApplicationState) => ({ ...state.appState, ...state.positionEmployee, ...state.deleteEmployee }), ({ ...EmployeeStore.actionCreators, ...DeleteStore.actionCreators }))(PositionEmployeeTable);