import axios from 'axios';
import {
    AddAdminMasterEmployee,
    deleteAdminMasterEmployee,
    getAdminMasterEmployee,
    getMasterEmployee
} from 'data/MasterEmployee/repositories/MasterEmployee.repository';
import {
    AdminMasterEmployeeResponse,
    MasterEmployeeRequest,
    WorkflowAdminReqeust
} from 'data/MasterEmployee/schemas/MasterEmployee.shema';
import useLoader from 'hooks/useLoader';
import {
    MasterCompanyList,
    MasterCompanyRes,
    MasterCompanyResponse
} from 'models/company/MasterCompany';
import { parseJwt } from 'modules/Common';
import { useEffect, useState } from 'react';
import Swal, { SweetAlertResult } from 'sweetalert2';

function WorkflowConfigAdminViewModel() {
    useEffect(() => {
        var ls: any = localStorage.getItem('WF_APPLICATION');
        var jls: any = JSON.parse(ls);
        var access_token = parseJwt(jls.access_token);
        var extra = JSON.parse(access_token.extra);

        setUserAdmin(extra.citizen_id);

        fetchUserAdminMasterEmployee();
        fetchUserMasterEmployee();
        fetchCompanyMaster();
    }, []);

    const loading = useLoader();
    const [userAdminResponse, setUserAdminResponse] =
        useState<AdminMasterEmployeeResponse[]>();

    const [userMasterEmployee, setUserMasterEmployee] =
        useState<MasterEmployeeRequest[]>();

    const [userAdmin, setUserAdmin] = useState<string>('');
    const [modeAddUser, setModeAddUser] = useState<boolean>(false);
    const [companyMaster, setCompanyMater] = useState<MasterCompanyList[]>();
    const [searchHelp, setSearchHelp] = useState<WorkflowAdminReqeust>({
        userID: '',
        company: '',
        employeeName: '',
        roleAccess: ''
    });

    const [selectdAllCompany, setSelectdAllCompany] = useState<boolean>(true);

    const fetchUserAdminMasterEmployee = async () => {
        if (searchHelp.company === '' && !selectdAllCompany) {
            Swal.fire({
                icon: 'info',
                title: 'Please choose',
                text: 'กรุณาเลือก Company'
            });

            return;
        }
        try {
            loading.show();
            const [_, response] = await getAdminMasterEmployee(searchHelp);

            if (response) {
                setUserAdminResponse(response);
            }
        } finally {
            loading.hide();
        }
    };

    const fetchUserMasterEmployee = async () => {
        try {
            loading.show();
            const [_, response] = await getMasterEmployee();

            if (response) {
                setUserMasterEmployee(response.result_list);
            }
        } finally {
            loading.hide();
        }
    };

    const handleAddAdminUser = () => {
        setModeAddUser(true);
    };

    const handleAddUserAdmin = async (
        sid: string,
        role: string,
        employee: string
    ) => {
        await createUserAdminMasterEmployee(employee, userAdmin, sid, role);
        // const options: { [key: string]: string } = {};
        // userMasterEmployee &&
        //     userMasterEmployee!.forEach(value => {
        //         options[value.email!] = `${value.id} (${value.email})`;
        //     });
        // Swal.fire({
        //     title: 'กรุณาเลือก User',
        //     input: 'select',
        //     inputOptions: options,
        //     inputValidator: value => {
        //         return new Promise(resolve => {
        //             if (!value) {
        //                 resolve('กรุณาเลือก User');
        //             } else {
        //                 resolve(undefined!);
        //             }
        //         });
        //     },
        //     allowOutsideClick: false,
        //     showCloseButton: true,
        //     showCancelButton: true,
        //     confirmButtonText: 'Add',
        //     customClass: {
        //         container: 'z-[9999]',
        //         confirmButton:
        //             'bg-[#FFFF] border-[#28a745] hover:bg-[#28a745] hover:text-[#FFFF] text-[#28a745]',
        //         cancelButton:
        //             'bg-[#FFFF] border-[#dc3545] hover:bg-[#dc3545] hover:text-[#FFFF] text-[#dc3545]',
        //         input: 'border rounded-lg p-2'
        //     }
        // }).then((result: SweetAlertResult<string>) => {
        //     if (result.isConfirmed) {
        //         // createUserAdminMasterEmployee(result.value!, userAdmin);
        //     }
        // });
    };

    const createUserAdminMasterEmployee = async (
        user: string,
        createBy: string,
        sid: string,
        roleAccess: string
    ) => {
        try {
            loading.show();
            const [_, response] = await AddAdminMasterEmployee({
                user: user,
                createBy: createBy,
                sid: sid,
                roleAccess: roleAccess
            });

            if (response.message === 'success') {
                Swal.fire({
                    title: 'Success',
                    text: `เพิ่ม User: ${user} เป็น Super Admin แล้ว`,
                    icon: 'success'
                }).then(result => {
                    if (result.isConfirmed) {
                        fetchUserAdminMasterEmployee();
                        setModeAddUser(false);
                    }
                });
            }
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: `User: ${user} เป็น Super Admin อยู่แล้ว`,
                icon: 'error'
            });
        } finally {
            loading.hide();
        }
    };

    const deleteUserAdminMasterEmployee = async (user: string) => {
        try {
            loading.show();
            const [_, response] = await deleteAdminMasterEmployee({
                userId: user
            });
            if (response.message === 'success') {
                Swal.fire({
                    title: 'Success',
                    text: `ลบ User: ${user} แล้ว`,
                    icon: 'success'
                }).then(result => {
                    if (result.isConfirmed) {
                        fetchUserAdminMasterEmployee();
                    }
                });
            }
        } finally {
            loading.hide();
        }
    };

    const handleDeleteAdminMasterEmployee = async (user: string) => {
        Swal.fire({
            title: 'คุณต้องการลบ',
            text: `User: ${user} ออกจากการเป็น Super Admin ใช่หรือไม่`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'yes'
        }).then(result => {
            if (result.isConfirmed) {
                deleteUserAdminMasterEmployee(user);
            }
        });
    };

    const fetchCompanyMaster = async () => {
        const response = await axios.get<MasterCompanyResponse>(
            '/v1/company_master',
            {
                headers: {
                    Authorization:
                        'Bearer ' + localStorage.getItem('WF_APPLICATION')
                }
            }
        );
        setCompanyMater(response.data.result_list);
    };

    const handleChangeSearchHelp = <K extends keyof WorkflowAdminReqeust>(
        key: K,
        value: WorkflowAdminReqeust[K]
    ) => {
        setSearchHelp(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const mappingCompany = new Map<string, string>(
        companyMaster?.map(value => [value.sid, value.company_name])
    );

    return {
        modeAddUser,
        companyMaster,
        selectdAllCompany,
        userAdminResponse,
        userMasterEmployee,

        mappingCompany,
        handleAddAdminUser,
        handleAddUserAdmin,
        setSelectdAllCompany,
        handleChangeSearchHelp,
        fetchUserAdminMasterEmployee,
        handleDeleteAdminMasterEmployee
    };
}

export default WorkflowConfigAdminViewModel;
