import axios from 'axios';
import { Input } from 'reactstrap';
import { FaSearch } from 'react-icons/fa';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    Button,
    Container,
    Row,
    Col,
    Form,
    ButtonGroup
} from 'react-bootstrap';

import {
    MasterCompanyList,
    MasterCompanyResponse
} from 'models/company/MasterCompany';
import { getAssignmentRouting } from 'data/AssignmentRouting/repositories/AssignmentRouting.repository';
import {
    AssignmentRoutingParams,
    AssignmentRoutingResponse
} from 'data/AssignmentRouting/schemas/AssignmentRouting.schema';
import AssignmentRoutingTable from './components/AssignmentRoutingConfigTable';
import useLoader from 'hooks/useLoader';
import { CreateEmployeeBodyAndResponse } from 'data/PositionEmployee/schemas/PositionEmployee.schema';
import { UpdateAssignmentRouting } from 'data/PositionEmployee/repositories/PositionEmployee.repository';
import { parseJwt } from 'helpers/decodeLocalStorage';
import Swal from 'sweetalert2';

function AssignmentRoutingConfig() {
    const loading = useLoader();

    const [filter, setFilter] = useState<'All' | 'Active' | 'InActive'>('All');
    const [companyMaster, setCompanyMater] = useState<MasterCompanyList[]>();
    const [assignmentRoutingParams, setAssignmentRoutingParams] =
        useState<AssignmentRoutingParams>({
            authgroup: '',
            empid: '',
            positioncode: '',
            sids: ['']
        });
    const [assignmentRoutingResponse, setAssignmentRoutingResponse] =
        useState<AssignmentRoutingResponse[]>();
    const [assignmentRoutingMapping, setAssignmentRoutingMapping] =
        useState<AssignmentRoutingResponse[]>();

    const company = useRef<string>('');
    const username = useRef<string>('');

    useEffect(() => {
        fetchCompanyMaster();

        var ls: any = localStorage.getItem('WF_APPLICATION');
        var jls: any = JSON.parse(ls);
        var access_token = parseJwt(jls.access_token);
        var extra = JSON.parse(access_token.extra);

        if (extra.citizen_id) {
            username.current = extra.citizen_id;
        } else if (extra.username) {
            username.current = extra.username;
        }
    }, []);

    const filteredReports = useMemo(() => {
        if (!assignmentRoutingResponse) {
            return undefined;
        }

        if (filter === 'All') {
            return assignmentRoutingResponse;
        }

        const condition =
            filter === 'Active'
                ? (validToDate: Date) => validToDate >= new Date()
                : (validToDate: Date) => validToDate < new Date();

        return assignmentRoutingResponse.filter(report => {
            const validto = report.validto;
            const year = Number(validto.slice(0, 4));
            const month = Number(validto.slice(4, 6)) - 1;
            const day = Number(validto.slice(6, 8));

            const validtoDate = new Date(year, month, day);

            return condition(validtoDate);
        });
    }, [filter, assignmentRoutingResponse]);

    const filterButtons = useMemo(
        () => [
            {
                label: 'All',
                value: 'All',
                onClick: () => setFilter('All')
            },
            {
                label: 'Active',
                value: 'Active',
                onClick: () => setFilter('Active')
            },
            {
                label: 'In-Active',
                value: 'InActive',
                onClick: () => setFilter('InActive')
            }
        ],
        []
    );

    const fetchCompanyMaster = async () => {
        const response = await axios.get<MasterCompanyResponse>(
            '/v1/company_master',
            {
                headers: {
                    Authorization:
                        'Bearer ' + localStorage.getItem('WF_APPLICATION')
                }
            }
        );
        setCompanyMater(response.data.result_list);
    };

    const fetchAssignmentRouting = async () => {
        if (company.current === '') {
            Swal.fire({
                icon: 'warning',
                text: 'กรุณาเลือก Company!'
            });
            return;
        }
        try {
            loading.show();

            const [, response] = await getAssignmentRouting(
                assignmentRoutingParams
            );

            if (response) {
                setAssignmentRoutingResponse(response.result_list);
            }
        } finally {
            loading.hide();
        }
    };

    const handleChangeSearchHelp = <K extends keyof AssignmentRoutingParams>(
        key: K,
        value: AssignmentRoutingParams[K]
    ) => {
        setAssignmentRoutingParams(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    // const handleChangeConfigAssignmentRouting1 = <
    //     K extends keyof AssignmentRoutingResponse
    // >(
    //     filteredIndex: number,
    //     key: K,
    //     value: AssignmentRoutingResponse[K]
    // ) => {
    //     if (!filteredReports) {
    //         return;
    //     }

    //     const reportToUpdate = filteredReports[filteredIndex];
    //     const originalIndex = assignmentRoutingResponse!.findIndex(
    //         report => report.sid === reportToUpdate.sid
    //     );

    //     if (originalIndex === -1) {
    //         return;
    //     }

    //     setAssignmentRoutingResponse(prevState => {
    //         const newState = [...prevState!];
    //         const data = newState[originalIndex];

    //         if (data) {
    //             newState[originalIndex] = {
    //                 ...data,
    //                 [key]: value
    //             };
    //         }

    //         return newState;
    //     });
    // };

    const handleChangeConfigAssignmentRouting = (
        assignmentToUpdate: AssignmentRoutingResponse,
        field: 'validfrom' | 'validto',
        value: string
    ) => {
        setAssignmentRoutingMapping(prevAssignments => {
            if (!prevAssignments || prevAssignments.length === 0) {
                return [{ ...assignmentToUpdate, [field]: value.trim() }];
            }

            let assignmentFound = false;

            const updatedAssignments = prevAssignments.map(assignment => {
                if (
                    assignment.positioncode ===
                        assignmentToUpdate.positioncode &&
                    assignment.emp_name === assignmentToUpdate.emp_name &&
                    assignment.authgroup === assignmentToUpdate.authgroup
                ) {
                    assignmentFound = true;
                    return {
                        ...assignment,
                        [field]: value.trim()
                    };
                }
                return assignment;
            });

            if (!assignmentFound) {
                updatedAssignments.push({
                    ...assignmentToUpdate,
                    [field]: value.trim()
                });
            }

            return updatedAssignments;
        });
    };

    const handleSubmitChangeData = async () => {
        const body: CreateEmployeeBodyAndResponse[] = [];

        assignmentRoutingMapping!.forEach(element => {
            const data: CreateEmployeeBodyAndResponse = {
                emp_id: element.empid,
                emp_name: element.emp_name,
                position_code: element.positioncode,
                position_name: element.position_condition,
                valid_to: element.validto,
                valid_from: element.validfrom,
                is_delegate: '',
                delegate_from_emp_id: ''
            };

            body.push(data);
        });

        const [, response] = await UpdateAssignmentRouting(
            company.current,
            body
        );

        if (response === 'success') {
            Swal.fire({
                icon: 'success',
                title: 'แก้ไข เสร็จสิ้น'
            });
        }
    };

    return (
        <Container>
            <Row>
                <Col className="import-export-actions">
                    <div
                        className="trapezoid-head"
                        style={{ width: '250px' }}
                    >
                        <span>Assignment Routing Config</span>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="mat-box">
                        <div
                            className="trapezoid trapezoid-purple"
                            style={{
                                width: '200px',
                                display: 'inline-block'
                            }}
                        >
                            <span>
                                <i
                                    className="fa fa-search"
                                    aria-hidden="true"
                                ></i>
                                Search menu
                            </span>
                        </div>
                        <div className="mat-box mb-5 rounded bg-white p-3 shadow">
                            <Row>
                                <Col md={3}>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Company Name :</Form.Label>
                                        <Input
                                            type="select"
                                            onChange={e => {
                                                e.persist();
                                                handleChangeSearchHelp('sids', [
                                                    e.target.value
                                                ]);
                                                company.current =
                                                    e.target.value;
                                            }}
                                        >
                                            <option selected>
                                                Please choose
                                            </option>
                                            {companyMaster &&
                                                companyMaster.map(
                                                    (value, index) => (
                                                        <option
                                                            key={index}
                                                            value={value.sid}
                                                        >
                                                            {value.company_name}
                                                        </option>
                                                    )
                                                )}
                                        </Input>
                                    </Form.Group>
                                </Col>
                                <Col
                                    md={2}
                                    className="flex items-end justify-center"
                                >
                                    <div className="text-center">
                                        <Button
                                            variant="outline-info"
                                            size="sm"
                                            onClick={fetchAssignmentRouting}
                                        >
                                            <FaSearch /> Search
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2}>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Auth Group :</Form.Label>
                                        <Input
                                            bsSize="sm"
                                            onChange={e => {
                                                e.persist();
                                                handleChangeSearchHelp(
                                                    'authgroup',
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Position Code :</Form.Label>
                                        <Input
                                            bsSize="sm"
                                            onChange={e => {
                                                e.persist();
                                                handleChangeSearchHelp(
                                                    'positioncode',
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={1}>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Emp ID :</Form.Label>
                                        <Input
                                            bsSize="sm"
                                            onChange={e => {
                                                e.persist();
                                                handleChangeSearchHelp(
                                                    'empid',
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                        {filteredReports && (
                            <React.Fragment>
                                <Row className="mt-3">
                                    <Col>
                                        <ButtonGroup size="sm">
                                            {filterButtons.map(filterButton => (
                                                <Button
                                                    variant={
                                                        filterButton.value !==
                                                        filter
                                                            ? 'outline-primary'
                                                            : undefined
                                                    }
                                                    onClick={
                                                        filterButton.onClick
                                                    }
                                                >
                                                    {filterButton.label}
                                                </Button>
                                            ))}
                                        </ButtonGroup>
                                    </Col>
                                    <Col>
                                        <ButtonGroup size="sm">
                                            <Button
                                                variant={'outline-success'}
                                                onClick={handleSubmitChangeData}
                                            >
                                                Save
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <AssignmentRoutingTable
                                            filteredReports={filteredReports}
                                            handleChangeConfigAssignmentRouting={
                                                handleChangeConfigAssignmentRouting
                                            }
                                            assignmentRoutingMapping={
                                                assignmentRoutingMapping
                                            }
                                        />
                                    </Col>
                                </Row>
                            </React.Fragment>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default AssignmentRoutingConfig;
