import {
    createRoleMasterEmployee,
    deleteRoleMasterEmployee,
    getRoleMasterEmployee,
    updateRoleMasterEmployee
} from 'data/MasterEmployee/repositories/MasterEmployee.repository';
import {
    RoleMasterEmployeeResponse,
    UpdateRoleMasterEmployeeRequest
} from 'data/MasterEmployee/schemas/MasterEmployee.shema';
import { parseJwt } from 'helpers/decodeLocalStorage';
import useLoader from 'hooks/useLoader';
import { useEffect, useState } from 'react';
import { icons } from 'react-icons/lib';
import Swal from 'sweetalert2';

function RoleMasterEmployeeViewModel() {
    const loading = useLoader();
    const [action, setAction] = useState('main');
    const [user, setUser] = useState<string>('');
    const [checkSCTL, setCheckSCTL] = useState<boolean>(false);
    const [roleMasterEmployee, setRoleMasterEmployee] =
        useState<RoleMasterEmployeeResponse[]>();
    const [roleMasterEmployeeMapping, setRoleMasterEmployeeMapping] =
        useState<RoleMasterEmployeeResponse[]>();
    const [selectedRoleMasterEmployee, setSelectedRoleMasterEmployee] =
        useState<RoleMasterEmployeeResponse>();

    useEffect(() => {
        var ls: any = localStorage.getItem('WF_APPLICATION');
        var jls: any = JSON.parse(ls);
        var access_token = parseJwt(jls.access_token);
        var extra = JSON.parse(access_token.extra);

        setUser(extra.citizen_id);

        fetchRoleMasterEmployee();
    }, []);

    const handleChangeAction = (actionChange: string) => {
        setAction(actionChange);
    };

    const handleSelectdRoleMasterEmployee = (
        roleMasterEmployee: RoleMasterEmployeeResponse,
        mode: string
    ) => {
        setSelectedRoleMasterEmployee(roleMasterEmployee);
        if (mode === 'edit') {
            handleChangeAction('edit');
        }
    };

    const handleSaveChange = async (
        request: UpdateRoleMasterEmployeeRequest,
        mode: string
    ) => {
        try {
            loading.show();
            request.ActionBy = user;
            if (mode === 'edit') {
                const [, response] = await updateRoleMasterEmployee(request);
                if (response === 'success') {
                    setAction('main');
                }
            }
            if (mode === 'add') {
                const [, response] = await createRoleMasterEmployee(request);
                if (response === 'success') {
                    setAction('main');
                }
            }
            Swal.fire({
                title: 'Add / Edit',
                text: `${action === 'add' ? 'เพิ่ม' : 'แก้ไข'} Position Code: ${
                    request.PositionCode
                } เสร็จสิ้น`,
                icon: 'success'
            });
        } finally {
            loading.hide();
            fetchRoleMasterEmployee();
        }
    };

    const handleDeletePositionCode = async (
        positionCode: string,
        positionName: string
    ) => {
        try {
            Swal.fire({
                title: 'Delete',
                text: `คุณต้องการลบ Position Code: ${positionCode} ใช่หรือไม่`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then(async result => {
                if (result.isConfirmed) {
                    try {
                        loading.show();
                        const [, response] = await deleteRoleMasterEmployee({
                            positionCode,
                            positionName
                        });
                        if (response === 'success') {
                            fetchRoleMasterEmployee();
                            Swal.fire({
                                title: 'Delete',
                                text: `Delete Position Code: ${positionCode} เสร็จสิ้น`,
                                icon: 'success'
                            });
                        }
                    } finally {
                        loading.hide();
                    }
                }
            });
        } finally {
        }
    };

    const handleSearchLikeOperator = (searchText: string) => {
        let search = searchText;
        if (checkSCTL === true) {
            search = 'sc_ct_' + searchText;
        }
        const filterdValues = roleMasterEmployee?.filter(val => {
            return (
                val.positioncode
                    .toLowerCase()
                    .includes(search.toLocaleLowerCase()) ||
                val.positionname
                    .toLowerCase()
                    .includes(search.toLocaleLowerCase()) ||
                val.created_by
                    .toLowerCase()
                    .includes(search.toLocaleLowerCase())
            );
        });

        setRoleMasterEmployeeMapping(filterdValues);
    };

    const fetchRoleMasterEmployee = async () => {
        try {
            loading.show();
            const [, response] = await getRoleMasterEmployee();

            if (response) {
                setRoleMasterEmployee(response);
                setRoleMasterEmployeeMapping(response);
            }
        } finally {
            loading.hide();
        }
    };

    return {
        action,
        roleMasterEmployee,
        roleMasterEmployeeMapping,
        selectedRoleMasterEmployee,

        setCheckSCTL,
        handleSaveChange,
        handleChangeAction,
        handleDeletePositionCode,
        handleSearchLikeOperator,
        handleSelectdRoleMasterEmployee
    };
}

export default RoleMasterEmployeeViewModel;
