import Pagination from '@material-ui/lab/Pagination';
import * as React from 'react';
import { Button, Col, Form, Row, Spinner, Tab, Table, Tabs, Card } from "react-bootstrap";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as ModelsWorkflowInbox from '../../models/workflow/WorkflowInbox';
import { changeSidToName, changeWFTypeToApproveType, formatDMY, numberWithCommas, parseJwt } from '../../modules/Common';
import Swal from 'sweetalert2'
import { FaCheck, FaCheckCircle, FaSync, FaTimesCircle } from "react-icons/fa";
//import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
//import BootstrapTable from 'react-bootstrap-table-next';
import { ApplicationState } from "../../store";
import * as WorkflowInboxStore from '../../store/workflow/WorkflowInbox';
import RemarkWorkflowMaster from '../remark/RemarkWorkflowMaster';
import WorkflowInboxInformationMobile from './WorkflowInboxInformationMobile';
import TransactionInformationMobile from './TransactionInformationMobile';
import ReportInformationMobile from './ReportInformationMobile';
import * as BatchDetailStore from '../../store/workflow/BatchDetail'
import * as BatchDetailModels from '../../models/workflow/BatchDetail'
import BorderGrid from '../../common/BorderGrid';
import ReportInformationMobileTable from "../mobiletable/ReportInformationMobileTable";
import { ViewportContext } from '../../context/ViewportProvider';
import WorkflowInformationGrid from 'common/components/WorkflowInformationGrid';

type align_ = "left" | "center" | "right";

interface State {
    products: any;
    valueBatchAll: any;
    uniqueBatchId: any;
    checkwfTypeAll: any;
    wfTypeAll: any;
    docNoAll: any;
    checkboxChecked: boolean;
    workflowValue: ModelsWorkflowInbox.WorkflowValue;
    userId: any;
    wfInstanceno: any;
    docNo: any;
    wfId: any;
    token: any;
    src: any;
    showModal: boolean;
    checkRequest: boolean;
    handleClose: boolean;
    checkResponseTable: boolean;
    dataTable: Array<ModelsWorkflowInbox.WorkflowInbox>;
    pageTable: any;
    pageSize: any;
    pageBtn: any;
    pageTab: any;
    JSONReportList: ModelsWorkflowInbox.JSONReportList;
    callBackReport: boolean;
    isShowBatchDetailModal: boolean;
    batchIdModal: string;
    docNoList: string[];
    wfTypeBatch: string;
    app_idBatch: string;
    checkboxRefs: any[];
    relatedDocuments: any[]
}

interface newModel {
    batchid: string,
    count_money_batchid: string,
    app_id: string,
    wf_type: string,
    count_list_batchid: number,
    currency: string,
    staffid: string,
    // result_list
    action_db: string,
    listApprovalDetail?: any,
    curtaskid: string,
    maxtaskid: string,
    subject: string,
    docType: string,
    docNo: string,
    docYear: string,
    docDate: string,
    docAmount: string,
    docCurrency?: any,
    requestorId?: any,
    requestorName?: any,
    requestorMessage?: any,
    wfId: string,
    wfType: string,
    wfInstanceno: string,
    wfstatus: string,
    numAttachment: string,
    createdOnFormatDateTime: string,
    updatedOnFormatDateTime: string,
    updatedOnFormat: string,
    createdOnFormat: string,
    created_by: string,
    created_on: string,
    created_byname: string,
    updated_byname: string,
    updated_by: string,
    updated_on: string,
    sid: string,
    listDMS?: any,
    bpName?: any,
    staffname: string,
}

class WorkflowInboxTableMobile extends React.Component<any, State> {
    public static contextType = ViewportContext;
    public documentContainerRef: React.RefObject<HTMLDivElement>;

    constructor(props: any) {
        super(props);
        this.blindopenModal = this.blindopenModal.bind(this)

        this.state = {
            showModal: false,
            handleClose: false,
            checkResponseTable: true,
            dataTable: [],
            checkRequest: true,
            wfId: "",
            docNo: "",
            wfInstanceno: "",
            userId: "",
            src: "",
            token: "",
            workflowValue: {
                wfId: "",
                docNo: "",
                wfInstanceNo: "",
                userId: "",
                token: "",
                appId: "",
                wfType: "",
                fiscalYear: "",
                docType: ""
            },
            checkboxChecked: false,
            products: [],
            docNoAll: [],
            checkwfTypeAll: [],
            wfTypeAll: [],
            uniqueBatchId: [],
            valueBatchAll: [],
            pageTable: 1,
            pageSize: 100,
            pageBtn: [],
            pageTab: '',
            JSONReportList: {
                DataHeader: {
                    column: []
                },
                ListDataItem: []
            },
            callBackReport: false,
            isShowBatchDetailModal: false,
            batchIdModal: "",
            docNoList: [],
            wfTypeBatch: "",
            app_idBatch: "",
            checkboxRefs: [],
            relatedDocuments: []
        }

        this.documentContainerRef = React.createRef();

        this.BeforehandleChangePage = this.BeforehandleChangePage.bind(this)
        this.haddleChangePage = this.haddleChangePage.bind(this)
        this.handlePageSize = this.handlePageSize.bind(this)
        this.refreshPageChild = this.refreshPageChild.bind(this)
        this.jsonReportCallback = this.jsonReportCallback.bind(this)
        this.handleCheckAllBatches = this.handleCheckAllBatches.bind(this);
    }

    componentDidMount() {
        var ls: any = localStorage.getItem("WF_APPLICATION");
        var jls: any = JSON.parse(ls)
        var access_token = parseJwt(jls.access_token)
        var extra = JSON.parse(access_token.extra)
        console.log(extra)

        if (extra.citizen_id) {
            console.log('Azure Login ID: ' + extra.citizen_id)
            this.setState({ userId: extra.citizen_id, token: jls.access_token })
        } else if (extra.username) {
            console.log('Local Login ID: ' + extra.sid)
            this.setState({ userId: extra.username, token: jls.access_token })
        }

        if (this.state.checkRequest) {
            //this.props.requestWorkflowInbox(this.state.checkRequest, "POST", jls.user_id, "")
            console.log('SID :')
            console.log(this.props.sids)
            console.log('--------------------------')
            //this.props.requestWorkflowInbox(this.state.checkRequest, "POST", jls.user_id, this.props.sids, 1, '', '')
            this.setState({
                checkRequest: false
            })
        }
    }

    componentDidUpdate(nextProps: any, nextState: any) {

        const { responseWorkflowInbox, statusWorkflowInbox, checkkey, pageTab, countTab, isLoadBatchDetail, statusBatchDetail, responseBatchDetail, messageBatchDetail } = this.props
        if (checkkey != "" && checkkey != undefined) {
            this.props.callbackkey("stop")
            this.getApiFresh()
        }

        if (statusWorkflowInbox == 200 && this.state.checkResponseTable) {
            //console.log(responseWorkflowInbox['result_list'])
            this.setState({
                dataTable: responseWorkflowInbox['result_list'],
                checkResponseTable: false
            })
            this.props.checkResponseTable(false)
        }

        if (nextProps.countTab != countTab || nextProps.pageTab != pageTab) {
            //console.log(pageTab + ' | ' + countTab)
            this.setState({
                pageTab: pageTab
            })
            if (pageTab != null) {
                this.haddleChangePage(pageTab, 1, null)
            } else {
                this.haddleChangePage(null, 1, null)
            }
        }

        if (isLoadBatchDetail && statusBatchDetail == 200 && !this.state.isShowBatchDetailModal) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            this.setState({
                isShowBatchDetailModal: true
            })
        } else if (isLoadBatchDetail && statusBatchDetail == 204 && !this.state.isShowBatchDetailModal) {
            this.props.requestGetBatchDetail(true, 'CLEAR', "", "", "", "", "")
            var msg = JSON.parse(messageBatchDetail)
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'Error',
                text: msg.message,
                icon: 'error',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ตกลง',
            }).then((result) => {
                if (result.value) {
                }
            })
        }

        if (this.props.ApprovedRejectBatch && this.state.docNoList.length != 0) {
            this.props.requestGetBatchDetail(true, 'CLEAR', '', '', '', this.state.userId, 'inbox')
            this.setState({
                isShowBatchDetailModal: false,
                batchIdModal: "",
                docNoList: [],
                wfTypeBatch: "",
                app_idBatch: ""
            })
            this.props.ApprovedRejectBatchCallback()
        }

        //if (responseBatchDetail && responseBatchDetail.listStandardReport && this.state.checkboxRefs.length === 0) {
        //    this.setState(prevState => {
        //        const nextCheckboxRefs = Array(responseBatchDetail.listStandardReport.length).fill(React.createRef<HTMLInputElement>())

        //        return {
        //            ...prevState,
        //            checkboxRefs: nextCheckboxRefs
        //        };
        //    });
        //}

        //console.log('this.props.checkReload')
        //console.log(this.props.checkReload)
        //if (this.props.checkReload) {
        //    setTimeout(() => {
        //        //this.props.callbackpage(false)
        //        this.haddleChangePage(this.props.pageTab, this.props.checkReloadPage)
        //    }, 1000);

        //}
    }

    componentWillUnmount() {
        this.props.requestWorkflowInbox(this.state.checkRequest, "CLEAR", this.state.userId, this.props.sids, '', this.state.pageSize, this.props.pageTab)
    }

    getApiFresh() {
        this.props.requestWorkflowInbox("", "CLEAR", this.state.userId, "")
    }

    handleClose = (wfId: string, docNo: string, wfInstanceno: string, userId: string, app_id: string, wfType: string, fiscalyear: string, doctype: string) => {

        if (this.state.showModal == false) {
            this.setState({
                showModal: true,
                wfId: wfId,
                docNo: docNo,
                wfInstanceno: wfInstanceno,
                userId: userId,
                workflowValue: {
                    wfId: wfId,
                    docNo: docNo,
                    wfInstanceNo: wfInstanceno,
                    userId: userId != this.state.userId ? userId : this.state.userId,
                    token: "",
                    appId: app_id,
                    wfType: wfType,
                    fiscalYear: fiscalyear,
                    docType: doctype
                }
            })
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        } else {
            this.setState({
                showModal: false,
                wfId: "",
                docNo: "",
                wfInstanceno: "",
                callBackReport: false
            })
        }
        setTimeout(() => {
            console.log("this.state.workflowValue")
            console.log(this.state.workflowValue)
        }, 1000);
    }

    blindopenModal = (value: any) => {
        //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
        if (value == "reload") {
            //window.location.reload();
            this.props.requestWorkflowInbox(true, "POST", this.state.userId, this.props.sids, 1, this.state.pageSize, '', this.props.searchReq)
        }
        this.setState({
            showModal: false,
            wfId: "",
            docNo: "",
            wfInstanceno: ""
        })
    }

    handleChange(evt, docNo, wfId, userId) {
        //if (evt.target.checked == true) {
        //    let data = {}
        //    data['wf_type'] = wfId
        //    this.state.checkwfTypeAll.push(data)
        //    this.state.docNoAll.push(docNo)
        //    this.state.wfTypeAll.push(wfId)
        //}
        //else {
        //    this.state.docNoAll.splice(this.state.docNoAll, 1);
        //    this.state.wfTypeAll.splice(this.state.wfTypeAll, 1);
        //    this.state.checkwfTypeAll.splice(this.state.checkwfTypeAll, 1);
        //}
        //this.props.dataChecked(this.state.docNoAll, wfId, userId, this.state.wfTypeAll, this.state.checkwfTypeAll)
        ////console.log(this.state.docNoAll)
    }

    handleChangeAll(evt) {
        var group = "group"
        var node = "node"
        if (evt.target.checked == true) {
            Array.from(document.querySelectorAll("input[name=" + group + "], input[name=" + node + "]"))
                .forEach((checkbox) => {
                    //document.getElementById(checkbox.id).checked = value;
                    var cb = document.getElementById(checkbox.id) as HTMLInputElement;
                    if (cb) {
                        cb.checked = true;
                    }
                });
        } else {
            Array.from(document.querySelectorAll("input[name=" + group + "], input[name=" + node + "]"))
                .forEach((checkbox) => {
                    //document.getElementById(checkbox.id).checked = value;
                    var cb = document.getElementById(checkbox.id) as HTMLInputElement;
                    if (cb) {
                        cb.checked = false;
                    }
                });
        }
    }

    handleChangeGroup(evt, docNo, wfId, userId) {
        var name = "node"
        if (evt.target.checked == true) {
            Array.from(document.querySelectorAll("input[name=" + name + "][id^='" + evt.target.id + "-']"))
                .forEach((checkbox) => {
                    //document.getElementById(checkbox.id).checked = value;
                    var cb = document.getElementById(checkbox.id) as HTMLInputElement;
                    if (cb) {
                        cb.checked = true;
                    }
                });
        } else {
            Array.from(document.querySelectorAll("input[name=" + name + "][id^='" + evt.target.id + "-']"))
                .forEach((checkbox) => {
                    //document.getElementById(checkbox.id).checked = value;
                    var cb = document.getElementById(checkbox.id) as HTMLInputElement;
                    if (cb) {
                        cb.checked = false;
                    }
                });
        }
    }

    formatData(value) {
        var valueBatch: any = []
        var valueBatchreverse: any = []
        var valuecheckdate: any = []
        var valuecheckdateUse: any = []
        var uniqueBatchIdUse: any = [];
        if (value['result_list'] != null) {
            value['result_list'].map(batch => {
                if (uniqueBatchIdUse.indexOf(batch.batchid) === -1) {
                    uniqueBatchIdUse.push(batch.batchid)
                }
            });

            //valuecheckdate = uniqueBatchIdUse.sort((a, b) => a - b);
            valuecheckdate = uniqueBatchIdUse.sort();
            valuecheckdateUse = valuecheckdate.reverse()

            this.state.valueBatchAll.splice(this.state.valueBatchAll, uniqueBatchIdUse.length);

            for (let f = 0; f < valuecheckdateUse.length; f++) {
                if (valuecheckdateUse[f] != "" && String(parseInt(valuecheckdateUse[f])) != "NaN") {
                    valueBatchreverse.push(valuecheckdateUse[f])
                }
            }
            for (let g = 0; g < valuecheckdateUse.length; g++) {
                if (String(parseInt(valuecheckdateUse[g])) == "NaN" && valuecheckdateUse[g] != "") {
                    //console.log(valuecheckdateUse[g])
                    valueBatchreverse.push(valuecheckdateUse[g])
                }
            }

            for (let f = 0; f < valuecheckdateUse.length; f++) {
                if (valuecheckdateUse[f] == "") {
                    valueBatchreverse.push(valuecheckdateUse[f])
                }
            }

            for (let i = 0; i < uniqueBatchIdUse.length; i++) {
                var dataresult: any = []
                var money: number = 0.00
                for (let k = 0; k < value['result_list'].length; k++) {
                    if (valueBatchreverse[i] == value['result_list'][k].batchid) {
                        dataresult.push(value['result_list'][k])
                        money += parseFloat(value['result_list'][k].docAmount)
                    }
                }

                let data = {}

                if (valueBatchreverse[i] == "" || valueBatchreverse[i] == undefined || valueBatchreverse[i] == null) {
                    data['batchid'] = "NONE"
                    data['count_money_batchid'] = ""
                    data['app_id'] = dataresult[0].app_id
                    data['wf_type'] = dataresult[0].wfType
                } else {
                    data['batchid'] = valueBatchreverse[i]
                    data['count_money_batchid'] = money.toFixed(2)
                    data['app_id'] = dataresult[0].app_id
                    data['wf_type'] = dataresult[0].wfType
                }
                data['count_list_batchid'] = dataresult.length
                data['currency'] = dataresult[0].docCurrency == null ? "THB" : dataresult[0].docCurrency
                data['result_list'] = dataresult
                data['staffid'] = dataresult[0].staffid

                this.state.valueBatchAll.push(data)
            }
        }
    }

    haddleChangePage = (key: any, pageNo: any, InpouPageSize: any) => {
        console.log("haddleChangePage!")
        if (InpouPageSize != undefined) {
            this.setState({
                pageSize: InpouPageSize
            })
        }
        console.log('SID :')
        console.log(this.props.sids)
        console.log(this.state.userId)
        console.log('--------------------------')
        if (key == null) {
            this.props.requestWorkflowInbox(this.state.checkRequest, "CLEAR", this.state.userId, this.props.sids, pageNo, this.state.pageSize, this.props.pageTab)
            var countingPage: any = 0
            var dataArr: any = []
            countingPage = Math.ceil(this.props.countTab / this.state.pageSize)
            console.log(countingPage)

            for (let i = 0; i < countingPage; i++) {
                var data: any = {}
                data['page'] = i + 1
                data['pageTab'] = this.props.pageTab
                dataArr.push(data)
            }

            console.log(dataArr)
            this.setState({
                pageBtn: dataArr,
                checkRequest: true,
                valueBatchAll: [],
                checkResponseTable: true
            })
            this.props.checkResponseTable(true)
            this.props.requestWorkflowInbox(true, "POST", this.state.userId, this.props.sids, pageNo, this.state.pageSize, '', this.props.searchReq)
        } else {
            this.props.requestWorkflowInbox(this.state.checkRequest, "CLEAR", this.state.userId, this.props.sids, pageNo, this.state.pageSize, this.props.pageTab)

            var countingPage: any = 0
            var dataArr: any = []
            countingPage = Math.ceil(this.props.countTab / this.state.pageSize)
            console.log(countingPage)

            for (let i = 0; i < countingPage; i++) {
                var data: any = {}
                data['page'] = i + 1
                data['pageTab'] = this.props.pageTab
                dataArr.push(data)
            }

            console.log(dataArr)
            this.setState({
                pageBtn: dataArr,
                checkRequest: true,
                valueBatchAll: [],
                checkResponseTable: true
            })
            this.props.checkResponseTable(true)
            this.props.requestWorkflowInbox(true, "POST", this.state.userId, this.props.sids, pageNo, this.state.pageSize, this.props.pageTab, this.props.searchReq)
        }
    }

    BeforehandleChangePage(event, value) {
        //this.props.callbackpage(value)
        //console.log(value);
        return this.haddleChangePage(this.props.pageTab, value, null)
    }

    handlePageSize(e) {
        //console.log(typeof e.target.value)
        var parse = parseInt(e.target.value)
        this.setState({ pageSize: parse });

        return this.haddleChangePage(this.props.pageTab, 1, parse)
    }

    public refreshPageChild() {
        this.props.refreshPage && this.props.refreshPage()
    }

    jsonReportCallback = (value: string) => {
        //console.log(value)
        this.setState({
            callBackReport: true,
        })
        try {
            if (value != null) {
                this.setState({
                    JSONReportList: JSON.parse(value)
                })
            }
        } catch (e) {
            console.log(e)
        }
    }

    handleOpenBatchDetail = (wf_type: string, batch_id: string, app_id: string, staffid: string, relatedDocuments: any[]) => {
        //console.log(staffid)
        this.props.requestGetBatchDetail(true, 'GET', wf_type, batch_id, app_id, staffid, 'inbox')
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        this.setState({
            batchIdModal: batch_id,
            wfTypeBatch: wf_type,
            app_idBatch: app_id,
            relatedDocuments
        })
    }

    handleCloseBatchDetail = () => {
        if (this.state.isShowBatchDetailModal) {
            this.props.requestGetBatchDetail(true, 'CLEAR', "", "", "", "", "")
            this.setState({
                isShowBatchDetailModal: false,
                docNoList: [],
                relatedDocuments: []
            })
        } else {
            this.setState({
                isShowBatchDetailModal: true
            })
        }
    }

    handleApproveBatch = () => {
        if (this.state.docNoList.length != 0) {
            this.props.prepareApprovedRejectBatch(this.state.docNoList, this.state.wfTypeBatch, this.state.app_idBatch, "approved")
        } else {
            Swal.fire({
                title: '',
                text: 'โปรดเลือกเอกสารที่ต้องการอนุมัติ',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'ตกลง'
            })
        }
    }

    handleRejectBatch = () => {
        if (this.state.docNoList.length != 0) {
            this.props.prepareApprovedRejectBatch(this.state.docNoList, this.state.wfTypeBatch, this.state.app_idBatch, "reject")
        } else {
            Swal.fire({
                title: '',
                text: 'โปรดเลือกเอกสารที่ไม่ต้องการอนุมัติ',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'ตกลง'
            })
        }
    }

    handleCheckedBatch = (e: any, docNum: string) => {
        //console.log(docNum)
        //console.log(e.target.checked)
        var data: string[] = this.state.docNoList
        if (e.target.checked == true) {
            data.push(docNum)
            this.setState({
                docNoList: data
            })
        } else {
            const index = data.indexOf(docNum);
            if (index > -1) {
                data.splice(index, 1);
            }
            this.setState({
                docNoList: data
            })
        }
        //console.log(this.state.docNoList)
    }

    public handleCheckAllBatches(event: React.MouseEvent<HTMLInputElement>) {
        const isChecked = (event.target as HTMLInputElement).checked;
        const container = this.documentContainerRef.current;

        if (container !== null) {
            const checkboxs = Array.from(container.querySelectorAll<HTMLInputElement>('input[type=checkbox]'));
            const documentNoList = checkboxs.map(checkbox => {
                checkbox.checked = isChecked;
                return checkbox.value;
            });

            this.setState({ docNoList: (isChecked) ? documentNoList : [] });
        }
    }

    private prepareDateForMobileMode(): newModel[] {
        const valueBatchAllArr = [...this.state.valueBatchAll]
        console.log("valueBatchAllArr =", valueBatchAllArr)

        const newModelArr: newModel[] = []

        valueBatchAllArr.map((data, index) => {
            data.result_list.map((result, subIndex) => {
                newModelArr.push({
                    batchid: data.batchid,
                    count_money_batchid: data.count_money_batchid,
                    app_id: data.app_id,
                    wf_type: data.wf_type,
                    count_list_batchid: data.count_list_batchid,
                    currency: data.currency,
                    staffid: data.staffid,
                    // result_list
                    action_db: result.action_db,
                    listApprovalDetail: result.listApprovalDetail,
                    curtaskid: result.curtaskid,
                    maxtaskid: result.maxtaskid,
                    subject: result.subject,
                    docType: result.docType,
                    docNo: result.docNo,
                    docYear: result.docYear,
                    docDate: result.docDate,
                    docAmount: result.docAmount,
                    docCurrency: result.docCurrency,
                    requestorId: result.requestorId,
                    requestorName: result.requestorName,
                    requestorMessage: result.requestorMessage,
                    wfId: result.wfId,
                    wfType: result.wfType,
                    wfInstanceno: result.wfInstanceno,
                    wfstatus: result.wfstatus,
                    numAttachment: result.numAttachment,
                    createdOnFormatDateTime: result.createdOnFormatDateTime,
                    updatedOnFormatDateTime: result.updatedOnFormatDateTime,
                    updatedOnFormat: result.updatedOnFormat,
                    createdOnFormat: result.createdOnFormat,
                    created_by: result.created_by,
                    created_on: result.created_on,
                    created_byname: result.created_byname,
                    updated_byname: result.updated_byname,
                    updated_by: result.updated_by,
                    updated_on: result.updated_on,
                    sid: result.sid,
                    listDMS: result.listDMS,
                    bpName: result.bpName,
                    staffname: result.staffname
                })
            })
        })

        //console.log("newModelArr =", newModelArr)

        return newModelArr
    }

    render() {
        const { responseWorkflowInbox, statusWorkflowInbox, checkkey, isLoadWorkflowInbox, isLoadBatchDetail, statusBatchDetail, responseBatchDetail, messageBatchDetail } = this.props
        //console.log(this.props.isLoadWorkflowInbox)
        if (this.props.responseWorkflowInbox != undefined && this.props.isLoadWorkflowInbox) {
            this.formatData(responseWorkflowInbox)
        }
        //console.log('#this.state.valueBatchAll')
        //console.log(this.state.valueBatchAll)

        const breakpoint = 768; // change this if enable table responsesive
        //console.log("width now =", this.state.width)

        const { width, heigth } = this.context;

        //const dataMobile = this.prepareDateForMobileMode();
        //console.log("dataMobile =", dataMobile)

        return (
            <div>
                <Row style={{ marginTop: '15px', marginBottom: '10px' }}>
                    <Col sm={4}>
                        <div className='d-flex justify-content-between my-2 my-lg-0'>
                            <Form inline>
                                <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
                                    Show
                                </Form.Label>
                                <Form.Control
                                    as="select"
                                    className="my-1 mr-sm-2"
                                    id="inlineFormCustomSelectPref"
                                    custom
                                    size="sm"
                                    value={this.state.pageSize}
                                    onChange={this.handlePageSize}
                                >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </Form.Control>
                                <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
                                    entries
                                </Form.Label>
                            </Form>
                        </div>
                    </Col>
                    <Col sm={8}>
                        <div className='d-flex justify-content-end my-2 my-lg-0'>
                            <Pagination
                                count={this.state.pageBtn.length > 0 ? this.state.pageBtn.length : undefined}
                                shape="rounded"
                                onChange={this.BeforehandleChangePage}
                                defaultPage={1}
                                showFirstButton
                                showLastButton />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {width > breakpoint
                            ? <div style={{ overflowX: 'scroll' }}>
                                <Table className="table-bordered align-middle table-responsive-sm table-hover table table-sm" size="sm">
                                    <thead >
                                        <tr className='text-center table-active thead-light' style={{ width: '100%' }}>
                                            <th className='th-center' style={{ width: '50px', textAlignLast: 'left' }} >
                                                <input type="checkbox" name="all"
                                                    defaultChecked={this.state.checkboxChecked}
                                                    onChange={this.handleChangeAll}
                                                />
                                            </th>
                                            <th style={{ width: '270px' }}>รายละเอียดการอนุมัติ</th>
                                            <th style={{ width: '200px', maxWidth: '150px', overflowWrap: 'break-word' }} >เลขที่เอกสาร</th>
                                            <th style={{ width: '100px', textAlignLast: 'center' }}>ปีเอกสาร</th>
                                            <th style={{ width: '150px', textAlignLast: 'center', textAlign: 'center' }}>Approve<br />Type</th>
                                            <th style={{ width: '200px', textAlignLast: 'center' }}>บริษัท</th>
                                            <th style={{ width: '200px', textAlignLast: 'center' }}>สำหรับ</th>
                                            <th style={{ width: '100px', textAlignLast: 'right' }}>มูลค่า</th>
                                            <th style={{ width: '100px', textAlignLast: 'center' }}>สกุลเงิน</th>
                                            <th style={{ width: '100px', textAlignLast: 'center' }}>ผู้สร้าง</th>
                                            <th style={{ width: '100px', textAlignLast: 'center' }}>ลำดับการอนุมัติปัจจุบัน</th>
                                            <th style={{ width: '170px' }}>วันที่สร้าง</th>
                                            <th style={{ width: '150px', textAlignLast: 'center' }}>ผู้ปรับปรุงล่าสุด</th>
                                            <th style={{ width: '150px', }}>วันที่ปรับปรุงล่าสุด</th>

                                        </tr>
                                    </thead>
                                    {!this.props.isLoadWorkflowInbox ? <tbody><tr><td colSpan={14} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr></tbody> : <tbody><tr></tr></tbody>}
                                    {this.props.isLoadWorkflowInbox && this.props.statusWorkflowInbox != 200 ? <tbody><tr><td colSpan={14} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr></tbody> : <tbody><tr></tr></tbody>}
                                    {this.props.isLoadWorkflowInbox && this.props.statusWorkflowInbox == 200 && this.props.responseWorkflowInbox.total_count == 0 ? <tbody><tr><td colSpan={14} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr></tbody> : <tbody><tr></tr></tbody>}
                                    {this.props.statusWorkflowInbox == 200 && this.props.isLoadWorkflowInbox && this.state.valueBatchAll != undefined && this.state.valueBatchAll.map((data, index) => {
                                        //console.log(this.state.valueBatchAll)
                                        return (
                                            <tbody key={index}>
                                                <tr key={index} onClick={() => this.handleOpenBatchDetail(data.wf_type, data.batchid, data.app_id, data.staffid, data.result_list)} style={{ cursor: 'pointer' }}>
                                                    <td
                                                        className="text-center"
                                                        onClick={(event) => {
                                                            if (!(event.nativeEvent.target as HTMLInputElement)?.checked) {
                                                                event.stopPropagation();
                                                            }
                                                        }}
                                                    >
                                                        <input id={index} type="checkbox" name="group"
                                                            defaultChecked={this.state.checkboxChecked}
                                                            onChange={(event) => this.handleChangeGroup(event, data.docNo, data.wfId, data.staffid)}
                                                        />
                                                    </td>
                                                    <td style={{ background: '#FABD02', fontWeight: "bold" }} colSpan={6}>({data.count_list_batchid}) Batch ID: {data.batchid}</td>
                                                    <td style={{ background: '#FABD02', fontWeight: "bold", color: "blue", textAlignLast: 'right' }} >{numberWithCommas(data.count_money_batchid)}</td>
                                                    <td style={{ background: '#FABD02', textAlignLast: 'center', fontWeight: "bold" }} >{data.currency}</td>
                                                    <td style={{ background: '#FABD02' }} colSpan={6} ></td>
                                                </tr>
                                                {data.result_list.map((data_r, index_r) => {
                                                    //console.log(data.result_list)
                                                    return (
                                                        <tr
                                                            key={index + "-" + index_r}
                                                            onClick={() => this.handleClose(data_r.wfId, data_r.docNo, data_r.wfInstanceno, data_r.staffid, data_r.app_id, data_r.wfType, data_r.docYear, data_r.docType)}
                                                        >
                                                            <td className="text-center" style={{ width: '5%', textAlignLast: 'right' }}>
                                                                <input
                                                                    id={index + "-" + index_r}
                                                                    type="checkbox"
                                                                    name="node"
                                                                    data-wfinstanceno={data_r.wfInstanceno}
                                                                    data-docno={data_r.docNo}
                                                                    data-wftype={data_r.wfType}
                                                                    data-appid={data_r.app_id}
                                                                    defaultChecked={this.state.checkboxChecked}
                                                                />
                                                            </td>
                                                            <td style={{ cursor: 'pointer' }}>{data_r.subject}</td>
                                                            <td style={{ width: '150px', maxWidth: '150px', overflowWrap: 'break-word', cursor: 'pointer' }}>{data_r.docNo}</td>
                                                            <td style={{ textAlignLast: 'center', cursor: 'pointer' }}>{data_r.docYear}</td>
                                                            <td style={{ textAlignLast: 'center', cursor: 'pointer' }}>{changeWFTypeToApproveType(data_r.wfType)}</td>
                                                            <td>{changeSidToName(data_r.app_id, this.props.sidArr)}</td>
                                                            <td>{data_r.bpName}</td>
                                                            <td style={{ textAlignLast: 'right', cursor: 'pointer' }}>{numberWithCommas(data_r.docAmount)}</td>
                                                            <td style={{ textAlignLast: 'center', cursor: 'pointer' }}>{data_r.docCurrency == "" || data_r.docCurrency == null ? "THB" : data_r.docCurrency}</td>
                                                            <td style={{ textAlignLast: 'center', cursor: 'pointer' }}>{data_r.requestorName}</td>
                                                            <td style={{ textAlignLast: 'center', cursor: 'pointer' }}>{data_r.curtaskid + '/' + data_r.maxtaskid}</td>
                                                            <td style={{ cursor: 'pointer' }}>{formatDMY(String(data_r.createdOnFormatDateTime))}</td>
                                                            <td style={{ textAlignLast: 'center', cursor: 'pointer' }}>{data_r.updated_byname}</td>
                                                            <td style={{ cursor: 'pointer' }}>{formatDMY(String(data_r.updatedOnFormatDateTime))}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        )
                                    })}
                                </Table>
                            </div>

                            : <div>
                                {!this.props.isLoadWorkflowInbox
                                    ? <div style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}>
                                        <Spinner animation="border" size="sm" />
                                        <label>&nbsp;&nbsp;Loading...</label>
                                    </div>
                                    : <div></div>}
                                {this.props.isLoadWorkflowInbox && this.props.statusWorkflowInbox != 200
                                    ? <div style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>
                                        <label>&nbsp;&nbsp;No Data...</label>
                                    </div>
                                    : <div></div>}
                                {this.props.isLoadWorkflowInbox && this.props.statusWorkflowInbox == 200 && this.props.responseWorkflowInbox.total_count == 0
                                    ? <div style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>
                                        <label>&nbsp;&nbsp;No Data...</label>
                                    </div>
                                    : <div></div>}

                                <BorderGrid>
                                    {this.props.statusWorkflowInbox == 200 &&
                                        this.props.isLoadWorkflowInbox &&
                                        this.state.valueBatchAll != undefined &&
                                        this.state.valueBatchAll.map((data, index) => {
                                            return (
                                                <BorderGrid.Row key={index}>
                                                    <BorderGrid.Col className="p-0">
                                                        <div
                                                            key={index}
                                                            style={{ cursor: 'pointer', background: '#FABD02', fontWeight: "bold" }}
                                                            className="flex"
                                                        >
                                                            <input
                                                                id={index}
                                                                type="checkbox"
                                                                name="group"
                                                                defaultChecked={this.state.checkboxChecked}
                                                                onChange={(event) => {
                                                                    if (event.target.checked) {
                                                                        this.handleOpenBatchDetail(data.wf_type, data.batchid, data.app_id, data.staffid, data.result_list)
                                                                    }

                                                                    this.handleChangeGroup(event, data.docNo, data.wfId, data.staffid)
                                                                }}
                                                            />
                                                            <div
                                                                onClick={() => this.handleOpenBatchDetail(data.wf_type, data.batchid, data.app_id, data.staffid, data.result_list)}
                                                            >
                                                                <span>({data.count_list_batchid}) Batch ID: {data.batchid}</span>
                                                                <span style={{ color: "blue" }}>{' ' + numberWithCommas(data.count_money_batchid) + ' ' + data.currency}</span>
                                                            </div>
                                                        </div>

                                                        {this.state.valueBatchAll != undefined && data.result_list.map((data_r, index_r) => {
                                                            return (
                                                                <React.Fragment key={index_r}>
                                                                    <div style={{ color: '#FF8F00' }}>
                                                                        <input
                                                                            id={index + "-" + index_r}
                                                                            type="checkbox"
                                                                            name="node"
                                                                            data-wfinstanceno={data_r.wfInstanceno}
                                                                            data-docno={data_r.docNo}
                                                                            data-wftype={data_r.wfType}
                                                                            data-appid={data_r.app_id}
                                                                            defaultChecked={this.state.checkboxChecked}
                                                                        />
                                                                        <span onClick={() => this.handleClose(data_r.wfId, data_r.docNo, data_r.wfInstanceno, data_r.staffid, data_r.app_id, data_r.wfType, data_r.docYear, data_r.docType)}>
                                                                            {changeSidToName(data_r.app_id, this.props.sidArr)}
                                                                        </span>
                                                                    </div>

                                                                    <BorderGrid.Row className="border-top border-bottom">
                                                                        <BorderGrid.Col style={{ display: 'grid' }}>
                                                                            <label style={{ color: '#6E6E6E' }}>รายละเอียดการอนุมัติ</label>
                                                                            <span
                                                                                style={{ cursor: 'pointer', fontWeight: "bold" }}
                                                                                onClick={() => this.handleClose(data_r.wfId, data_r.docNo, data_r.wfInstanceno, data_r.staffid, data_r.app_id, data_r.wfType, data_r.docYear, data_r.docType)}>
                                                                                {data_r.subject}
                                                                            </span>
                                                                        </BorderGrid.Col>
                                                                        <BorderGrid.Col style={{ display: 'grid' }}>
                                                                            <label style={{ color: '#6E6E6E' }}>เลขที่เอกสาร</label>
                                                                            <span
                                                                                style={{ cursor: 'pointer', fontWeight: "bold" }}
                                                                                onClick={() => this.handleClose(data_r.wfId, data_r.docNo, data_r.wfInstanceno, data_r.staffid, data_r.app_id, data_r.wfType, data_r.docYear, data_r.docType)}>
                                                                                {data_r.docNo}
                                                                            </span>
                                                                        </BorderGrid.Col>
                                                                    </BorderGrid.Row>
                                                                    <BorderGrid.Row className="border-bottom">
                                                                        <BorderGrid.Col style={{ display: 'grid' }}>
                                                                            <label style={{ color: '#6E6E6E' }}>ปีเอกสาร</label>
                                                                            <span
                                                                                style={{ cursor: 'pointer', fontWeight: "bold" }}
                                                                                onClick={() => this.handleClose(data_r.wfId, data_r.docNo, data_r.wfInstanceno, data_r.staffid, data_r.app_id, data_r.wfType, data_r.docYear, data_r.docType)}>
                                                                                {data_r.docYear}
                                                                            </span>
                                                                        </BorderGrid.Col>
                                                                        <BorderGrid.Col style={{ display: 'grid' }}>
                                                                            <label style={{ color: '#6E6E6E' }}>ประเภทการอนุมัติ</label>
                                                                            <span
                                                                                style={{ cursor: 'pointer', fontWeight: "bold" }}
                                                                                onClick={() => this.handleClose(data_r.wfId, data_r.docNo, data_r.wfInstanceno, data_r.staffid, data_r.app_id, data_r.wfType, data_r.docYear, data_r.docType)}>
                                                                                {changeWFTypeToApproveType(data_r.wfType)}
                                                                            </span>
                                                                        </BorderGrid.Col>
                                                                    </BorderGrid.Row>
                                                                    <BorderGrid.Row className="border-bottom">
                                                                        <BorderGrid.Col style={{ display: 'grid' }}>
                                                                            <label style={{ color: '#6E6E6E' }}>สำหรับ</label>
                                                                            <span
                                                                                style={{ cursor: 'pointer', fontWeight: "bold" }}
                                                                                onClick={() => this.handleClose(data_r.wfId, data_r.docNo, data_r.wfInstanceno, data_r.staffid, data_r.app_id, data_r.wfType, data_r.docYear, data_r.docType)}>
                                                                                {data_r.bpName}
                                                                            </span>
                                                                        </BorderGrid.Col>
                                                                        <BorderGrid.Col style={{ display: 'grid' }}>
                                                                            <label style={{ color: '#6E6E6E' }}>มูลค่า</label>
                                                                            <span
                                                                                style={{ cursor: 'pointer', fontWeight: "bold" }}
                                                                                onClick={() => this.handleClose(data_r.wfId, data_r.docNo, data_r.wfInstanceno, data_r.staffid, data_r.app_id, data_r.wfType, data_r.docYear, data_r.docType)}>
                                                                                {numberWithCommas(data_r.docAmount)}
                                                                            </span>
                                                                        </BorderGrid.Col>
                                                                    </BorderGrid.Row>
                                                                    <BorderGrid.Row className="border-bottom">
                                                                        <BorderGrid.Col style={{ display: 'grid' }}>
                                                                            <label style={{ color: '#6E6E6E' }}>สกุลเงิน</label>
                                                                            <span
                                                                                style={{ cursor: 'pointer', fontWeight: "bold" }}
                                                                                onClick={() => this.handleClose(data_r.wfId, data_r.docNo, data_r.wfInstanceno, data_r.staffid, data_r.app_id, data_r.wfType, data_r.docYear, data_r.docType)}>
                                                                                {data_r.docCurrency == "" || data_r.docCurrency == null ? "THB" : data_r.docCurrency}
                                                                            </span>
                                                                        </BorderGrid.Col>
                                                                        <BorderGrid.Col style={{ display: 'grid' }}>
                                                                            <label style={{ color: '#6E6E6E' }}>ผู้สร้าง</label>
                                                                            <span
                                                                                style={{ cursor: 'pointer', fontWeight: "bold" }}
                                                                                onClick={() => this.handleClose(data_r.wfId, data_r.docNo, data_r.wfInstanceno, data_r.staffid, data_r.app_id, data_r.wfType, data_r.docYear, data_r.docType)}>
                                                                                {data_r.requestorName}
                                                                            </span>
                                                                        </BorderGrid.Col>
                                                                    </BorderGrid.Row>
                                                                    <BorderGrid.Row className="border-bottom">
                                                                        <BorderGrid.Col style={{ display: 'grid' }}>
                                                                            <label style={{ color: '#6E6E6E' }}>ลำดับการอนุมัติปัจจุบัน</label>
                                                                            <span
                                                                                style={{ cursor: 'pointer', fontWeight: "bold" }}
                                                                                onClick={() => this.handleClose(data_r.wfId, data_r.docNo, data_r.wfInstanceno, data_r.staffid, data_r.app_id, data_r.wfType, data_r.docYear, data_r.docType)}>
                                                                                {data_r.curtaskid + '/' + data_r.maxtaskid}
                                                                            </span>
                                                                        </BorderGrid.Col>
                                                                        <BorderGrid.Col style={{ display: 'grid' }}>
                                                                            <label style={{ color: '#6E6E6E' }}>วันที่สร้าง</label>
                                                                            <span
                                                                                style={{ cursor: 'pointer', fontWeight: "bold" }}
                                                                                onClick={() => this.handleClose(data_r.wfId, data_r.docNo, data_r.wfInstanceno, data_r.staffid, data_r.app_id, data_r.wfType, data_r.docYear, data_r.docType)}>
                                                                                {formatDMY(String(data_r.createdOnFormatDateTime))}
                                                                            </span>
                                                                        </BorderGrid.Col>
                                                                    </BorderGrid.Row>
                                                                    <BorderGrid.Row className="border-bottom">
                                                                        <BorderGrid.Col style={{ display: 'grid' }}>
                                                                            <label style={{ color: '#6E6E6E' }}>ผู้ปรับปรุงล่าสุด</label>
                                                                            <span
                                                                                style={{ cursor: 'pointer', fontWeight: "bold" }}
                                                                                onClick={() => this.handleClose(data_r.wfId, data_r.docNo, data_r.wfInstanceno, data_r.staffid, data_r.app_id, data_r.wfType, data_r.docYear, data_r.docType)}>
                                                                                {data_r.updated_byname}
                                                                            </span>
                                                                        </BorderGrid.Col>
                                                                        <BorderGrid.Col style={{ display: 'grid' }}>
                                                                            <label style={{ color: '#6E6E6E' }}>วันที่ปรับปรุงล่าสุด</label>
                                                                            <span
                                                                                style={{ cursor: 'pointer', fontWeight: "bold" }}
                                                                                onClick={() => this.handleClose(data_r.wfId, data_r.docNo, data_r.wfInstanceno, data_r.staffid, data_r.app_id, data_r.wfType, data_r.docYear, data_r.docType)}>
                                                                                {formatDMY(String(data_r.updatedOnFormatDateTime))}
                                                                            </span>
                                                                        </BorderGrid.Col>
                                                                    </BorderGrid.Row>
                                                                </React.Fragment>
                                                            )
                                                        })}
                                                    </BorderGrid.Col>
                                                </BorderGrid.Row>
                                            )
                                        })}
                                </BorderGrid>
                                {/*</Table>*/}
                            </div>
                        }
                    </Col>
                </Row>

                <Modal className="modal-dialog" isOpen={this.state.showModal} toggle={() => this.handleClose(this.state.wfId, "", "", "", "", "", "", "")} size="lg" backdrop="static" keyboard={false} style={{ maxWidth: '1920px', width: '98%', height: '90%' }}>
                    <ModalHeader toggle={() => this.handleClose(this.state.wfId, "", "", "", "", "", "", "")}>Workflow Information</ModalHeader>
                    <ModalBody>

                        <Tabs defaultActiveKey="WorkflowInboxInformation" id="tab">
                            <Tab eventKey="WorkflowInboxInformation" title="Workflow Information" >
                                {/* <WorkflowInboxInformation wfId={this.state.wfId} docNo={this.state.docNo} wfInstanceno={this.state.wfInstanceno} userId={this.state.userId} showmodal={this.state.showModal} blindopenModal={this.blindopenModal.bind(this)} />*/}
                                <WorkflowInboxInformationMobile workflowValue={this.state.workflowValue} showmodal={this.state.showModal} blindopenModal={this.blindopenModal.bind(this)} refreshPageChild={this.refreshPageChild.bind(this)} jsonReportCallback={this.jsonReportCallback.bind(this)} />
                                {/* <iframe src={this.state.src} name="myIframe" height="100%" width="100%" title="Iframe Example"  frameBorder="0" ref="iframe"></iframe>*/}
                            </Tab>
                            {this.state.callBackReport && <Tab eventKey="ReportInformation" title="Report information">
                                <ReportInformationMobile JSONReportList={this.state.JSONReportList} />
                            </Tab>}
                            {(this.state.workflowValue.wfType == "PR" || this.state.workflowValue.wfType == "PO") && <Tab eventKey="Transaction" title="Transaction Information">
                                <TransactionInformationMobile workflowValue={this.state.workflowValue} />
                            </Tab>}
                            <Tab eventKey="Remark" title="Work Chat">
                                <RemarkWorkflowMaster workflowValue={this.state.workflowValue} />
                            </Tab>
                        </Tabs>

                    </ModalBody>
                    <ModalFooter>
                        {/* <Button color="primary" onClick={() => this.handleClose(this.state.wfId, "", "", "")}>Save</Button> */}
                        <Button color="secondary" onClick={() => this.handleClose(this.state.wfId, "", "", "", "", "", "", "")}>Close</Button>
                    </ModalFooter>
                </Modal>

                {/*Batch Detail Modal*/}
                <Modal className="modal-dialog" isOpen={this.state.isShowBatchDetailModal} toggle={() => this.handleCloseBatchDetail()} size="lg" backdrop="static" keyboard={false} style={{ maxWidth: '1920px', width: '98%', height: '90%' }}>
                    <ModalHeader toggle={() => this.handleCloseBatchDetail()}>Batch Detail : {this.state.batchIdModal}</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col sm={4}>
                                <Button variant="outline-success" size="sm" onClick={() => this.handleApproveBatch()}>
                                    <FaCheck />
                                    &nbsp;Approve
                                </Button>
                                <Button variant="outline-danger" size="sm" className="mx-1" onClick={() => this.handleRejectBatch()}>
                                    <FaTimesCircle />
                                    &nbsp;Reject
                                </Button>
                            </Col>
                            <Col sm={8}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Control type="text" placeholder="Selected Document Number" value={this.state.docNoList} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div
                                    className="d-inline-flex p-2 ml-3 align-items-center border border-darken-1 rounded-sm"
                                    style={{ gap: '12px' }}
                                >
                                    <input
                                        id="select-all-document"
                                        type="checkbox"
                                        style={{
                                            width: '17px',
                                            height: '17px'
                                        }}
                                        onClick={this.handleCheckAllBatches}
                                    />
                                    <label
                                        htmlFor="select-all-document"
                                        className="m-0 font-weight-bold"
                                    >
                                        เลือกเอกสารทั้งหมด
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col ref={this.documentContainerRef}>
                                {
                                    isLoadBatchDetail
                                        && statusBatchDetail == 200
                                        && responseBatchDetail.listStandardReport != undefined
                                        && responseBatchDetail.listStandardReport.length > 0
                                        ? responseBatchDetail.listStandardReport.map((ListItem: BatchDetailModels.listStandardReport, idList) => {
                                            //console.log(ListItem.DataHeader.row.length + ":" + ListItem.DataHeader.column.length)
                                            return (
                                                <Row className="mt-3" key={idList}>
                                                    <Col>
                                                        <div className="master-data-content">
                                                            <div className='trapezoid-head' style={{ width: '500px', textAlign: 'left' }}>
                                                                <Row>
                                                                    <Col>
                                                                        <div className="mx-3 mt-1 custom-checkbox" >
                                                                            {
                                                                                ListItem.DataHeader.row.length == 0
                                                                                && ListItem.DataHeader.column.length != 0
                                                                                && (
                                                                                    <label>
                                                                                        <input
                                                                                            value={ListItem.DataHeader.column[0].value}
                                                                                            type="checkbox"
                                                                                            onClick={(e) => this.handleCheckedBatch(e, ListItem.DataHeader.column[0].value)}
                                                                                        />
                                                                                        &nbsp;{ListItem.DataHeader.column[0] != undefined
                                                                                            ? ListItem.DataHeader.column[0].label + " : " + ListItem.DataHeader.column[0].value
                                                                                            : ''}
                                                                                    </label>
                                                                                )
                                                                            }
                                                                            {
                                                                                ListItem.DataHeader.row.length != 0
                                                                                && ListItem.DataHeader.column.length == 0
                                                                                && (
                                                                                    <label>
                                                                                        <input
                                                                                            value={ListItem.DataHeader.row[0].column[0].value + '|' + ListItem.InstanceNo}
                                                                                            type="checkbox"
                                                                                            onClick={(e) => this.handleCheckedBatch(e, ListItem.DataHeader.row[0].column[0].value)}
                                                                                        />
                                                                                        &nbsp;{ListItem.DataHeader.row[0].column[0] != undefined
                                                                                            ? ListItem.DataHeader.row[0].column[0].label + " : " + ListItem.DataHeader.row[0].column[0].value
                                                                                            : ''}
                                                                                    </label>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                        <div className="mat-box">

                                                            {ListItem.DataHeader.row.length == 0

                                                                ? <Row>
                                                                    <Col>
                                                                        <Card>
                                                                            <Card.Body>
                                                                                {ListItem.DataHeader.column.length == 0 &&
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <div className="text-center">
                                                                                                <label>ไม่พบข้อมูล..</label>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                }
                                                                                {ListItem.DataHeader.column.length != 0 &&
                                                                                    <Row>
                                                                                        <Col lg={12}>
                                                                                            <BorderGrid>
                                                                                                {ListItem.DataHeader.column.map((data: ModelsWorkflowInbox.ColoumList, index) => {
                                                                                                    return (
                                                                                                        <BorderGrid.Row key={index}>
                                                                                                            <BorderGrid.Col>
                                                                                                                <div>
                                                                                                                    <label>{data.label}</label>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <b>{data.value}</b>
                                                                                                                </div>
                                                                                                            </BorderGrid.Col>
                                                                                                        </BorderGrid.Row>
                                                                                                    )
                                                                                                })}
                                                                                            </BorderGrid>
                                                                                        </Col>
                                                                                    </Row>
                                                                                }
                                                                            </Card.Body>
                                                                        </Card>
                                                                    </Col>
                                                                </Row>

                                                                : <Row>
                                                                    <Col>
                                                                        <Card>
                                                                            <Card.Body>
                                                                                {ListItem.DataHeader.row.length == 0 &&
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <div className="text-center">
                                                                                                <label>ไม่พบข้อมูล..</label>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                }
                                                                                {ListItem.DataHeader.row.length != 0 &&
                                                                                    <Row>
                                                                                        <Col lg={12}>
                                                                                            {ListItem.DataHeader.row.map((data: ModelsWorkflowInbox.DataHeader, idx) => {
                                                                                                return (
                                                                                                    <Row key={idx} className="mt-3">
                                                                                                        {data.column.map((item: ModelsWorkflowInbox.ColoumList, ids) => {
                                                                                                            return (
                                                                                                                <Col key={ids} lg="3" className="table-hover">
                                                                                                                    <Row>
                                                                                                                        <Col lg="5" className="font-weight-bold bg-light text-dark">{item.label} :</Col>
                                                                                                                        <Col lg="7">{item.value}</Col>
                                                                                                                    </Row>
                                                                                                                </Col>
                                                                                                            )
                                                                                                        })}
                                                                                                    </Row>
                                                                                                )
                                                                                            })}
                                                                                        </Col>
                                                                                    </Row>
                                                                                }
                                                                                {/*}*/}
                                                                            </Card.Body>
                                                                        </Card>
                                                                    </Col>
                                                                </Row>
                                                            }

                                                            {/*Footer*/}
                                                            {width > breakpoint ?
                                                                ListItem.ListDataItem.length !== 0 &&
                                                                ListItem.ListDataItem.map((data: BatchDetailModels.ListDataItem, idx) => {
                                                                    return (
                                                                        <Row key={idx}>
                                                                            <Col lg={12}>
                                                                                <div style={{ overflowX: "scroll" }}>
                                                                                    <table className="table table-bordered" style={{ width: "max-content" }}>
                                                                                        <thead>
                                                                                            <tr className="table-active">
                                                                                                {data.tableheader.length != 0 && data.tableheader.map((DataHeader: BatchDetailModels.tableheader, idHeader) => {
                                                                                                    return (
                                                                                                        <th key={idHeader} style={{ width: DataHeader.width != undefined ? DataHeader.width + "px" : "auto", textAlign: DataHeader.align as align_ }} align={DataHeader.align as align_}>{DataHeader.label}</th>
                                                                                                    )
                                                                                                })}
                                                                                            </tr>
                                                                                        </thead>
                                                                                        {data.tabledata.length == 0 && <tr><td colSpan={data.tableheader.length}><div className="text-center">No data..</div></td></tr>}
                                                                                        {data.tabledata.length != 0 &&
                                                                                            <tbody>
                                                                                                {data.tabledata.length != 0 && data.tabledata.map((dataTableData: BatchDetailModels.tabledata, idxTable) => {
                                                                                                    return (
                                                                                                        <tr key={idxTable}>
                                                                                                            {dataTableData.row.length == 0 && <td colSpan={data.tabledata.length}><div className="text-center">No data..</div></td>}
                                                                                                            {dataTableData.row.length != 0 && dataTableData.row.map((item: string, idx2) => {
                                                                                                                return (
                                                                                                                    <td key={idx2} style={{ textAlign: data.tableheader[idx2].align as align_ }}>{item}</td>
                                                                                                                )
                                                                                                            })}
                                                                                                        </tr>
                                                                                                    )
                                                                                                })}
                                                                                            </tbody>
                                                                                        }
                                                                                    </table>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    )
                                                                })

                                                                : <ReportInformationMobileTable
                                                                    isLoading={false}
                                                                    listDataItem={ListItem.ListDataItem as any} />
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                        : this.state.relatedDocuments.map((document, index) => (
                                            <Row className="mt-3" key={document.wfInstanceno}>
                                                <Col>
                                                    <div className="master-data-content">
                                                        <div className='trapezoid-head' style={{ width: '500px', textAlign: 'left' }}>
                                                            <Row>
                                                                <Col>
                                                                    <div className="mx-3 mt-1 custom-checkbox" >
                                                                        <label>
                                                                            <input
                                                                                value={document.docNo}
                                                                                type="checkbox"
                                                                                onClick={(e) => this.handleCheckedBatch(e, document.docNo)}
                                                                            />
                                                                            &nbsp;Document Number : {document.docNo}
                                                                        </label>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                    <div className="mat-box">
                                                        <WorkflowInformationGrid document={document} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        ))
                                }
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {/* <Button color="primary" onClick={() => this.handleClose(this.state.wfId, "", "", "")}>Save</Button> */}
                        <Button color="secondary" onClick={() => this.handleCloseBatchDetail()}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({ ...state.workflowInbox, ...state.BatchDetail }), ({ ...WorkflowInboxStore.actionCreators, ...BatchDetailStore.actionCreators }))(WorkflowInboxTableMobile)