import * as React from "react";
import { connect } from "react-redux";
import { Card, Button, Container, Row, Col, Table, Spinner } from "react-bootstrap";

import { FaEdit, FaTimesCircle } from 'react-icons/fa';
import { Link } from "react-router-dom";
// import ReactDatatable  from '@ashvin27/react-datatable';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
// redux
import * as ConditionMasterStore from '../../store/condition/ConditionMaster'
import * as Models from '../../models/condition/ConditionMaster'

import * as DeleteConditionMasterStore from '../../store/condition/DeleteConditionMaster'
import * as ModelsDeleteDeleteConditionMaster from '../../models/condition/DeleteConditionMaster'
import { ApplicationState } from "../../store";


type conditionMasterProps = Models.GetConditionMasterState & typeof ConditionMasterStore.actionCreators;
type deleteconditionProps = ModelsDeleteDeleteConditionMaster.DeleteConditionIDState & typeof DeleteConditionMasterStore.actionCreators

class ConditionTable extends React.Component<any, { response: any, isready: boolean, groupData: [], delete: boolean, company?: string }, deleteconditionProps & conditionMasterProps> {

    constructor(props: any) {
        super(props);
        this.state = {
            response: "",
            isready: true,
            groupData: [],
            delete: false,
            company: undefined
        }
    }

    componentDidMount() {
        var company = this.props.company
        if (company != this.state.company) {
            if (company) {
                this.props.requestConditionMaster(this.state.isready, "POST", company);
            }

            this.setState({
                company: company,
                isready: false
            });
        }
    }

    ondelete = (conditionid: string) => {
        Swal.fire({
            title: 'ลบข้อมูล ?',
            text: 'คุณต้องการลบหรือไม่ ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ไม่'
        }).then((result) => {
            if (result.value) {
                //  this.props.submitUser(this.state)
                console.log(conditionid)
                this.props.requestdeleteConditionMaster(true, "POST", conditionid, this.state.company)
                document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
                this.setState({
                    delete: true
                })
            }
        })
    }

    onupdate = (conditionid: string, conditionname: string, createby: string) => {
        var SearchCondition = this.props.responseMaster['result_list'].find((opt: Models.ConditionMaster) => opt.conditionid === conditionid);
        // console.log(this.props.responseMaster['result_list'].find((opt: Models.ConditionMaster) => opt.conditionid === conditionid))
        var dataset: any = {
            id: conditionid,
            name: conditionname,
            user_login_id: createby
        }
        this.props.blindUpdateCondition(dataset)
    }

    formatDMY = (dmy: string) => {

        var year = dmy.substring(0, 4);
        var month = dmy.substring(4, 6);
        var day = dmy.substring(6, 8);
        var Datevalue = "";
        if (dmy.length < 9) {
            Datevalue = year + '-' + month + '-' + day
        } else {
            var hour = dmy.substring(8, 10);
            var second = dmy.substring(10, 12);
            var millssecond = dmy.substring(12, 14);
            Datevalue = year + '-' + month + '-' + day + ' ' + hour + ':' + second + ':' + millssecond
        }

        return Datevalue;
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { messageCreate, key } = this.props
        var company = this.props.company
        if (company != this.state.company) {
            if (company) {
                this.props.requestConditionMaster(true, "POST", company);
            }

            this.setState({
                company: company
            });
        }

        if (nextProps.key != key) {
            this.props.requestConditionMaster(true, "POST", this.state.company);
            this.props.callbackkey();
        }

        if (this.props.statusDelete == 200 && this.state.delete == true) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            console.log('delete : ' + this.props.statusDelete)
            this.setState({
                delete: false
            })
            this.props.requestdeleteConditionMaster(true, "CLEAR", '', this.state.company)
            Swal.fire({
                title: 'สำเร็จ!',
                text: 'ลบข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {
                if (result.value) {
                    this.props.requestConditionMaster(true, "POST", this.state.company);
                }
            })
        } else if (this.props.statusDelete == 204 && this.state.delete == true) {
            this.props.requestdeleteConditionMaster(true, "CLEAR", '', this.state.company)
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            var msg = JSON.parse(messageCreate);
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {
                this.props.requestConditionMaster(true, "POST", this.state.company);
            })
        }
    }

    render() {
        return (
            <div>
                <Table className="table-bordered align-middle" size='sm'>
                    <thead>
                        <tr style={{ width: '100%' }} className="text-center table-active thead-light">
                            <th style={{ width: '7%' }}></th>
                            <th style={{ width: '23%' }}>Parameter ID</th>
                            <th style={{ width: '25%' }}>Parameter Name</th>
                            <th style={{ width: '20%' }}>Create By</th>
                            <th style={{ width: '25%' }}>Create On</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!this.props.isLoadConditionMaster ? <tr><td colSpan={7} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr> : <tr></tr>}
                        {/* {this.props.responseMaster['result_list'] == null && this.props.isLoadConditionMaster ? <tr><td colSpan={7} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr> : <tr></tr>} */}
                        {this.props.statusMaster == 200 && this.props.responseMaster['total_count'] != 0 && this.props.responseMaster['result_list'].map((item: Models.ConditionMaster) =>
                            <tr key={item.conditionid}>
                                <td className="text-center">
                                    <div>
                                        {/* <Link to={{ pathname: '/addauthoritygroup', state: { positioncode: item.conditionid } }}> */}
                                        <Button variant="outline-info" size="sm" onClick={() => this.onupdate(item.conditionid, item.conditionname, item.created_by)}><FaEdit /></Button>
                                        {/* </Link> */}
                                        <Button className="mx-1" variant="outline-danger" size="sm" onClick={() => this.ondelete(item.conditionid)}><FaTimesCircle /></Button>
                                    </div>
                                </td>
                                <td>{item.conditionid}</td>
                                <td>{item.conditionname}</td>
                                <td>{item.created_by}</td>
                                <td>{this.formatDMY(String(item.created_on))}</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        );
    }
}
export default connect((state: ApplicationState) => ({ ...state.appState, ...state.conditionMaster, ...state.deleteconditionMaster }), ({ ...ConditionMasterStore.actionCreators, ...DeleteConditionMasterStore.actionCreators }))(ConditionTable);
