import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

import * as Models from '../../models/workflow/DeleteWorkflow'

interface BeforeDeleteWorkflow {
    type: 'DELETE_WORKFLOW'
}

interface DeleteWorkflow {
    type: 'DELETEWORKFLOW'
    response: Models.ResponseWorkflow
    statusDelete: number
}

interface DeleteFail {
    type: 'DELETEFAIL'
    statusDelete: number
    messageDelete: string
}

interface ClearDelete {
    type: 'CLEARDELETE'
}

type KnownAction = DeleteWorkflow | DeleteFail | ClearDelete | BeforeDeleteWorkflow

export const actionCreators = {
    requestdeleteWorkflow: (p: boolean, method: String, wfid: string , company : string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestdeleteWorkflow')
        if (appState && appState.deleteWorkflow && p && method == 'POST') {
            var wfIdUri = encodeURIComponent(wfid);
            await fetch(`/v2/Workflow/delete?wf_id=` + wfIdUri + '&app_id=' + company, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('dispatch : DELETEFAIL')
                            dispatch({ type: 'DELETEFAIL', statusDelete: 204, messageDelete: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            dispatch({ type: 'DELETEWORKFLOW', response: data as Models.ResponseWorkflow, statusDelete: 200 });
                        })
                    }
                })
            dispatch({ type: 'DELETE_WORKFLOW' });

        } else if (method == 'CLEAR') {
            console.log('dispatch : CLEARDELETE')
            dispatch({ type: 'CLEARDELETE' });
        }
    }
}

const unloadedState: Models.DeleteWorkflowState = { isLoadDeleteWorkflowMaste: false, statusDeleteWorkflowMaste: 400 };

export const reducer: Reducer<Models.DeleteWorkflowState> = (state: Models.DeleteWorkflowState | undefined, incomingAction: Action): Models.DeleteWorkflowState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'DELETEWORKFLOW':
            return {
                isLoadDeleteWorkflowMaste: true,
                responseDeleteWorkflowMaste: action.response,
                statusDeleteWorkflowMaste: action.statusDelete
            }

        case 'DELETEFAIL':
            return {
                isLoadDeleteWorkflowMaste: true,
                statusDeleteWorkflowMaste: action.statusDelete,
            }

        case 'CLEARDELETE':
            return {
                isLoadDeleteWorkflowMaste: false,
                statusDeleteWorkflowMaste: 400
            }

        default: return state;
    }
}