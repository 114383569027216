import { BufferLogResponseList } from 'data/Log/schemas/LogBuffer.shema';
import { formatYMDToDMY } from 'modules/Common';
import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

type Props = {
    explorerDetail: () => void;
    mappingCompany: Map<string, string>;
    logBufferDetail: BufferLogResponseList;
};

function WorkflowLogBufferDetail(props: Props) {
    const { explorerDetail, logBufferDetail, mappingCompany } = props;

    return (
        <div>
            <div className="flex justify-end">
                <Button
                    variant="outline-success"
                    size="sm"
                    onClick={explorerDetail}
                >
                    Back
                </Button>
            </div>
            <Col>
                <Row className="flex gap-2">
                    <Form.Label className="w-[10%]">Buffer ID :</Form.Label>
                    <Form.Label>{logBufferDetail.bufferid}</Form.Label>
                </Row>
                <Row className="flex gap-2">
                    <Form.Label className="w-[10%]">Buffer Status :</Form.Label>
                    <Form.Label>{logBufferDetail.bufferstatus}</Form.Label>
                </Row>
                <Row className="flex gap-2">
                    <Form.Label className="w-[10%]">
                        Instance Number :
                    </Form.Label>
                    <Form.Label>{logBufferDetail.instanceno}</Form.Label>
                </Row>
                <Row className="flex gap-2">
                    <Form.Label className="w-[10%]">
                        Document Number :
                    </Form.Label>
                    <Form.Label>{logBufferDetail.paramdocno}</Form.Label>
                </Row>
                <Row className="flex gap-2">
                    <Form.Label className="w-[10%]">Document Year :</Form.Label>
                    <Form.Label>{logBufferDetail.paramdocyear}</Form.Label>
                </Row>
                <Row className="flex gap-2">
                    <Form.Label className="w-[10%]">SID :</Form.Label>
                    <Form.Label>{mappingCompany.get(logBufferDetail.sid)}</Form.Label>
                </Row>
                <Row className="flex gap-2">
                    <Form.Label className="w-[10%]">Type :</Form.Label>
                    <Form.Label>{logBufferDetail.type}</Form.Label>
                </Row>
                <Row className="flex gap-2">
                    <Form.Label className="w-[10%]">Create On :</Form.Label>
                    <Form.Label>
                        {formatYMDToDMY(logBufferDetail.created_on)}
                    </Form.Label>
                </Row>
                <Row className="flex gap-2">
                    <Form.Label className="w-[10%]">Create By :</Form.Label>
                    <Form.Label>{logBufferDetail.created_by}</Form.Label>
                </Row>
                <Row className="flex gap-2">
                    <Form.Label className="w-[10%]">
                        Buffer Error Massage :
                    </Form.Label>
                    <Form.Label className="overflow-x-auto whitespace-pre-wrap">
                        {logBufferDetail.buffer_errormessage}
                    </Form.Label>
                </Row>
                <Row className="flex gap-2">
                    <Form.Label className="w-[10%]">Json Data :</Form.Label>
                    <Form.Label className="overflow-x-auto whitespace-pre-wrap">
                        {logBufferDetail.jsondata}
                    </Form.Label>
                </Row>
            </Col>
        </div>
    );
}

export default WorkflowLogBufferDetail;
