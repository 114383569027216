import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/workflow/WorkflowInbox'

interface BeforeLoadWorkflowInbox {
    type: 'LOAD_WORKFLOWINBOX'
}
interface LoadWorkflowInbox {
    type: 'LOADWORKFLOWINBOX'
    response: Models.ResponseWorkflowInbox
    status: number,
    statusMessage: String
}
interface FailLoadWorkflowInbox {
    type: 'LOADWORKFLOWINBOXFAIL'
    status: number,
    statusMessage: any
}

interface ClearLoadWorkflowInbox {
    type: 'CLEARLOADWORKFLOWINBOX'
    status: number,
    statusMessage: any
}

type KnownAction = LoadWorkflowInbox | FailLoadWorkflowInbox | ClearLoadWorkflowInbox | BeforeLoadWorkflowInbox

export const actionCreators = {
    requestWorkflowInbox: (p: boolean, method: string, user_id: string, sids: string[], page: number, page_size: number, wf_type: string, searchReq: Models.searchValue, accessToken?: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.workflowInbox && p && method == 'POST') {
            var sid = "", stanffid = "";
            if (sids) {
                for (let i = 0; i < sids.length; i++) {
                    sid += '&app_id=' + sids[i]
                }
            }
            var createdOnFrom = searchReq.createBy;
            var createdOnTo = searchReq.createByTo;

            //if (searchReq) {
            //if (searchReq.createByTo) {
            //    createdOnTo = searchReq.createByTo + '235900';
            //}

            //if (searchReq.createBy) {
            //    createdOnFrom = searchReq.createBy + '000000'
            //}
            //}
            if (searchReq.userName !== "" && searchReq.userName !== "*") {
                //if (searchReq.userName == "*") {
                //    user_id = "";
                //} else {
                user_id = searchReq.userName
                //}
            }
            if (wf_type === null) {
                wf_type = ""
            }
            const bearer = `Bearer ${accessToken || localStorage.getItem('WF_APPLICATION')}`;

            fetch('/v4/WorkflowInbox/Get?user_id=' + user_id + sid + '&page=' + page + '&page_size=' + page_size + '&wf_type=' + wf_type + '&doc_no=' + searchReq.wfNo + '&doc_year=' + searchReq.fiscalYear + '&amount_from=' + searchReq.docAmount + '&amount_to=' + searchReq.docAmountTo + '&doc_date_from=' + createdOnFrom + '&doc_date_to=' + createdOnTo + '&requester_id=' + searchReq.requester_id + '&stanffid=' + stanffid, {
                method: 'GET',
                headers: {
                    'Authorization': bearer,
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            dispatch({ type: 'LOADWORKFLOWINBOXFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            if (data.result_list != null) {
                                var byDate = data.result_list.slice(0);
                                byDate.sort(function (a, b) {
                                    var x, y;
                                    if (b.docDate != null) {
                                        x = b.docDate.toLowerCase();
                                    }
                                    if (a.docDate != null) {
                                        y = a.docDate.toLowerCase();
                                    }
                                    return x < y ? -1 : x > y ? 1 : 0;
                                });
                                data.result_list = byDate
                            }
                            dispatch({ type: 'LOADWORKFLOWINBOX', response: data as Models.ResponseWorkflowInbox, status: 200, statusMessage: '' });
                        })
                    }
                })

            dispatch({ type: 'LOAD_WORKFLOWINBOX' });

        } else if (method == "CLEAR") {
            dispatch({ type: 'CLEARLOADWORKFLOWINBOX', status: 400, statusMessage: "" });
        }
    }
}


//export const actionCreators = {
//    requestWorkflowInbox: (p: boolean, method: String, wf_id: string, json_workflow_inbox: Array<Models.DataWorkflowInbox>): AppThunkAction<KnownAction> => (dispatch, getState) => {
//        const appState = getState();
//        console.log('call actionCreators : requestWorkflowInbox')
//        if (appState && appState.workflowInbox && p && method == 'POST') {
//            fetch('/v1/WorkflowInbox/Get?user_id=' + wf_id, {
//                method: 'GET',
//                headers: {
//                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
//                },
//            })
//                .then((response) => {
//                    console.log("requestWorkflowInbox")
//                    //console.log(response)
//                    if (response.status != 200) {
//                        throw new Error(response.json() as any)
//                    } else {
//                        return response.json() as Promise<Models.ResponseWorkflowInbox>
//                    }
//                })
//                .then(data => {
//                    var byDate = data.result_list.slice(0);
//                    byDate.sort(function (a, b) {
//                        var x = b.docDate.toLowerCase();
//                        var y = a.docDate.toLowerCase();
//                        return x < y ? -1 : x > y ? 1 : 0;
//                    });
//                    data.result_list = byDate
//                    dispatch({ type: 'LOADWORKFLOWINBOX', response: data, status: 200, statusMessage: '' });
//                })
//                .catch(error => {
//                    //console.log('errorrrr')
//                    console.log(error)
//                    dispatch({ type: 'LOADWORKFLOWINBOXFAIL', status: 204, statusMessage: error });
//                });
//        } else if (json_workflow_inbox.length > 0 && method == "Approve") {
//            console.log(JSON.stringify(json_workflow_inbox))
//            fetch(`/workflow/approve`, {
//                method: 'PUT',
//                headers: {
//                    'Content-Type': 'application/json',
//                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION')
//                    },
//                body: JSON.stringify(json_workflow_inbox[0]),
//            })
//                .then(response => {
//                    if (!response.ok) {
//                        throw new Error(response.json() as any)
//                    }
//                    return response.json() 
//                })
//                .then(data => {
//                    return dispatch({ type: 'LOADWORKFLOWINBOX', response: data, status: 200, statusMessage: '' });
//                })
//                .catch(error => {
//                    console.log('dispatch : CREATEWORKFLOWFAIL')
//                    dispatch({ type: 'LOADWORKFLOWINBOXFAIL', status: 204, statusMessage: error });
//                });

//        }
//        else if (json_workflow_inbox.length > 0 && method == "Reject") {
//            console.log(json_workflow_inbox)
//            fetch(`/workflow/reject`, {
//                method: 'PUT',
//                headers: {
//                    'Accept': 'application/json',
//                    'Content-Type': 'application/json',
//                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
//                },
//                body: JSON.stringify(json_workflow_inbox),
//            })
//                .then(response => {
//                    if (!response.ok) {
//                        throw new Error(response.json() as any)
//                    }
//                    return response.json()
//                })
//                .then(data => {
//                    return dispatch({ type: 'LOADWORKFLOWINBOX', response: data, status: 200, statusMessage: '' });
//                })
//                .catch(error => {
//                    console.log('dispatch : CREATEWORKFLOWFAIL')
//                    dispatch({ type: 'LOADWORKFLOWINBOXFAIL', status: 204, statusMessage: error });
//                });

//        }
//        else if (method == "CLEAR") {
//            console.log("dispatch : CLEARLOADWORKFLOWINBOX")
//            dispatch({ type: 'CLEARLOADWORKFLOWINBOX', status: 400, statusMessage: "" });
//        }
//    }
//}

const unloadedState: Models.GetWorkflowInboxState = { isLoadWorkflowInbox: false };

export const reducer: Reducer<Models.GetWorkflowInboxState> = (state: Models.GetWorkflowInboxState | undefined, incomingAction: Action): Models.GetWorkflowInboxState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_WORKFLOWINBOX':
            return {
                isLoadWorkflowInbox: false,
            }

        case 'LOADWORKFLOWINBOX':
            return {
                isLoadWorkflowInbox: true,
                responseWorkflowInbox: action.response,
                statusWorkflowInbox: action.status
            }

        case 'LOADWORKFLOWINBOXFAIL':
            return {
                isLoadWorkflowInbox: true,
                messageWorkflowInbox: action.statusMessage,
                statusWorkflowInbox: action.status
            }
        case 'CLEARLOADWORKFLOWINBOX':
            return {
                isLoadWorkflowInbox: false,
                messageWorkflowInbox: "",
                statusWorkflowInbox: 400
            }
        default: return state;
    }
}