export class SpinnerLoader {
    static show() {
        document.getElementsByClassName('Loader__background')[0].className =
            'Loader__background show';
    }

    static hide() {
        document.getElementsByClassName('Loader__background')[0].className =
            'Loader__background hide';
    }
}