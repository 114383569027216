import { green } from "@material-ui/core/colors";
import QueryString from "query-string";
import * as React from "react";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { FaCheck, FaCheckCircle, FaClock, FaFileAlt, FaTimes, FaTimesCircle, FaShare, FaCircle, FaCircleNotch } from "react-icons/fa";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { CardBody, Container, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Swal from 'sweetalert2';
import * as Models from '../../models/reference/Reference';
import * as ModelApproveRejectWorkflow from '../../models/workflow/ApproveRejectWorkflow';
import * as ModelSearchWorkflowInbox from '../../models/workflow/SearchWorkflowInbox';
import * as ModelsWorkflowInbox from '../../models/workflow/WorkflowInbox';
import { formatDMY, formatYMDToDMY, numberWithCommas, onClickFileShowIframe, parseJwt, onClickFileShow } from '../../modules/Common';
import { ApplicationState } from "../../store";
import * as ApproveRejectWorkflowStore from '../../store/workflow/ApproveRejectWorkflow';
import * as SearchWorkflowInboxStore from '../../store/workflow/SearchWorkflowInbox';
import SignInComponent from '../auth/SignInComponent';
import { WifiRounded } from "@material-ui/icons";
import { ViewportContext } from "../../context/ViewportProvider";
import WorkflowInboxInformationMobileTable from "../mobiletable/WorkflowInboxInformationMobileTable";

var Loader = require('react-loader');
//var exdata = '[{},{},{},{},{},{},{},{},{},{"file_name":"D12052100085.pdf","file_path":"https://s3-ap-southeast-1.amazonaws.com/sni-1xue03kdf4dk9m7wzufvef0i0zl/1xUETKOx6ltGnNRXeAOkfmyBBKg/dms/202112/20211222053619-39f5cdfe-b803-49e4-9010-45be5225173a.pdf"},{"file_name":"ใบกำกับภาษี 20-0987.pdf","file_path":"https://s3-ap-southeast-1.amazonaws.com/sni-1xue03kdf4dk9m7wzufvef0i0zl/1xUETKOx6ltGnNRXeAOkfmyBBKg/dms/202112/20211222053619-6c2fd9a2-bf05-4fbc-825a-827f07451ed8"},{"file_name":"สมุดรายวันไม้สับลานเทไม้20-0987.pdf","file_path":"https://s3-ap-southeast-1.amazonaws.com/sni-1xue03kdf4dk9m7wzufvef0i0zl/1xUETKOx6ltGnNRXeAOkfmyBBKg/dms/202112/20211222053620-f791455a-08f8-4b55-b411-81379c1d3395"},{},{},{},{},{}]'
type SearchworkflowInboxProps = ModelSearchWorkflowInbox.GetWorkflowInboxValueState & typeof SearchWorkflowInboxStore.actionCreators
type ApproveRejectWorkflowProps = ModelApproveRejectWorkflow.ApproveRejectWorkflowState & typeof ApproveRejectWorkflowStore.actionCreators

type Props = {
    [key: string]: any;
} & {
    disabledApproval?: boolean;
}

type State = {
    fileName: any;
    src: any;
    mode: any;
    userId: string;
    token: string;
    controlRedirect: boolean;
    showModalUrl: boolean;
    checkurl: any;
    workflowValueUse: ModelsWorkflowInbox.WorkflowValue;
    isLoading: boolean;
    checkwfid: boolean;
    formData: ModelSearchWorkflowInbox.ResponseWorkflowInboxValue;
    checkresponse: boolean;
    checkapprove: boolean;
    checkreject: boolean;
    messageModal: boolean;
    message: string;
    modeApproveReject: string;
    loginModal: boolean;
    srcLogin: any;
    srcHistory: any;
    docNoList: any;
    successModal: boolean;
    successModalHeader: string;
    exdata: string;
    seconds: number;
};

class OnlineForm extends React.Component<Props, State, SearchworkflowInboxProps> {
    public static contextType = ViewportContext;
    private intervalId: NodeJS.Timeout | null = null;
    private isAlreadyDefaultClickFile = false;

    constructor(props: any) {
        super(props);
        this.state = {
            userId: "",
            token: "",
            isLoading: false,
            checkwfid: true,
            checkresponse: true,
            formData: {} as ModelSearchWorkflowInbox.ResponseWorkflowInboxValue,
            checkapprove: true,
            checkreject: true,
            messageModal: false,
            message: "",
            modeApproveReject: "",
            workflowValueUse: {
                wfId: "",
                docNo: "",
                wfInstanceNo: "",
                userId: "",
                token: "",
                sid: '',
                appId: ''
            },
            checkurl: "",
            showModalUrl: true,
            controlRedirect: false,
            loginModal: false,
            srcLogin: "",
            srcHistory: "",
            docNoList: [],
            successModal: false,
            successModalHeader: "",
            src: undefined,
            fileName: undefined,
            mode: undefined,
            exdata: '',
            seconds: 10,
        }

        this.blindLogin = this.blindLogin.bind(this)
    }

    componentDidMount() {
        //this.state.srcHistory = window.document.location.href
        this.setState({
            srcHistory: window.document.location.href
        })
        console.log(window.document.location.href)
        //window.history.pushState({}, "Workflow Information", "/workflow-information");

        var ls: any = localStorage.getItem("WF_APPLICATION");

        if (ls) {
            var jls: any = JSON.parse(ls)
            var access_token = parseJwt(jls.access_token)
            var extra = JSON.parse(access_token.extra)
            console.log(extra)

            if (extra.citizen_id) {
                console.log('Azure Login ID: ' + extra.citizen_id)
                this.setState({ userId: extra.citizen_id, token: jls.access_token })
            } else if (extra.username) {
                console.log('Local Login ID: ' + extra.sid)
                this.setState({ userId: extra.username, token: jls.access_token })
            }
        }
        else {
            this.setState({ userId: 'unknown', token: '' })
        }

        const { showmodal } = this.props
        if (this.props.location != undefined) {
            window.history.pushState({}, "Workflow Information", "/workflow-information");

            var { wfId, docNo, wfInstanceNo, userId, token, ref_id, sid } = QueryString.parse(this.props.location.search) as Record<string, string>;
            if (ref_id) {
                this.props.history.push("/workflow-inbox-overview?ref_id=" + ref_id);
                //fetch('/workflow/reference?id=' + ref_id, {
                //    method: 'GET',
                //})
                //    .then((response) => {
                //        if (response.status != 200) {
                //            throw new Error(response.json() as any)
                //        } else {
                //            return response.json() as Promise<Models.Response>
                //        }
                //    })
                //    .then(data => {
                //        console.log(data)
                //        let params = new URLSearchParams(data.parameter);
                //        wfId = params.get("wfId");
                //        docNo = params.get("docNo");
                //        wfInstanceNo = params.get("wfInstanceNo");
                //        userId = params.get("userId");
                //        token = params.get("token");
                //        sid = params.get("app_id");
                //        if (params.get("app_id") != null || params.get("app_id") != undefined) {
                //            sid = params.get("app_id");
                //        } else {
                //            sid = data.sid;
                //        }
                //var localUserId: string = ""

                //if (localStorage.getItem("WF_APPLICATION") != undefined) {

                //    var ls: any = localStorage.getItem("WF_APPLICATION");
                //    var jls: any = JSON.parse(ls)
                //    var access_token = parseJwt(jls.access_token)
                //    var extra = JSON.parse(access_token.extra)
                //    console.log(extra)

                //    if (extra.citizen_id) {
                //        console.log('Azure Login ID: ' + extra.citizen_id)
                //        localUserId = extra.citizen_id
                //        this.setState({ userId: extra.citizen_id, token: jls.access_token })
                //    } else if (extra.username) {
                //        console.log('Local Login ID: ' + extra.sid)
                //        localUserId = extra.username
                //        this.setState({ userId: extra.username, token: jls.access_token })
                //    }
                //}

                //        if (localUserId != userId) {
                //            var srcc = window.location.protocol + '//' + window.location.host + '/auth/signin'
                //            this.setState({
                //                srcLogin: srcc
                //            })
                //            this.props.requestsearchworkflowInbox(true, "CLOSE", wfId, docNo, userId, wfInstanceNo)
                //            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                //            Swal.fire({
                //                title: 'ไม่สำเร็จ!',
                //                text: 'ไม่พบข้อมูล กรุณาล็อคอินใหม่',
                //                icon: 'error',
                //                showCancelButton: false,
                //                confirmButtonColor: '#3085d6',
                //                confirmButtonText: 'ยืนยัน!',
                //                allowOutsideClick: false,
                //            }).then((result) => {
                //                if (result.value) {
                //                    this.setState({
                //                        loginModal: true,
                //                        //srcLogin: srcc
                //                    })

                //                    console.log(this.state)
                //                }
                //            })
                //        } else {
                //            this.setState({
                //                workflowValueUse: {
                //                    wfId: wfId,
                //                    docNo: docNo,
                //                    wfInstanceNo: wfInstanceNo,
                //                    userId: userId,
                //                    token: token,
                //                    sid: sid as any
                //                },
                //                checkurl: "URL",
                //                showModalUrl: true,
                //                controlRedirect: true
                //            })
                //        }
                //    })
                //    .catch(error => {
                //        console.log(error)
                //    });
            }
            else {
                this.setState({
                    workflowValueUse: {
                        wfId: wfId,
                        docNo: docNo,
                        wfInstanceNo: wfInstanceNo,
                        userId: userId,
                        token: token,
                        sid: sid
                    },
                    checkurl: "URL",
                    showModalUrl: true
                })
            }
        } else {
            const { workflowValue } = this.props
            this.setState({
                workflowValueUse: {
                    wfId: workflowValue.wfId,
                    docNo: workflowValue.docNo,
                    wfInstanceNo: workflowValue.wfInstanceNo,
                    userId: workflowValue.userId,
                    token: workflowValue.token,
                    sid: workflowValue.appId,
                    appId: workflowValue.appId
                },
                checkurl: "No_URL",
                showModalUrl: showmodal
            });
        }
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        console.log(this.state)
        const { workflowValueUse, showModalUrl } = this.state
        const { showmodal, statusResponseApproveReject, statusWorkflowInboxValue, responseWorkflowInboxValue, isLoadWorkflowInboxValue, isLoadApproveReject, messageResponseApproveReject } = this.props

        if (!showmodal && this.state.checkwfid) {
            this.setState({
                checkwfid: false,
            })
            this.props.requestsearchworkflowInbox(true, "CLEAR", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo)
        }
        if (isLoadApproveReject && statusResponseApproveReject == 200 && (this.state.checkapprove || this.state.checkreject)) {
            console.log('WFINFO APPROVE SUCCESS')
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            this.setState({
                checkresponse: true,
                checkapprove: false,
                checkreject: false,
                formData: {} as ModelSearchWorkflowInbox.ResponseWorkflowInboxValue,
                messageModal: false,
                modeApproveReject: "",
                message: "",
                successModal: true
            })
            //setTimeout(() => {
            //    Swal.fire({
            //        title: 'สำเร็จ!',
            //        text: 'กดปุ่มยืนยัน',
            //        icon: 'success',
            //        showCancelButton: false,
            //        confirmButtonColor: '#3085d6',
            //        confirmButtonText: 'ยืนยัน!',
            //        allowOutsideClick: false,
            //    }).then((result) => {
            //        if (result.value) {
            //            if (this.state.checkurl == "No_URL") {
            //                this.props.blindopenModal("reload")
            //            } else {
            //                this.setState({
            //                    controlRedirect: true
            //                })
            //            }
            //            this.setState({
            //                checkapprove: true,
            //            })
            //            this.props.requestsearchworkflowInbox(true, "POST", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo)

            //            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
            //        }
            //    })
            //}, 500);
        } else if (isLoadApproveReject && statusResponseApproveReject == 400 && (this.state.checkapprove || this.state.checkreject)) {
            try {
                var msg = JSON.parse(messageResponseApproveReject)
                document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                this.setState({
                    checkresponse: true,
                    checkapprove: false,
                    checkreject: false,
                    formData: {} as ModelSearchWorkflowInbox.ResponseWorkflowInboxValue,
                    messageModal: false,
                    modeApproveReject: "",
                    message: ""
                })
                this.props.requestsearchworkflowInbox(true, "CLEAR", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo)
                this.props.requestApproveWorkflow(true, "CLEAR", "", "", "");
                this.props.requestsearchworkflowInbox(true, "POST", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo, workflowValueUse.appId)
                setTimeout(() => {
                    Swal.fire({
                        title: 'ไม่สำเร็จ!',
                        text: msg.message,
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'ตกลง',
                        allowOutsideClick: false,
                    }).then((result) => {
                        if (result.value) {
                            if (this.state.checkurl == "No_URL") {
                                //this.props.blindopenModal("reload")
                            }
                            this.setState({
                                checkapprove: true,
                            })
                            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
                        }
                    })
                }, 500);
            } catch {
                document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                this.setState({
                    checkresponse: true,
                    checkapprove: false,
                    checkreject: false,
                    formData: {} as ModelSearchWorkflowInbox.ResponseWorkflowInboxValue,
                    messageModal: false,
                    modeApproveReject: "",
                    message: ""
                })
                this.props.requestsearchworkflowInbox(true, "CLEAR", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo)
                this.props.requestApproveWorkflow(true, "CLEAR", "", "", "");
                this.props.requestsearchworkflowInbox(true, "POST", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo, workflowValueUse.appId)
                setTimeout(() => {
                    Swal.fire({
                        title: 'ไม่สำเร็จ!',
                        html: messageResponseApproveReject,
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'ตกลง',
                        allowOutsideClick: false,
                    }).then((result) => {
                        if (result.value) {
                            if (this.state.checkurl == "No_URL") {
                                //this.props.blindopenModal("reload")
                            }
                            this.setState({
                                checkapprove: true,
                            })
                            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
                        }
                    })
                }, 500);
            }
        }
        if (workflowValueUse.userId && showModalUrl == true && this.state.checkwfid && this.state.checkapprove && this.state.checkreject) {
            this.props.requestsearchworkflowInbox(true, "POST", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo, workflowValueUse.appId)
            this.setState({
                checkwfid: false,
                showModalUrl: false
            })
        }
        else if (isLoadWorkflowInboxValue && statusWorkflowInboxValue == 200 && responseWorkflowInboxValue != undefined && this.state.checkresponse) {
            console.log(responseWorkflowInboxValue)
            this.setState({
                checkresponse: false,
                formData: responseWorkflowInboxValue
            })
            this.props.jsonReportCallback(responseWorkflowInboxValue['jsonReport'])

            setTimeout(() => {
                console.log(this.state.formData['subject'])
            }, 500);
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
        } else if (isLoadWorkflowInboxValue && statusWorkflowInboxValue == 204 && isLoadWorkflowInboxValue && this.state.checkresponse) {

            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            if (this.state.checkurl == "No_URL") {
                this.props.blindopenModal(false)
            }

            this.props.requestsearchworkflowInbox(true, "CLEAR", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo)
            //Swal.fire({
            //    title: 'ไม่สำเร็จ!',
            //    text: 'ไม่พบข้อมูลเอกสารที่ต้อง Approve\nกรุณากดยืนยัน เพื่อดำเนินการต่อ',
            //    icon: 'error',
            //    showCancelButton: false,
            //    confirmButtonColor: '#3085d6',
            //    confirmButtonText: 'ยืนยัน!',
            //    allowOutsideClick: false,
            //}).then((result) => {
            //    console.log("gene")
            //    if (result.value) {
            //        this.setState({
            //            controlRedirect: true
            //        })
            //    }
            //})

            this.setState({
                controlRedirect: true
            })
        }
        else if (isLoadWorkflowInboxValue && statusWorkflowInboxValue == 999 && isLoadWorkflowInboxValue && this.state.checkresponse) {

            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            if (this.state.checkurl == "No_URL") {
                this.props.blindopenModal(false)
            }

            this.props.requestsearchworkflowInbox(true, "CLEAR", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo)
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: 'ไม่พบข้อมูลเอกสารที่ต้อง Approve\nกรุณากดยืนยัน เพื่อดำเนินการต่อ',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false,
            }).then((result) => {
                console.log("gene")
                if (result.value) {
                    this.setState({
                        controlRedirect: true
                    })
                }
            })
        }
        else if (!showmodal && !this.state.checkwfid && statusWorkflowInboxValue == 200) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            if (this.state.checkurl == "No_URL") {
                this.props.blindopenModal(false)

                this.setState({
                    checkwfid: true,
                    checkresponse: true,
                    checkapprove: true,
                    formData: {} as ModelSearchWorkflowInbox.ResponseWorkflowInboxValue
                })
                this.props.requestsearchworkflowInbox(true, "CLEAR", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo)
            }
        }
    }

    public blindLogin(value: any) {
        console.log(value)
        if (value == true && this.state.loginModal) {
            window.location.href = "" + this.state.srcHistory + ""
            this.setState({
                loginModal: false
            })
        }
    }

    handleMessage = () => {
        if (this.state.messageModal) {
            this.setState({
                messageModal: false,
                modeApproveReject: "",
                message: "",
                docNoList: []
            })
        } else {
            this.setState({
                messageModal: true
            })
        }
    }

    handleSuccessModal = () => {
        const { workflowValueUse } = this.state
        if (this.state.successModal) {
            this.props.requestApproveWorkflow(true, "CLEAR", workflowValueUse.userId, workflowValueUse.docNo, "");
            //this.props.requestsearchworkflowInbox(true, "CLEAR", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo)
            this.props.refreshPageChild()
            if (this.state.checkurl == "No_URL") {
                this.props.blindopenModal("reload")
            } else {
                this.setState({
                    controlRedirect: true
                })
            }
            this.setState({
                successModal: false,
                checkapprove: true,
                successModalHeader: ""
            })
        } else {
            this.setState({
                successModal: true,
                successModalHeader: ""
            })
        }
    }

    handleLoginModal = () => {
        if (this.state.loginModal) {
            this.setState({
                loginModal: false,
            })
        } else {
            this.setState({
                loginModal: true
            })
        }
    }

    onchangeMessage = (e: { target: { name: any; value: any; }; }) => {
        this.setState({
            message: e.target.value
        })
        setTimeout(() => {
            console.log(this.state.message)
        }, 500);
    }

    onsuccessApproveReject = (docNo: string, userId: string, wfType: string) => {
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";

        var messagePush: any = []
        messagePush = this.state.docNoList
        for (let i = 0; i < messagePush.length; i++) {
            messagePush[i]['message'] = this.state.message
        }
        this.setState({
            docNoList: messagePush
        })
        console.log(this.state.docNoList);

        if (this.state.modeApproveReject == "approved") {
            //this.props.requestApproveWorkflow(true, "PUT", userId, docNo, this.state.message, wfType);
            this.props.requestApproveWorkflowList(true, "PUT", this.state.docNoList);
            this.startCountdown();
        } else {
            //this.props.requestRejectWorkflow(true, "PUT", userId, docNo, this.state.message, wfType);
            this.props.requestRejectWorkflowList(true, "PUT", this.state.docNoList);
        }
    }

    handleApprove = (wfInstanceNo: string, docNo: string, userId: string, wfType: string, sid: string) => {
        console.log(wfInstanceNo + " | " + docNo + " | " + userId + " | " + wfType)
        var wfUse: any = []
        var wfins = {
            app_id: sid,
            user_id: userId,
            admin_user_id: this.state.userId,
            wf_type: wfType,
            doc_no_list: [docNo],
            instance_no_list: [wfInstanceNo]
        }
        wfUse.push(wfins)
        console.log(wfins)
        this.setState({
            docNoList: wfUse,
            successModalHeader: "Approved Status"
        })
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        setTimeout(() => {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            this.setState({
                messageModal: true,
                modeApproveReject: "approved"
            })
        }, 500)

        //this.props.requestApproveWorkflow(true, "PUT", userId, docNo, "", wfType);
    }

    handleReject = (wfInstanceNo: string, docNo: string, userId: string, wfType: string, sid: string) => {
        console.log(wfInstanceNo + " | " + docNo + " | " + userId + " | " + wfType)
        var wfUse: any = []
        var wfins = {
            app_id: sid,
            user_id: userId,
            admin_user_id: this.state.userId,
            wf_type: wfType,
            doc_no_list: [docNo],
            instance_no_list: [wfInstanceNo]
        }
        wfUse.push(wfins)
        this.setState({
            docNoList: wfUse,
            successModalHeader: "Rejected Status"
        })
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        setTimeout(() => {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            this.setState({
                messageModal: true,
                modeApproveReject: "reject"
            })
        }, 500)
        //this.props.requestRejectWorkflow(true, "PUT", userId, docNo, "", wfType);
    }

    loadingScreen(mode) {
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background " + mode;
    }

    onClickFileShowIframe = (e: any, fileName: any, mode: any) => {
        onClickFileShowIframe(e, mode, this.state.token)
            .then(src => {
                this.setState({
                    src: src,
                    fileName: fileName,
                    mode: mode
                })
            });
    }

    onClickFileShowFile = (e: any, fileName: any, filepath: any, mode: any) => {
        e.target.dataset.filepath = filepath;
        e.target.dataset.filename = fileName;
        e.target.dataset.mode = mode;
        //console.log(e.target.dataset.filepath)
        //console.log(e.target.dataset.filename)
        onClickFileShow(e, this.state.token)
        //.then(src => {
        //    console.log("111111111111111111111")
        //    console.log(src)
        //    //window.open(src);
        //});
    }

    handleCloseFile = () => {
        this.setState({
            src: undefined,
            fileName: undefined
        })
    }

    startCountdown() {
        this.intervalId = setInterval(() => {
            if (this.state.seconds > 0) {
                this.setState(prevState => ({
                    seconds: prevState.seconds - 1,
                }));
            } else {
                this.stopCountdown();
            }
        }, 1000);
    }

    stopCountdown() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    render() {
        const { width } = this.context;
        const { statusWorkflowInboxValue, responseWorkflowInboxValue, showmodal, isLoadWorkflowInboxValue, responseApproveReject } = this.props
        const { workflowValueUse, formData } = this.state
        const [docDate, docTime] = formData.createdOnFormatDateTime?.split(' ') ?? ['', '']

        const breakpoint = 768; // change this if enable table responsesive

        if (this.state.controlRedirect) {
            console.log(this.props.workflowValue)
            if (this.props.location) {
                //https://workflow.sonarinno.com/workflow-report-detail?userId=focusone&docNo=PR01-21030036&wfId=PR1&token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjFwc0xwbHZLYXc3N0hPMEIzMzZ1UnhIOGNCSyIsInNlciI6IlMwMDAyIiwiYXBwIjoiMW1IVVNYTktlR25tM3lNejRrOXZEb0RCQTNiIiwia2V5IjoiMXBtdW9waEZIU21hUExITER6d3g3NmpGVW5OIiwiZXh0cmEiOiJ7XCJjYWxsYmFja19pZFwiOlwiNmNjNzc1NTNjZWQyNDU5M2FkNDI5OTg4MGQ3MzI4MmNcIn0iLCJleHAiOjE5MzE1NDMwMzUsImlhdCI6MTYxNTk2NzAzNSwiaXNzIjoiU09OQVIgSU5OT1ZBVElPTiBDTy4sIExURC4ifQ.s64-72O2e4a0nxDwTKLNN8U6bUM5lsub9vqje7FHaPEVuKHSwSLfewwDv3luQdTA6BukulZ2gjPQNCpC5gzmN6-070Zv913WV3EGlf3ly1KIiVyM26AL3U37KIJuLfZe29UBOy4IdJAQhzO5LeTOTkyqO-tkJkqPx0gZOgc7-EM
                //return <Redirect to='/workflowdashboard' />;
                //var endpoint = '/workflow-report-detail?userId=' + workflowValueUse.userId + '&docNo=' + workflowValueUse.docNo + '&wfId=' + workflowValueUse.wfId + '&wfInstanceNo=' + workflowValueUse.wfInstanceNo + '&token=' + workflowValueUse.token;
                var { wfId, docNo, wfInstanceNo, userId, token, ref_id, sid } = QueryString.parse(this.props.location.search)

                var endpoint = '/workflow-report-detail?ref_id=' + ref_id;

                return <Redirect to={endpoint} />
            }
            else if (this.props.workflowValue) {
                var refId = this.props.workflowValue.refId
                if (refId) {
                    var endpoint = '/workflow-report-detail?ref_id=' + refId;

                    return <Redirect to={endpoint} />
                }
            }
        }

        if (this.state.loginModal) {
            console.log('redirect to logout')
            return <Redirect to={{ pathname: '/auth/signin', state: { from: this.state.srcHistory } }} />;
        }

        return (
            <Container fluid={true} className="mt-3">

                {/*<Loader loaded={isLoadWorkflowInboxValue} >*/}

                {
                    !this.props.disabledApproval
                    && (
                        <Row>
                            <Col>
                                <Button variant="outline-success mx-1" size="sm" onClick={() => this.handleApprove(workflowValueUse.wfInstanceNo, workflowValueUse.docNo, workflowValueUse.userId, this.state.formData['wfType'], workflowValueUse.appId ? workflowValueUse.appId : '')}>
                                    <FaCheck />&nbsp;Approve
                                </Button>
                                <Button variant="outline-danger mx-1" size="sm" onClick={() => this.handleReject(workflowValueUse.wfInstanceNo, workflowValueUse.docNo, workflowValueUse.userId, this.state.formData['wfType'], workflowValueUse.appId ? workflowValueUse.appId : '')}>
                                    <FaTimesCircle />&nbsp;Reject
                                </Button>
                                {/* <Button variant="outline-secondary mx-1" size="sm">
                            <FaPlusCircle />&nbsp;Reject 1 Step
                        </Button>
                        <Button variant="outline-secondary mx-1" size="sm">
                            <FaPlusCircle />&nbsp;Reject To Requestor
                        </Button>
                        <Button variant="outline-secondary mx-1" size="sm">
                            <FaPlusCircle />&nbsp;Reject To 1st Apporver
                        </Button>
                        <Button variant="outline-secondary mx-1" size="sm">
                            <FaPlusCircle />&nbsp;Reject To 1st Reject
                        </Button> */}
                            </Col>
                        </Row>
                    )
                }
                <Row className="mt-4">
                    <Col>
                        <div className="grid grid-cols-4 gap-4">
                            {/* <div className="col-span-4 space-y-2">
                                <p className="font-semibold">Subject</p>
                                <p className="bg-blue-100 px-4 py-2 rounded-md drop-shadow-md">
                                    {formData.subject}
                                </p>
                            </div>
                            <div className="col-span-4 space-y-2">
                                <p className="font-semibold">Requestor</p>
                                <p className="bg-blue-100 px-4 py-2 rounded-md drop-shadow-md">
                                    {formData.requestorName}
                                </p>
                            </div>
                            <div className="col-span-2 space-y-2">
                                <p className="font-semibold">Document Date</p>
                                <p className="bg-blue-100 px-4 py-2 rounded-md drop-shadow-md">
                                    {
                                        !this.state.checkresponse
                                            ? (docDate as any).replaceAll('.', '/')
                                            : ''
                                    }
                                </p>
                            </div>
                            <div className="col-span-2 space-y-2">
                                <p className="font-semibold">Document Time</p>
                                <p className="bg-blue-100 px-4 py-2 rounded-md drop-shadow-md">
                                    {
                                        !this.state.checkresponse
                                            ? `${docTime} น.`
                                            : ''
                                    }
                                </p>
                            </div> */}
                            <div className="col-span-4 space-y-2">
                                <p className="font-semibold">Document Online Form</p>
                                <p className="bg-blue-100 px-4 py-2 rounded-md drop-shadow-md">
                                    {
                                        JSON
                                            .parse(formData.listSignFileUrl || formData.listFileUrl || '[]')
                                            .map((file: ModelSearchWorkflowInbox.FileUrl, index: number) => (
                                                <React.Fragment key={file.url}>
                                                    {
                                                        file
                                                        && file.file_name
                                                        && file.url
                                                        && (
                                                            <>
                                                                <span
                                                                    data-filename={file.file_name}
                                                                    data-filepath={file.url}
                                                                    onClick={event => this.onClickFileShowFile(event, file.file_name, file.url, 's3')}
                                                                    className="cursor-pointer"
                                                                >
                                                                    <FaShare style={{ transform: 'rotate(270deg)', color: 'green' }} />
                                                                </span>
                                                                {' '}
                                                                <span
                                                                    ref={ref => {
                                                                        !this.isAlreadyDefaultClickFile && index === 0 && ref?.click();
                                                                        this.isAlreadyDefaultClickFile = true
                                                                    }}
                                                                    data-filename={file.file_name}
                                                                    data-filepath={file.url}
                                                                    onClick={event => this.onClickFileShowIframe(event, file.file_name, 's3')}
                                                                    className="text-[#0366d6] cursor-pointer"
                                                                >
                                                                    <FaFileAlt />
                                                                    {' '}
                                                                    {file.file_name}
                                                                </span>
                                                            </>
                                                        )
                                                    }
                                                </React.Fragment>
                                            ))
                                    }
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
                {
                    this.state.src != undefined
                    && (
                        <Row className="mt-3">
                            <Col>
                                <Card>
                                    <Card.Header>{this.state.fileName}
                                        <span style={{ float: 'right' }}><Button size="sm" variant="outline-danger" onClick={this.handleCloseFile}><FaTimes size={15} color="red" />&nbsp;ปิด</Button></span>
                                    </Card.Header>
                                    <Card.Body>
                                        <iframe
                                            style={{ minHeight: '1025px' }}
                                            src={this.state.src}
                                            height="100%"
                                            width="100%"
                                            frameBorder="0"
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    )
                }
                {/* <Row className="mt-3">
                    <Col>
                        {
                            width > breakpoint
                                ? (
                                    <Table responsive hover size="lg" className="table-bordered">
                                        <thead >
                                            <tr className='text-center table-active' style={{ width: '100%' }}>
                                                <th style={{ width: '2%' }}>
                                                </th>
                                                <th style={{ width: '20%' }}>Approver Code</th>
                                                <th style={{ width: '20%' }}>Approver Name</th>
                                                <th style={{ width: '10%' }}>สถานะ</th>
                                                <th style={{ width: '28%' }}>Description</th>
                                                <th style={{ width: '25%' }}>Approve Date/Time</th>
                                            </tr>
                                        </thead>

                                        {!this.state.checkresponse && this.state.formData['listApprovalDetail'].map((item: ModelSearchWorkflowInbox.WorkflowInboxValue, idx) =>
                                            <tbody key={idx}>
                                                <tr key={'1-' + idx} style={item.status == "APPROVED" ? { backgroundColor: '#90be6d', width: '100%' } : item.status == "CANCEL" ? { backgroundColor: '#e5383b', width: '100%' } : { backgroundColor: '#f8f9fa', width: '100%' }}>
                                                    <td style={{ width: '2%' }}>{idx + 1}</td>
                                                    <td style={{ width: '20%' }}>{item.positiongroupname}</td>
                                                    <td style={{ width: '20%' }}>{item.status == "APPROVED" || item.status == "CANCEL" ? item.updated_byname : ""}</td>
                                                    <td style={{ width: '10%' }} className="text-center">
                                                        {item.status == "APPROVED" ? <FaCheck /> : item.status == "CANCEL" ? <FaTimes /> : <FaClock />}
                                                    </td>
                                                    <td style={{ width: '28%' }}>
                                                        {item.status == "APPROVED" || item.status == "CANCEL" ? item.reason : ""}
                                                    </td>
                                                    <td style={{ width: '25%' }}>
                                                        {item.status == "APPROVED" || item.status == "CANCEL" ? formatDMY(String(item.updatedOnFormatDateTime)) : ""}
                                                    </td>
                                                </tr>
                                                <tr key={'2-' + idx}>
                                                    <td></td>
                                                    <td colSpan={5}>
                                                        <table style={{ width: '100%' }} className="table-borderless">

                                                            {(!this.state.checkresponse) ? this.state.formData['listApprovalDetail'][idx]['listEmployeeInPositionGroup'].map((subitem: ModelSearchWorkflowInbox.listEmployeeInPositionGroup, subidx) =>
                                                                <tbody key={subidx}>
                                                                    <tr key={subidx} style={{ width: '100%' }}>
                                                                        <td style={{ width: '20%' }}>{subitem.empid}</td>
                                                                        <td style={{ width: '20%' }}>{subitem.empname}</td>
                                                                        <td className="text-center" style={{ width: '10%' }}></td>
                                                                        <td style={{ width: '30%' }}></td>
                                                                        <td style={{ width: '25%' }}></td>
                                                                    </tr>
                                                                </tbody>
                                                            ) : null}

                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        )}
                                    </Table>
                                ) : (
                                    <WorkflowInboxInformationMobileTable
                                        checkresponse={this.state.checkresponse}
                                        formData={this.state.formData as unknown as (typeof formData)[]}
                                    />
                                )
                        }
                    </Col>
                </Row> */}
                <Modal isOpen={this.state.messageModal} toggle={() => this.handleMessage()} size="lg" backdrop="static" keyboard={false}>
                    <ModalHeader toggle={() => this.handleMessage()}>Message</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Control as="textarea" rows={5} onChange={this.onchangeMessage} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {/*  <Button color="primary" onClick={() => this.handleClose(this.state.wfId, "", "", "")}>Save</Button>*/}
                        <Button className="bg-[#17a2b8]" variant="info" onClick={() => this.onsuccessApproveReject(workflowValueUse.docNo, workflowValueUse.userId, this.state.formData['wfType'])}>Confirm</Button>
                        <Button className="bg-[#6c757d]" variant="secondary" onClick={() => this.handleMessage()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                {/*success modal*/}
                <Modal isOpen={this.state.successModal} toggle={() => this.handleSuccessModal()} size="lg" backdrop="static" keyboard={false}>
                    {this.props.isLoadApproveReject && this.props.statusResponseApproveReject == 200 && this.props.responseApproveReject.length != 0 && this.props.responseApproveReject['result_list'].map((item: ModelApproveRejectWorkflow.ApproveRejectWorkflowResponse, idx) => {
                        item.status === 'wait_otp' && window.location.replace(item.redirectLink);
                        return (
                            <React.Fragment>
                                <ModalHeader toggle={() => this.handleSuccessModal()}>{this.state.successModalHeader}</ModalHeader>
                                <ModalBody scrollable>
                                    <Row>
                                        <Col>
                                            <Card className="shadow-sm p-1 mb-1 bg-white rounded">
                                                <CardBody>
                                                    <Row>
                                                        <Col lg={2}>
                                                            <div style={{ alignContent: 'center', verticalAlign: 'middle', textAlign: 'center' }}>
                                                                {item.status == "SUCCESS" ? <React.Fragment><FaCheckCircle size={50} color="green" /></React.Fragment> : item.status === "wait_otp" ? <FaCircleNotch size={80} color="yellow" style={{ paddingTop: '30px' }} /> : <React.Fragment><FaTimesCircle size={80} color="red" style={{ paddingTop: '30px' }} /></React.Fragment>}
                                                            </div>
                                                        </Col>
                                                        <Col lg={10}>
                                                            <Table className="table table-borderless table-hover" size="sm">
                                                                <tr className="require-field" style={{ width: '100%' }}>
                                                                    <td className="font-weight-bold" style={{ width: '30%' }}>Document NO. :</td>
                                                                    <td style={{ width: '70%' }}>{item.docNo}</td>
                                                                </tr>
                                                                <tr className="require-field" style={{ width: '100%' }}>
                                                                    <td className="font-weight-bold" style={{ width: '30%' }}>Status :</td>
                                                                    <td style={{ width: '70%' }}>{item.status}</td>
                                                                </tr>
                                                                <tr className="require-field" style={{ width: '100%' }}>
                                                                    <td className="font-weight-bold" style={{ width: '30%' }}>Message :</td>
                                                                    <td style={{ width: '70%' }}>{item.message}</td>
                                                                </tr>
                                                                {item.status == "error" ?
                                                                    <tr className="require-field" style={{ width: '100%' }}>
                                                                        <td className="font-weight-bold" style={{ width: '30%' }}>Error Message :</td>
                                                                        <td style={{ width: '70%' }}>{item.errorMessage}</td>
                                                                    </tr>
                                                                    : ''}
                                                                {/* {item.status == "wait_otp" ?
                                                                    <tr className="require-field" style={{ width: '100%' }}>
                                                                        <td className="font-weight-bold" style={{ width: '30%' }}>Redirect In :</td>
                                                                        <td style={{ width: '70%' }}>{this.state.seconds}</td>
                                                                    </tr>
                                                                    : ''} */}
                                                            </Table>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </ModalBody>
                                <ModalFooter>
                                    <Button className="bg-[#dc3545]" variant="danger" onClick={() => this.handleSuccessModal()} disabled={item.status === "wait_otp"}>Close</Button>
                                </ModalFooter>
                            </React.Fragment>
                        )
                    })}
                </Modal>

                <Modal isOpen={this.state.loginModal} toggle={() => this.handleLoginModal()} size="xl" backdrop="static" keyboard={false}>
                    <ModalBody>
                        <SignInComponent AnonymousMode={this.state.loginModal} blindLogin={this.blindLogin.bind(this)} />
                        <iframe style={{ WebkitWritingMode: "vertical-lr", overflow: "none" }} src={this.state.srcLogin} name="myIframe" height="70%" width="100%" title="Iframe Example" frameBorder="0" ref="iframe"></iframe>
                    </ModalBody>
                </Modal>
                {/*</Loader>*/}
            </Container >
        );
    }
}
//export default connect((state: ApplicationState) => state.searchworkflowInbox, SearchWorkflowInboxStore.actionCreators)(OnlineForm)
export default connect((state: ApplicationState) => ({
    ...state.searchworkflowInbox,
    ...state.approveRejectWorkflow
}), ({
    ...SearchWorkflowInboxStore.actionCreators,
    ...ApproveRejectWorkflowStore.actionCreators
}))(OnlineForm)