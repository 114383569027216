import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import * as Model from '../../models/admin/MasterAdmin';

interface GetAdminDetail {
    type: 'GETADMINDETAIL'
    response: Model.resAdminDetail
    status: number,
    statusMessage: String
}
interface FailGetAdminDetail {
    type: 'GETFAIL'
    status: number,
    statusMessage: any
}
interface ClearGetAdminDetail {
    type: 'CLEARGETADMINDETAIL'
}
interface GetedAdminDetail {
    type: 'GETEDADMINDETAIL'
}

type KnownAction = GetAdminDetail | FailGetAdminDetail | ClearGetAdminDetail | GetedAdminDetail

export const actionCreators = {
    requestGetAdminDetail: (p: boolean, method: string, citizen_id: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        console.log('call actionCreators : requestGetAdminDetail')
        const appState = getState();
        if (appState && appState.adminDetail && p && method == 'GET') {
            await fetch(`/v2/configuration/admin?citizen_id=` + citizen_id, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err)
                            console.log('dispatch : GETFAIL')
                            dispatch({ type: 'GETFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            console.log('dispatch : GETADMINDETAIL')
                            dispatch({ type: 'GETADMINDETAIL', response: data as Model.resAdminDetail, status: 200, statusMessage: '' });
                        })
                    }
                })
            dispatch({ type: 'GETEDADMINDETAIL' });
        } else if (method == "CLEAR") {
            dispatch({ type: 'CLEARGETADMINDETAIL' });
        }
    }
}

const unloadedState: Model.GetAdminDetailState = { isLoadGeAdminDetail: false };

export const reducer: Reducer<Model.GetAdminDetailState> = (state: Model.GetAdminDetailState | undefined, incomingAction: Action): Model.GetAdminDetailState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'GETADMINDETAIL':
            return {
                isLoadGeAdminDetail: true,
                responseGetAdminDetail: action.response,
                statusGetAdminDetail: action.status

            }
        case 'GETFAIL':
            return {
                isLoadGeAdminDetail: true,
                responseGetAdminDetail: action.statusMessage,
                statusGetAdminDetail: action.status
            }
        case 'CLEARGETADMINDETAIL':
            return {
                isLoadGeAdminDetail: false,
                messageGetAdminDetail: '',
                statusGetAdminDetail: 400
            }
        case 'GETEDADMINDETAIL':
            return {
                isLoadGeAdminDetail: false,
            }
        default: return state;
    }
}

