import { UserInformation } from '../models/auth/SignIn';
import { JsonDecodeUser } from '../models/common/UserLocalStorage';

export function parseJwt(token: string) {
    const base64Url: string = token.split('.')[1];
    const base64: string = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload: JsonDecodeUser = JSON.parse(
        decodeURIComponent(
            atob(base64)
                .split('')
                .map(function (c) {
                    return (
                        '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                    );
                })
                .join('')
        )
    );

    return jsonPayload;
}

type AccessTokenOptions = {
    replaceOldToken: boolean;
}

export function extractAccessToken(token?: string, options?: AccessTokenOptions): UserInformation {
    if (token && !token.includes('access_token')) {
        token = JSON.stringify({ access_token: token });
    }

    const auth = token ?? localStorage.getItem('WF_APPLICATION');

    if (!auth) {
        return {} as UserInformation;
    }

    const credentials = JSON.parse(auth);
    const accessToken = credentials.access_token;
    const jwt = parseJwt(accessToken);
    const extra = JSON.parse(jwt.extra);

    checkExpUser(jwt);

    if (token && options?.replaceOldToken) {
        localStorage.setItem('WF_APPLICATION', token);
    }

    return extra;
}

//old
export function checkExpUser(e: any) {
    var exp = new Date(e * 1000);
    var date_now = Date.now();
    var now = new Date(date_now);

    if (exp < now) {
        getRedirectURL();
    }
}

export async function getRedirectURL() {
    var res = '';
    await fetch('/v1/env')
        .then((res) => res.json())
        .then(
            (result) => {
                console.log(result);
                res = result.redirect_url;
            },
            (error) => { }
        );

    console.log(res);

    return window.open(res, '_self');
}
