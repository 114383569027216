import { Action, Reducer, combineReducers } from 'redux';
import { AppThunkAction } from '..';
import { parseJwt } from '../../modules/Common';
// models
import * as Models from '../../models/workflow/CreateWorkflowWithWfType'

interface LoadCreateWfWithWfTyp {
    type: 'LOADCREATECUSTOM'
    response: Models.CreateWfWithWfTypeRes
    status: Number,
    statusMessage: String
}
interface FailLoadCreateWfWithWfTyp {
    type: 'LOADFAILCREATECUSTOM'
    status: number,
    statusMessage: any
}
interface ClearCreateWfWithWfTyp {
    type: 'CLEARCREATECUSTOM'
    response: Models.CreateWfWithWfTypeRes
    status: Number,
    statusMessage: String
}

interface Search_WfWithWftype {
    type: 'SEARCH_WFWITHWFTYPE'
}

interface SearchWfWithWftype {
    type: 'SEARCHWFWITHWFTYPE'
    response: Models.CreateWfWithWfTypeReq
    status: Number,
    statusMessage: String
}

interface SearchWfWithWftypeFail {
    type: 'SEARCHWFWITHWFTYPEFAIL'
    status: number,
    statusMessage: any
}

interface ClearSearchWfWithWftype {
    type: 'CLEARSEARCHWFWITHWFTYPE'
}

type KnownAction = LoadCreateWfWithWfTyp | FailLoadCreateWfWithWfTyp | ClearCreateWfWithWfTyp | SearchWfWithWftype | SearchWfWithWftypeFail | ClearSearchWfWithWftype | Search_WfWithWftype

export const actionCreators = {
    requestCreateWfWithWfTyp: (p: boolean, method: string, wf_approve_list: Array<Models.wf_approve_list>, wf_header: string, wf_detail: string, list_file: any, app_id: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestCreateworkflow')
        if (appState && appState.createCustom && wf_approve_list && p && method == 'POST') {
            var list: any = []
            for (var i = 0; i < wf_approve_list.length; i++) {
                var list_id: any = []
                for (var a = 0; a < wf_approve_list[i]['wf_approve_list'].length; a++) {
                    list_id.push(wf_approve_list[i]['wf_approve_list'][a].wf_id)
                }
                list.push(list_id)
            }
            console.log("list :")
            //var list: any = []
            //list.push(listInner);
            console.log(list)

            var localUserId = ""
            if (localStorage.getItem("WF_APPLICATION") != undefined) {

                var ls: any = localStorage.getItem("WF_APPLICATION");
                var jls: any = JSON.parse(ls)
                var access_token = parseJwt(jls.access_token)
                var extra = JSON.parse(access_token.extra)
                console.log(extra)

                if (extra.citizen_id) {
                    console.log('Azure Login ID: ' + extra.citizen_id)
                    localUserId = extra.citizen_id
                } else if (extra.username) {
                    console.log('Local Login ID: ' + extra.sid)
                    localUserId = extra.username
                }
            }

            var body = {
                'doc_subject': wf_header,
                'requestor_message': wf_detail,
                'requestor_id': localUserId,
                'wf_approve_list': list,
                'user_login_id': localUserId,
                'sid': "",
                'companycode': "",
                'list_dms': JSON.stringify(list_file),
                'app_id': app_id
            }
            console.log(body)
            await fetch(`/v1/Workflow/CreateWfWithWfTyp`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
                body: JSON.stringify(body),
            })
                .then(response => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('dispatch : LOADFAILCREATECUSTOM')
                            dispatch({ type: 'LOADFAILCREATECUSTOM', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            dispatch({ type: 'LOADCREATECUSTOM', response: data as Models.CreateWfWithWfTypeRes, status: 200, statusMessage: '' });
                        })
                    }
                })
        } else if (method == "CLEAR") {
            dispatch({ type: 'CLEARCREATECUSTOM', response: [] as any, status: 400, statusMessage: '' });
        }
    },

    requestSearchWfWithWftype: (p: boolean, method: string, wf_type: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestSearchWfWithWftype')
        if (appState && appState.createCustom && p && method == 'POST') {
            await fetch(`/v1/Workflow/SearchWfWithWfType?Wf_Type=` + wf_type, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
            })
                .then(response => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('dispatch : SEARCHWFWITHWFTYPEFAIL')
                            dispatch({ type: 'LOADFAILCREATECUSTOM', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            dispatch({ type: 'SEARCHWFWITHWFTYPE', response: data as Models.CreateWfWithWfTypeReq, status: 200, statusMessage: '' });
                        })
                    }
                })
            dispatch({ type: 'SEARCH_WFWITHWFTYPE' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARSEARCHWFWITHWFTYPE' });
        }
    }
}

const unloadedState: Models.CreateWfWithWfTypeState = { isLoadCreateWfWithWfType: false, responseCreateWfWithWfType: [] as any };
const unloadedSearchState: Models.SearchWfWithWfTypeState = { isLoadSearchWfWithWfType: false, responseSearchWfWithWfType: [] as any };

const reducerAdd: Reducer<Models.CreateWfWithWfTypeState> = (state: Models.CreateWfWithWfTypeState | undefined, incomingAction: Action): Models.CreateWfWithWfTypeState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOADCREATECUSTOM':
            return {
                isLoadCreateWfWithWfType: true,
                responseCreateWfWithWfType: action.response,
                statusCreateWfWithWfType: action.status
            }

        case 'LOADFAILCREATECUSTOM':
            return {
                isLoadCreateWfWithWfType: true,
                messageCreateWfWithWfType: action.statusMessage,
                responseCreateWfWithWfType: [] as any,
                statusCreateWfWithWfType: action.status
            }
        case 'CLEARCREATECUSTOM':
            return {
                isLoadCreateWfWithWfType: false,
                responseCreateWfWithWfType: action.response,
                statusCreateWfWithWfType: action.status
            }
        default: return state;
    }
}

const reducerSearch: Reducer<Models.SearchWfWithWfTypeState> = (state: Models.SearchWfWithWfTypeState | undefined, incomingAction: Action): Models.SearchWfWithWfTypeState => {
    if (state == undefined) {
        return unloadedSearchState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOADCREATECUSTOM':
            return {
                isLoadSearchWfWithWfType: true,
                responseSearchWfWithWfType: action.response,
                statusSearchWfWithWfType: action.status
            }
        case 'LOADFAILCREATECUSTOM':
            return {
                isLoadSearchWfWithWfType: true,
                messageSearchWfWithWfType: action.statusMessage,
                responseSearchWfWithWfType: [] as any,
                statusSearchWfWithWfType: action.status
            }
        case 'CLEARCREATECUSTOM':
            return {
                isLoadSearchWfWithWfType: false,
                responseSearchWfWithWfType: [] as any,
                statusSearchWfWithWfType: 0
            }
        case 'CLEARSEARCHWFWITHWFTYPE':
            return {
                isLoadSearchWfWithWfType: false,
                responseSearchWfWithWfType: [] as any
            }
        default: return state;
    }
}

export const rootReducer = combineReducers({ AddWfWithWfType: reducerAdd, SearchWfWithWfType: reducerSearch });