import * as React from "react";
import { connect } from "react-redux";
import { Button, Row, Col } from "react-bootstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FaPlusCircle } from "react-icons/fa";
import ConditionTable from "./ConditionTable";
import AddConditionDetail from "./AddCondition";
import Swal from 'sweetalert2'

import * as AppState from '../../store/AppState'
import * as ModelsAppState from '../../models/AppState'

import * as CreateConditionMasterStore from '../../store/condition/CreateConditionID';
import * as CreateConditionMasterModels from '../../models/condition/CreateConditionID'
import { ApplicationState } from "../../store";

type CreateConditionProps = CreateConditionMasterModels.CreateConditionIDState & typeof CreateConditionMasterStore.actionCreators;
type AppStateProps = ModelsAppState.AppState & typeof AppState.actionCreators
class ConditionMaster extends React.Component<any, { checkSetEditModal: any, checkEditModal: any, checkSuccess: boolean, showModal: boolean, handleClose: boolean, requestValue: boolean, formData: {}, updateValue: string, checkupdateID: boolean, readonlyUpdate: boolean, company?: string, key: string },
    CreateConditionProps & AppStateProps> {
    constructor(props: any) {
        super(props);
        this.blindConditionValue = this.blindConditionValue.bind(this)
        this.blindUpdateCondition = this.blindUpdateCondition.bind(this)

        this.state = {
            showModal: false,
            handleClose: false,
            requestValue: false,
            updateValue: '',
            checkupdateID: false,
            formData: {},
            readonlyUpdate: false,
            company: undefined,
            key: '',
            checkSuccess: false,
            checkEditModal: false,
            checkSetEditModal: false
        }
    }

    componentDidMount() {
        this.props.updateUrl(this.props.location.pathname)
        var company = this.props.company
        if (company != this.state.company) {
            this.setState({
                company: company
            });
        }
    }

    onsubmit = () => {
        console.log("submit");
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        // return <Redirect to="/addpositionemployee" />;
    };

    handleClose = () => {
        console.log(this.state.showModal)
        if (this.state.showModal == false) {
            this.setState({
                showModal: true
            })
        } else {
            this.setState({
                showModal: false,
                checkupdateID: false,
                readonlyUpdate: false
            })
        }
    }

    blindConditionValue = (value: any) => {
        this.setState({
            formData: value
        })
    }

    blindUpdateCondition = (value: any) => {
        if (value != undefined) {
            this.setState({
                checkupdateID: true,
                updateValue: value,
                readonlyUpdate: true,
                checkSetEditModal: true,
                key: ''
            })
            this.handleClose()
        }
    }

    onsaveCondition = () => {
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        this.setState({
            checkEditModal: true,
            requestValue: true,
            checkSuccess: true,
            readonlyUpdate: false,
            key: ''

        })
        console.log(this.state.company)
        console.log(this.state.formData)
        setTimeout(() => {
            if (!this.state.checkupdateID) {
                this.props.requestCreateConditionMaster(this.state.requestValue, "POST", this.state.formData, this.state.company)
            } else {
                console.log("update action")
                this.props.requestCreateConditionMaster(this.state.requestValue, "PUT", this.state.formData, this.state.company)
            }
        }, 1000);
    }

    callbackkey() {
        this.setState({
            key: ''
        })
    }

    clearCheckEditModal = (value) => {
        if (value == "Edit") {
            this.setState({ checkEditModal: false })
        } else if (value == "setEdit") {
            this.setState({ checkSetEditModal: false })
        }
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { updateValue, checkupdateID } = this.state
        const { statusCreate, messageCreate, isLoad } = this.props
        var company = this.props.company
        if (company != this.state.company) {
            this.setState({
                company: company
            });
        }
        if (statusCreate == 200 && this.state.checkSuccess) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'สำเร็จ!',
                text: 'เพิ่มข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {
                if (result.value) {
                    // return <Redirect to="/positiongroup" />;
                    //window.location.href = "/conditionmaster"
                    this.setState({
                        showModal: false,
                        key: 'refresh',
                        checkSuccess: false,
                        checkupdateID: false
                    })
                    this.props.requestCreateConditionMaster(true, "CLEAR", "", "")
                    //this.props.history.push('/conditionmaster')
                }
            })
        } else if (statusCreate != 200 && isLoad) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            var msg = JSON.parse(messageCreate);
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {
                console.log('Delete Fail :')
            })
        }
    }

    render() {
        const { requestValue, key } = this.state
        console.log("this.state.checkupdateID")
        console.log(this.state.checkupdateID)
        return (
            <div>
                <div className="master-data-content">
                    <div className='trapezoid-head' style={{ width: '200px' }}>
                        <span>
                            Parameter Master
                        </span>
                    </div>
                    <div className="mat-box">
                        <Row>
                            <Col>
                                <div className='trapezoid trapezoid-purple' style={{ display: 'inline-block', width: 'fit-content' }}>
                                    <span>
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                        Parameter Master
                                    </span>
                                </div>
                                <div style={{ display: 'inline-block', float: 'right' }}>
                                    <Button variant="outline-info" size="sm" onClick={this.handleClose}>
                                        <FaPlusCircle />
                                        &nbsp;Add Parameter
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ConditionTable blindUpdateCondition={this.blindUpdateCondition.bind(this)} key={this.state.key} callbackkey={this.callbackkey.bind(this)} />
                            </Col>
                        </Row>
                    </div>
                </div>
                <Modal isOpen={this.state.showModal} toggle={this.handleClose} size="lg">
                    <ModalHeader toggle={this.handleClose}>Add Parameter</ModalHeader>
                    <ModalBody>
                        <AddConditionDetail checkSetEditModal={this.state.checkSetEditModal} clearCheckEditModal={this.clearCheckEditModal.bind(this)} checkEditModal={this.state.checkEditModal} requestValue={this.state.requestValue} blindConditionValue={this.blindConditionValue.bind(this)} updateValue={this.state.updateValue} checkupdateID={this.state.checkupdateID} readonlyUpdate={this.state.readonlyUpdate} />
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="primary" onClick={this.onsaveCondition}>Save</Button>
                        <Button variant="danger" onClick={this.handleClose}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({ ...state.appState, ...state.createCondtiotionID }), ({ ...CreateConditionMasterStore.actionCreators, ...AppState.actionCreators }))(ConditionMaster);
