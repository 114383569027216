import * as React from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Table, InputGroup, FormControl, Form, ButtonGroup, Spinner } from "react-bootstrap";
import { FaPlusCircle, FaKey, FaTrashAlt, FaRegCopy } from 'react-icons/fa';
import Swal from 'sweetalert2'
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { ApplicationState } from "../../store";
import * as CallbackConfigMasterStore from '../../store/callbackconfig/CallbackMaster'
import * as ModelsCallbackConfigMaster from '../../models/callbackconfig/CallbackMaster'

import * as AppState from '../../store/AppState'
import * as ModelsAppState from '../../models/AppState'

import * as CallbackTokenStore from '../../store/callbackconfig/CallbackTokens'
import * as ModelsCallbackTokens from '../../models/callbackconfig/CallbackTokens'

import * as CreateCallbackStore from '../../store/callbackconfig/CreateCallback'
import * as ModelsCreateCallback from '../../models/callbackconfig/CreateCallback'

type CallbackConfigProps = ModelsCallbackConfigMaster.CallbackMasterState & typeof CallbackConfigMasterStore.actionCreators;
type CallbackTokenProps = ModelsCallbackTokens.CallbackTokenState & typeof CallbackTokenStore.actionCreators;
type CreateCallbackProps = ModelsCreateCallback.SaveCallbackState & typeof CreateCallbackStore.actionCreators;
type AppStateProps = ModelsAppState.AppState & typeof AppState.actionCreators

export interface initRow {
    id: string,
    name: string,
    token: string,
}

class AddConfigMaster extends React.Component<any, { rows: Array<ModelsCallbackConfigMaster.Callback>, checkRequest: boolean, checkResponse: boolean, checkGenerate: boolean, textarea: any, requestrows: Array<ModelsCreateCallback.CallbackReq>, deleterows: Array<ModelsCreateCallback.CallbackReq>, copied: boolean, company?: string },
    CallbackConfigProps & CallbackTokenProps & CreateCallbackProps & AppStateProps>{
    //private  textInput = React.useRef<HTMLInputElement>(null);
    constructor(props: any) {
        super(props);
        this.state = {
            rows: [],
            requestrows: [],
            deleterows: [],
            checkRequest: true,
            checkResponse: true,
            checkGenerate: false,
            textarea: "",
            copied: false,
            company: undefined
        }
    }

    componentDidMount() {
        this.props.updateUrl(this.props.location.pathname)
        var company = this.props.company
        if (company != this.state.company && this.state.checkRequest) {
            if (company != "") {
                this.props.requestCallbackMaster(true, "POST", company)
            }

            this.setState({
                company: company,
                checkRequest: false,
            });
        }
    }

    componentWillUnmount() {
        this.props.requestCallbackMaster(true, "CLEAR", "")
    }

    handleAddRow = () => {
        const item = {
            id: "",
            app_id: "",
            name: "",
            token: "",
            endpoint: "",
            option_sign_pdf: "",
            option_buffer_pdf: "",
            option_link_approve: "",
            created_by: "",
            created_on: ""
        };
        this.setState({
            rows: [...this.state.rows, item]
        });
        setTimeout(() => {
            console.log(this.state.rows)
        }, 1000);
    };

    handleRemoveSpecificRow = (idx: number) => () => {
        const rows = [...this.state.rows]
        rows.splice(idx, 1)
        this.setState({ rows })
        var deletesub: Array<ModelsCreateCallback.CallbackReq> = [];
        var landingrow = {
            id: this.state.rows[idx].app_id,
            token: this.state.rows[idx].token,
            name: this.state.rows[idx].name,
            endpoint: this.state.rows[idx].endpoint,
            option_sign_pdf: this.state.rows[idx].option_sign_pdf,
            option_buffer_pdf: this.state.rows[idx].option_buffer_pdf,
            option_link_approve: this.state.rows[idx].option_link_approve
        };
        deletesub.push(landingrow);
        this.setState({
            deleterows: deletesub
        })
    }

    handleChange = (idx: number, mode: String) => (e: { target: { name: any; value: any; } }) => {
        const { name, value } = e.target;
        const rows = [...this.state.rows];
        console.log(mode)
        if (mode == "Token") {
            const item = {
                app_id: rows[idx].app_id,
                id: rows[idx].id,
                name: rows[idx].name,
                token: value,
                endpoint: rows[idx].endpoint,
                option_sign_pdf: rows[idx].option_sign_pdf,
                option_buffer_pdf: rows[idx].option_buffer_pdf,
                option_link_approve: rows[idx].option_link_approve,
                created_by: rows[idx].created_by,
                created_on: rows[idx].created_on
            };
            rows[idx] = item;
            this.setState({
                rows
            });
        } else if (mode == "Name") {
            const item = {
                id: rows[idx].id,
                app_id: rows[idx].app_id,
                name: value,
                token: rows[idx].token,
                endpoint: rows[idx].endpoint,
                option_sign_pdf: rows[idx].option_sign_pdf,
                option_buffer_pdf: rows[idx].option_buffer_pdf,
                option_link_approve: rows[idx].option_link_approve,
                created_by: rows[idx].created_by,
                created_on: rows[idx].created_on
            };
            rows[idx] = item;
            this.setState({
                rows
            });
        } else if (mode == "Callback") {
            const item = {
                id: rows[idx].id,
                app_id: rows[idx].app_id,
                name: rows[idx].name,
                token: rows[idx].token,
                endpoint: value,
                option_sign_pdf: rows[idx].option_sign_pdf,
                option_buffer_pdf: rows[idx].option_buffer_pdf,
                option_link_approve: rows[idx].option_link_approve,
                created_by: rows[idx].created_by,
                created_on: rows[idx].created_on
            };
            rows[idx] = item;
            this.setState({
                rows
            });
        }
    }

    onsubmit = () => {
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        var rowsub: Array<ModelsCreateCallback.CallbackReq> = [];
        rowsub = this.state.requestrows
        for (var i = 0; i < this.state.rows.length; i++) {
            //rowsub[i].id = this.state.rows[i].id
            //rowsub[i].name = this.state.rows[i].name
            //rowsub[i].token = this.state.rows[i].token
            //rowsub[i].endpoint = this.state.rows[i].endpoint
            var landingrow = {
                id: this.state.rows[i].id,
                app_id: this.state.rows[i].app_id,
                token: this.state.rows[i].token,
                name: this.state.rows[i].name,
                endpoint: this.state.rows[i].endpoint,
                option_sign_pdf: this.state.rows[i].option_sign_pdf,
                option_buffer_pdf: this.state.rows[i].option_buffer_pdf,
                option_link_approve: this.state.rows[i].option_link_approve
            };
            rowsub.push(landingrow);
        }
        this.setState({
            requestrows: rowsub,
            rows: []
        })
        console.log(rowsub)
        this.props.requestCreateCallback(true, "POST", rowsub, this.state.company);

    }

    generateToken = (id: any, idx: any) => {
        console.log(id + " : " + idx)
        if (id != undefined && id != null && id != "") {
            this.props.requestCallbackToken(true, "POST", id, idx, this.state.company)
            this.setState({
                checkGenerate: true
            })
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        } else {
            /*alertError*/
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: 'กรุณาบันทึก Name และ Callback ก่อนจึงจะสามารถ generate token ได้',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {
                if (result.value) {
                }
            })
        }
    }

    //copyCodeToClipboard = () => {
    //    const el = this.textInput
    //    //el.current
    //    document.execCommand("copy")
    //}

    onCopy = () => {
        this.setState({ copied: true });
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: 'Copy success!'
        })
    };

    componentDidUpdate(nextProps: any, nextState: any) {
        const { CallbackisLoad, Callbackstatus, Callbackresponse, CallbackTokenstatus, CallbackTokenresponse, CallbackTokenrequest, SaveCallbackstatus, SaveCallbackresponse, SaveCallbackisLoad, SaveCallbackmessage, CallbackTokenmessage } = this.props
        var company = this.props.company
        if (company != this.state.company) {
            this.props.requestCallbackMaster(true, "CLEAR", this.state.company)
            this.setState({
                company: company,
                checkResponse: true,
                rows: []
            });
            setTimeout(() => {
                if (company) {
                    this.props.requestCallbackMaster(true, "POST", company)
                }
            }, 1000);
        }
        if (CallbackisLoad && Callbackstatus == 200 && this.state.checkResponse && Callbackresponse['total_count'] != 0) {
            console.log("------ SET DATATABLE --------")
            this.setState({
                checkResponse: false,
                rows: Callbackresponse['result_list'],
            })
            //this.props.requestCallbackMaster(true, "CLEAR", this.state.company)
        }

        if (CallbackTokenstatus == 200 && this.state.checkGenerate) {
            console.log("------ SET TOKENS --------")
            this.setState({
                checkGenerate: false
            })
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            /*alertError*/
            Swal.fire({
                title: 'สำเร็จ!',
                text: 'สร้าง token เรียบร้อย',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {
                if (result.value) {
                    const rows = [...this.state.rows];
                    const item = {
                        id: rows[CallbackTokenrequest['idx']].id,
                        app_id: rows[CallbackTokenrequest['idx']].app_id,
                        name: rows[CallbackTokenrequest['idx']].name,
                        token: CallbackTokenresponse['token'],
                        endpoint: rows[CallbackTokenrequest['idx']].endpoint,
                        option_sign_pdf: rows[CallbackTokenrequest['idx']].option_sign_pdf,
                        option_buffer_pdf: rows[CallbackTokenrequest['idx']].option_buffer_pdf,
                        option_link_approve: rows[CallbackTokenrequest['idx']].option_link_approve,
                        created_by: rows[CallbackTokenrequest['idx']].created_by,
                        created_on: rows[CallbackTokenrequest['idx']].created_on
                    };
                    rows[CallbackTokenrequest['idx']] = item;
                    this.props.requestCallbackToken(true, "CLEAR", "", 0, this.state.company)
                    this.props.requestCreateCallback(true, "POST", rows, this.state.company);
                    this.setState({
                        rows,
                        checkResponse: true
                    });
                    document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
                }
            })
        } else if (CallbackTokenstatus == 204 && this.state.checkGenerate) {
            this.setState({
                checkGenerate: false
            })
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            console.log("------ SET TOKENS FAIL--------")
            var msg = JSON.parse(CallbackTokenmessage);
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestCallbackToken(true, "CLEAR", "", 0, this.state.company)
                }
            })
        }

        if (SaveCallbackstatus == 200 && SaveCallbackresponse['total_count'] != 0 && SaveCallbackisLoad) {
            console.log("------ SET CALLBACK SUCCESS --------")
            this.setState({
                requestrows: [],
                rows: [],
            })
            this.props.requestCreateCallback(true, "CLEAR", this.state.requestrows, this.state.company);
            this.props.requestCallbackMaster(true, "CLEAR", this.state.company)
            this.props.requestCallbackMaster(true, "POST", this.state.company)
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            /*alertError*/
            Swal.fire({
                title: 'สำเร็จ!',
                text: 'บันทึกข้อมูลเรียบร้อย',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.setState({
                        checkResponse: true
                    })
                }
            })
        } else if (SaveCallbackstatus == 204 && SaveCallbackisLoad) {
            console.log("------ SET CALLBACK FAIL --------")
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            this.props.requestCreateCallback(true, "CLEAR", this.state.requestrows, this.state.company);
            /*alertError*/
            var msg = JSON.parse(SaveCallbackmessage);
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: '' + msg.message + '',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.setState({
                        checkResponse: true
                    })
                    this.props.requestCallbackMaster(true, "CLEAR", this.state.company)
                    this.props.requestCreateCallback(true, "CLEAR", this.state.requestrows, this.state.company);
                    this.props.requestCallbackMaster(true, "POST", this.state.company)
                }
            })
        }
    }

    render() {
        return (
            <div>
                <div className="master-data-content">
                    <div className='trapezoid-head' style={{ width: '200px' }}>
                        <span>
                            Callback Master
                        </span>
                    </div>
                    <div className="mat-box">
                        <Row>
                            <Col>
                                <div className="container">
                                    <div className='trapezoid trapezoid-purple' style={{ display: 'inline-block', width: 'fit-content' }}>
                                        <span>
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                            Callback Master
                                        </span>
                                    </div>
                                    <div style={{ display: 'inline-block', float: 'right' }}>
                                        <Button className="mx-2" variant="outline-info" size="sm" onClick={this.onsubmit}><FaPlusCircle />&nbsp;Save</Button>
                                    </div>
                                    <Table className="table-bordered align-middle table-responsive-sm table-hover table table-sm" size="sm">
                                        <thead>
                                            <tr className='text-center thead-light' style={{ width: '100%' }}>
                                                <th style={{ width: '3%' }}>#</th>
                                                <th style={{ width: '20%' }}>Name</th>
                                                <th style={{ width: '47%' }}>Token</th>
                                                <th style={{ width: '30%' }}>Callback</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!this.props.CallbackisLoad ? <tr><td colSpan={4} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr> : <tr></tr>}
                                            {this.props.CallbackisLoad && this.state.rows.map((item, idx) => (
                                                <tr id="addr0" key={idx}>
                                                    <td className='text-center'><Button className="mx-2" variant="outline-danger" size="sm" onClick={this.handleRemoveSpecificRow(idx)}><FaTrashAlt /></Button></td>
                                                    <td>
                                                        <InputGroup className="mb-3">
                                                            <FormControl
                                                                placeholder="username"
                                                                value={this.state.rows[idx].name}
                                                                onChange={this.handleChange(idx, 'Name')}
                                                            />
                                                        </InputGroup>
                                                    </td>
                                                    <td>
                                                        <InputGroup className="mb-3">
                                                            <Form.Control
                                                                as="textarea"
                                                                rows={3}
                                                                readOnly={true}
                                                                placeholder=""
                                                                value={this.state.rows[idx].token}
                                                                onChange={this.handleChange(idx, 'Token')}
                                                                id="tokenText"
                                                            //ref={this.textInput}
                                                            />
                                                            <InputGroup.Append>
                                                                <ButtonGroup vertical>
                                                                    <Button variant="outline-info" onClick={() => this.generateToken(item.id, idx)}><FaKey /></Button>
                                                                    <CopyToClipboard onCopy={this.onCopy} text={this.state.rows[idx].token}>
                                                                        <Button variant="outline-primary"><FaRegCopy /></Button>
                                                                    </CopyToClipboard>
                                                                </ButtonGroup>
                                                            </InputGroup.Append>
                                                        </InputGroup>
                                                    </td>
                                                    <td>
                                                        <FormControl
                                                            placeholder=""
                                                            value={this.state.rows[idx].endpoint}
                                                            onChange={this.handleChange(idx, 'Callback')}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td className='text-center'><Button className="mx-2" variant="outline-info" size="sm" onClick={this.handleAddRow}><FaPlusCircle /></Button></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({ ...state.appState, ...state.callbackConfig, ...state.callbackTokens, ...state.createCallback }), ({ ...CallbackConfigMasterStore.actionCreators, ...CallbackTokenStore.actionCreators, ...CreateCallbackStore.actionCreators, ...AppState.actionCreators }))(AddConfigMaster)