import React from 'react';

import BorderGrid from 'common/BorderGrid';
import { ResponseWorkflowInboxValue } from 'models/workflow/SearchWorkflowInbox';
import { formatYMDToDMY, numberWithCommas } from 'modules/Common';

type Props = {
    document?: ResponseWorkflowInboxValue;
}

function WorkflowInformationGrid(props: Props) {
    const { document } = props;

    if (!document) {
        return null;
    }

    return (
        <React.Fragment>
            <BorderGrid>
                <BorderGrid.Row>
                    <BorderGrid.Col>
                        <div>
                            <label>Subject</label>
                        </div>
                        <div>
                            <b>{document['subject']}</b>
                        </div>
                    </BorderGrid.Col>
                    <BorderGrid.Col>
                        <div>
                            <label>Document Type</label>
                        </div>
                        <div>
                            <b>{document['docType']}</b>
                        </div>
                    </BorderGrid.Col>
                </BorderGrid.Row>

                <BorderGrid.Row>
                    <BorderGrid.Col>
                        <div>
                            <label>Document No.</label>
                        </div>
                        <div>
                            <b>{document['docNo']}</b>
                        </div>
                    </BorderGrid.Col>
                    {document['projectName'] &&
                        <BorderGrid.Col>
                            <div>
                                <label>Project Name</label>
                            </div>
                            <div>
                                <b>{document['projectName']}</b>
                            </div>
                        </BorderGrid.Col>
                    }
                    {document['jobName'] &&
                        <BorderGrid.Col>
                            <div>
                                <label>Job Name</label>
                            </div>
                            <div>
                                <b>{document['jobName']}</b>
                            </div>
                        </BorderGrid.Col>
                    }
                </BorderGrid.Row>

                <BorderGrid.Row>
                    <BorderGrid.Col>
                        <div>
                            <label>Year</label>
                        </div>
                        <div>
                            <b>{document['docYear']}</b>
                        </div>
                    </BorderGrid.Col>
                    <BorderGrid.Col>
                        <div>
                            <label>Document Date</label>
                        </div>
                        <div>
                            <b>{formatYMDToDMY(String(document['docDate']))}</b>
                        </div>
                    </BorderGrid.Col>
                </BorderGrid.Row>

                <BorderGrid.Row>
                    <BorderGrid.Col>
                        <div>
                            <label>Requestor</label>
                        </div>
                        <div>
                            <b>{document['requestorName']}</b>
                        </div>
                    </BorderGrid.Col>
                    <BorderGrid.Col>
                        <div>
                            <label>Requestor Amount</label>
                        </div>
                        <div>
                            <b>{numberWithCommas(document['docAmount'])}&nbsp;{document['docCurrency']}</b>
                        </div>
                    </BorderGrid.Col>
                </BorderGrid.Row>

                <BorderGrid.Row>
                    <BorderGrid.Col>
                        <div>
                            <label>Current approval</label>
                        </div>
                        <div>
                            <b>{document['curtaskid'] + '/' + document['maxtaskid']}</b>
                        </div>
                    </BorderGrid.Col>
                    <BorderGrid.Col>
                        <div>
                            <label>Message from requested</label>
                        </div>
                        <div>
                            <b>{document['requestorMessage']}</b>
                        </div>
                    </BorderGrid.Col>
                </BorderGrid.Row>

                <BorderGrid.Row>
                    <BorderGrid.Col>
                        <div>
                            <label>Current approval</label>
                        </div>
                        <div>
                            <b>{document['curtaskid'] + '/' + document['maxtaskid']}</b>
                        </div>
                    </BorderGrid.Col>
                    <BorderGrid.Col>
                        <div>
                            <label>Message from requested</label>
                        </div>
                        <div>
                            <b>{document['requestorMessage']}</b>
                        </div>
                    </BorderGrid.Col>
                </BorderGrid.Row>
            </BorderGrid>
        </React.Fragment>
    );
}

export default WorkflowInformationGrid;