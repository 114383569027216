import { DatasourceReturnType, ResponseError } from "models/common/respone.model";
import { retrieveWorkflowAssignment } from "../datasources/AssignmentRouting.datasource";
import { AssignmentRoutingParams, AssignmentRoutingResponse } from "../schemas/AssignmentRouting.schema";
import { ResponseList } from "models/Response";

export async function getAssignmentRouting(params: AssignmentRoutingParams): Promise<
    DatasourceReturnType<ResponseError, ResponseList<AssignmentRoutingResponse>>
> {
    try {
        const data = (await retrieveWorkflowAssignment(params)).data;

        return [null!, data];
    } catch (error) {
        throw error;
    }
}