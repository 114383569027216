import * as React from "react";
import { connect } from "react-redux";
import Select from 'react-dropdown-select';
import { ApplicationState } from "../../store";

import * as AutocompleteWorkflowParamStore from '../../store/autocomplete/ParameterID'
import * as Models from '../../models/autocomplete/ParameterID'

type autocompleteparamProps = Models.GetParameterState & typeof AutocompleteWorkflowParamStore.actionCreators

export interface inititem {
    conditionid: string,
    conditionname: string
};

class AutcoCompleteConditionID extends React.Component<any, { response: any, p: boolean, dataResult: Array<inititem>, dataSearch: {}, checkSearch: boolean, checkSearch2: boolean, checkResult: boolean, company? : string }, autocompleteparamProps> {
    constructor(props: any) {
        super(props);
        this.state = {
            response: "",
            p: true,
            dataResult: [],
            dataSearch: {},
            checkSearch: true,
            checkSearch2: true,
            checkResult: true,
            company: undefined
        }
    }

    componentDidMount() {
        var company = this.props.company

        if (company) {
            this.props.requestParameterSearch(this.state.p, "POST", company )
        }

        this.setState({
            company: company,
            p: false
        });
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { status, response, valueData, valueWorflow } = this.props
        if (valueData != undefined && this.state.checkSearch) {
            var blindAuto = this.state.dataSearch
            var landingauto: inititem = {
                conditionid: valueData['parameter_code'],
                conditionname: valueData['parameter_name'],
            }
            blindAuto = landingauto
            this.setState({
                dataSearch: blindAuto,
                checkSearch: false
            })
        } else if (valueWorflow != "" && this.state.checkSearch2) {

            // var workflowcondi : any = this.state.dataResult.find((opt: Models.ParameterSearch) => opt.conditionid === valueWorflow);
            // console.log(workflowcondi);
            var blindAuto = this.state.dataSearch
            var landingauto: inititem = {
                conditionid: valueWorflow,
                conditionname: valueWorflow,
            }
            blindAuto = landingauto
            this.setState({
                dataSearch: blindAuto,
                checkSearch2: false
            })
        }
    }

    render() {
        const { status, response, valueData, valueWorflow, blockData } = this.props
        if (status == 200 && this.state.p == false && this.state.checkResult && response['result_list'] != null) {
            var autocompleterow: Array<inititem> = []
            for (var i = 0; i < response['result_list'].length; i++) {
                autocompleterow = this.state.dataResult
                var landingrow: inititem = {
                    conditionid: response['result_list'][i]['conditionid'],
                    conditionname: response['result_list'][i]['conditionid'] + " : " + response['result_list'][i]['conditionname'],
                }
                autocompleterow.push(landingrow)
            }
            this.setState({
                dataResult: autocompleterow,
                checkResult: false
            })
        }
        return (
            <Select
                key="conditionid"
                options={this.state.dataResult}
                searchBy="conditionid"
                labelField="conditionname"
                valueField="conditionid"
                values={[this.props.valueData]}
                //values={[this.state.dataSearch]}
                dropdownGap={5}
                required={true}
                onChange={(value) => this.props.handlechageCondition(value)}
            />
        )
    }
}
export default connect((state: ApplicationState) => ({ ...state.autocompleteParam, ...state.appState }), AutocompleteWorkflowParamStore.actionCreators)(AutcoCompleteConditionID)