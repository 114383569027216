import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

import * as Models from '../../models/group/DeleteGroup'

interface LoadDeletedGroup {
    type: 'LOAD_DELETE_GROUP'
}

interface DeletedGroup {
    type: 'DELETEDGROUP'
    response: Models.ResponseDeleteGrouo
    statusDelete: number
}

interface DeleteFail {
    type: 'DELETEGROUPFAIL'
    statusDelete: number,
    message : string
}

interface ClearDelete {
    type: 'CLEARDELETE'
}

type KnownAction = DeletedGroup | DeleteFail | ClearDelete | LoadDeletedGroup

export const actionCreators = {
    requestdeleteGroup: (p: boolean, method: String, position_group_id: string , company : string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestdeleteGroup')
        if (appState && appState.deleteGroup && p && method == 'POST') {
            await fetch(`/v2/Group/delete?groupID=` + position_group_id + '&app_id=' + company, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                    'Accept': 'application/json',
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('errorrrr')
                            dispatch({ type: 'DELETEGROUPFAIL', statusDelete: 204, message : err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            dispatch({ type: 'DELETEDGROUP', response: data as Models.ResponseDeleteGrouo, statusDelete: 200 });
                        })
                    }
                })
            dispatch({ type: 'LOAD_DELETE_GROUP' });

        } else if (method == 'CLEAR') {
            console.log('unloadstate')
            dispatch({ type: 'CLEARDELETE' });
        }
    }
}

const unloadedState: Models.DeleteGroupState = { isLoadDeleteGroup: false, statusDeleteGroup: 0 };

export const reducer: Reducer<Models.DeleteGroupState> = (state: Models.DeleteGroupState | undefined, incomingAction: Action): Models.DeleteGroupState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'DELETEDGROUP':
            return {
                isLoadDeleteGroup: true,
                responseDeleteGroup: action.response,
                statusDeleteGroup: action.statusDelete

            }
        case 'DELETEGROUPFAIL':
            return {
                isLoadDeleteGroup: true,
                statusDeleteGroup: action.statusDelete,
                messageDeleteGroup: action.message
            }

        case 'CLEARDELETE':
            return {
                isLoadDeleteGroup: false,
                statusDeleteGroup: 0
            }

        default: return state;
    }
}