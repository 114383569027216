import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

import * as Models from '../../models/employee/CreateEmployee'

interface CreatePositionEmployee {
    type: 'CREATEPOSITIONEMPLOYEE'
    response: Models.ResCreatePositionEmployee
    status: Number,
    statusMessage: String
}
interface FailCreatePositionEmployee {
    type: 'CREATEFAIL'
    status: number,
    statusMessage: any
}
interface ClearCreatePositionEmployee {
    type: 'CLEARCREATEPOSITIONEMPLOYEE'
}
interface CreatedPositionEmployee {
    type: 'CREATEDPOSITIONEMPLOYEE'
}


type KnownAction = CreatePositionEmployee | FailCreatePositionEmployee | ClearCreatePositionEmployee | CreatedPositionEmployee

export const actionCreators = {
    requestCreatePositionemployee: (p: boolean, method: String, username: string, formData: Array<Models.CreateEmployee>, app_id : string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        console.log('call actionCreators : requestCreatePositionemployee')
        const appState = getState();
        if (appState && appState.createEmployee && p && method == 'POST') {
            console.log(formData)
            var body = {
                'username': username,
                'position_employee_list': formData,
            }
            console.log(body)
            await fetch(`/v2/configuration/position-employee?app_id=` + app_id, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
                body: JSON.stringify(body),
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('dispatch : CREATEFAIL')
                            dispatch({ type: 'CREATEFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            console.log('dispatch : CREATEPOSITIONEMPLOYEE')
                            dispatch({ type: 'CREATEPOSITIONEMPLOYEE', response: data as Models.ResCreatePositionEmployee, status: 200, statusMessage: '' });
                        })
                    }
                })
            dispatch({ type: 'CREATEDPOSITIONEMPLOYEE' });
        } else if (method == "CLEAR") {
            dispatch({ type: 'CLEARCREATEPOSITIONEMPLOYEE'});
        }
    }
}

const unloadedState: Models.ResCreatePositionEmployeeState = { isLoadCreateEmployee: false };

export const reducer: Reducer<Models.ResCreatePositionEmployeeState> = (state: Models.ResCreatePositionEmployeeState | undefined, incomingAction: Action): Models.ResCreatePositionEmployeeState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'CREATEPOSITIONEMPLOYEE':
            return {
                isLoadCreateEmployee: true,
                responseCreateEmployee: action.response,
                statusCreateEmployee: action.status

            }
        case 'CREATEFAIL':
            return {
                isLoadCreateEmployee: true,
                messageCreateEmployee: action.statusMessage,
                statusCreateEmployee: action.status
            }
        case 'CLEARCREATEPOSITIONEMPLOYEE':
            return {
                isLoadCreateEmployee: false,
                messageCreateEmployee: '',
                statusCreateEmployee: 400
            }
        case 'CREATEDPOSITIONEMPLOYEE':
            return {
                isLoadCreateEmployee: false,
            }
        default: return state;
    }
}