import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/type/CreateTypeMaster'

interface CreateTypeMaster {
    type: 'CREATETYPEMASTER'
    response: Models.ResCreateType
    status: Number,
    statusMessage: String
}
interface FailTypeMaster {
    type: 'CREATEFAIL'
    status: number,
    statusMessage: any
}
interface ClearTypeMaster {
    type: 'CLEARTYPE'
    status: number,
}


type KnownAction = CreateTypeMaster | FailTypeMaster | ClearTypeMaster

export const actionCreators = {
    requestCreateTypeMaster: (p: boolean, method: String, formData: Array<Models.ReqCreateType>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.positionGroup && p && method == 'POST') {
            console.log('call actionCreators : requestCreateTypeMaster')
            fetch(`/v1/Type/Create`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
                body: JSON.stringify(formData),
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('errorrrr')
                            dispatch({ type: 'CREATEFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            dispatch({ type: 'CREATETYPEMASTER', response: data as Models.ResCreateType, status: 200, statusMessage: '' });
                        })
                    }
                })
        } else if (method == "PUT") {
            console.log('call actionCreators : requestUpdateTypeMaster')
            fetch(`/v1/Type/Update`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
                body: JSON.stringify(formData),
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('errorrrr')
                            dispatch({ type: 'CREATEFAIL', status: 204, statusMessage: err });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            dispatch({ type: 'CREATETYPEMASTER', response: data as Models.ResCreateType, status: 200, statusMessage: '' });
                        })
                    }
                })
        } else if (method == 'CLEAR') {
            console.log('unloadstate')
            dispatch({ type: 'CLEARTYPE', status: 400 });
        }
    }
}

const unloadedState: Models.CreateTypeState = { isLoadCreateType: false };

export const reducer: Reducer<Models.CreateTypeState> = (state: Models.CreateTypeState | undefined, incomingAction: Action): Models.CreateTypeState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'CREATETYPEMASTER':
            return {
                isLoadCreateType: true,
                responseCreate: action.response,
                statusCreate: action.status
            }

        case 'CREATEFAIL':
            return {
                isLoadCreateType: true,
                messageCreate: action.statusMessage,
                statusCreate: action.status
            }
        case 'CLEARTYPE':
            return {
                isLoadCreateType: false,
                statusCreate: 400
            }
        default: return state;
    }
}