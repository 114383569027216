import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import * as React from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { FaCheck, FaCheckCircle, FaCircle, FaCircleNotch, FaSync, FaTimesCircle } from "react-icons/fa";
import { connect } from "react-redux";
import { Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Swal from 'sweetalert2';
import * as ModelsAppState from '../../models/AppState';
import * as ModelApproveRejectWorkflow from '../../models/workflow/ApproveRejectWorkflow';
import * as ModelSearchWorkflowInbox from '../../models/workflow/SearchWorkflowInbox';
import * as ModelsWorkflowInbox from '../../models/workflow/WorkflowInbox';
import * as ModelsUniqueWorkflowType from '../../models/workflow/WorkflowType';
import { parseJwt } from '../../modules/Common';
import { ApplicationState } from "../../store";
import * as AppState from '../../store/AppState';
import * as ApproveRejectWorkflowStore from '../../store/workflow/ApproveRejectWorkflow';
import * as SearchWorkflowInboxStore from '../../store/workflow/SearchWorkflowInbox';
import * as WorkflowInboxStore from '../../store/workflow/WorkflowInbox';
import * as UniqueWorkflowTypeStore from '../../store/workflow/WorkflowType';
import * as MasterCompanyStore from '../../store/company/MasterCompany'
import * as MasterCompanyModels from '../../models/company/MasterCompany'
import WorkflowInboxTable from './WorkflowInboxTable';
import WorkflowSearchIdTab from './WorkflowSearchIdTab';

export interface batchModel {
    app_id: string,
    user_id: string,
    wf_type: string,
    doc_no_list: string[],
    instance_no_list: string[]
}

type Store = ModelSearchWorkflowInbox.GetWorkflowInboxValueState & typeof SearchWorkflowInboxStore.actionCreators & ModelsUniqueWorkflowType.GetWorkflowTypeState & typeof UniqueWorkflowTypeStore.actionCreators
type AppStateProps = ModelsAppState.AppState & typeof AppState.actionCreators

class WorkflowInbox extends React.Component<any, {
    userId: string,
    token: string,
    updateKey: any,
    docNoList: any,
    modeApproveReject: string,
    message: string,
    messageModal: boolean,
    checkRequest: boolean,
    checkResponse: boolean,
    ResponseTable: Array<ModelsWorkflowInbox.WorkflowInbox>,
    countResponse: any,
    key: string,
    uniqueWfType: any,
    wfTypeColour: any,
    pageTab: any,
    countTab: any,
    initialTab: any,
    user_id: any,
    checkedALL: boolean,
    checkedSLSN: boolean,
    checkedSLTS: boolean,
    checkedSRIC: boolean,
    sids: string[],
    currentPage: any,
    SidArr: any,
    company?: string,
    successModal: boolean,
    successModalHeader: string,
    checkPage: any,
    searchReq: ModelsWorkflowInbox.searchValue,
    ApprovedRejectBatch: boolean,
    checkResponseTable: boolean,
    checkOnloadWorkflowinboxadmin: boolean,
    headerText: string,
    seconds: number,
    page: number
},
    Store & AppStateProps> {
    private intervalId: NodeJS.Timeout | null = null;
    private skipModal: boolean = false;
    constructor(props: any) {
        super(props)

        this.state = {
            userId: "",
            token: "",
            checkRequest: true,
            checkResponse: true,
            ResponseTable: [],
            countResponse: "",
            messageModal: false,
            message: "",
            modeApproveReject: "",
            docNoList: [],
            key: "",
            updateKey: "",
            uniqueWfType: [],
            wfTypeColour: ['#6D6875', '#B5838D', '#E5989B', '#FFB4A2', '#FFCDB2', '#CDB4DB', '#FFAFCC', '#BDE0FE', '#A2D2FF', '#0096C7'],
            pageTab: "",
            countTab: 0,
            initialTab: {},
            user_id: "",
            checkedALL: true,
            checkedSLSN: false,
            checkedSLTS: false,
            checkedSRIC: false,
            sids: ["*"],
            currentPage: 0,
            SidArr: [],
            company: undefined,
            successModal: false,
            successModalHeader: "",
            checkPage: 0,
            searchReq: {
                wfNo: "",
                //fiscalYear: new Date().getFullYear().toString(),
                fiscalYear: "",
                docAmount: "",
                docAmountTo: "",
                createBy: "",
                createByTo: "",
                requester_id: "",
                updatedFrom: "",
                updatedTo: "",
                userName: ""
            },
            ApprovedRejectBatch: false,
            checkResponseTable: true,
            checkOnloadWorkflowinboxadmin: false,
            headerText: "Workflow Inbox",
            seconds: 10,
            page: 1
        }
        this.blindSidArr = this.blindSidArr.bind(this)
        this.pageSet = this.pageSet.bind(this)
    }

    refresh = () => {
        this.setState({ page: 1 });
        // re-renders the component
        this.props.requestWorkflowTypeInbox(true, 'CLEAR', this.state.userId, this.state.sids, this.state.searchReq)
        this.props.requestWorkflowInbox(this.state.checkRequest, "CLEAR", this.state.userId, this.state.sids, 1, '', '')
        this.setState({
            key: "refresh",
            uniqueWfType: [],
            pageTab: null,
            countTab: 0,
            checkRequest: true,
            checkOnloadWorkflowinboxadmin: true
        });
        this.props.requestWorkflowTypeInbox(true, 'POST', this.state.userId, this.state.sids, this.state.searchReq)
        this.setState({ checkPage: 1 });
    };

    refreshOnUpdate = () => {
        this.setState({
            key: "refresh",
            uniqueWfType: [],
            currentPage: 0,
            countTab: 0,
            checkRequest: false
        });
        this.props.requestWorkflowInbox(this.state.checkRequest, "CLEAR", this.state.userId, this.state.sids, 1, '', '')
        this.props.requestWorkflowTypeInbox(true, 'POST', this.state.userId, this.state.sids, this.state.searchReq)
    };

    resetRefresh(key) {
        const { responseWorkflowInbox, statusWorkflowInbox, statusResponse } = this.props
        if (responseWorkflowInbox != undefined) {
            this.setState({ key: "" });
            this.setState({
                checkRequest: false,
                countResponse: "เอกสารรออนุมัติ (" + this.state.countTab + " รายการ)",
                currentPage: 0,
                countTab: 0
            })
        } else {
            this.setState({ key: "" });
            this.setState({
                checkRequest: false,
                countResponse: "เอกสารรออนุมัติ (0 รายการ)",
                currentPage: 0,
                countTab: 0
            })
        }
    }

    componentDidMount() {
        console.log("TEll Do tihs Ja")
        this.props.updateUrl(this.props.location.pathname)
        var path = window.location.pathname
        //if (this.state.checkRequest) {
        var ls: any = localStorage.getItem("WF_APPLICATION");
        var jls: any = JSON.parse(ls)
        var access_token = parseJwt(jls.access_token)
        var extra = JSON.parse(access_token.extra)

        var user_id = ""
        if (extra.citizen_id) {
            user_id = extra.citizen_id
            this.setState({ userId: extra.citizen_id, token: jls.access_token })
        } else if (extra.username) {
            user_id = extra.username
            this.setState({ userId: extra.username, token: jls.access_token })
        }
        //    this.props.requestWorkflowInbox(this.state.checkRequest, "POST", jls.user_id)
        //}
        //this.props.requestWorkflowTypeInbox(true, 'POST', jls.user_id, ["1mHUSXNKeGnm3yMz4k9vDoDBA3b"])
        if (path != "/workflowinboxadmin") {
            this.setState({
                checkOnloadWorkflowinboxadmin: true,
            })
        } else {
            this.setState({
                headerText: "Workflow Inbox Admin"
            })
        }

        var company = this.props.company
        if (company != this.state.company) {
            if (company) {
                this.props.requestWorkflowTypeInbox(true, 'POST', user_id, this.state.sids, this.state.searchReq);
            }

            this.setState({
                company: company
            });
        }
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { responseWorkflowInbox, statusWorkflowInbox, statusResponseApproveReject, responseWorkflowType, statusWorkflowType, isLoadApproveReject, messageResponseApproveReject } = this.props
        var company = this.props.company
        if (company != this.state.company) {

            this.setState({
                company: company
            });

            if (company) {
                this.props.requestWorkflowTypeInbox(true, 'POST', this.state.userId, this.state.sids, this.state.searchReq);
            }
        }
        if (statusWorkflowType == 200 && this.state.checkRequest) {
            if (responseWorkflowType['result_list'] != null) {
                var dataRs: any = []
                //var dataArr: any = {}
                var countArr: number = 0
                for (let i = 0; i < responseWorkflowType['result_list'].length; i++) {
                    countArr += parseInt(responseWorkflowType['result_list'][i].count)
                }
                for (let i = 0; i < responseWorkflowType['result_list'].length; i++) {
                    var result = dataRs.filter(item => item.wftype === responseWorkflowType['result_list'][i].wftype);
                    if (result.length > 0) {
                        result[0].count = (parseInt(result[0].count) + parseInt(responseWorkflowType['result_list'][i].count)).toString()
                    }
                    else {
                        dataRs.push(responseWorkflowType['result_list'][i])
                    }
                }

                this.setState({
                    uniqueWfType: dataRs,
                    checkRequest: false,
                    countResponse: "เอกสารรออนุมัติ (" + countArr + " รายการ)",
                    pageTab: null,
                    countTab: countArr,
                    initialTab: { wfid: null, count: countArr }
                })
                setTimeout(() => {
                }, 1000);
            } else {
                this.setState({
                    countTab: 1,
                    checkRequest: false,
                    pageTab: null,
                    countResponse: "เอกสารรออนุมัติ ( 0 รายการ)",
                })
            }
        }
        if (this.state.key != "") {
            this.setState({ checkRequest: true, countResponse: "" })
        }
        if (isLoadApproveReject && statusResponseApproveReject == 200 && (this.state.modeApproveReject == "approved" || this.state.modeApproveReject == "reject")) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            //this.props.requestsearchworkflowInbox(true, "CLEAR", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo)
            //this.props.requestApproveWorkflow(true, "CLEAR", workflowValueUse.userId, workflowValueUse.docNo, "");
            console.log("tell เข้ามาทำตรงนี้")
            this.props.requestWorkflowTypeInbox(true, 'CLEAR', this.state.userId, this.state.sids, this.state.searchReq)
            this.props.requestWorkflowTypeInbox(true, 'POST', this.state.userId, this.state.sids, this.state.searchReq)

            if (Array.isArray(this.props.responseApproveReject['result_list'])) {
                this.setState({
                    messageModal: false,
                    message: "",
                    modeApproveReject: "",
                    docNoList: [],
                    // successModal: (() => {
                    //     if (this.skipModal) {
                    //         this.skipModal = false

                    //         return false
                    //     }

                    //     return true
                    // })(),
                    successModal: this.skipModal ? (this.skipModal = false) : true,
                    // successModal: !(this.skipModal = false),
                    ApprovedRejectBatch: true
                })
            }
            else {
                this.setState({
                    messageModal: false,
                    message: "",
                    modeApproveReject: "",
                    docNoList: [],
                    ApprovedRejectBatch: true
                })
                setTimeout(() => {
                    Swal.fire({
                        title: 'ไม่สำเร็จ!',
                        text: 'ไม่พบการส่งข้อมูล Instance Number',
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'ตกลง',
                        allowOutsideClick: false,
                    }).then((result) => {
                        this.props.requestRejectWorkflowList(true, "CLEAR", "");
                        this.refreshOnUpdate()
                        //if (result.value) {
                        //    if (this.state.checkurl == "No_URL") {
                        this.props.requestApproveWorkflow(true, "CLEAR", "", "", "");
                        this.refresh()
                        //    }
                        //    this.setState({
                        //        checkapprove: true,
                        //    })
                        //    this.props.requestsearchworkflowInbox(true, "POST", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo)
                        //    //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
                        //}
                    })
                }, 500);
            }
            //setTimeout(() => {
            //    Swal.fire({
            //        title: 'สำเร็จ!',
            //        text: 'กดปุ่มยืนยัน',
            //        icon: 'success',
            //        showCancelButton: false,
            //        confirmButtonColor: '#3085d6',
            //        confirmButtonText: 'ยืนยัน!'
            //    }).then((result) => {
            //if (result.value) {
            //    if (this.state.checkurl == "No_URL") {
            //window.location.reload();
            //    } else {
            //        this.setState({
            //            controlRedirect: true
            //        })
            //    }
            //    this.setState({
            //        checkapprove: true,
            //    })
            //    this.props.requestsearchworkflowInbox(true, "POST", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo)

            //    //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
            //}
            //    })
            //}, 500);
        } else if (isLoadApproveReject && statusResponseApproveReject > 200 && (this.state.modeApproveReject == "approved" || this.state.modeApproveReject == "reject")) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            //this.props.requestsearchworkflowInbox(true, "CLEAR", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo)
            //this.props.requestApproveWorkflow(true, "CLEAR", workflowValueUse.userId, workflowValueUse.docNo, "");
            try {
                var msg = JSON.parse(messageResponseApproveReject)
                this.setState({
                    messageModal: false,
                    message: "",
                    modeApproveReject: "",
                    docNoList: [],
                    ApprovedRejectBatch: true
                })
                setTimeout(() => {
                    Swal.fire({
                        title: 'ไม่สำเร็จ!',
                        text: msg.message,
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'ตกลง',
                        allowOutsideClick: false,
                    }).then((result) => {
                        this.props.requestRejectWorkflowList(true, "CLEAR", "");
                        this.refreshOnUpdate()
                        //if (result.value) {
                        //    if (this.state.checkurl == "No_URL") {
                        this.props.requestApproveWorkflow(true, "CLEAR", "", "", "");
                        this.refresh()
                        //    }
                        //    this.setState({
                        //        checkapprove: true,
                        //    })
                        //    this.props.requestsearchworkflowInbox(true, "POST", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo)
                        //    //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
                        //}
                    })
                }, 500);
            } catch {
                this.setState({
                    messageModal: false,
                    message: "",
                    modeApproveReject: "",
                    docNoList: [],
                    ApprovedRejectBatch: true
                })
                setTimeout(() => {
                    Swal.fire({
                        title: 'ไม่สำเร็จ!',
                        html: messageResponseApproveReject,
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'ตกลง',
                        allowOutsideClick: false,
                    }).then((result) => {
                        this.props.requestRejectWorkflowList(true, "CLEAR", "");
                        this.refreshOnUpdate()
                        //if (result.value) {
                        //    if (this.state.checkurl == "No_URL") {
                        this.props.requestApproveWorkflow(true, "CLEAR", "", "", "");
                        this.refresh()
                        //    }
                        //    this.setState({
                        //        checkapprove: true,
                        //    })
                        //    this.props.requestsearchworkflowInbox(true, "POST", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo)
                        //    //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
                        //}
                    })
                }, 500);
            }
        }
    }

    hamdleMessage = () => {
        if (this.state.messageModal) {
            this.setState({
                messageModal: false,
                message: "",
                docNoList: []
            })
        } else {
            this.setState({
                messageModal: true
            })
        }
    }

    onchangeMessage = (e: { target: { name: any; value: any; }; }) => {
        this.setState({
            message: e.target.value
        })
        setTimeout(() => {
        }, 500);
    }

    onsuccessApproveReject = () => {
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";

        var messagePush: any = []
        messagePush = this.state.docNoList
        for (let i = 0; i < messagePush.length; i++) {
            messagePush[i]['message'] = this.state.message
        }
        this.setState({
            docNoList: messagePush
        })

        if (this.state.modeApproveReject == "approved") {
            //this.props.requestWorkflowInbox(true, "Approve", "", this.state.dataAll);
            this.props.requestApproveWorkflowList(true, "PUT", this.state.docNoList);
            // this.startCountdown();
        }
        else {
            //this.props.requestWorkflowInbox(true, "Reject", "", this.state.dataAll);
            this.props.requestRejectWorkflowList(true, "PUT", this.state.docNoList);
        }
    }

    handleApprove = () => {
        this.setState({ page: 1 });
        var wfList = this.prepareApproveReject();
        if (wfList.length > 0) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
            setTimeout(() => {
                document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                this.setState({
                    messageModal: true,
                    modeApproveReject: "approved",
                    successModalHeader: "Approved Status"
                })
            }, 500)
        } else {
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: 'ไม่พบการเลือกข้อมูล กรุณาลองอีกครั้ง',
                icon: 'error',
                showCancelButton: false
            })
        }

        //this.props.requestApproveWorkflow(true, "PUT", userId, docNo, "", wfType);
    }

    handleReject = () => {
        this.setState({ page: 1 });
        var wfList = this.prepareApproveReject();
        if (wfList.length > 0) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
            setTimeout(() => {
                document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                this.setState({
                    messageModal: true,
                    modeApproveReject: "reject",
                    successModalHeader: "Rejected Status"
                })
            }, 500)
        } else {
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: 'ไม่พลการเลือกข้อมูล กรุณาลองอีกครั้ง',
                icon: 'error',
                showCancelButton: false
            })
        }
        //this.props.requestRejectWorkflow(true, "PUT", userId, docNo, "", wfType);
    }

    prepareApproveReject = () => {
        this.setState({ page: 1 });
        var node = "node"
        var wfList: any = [];
        var wfArr: any = []
        var wfUse: any = [];
        var wfUnique: any = [];

        Array.from(document.querySelectorAll("input[name=" + node + "]"))
            .forEach((checkbox) => {
                var cb = document.getElementById(checkbox.id) as HTMLInputElement;
                if (cb.checked) {
                    var docNo = cb.dataset.docno;
                    var wfInstanceNo = cb.dataset.wfinstanceno;
                    var wfType = cb.dataset.wftype;
                    var appIdCheck = cb.dataset.appid
                    var wfins = {
                        appId: appIdCheck,
                        userId: this.state.userId,
                        wfType: wfType,
                        docNo: [docNo],
                        instanceNo: [wfInstanceNo]
                    }
                    wfList.push(wfins)
                }
            });

        wfList.map(batch => {
            if (wfArr.indexOf(batch.appId) === -1) {
                wfArr.push(batch.appId)
            }
        });

        let result = wfList.filter((value, index, self) => self.findIndex((m) => m.appId === value.appId) === index);
        wfUnique.push(result)

        for (let i = 0; i < wfUnique[0].length; i++) {
            var wfNum: any = []
            var wfInstanceNum: any = []
            for (let j = 0; j < wfList.length; j++) {

                if (wfUnique[0][i].appId == wfList[j].appId) {
                    wfNum.push(wfList[j].docNo[0])
                    wfInstanceNum.push(wfList[j].instanceNo[0])
                }
            }

            var wfins = {
                app_id: wfUnique[0][i].appId,
                //user_id: wfUnique[0][i].userId,
                user_id: this.state.userId,
                wf_type: wfUnique[0][i].wfType,
                doc_no_list: wfNum,
                instance_no_list: wfInstanceNum
            }
            wfUse.push(wfins)
        }

        this.setState({
            docNoList: wfUse
        })

        return wfList;
    }

    handdleChangeWftype = (key: any, index: any) => {
        let x = document.getElementsByClassName("trapezoid");
        for (let i = 0; i < x.length; i++) {
            if (x.length > 0) { x[i].classList.remove("active"); }
        }
        if (index != null) {
            var element = document.getElementById("trapezoidTap" + index)
            if (element) {
                element.classList.add('active')
                this.setState({
                    pageTab: key.wftype,
                    countTab: key.count,
                    currentPage: index + 1
                })

                setTimeout(() => {
                }, 1000);
            }
        } else {
            var element = document.getElementById("trapezoidTapAll")
            if (element) {
                element.classList.add('active')
                this.setState({
                    pageTab: key.wftype,
                    countTab: key.count,
                    currentPage: 0
                })
                //this.refresh()
                setTimeout(() => {
                }, 1000);
            }
        }
    }

    handleSearch = (value: any, checkAll: any, searchReq: any, dashboardStatus: any, selectUserValue: string) => {
        var dataReq = searchReq
        dataReq['createBy'] = searchReq.createBy
        dataReq['createByTo'] = searchReq.createByTo
        dataReq['userName'] = selectUserValue

        var sids = ['']
        //false
        if (checkAll) {
            sids = ["*"]
        }
        else {
            sids.shift()
            sids.push(value);
        }

        this.setState({
            sids: sids,
            searchReq: dataReq
        });
        setTimeout(() => {
            //this.componentDidMount();
            this.refresh();
        }, 1000);
    };

    blindTabs() {

    }

    public blindSidArr(value: any) {
        this.setState({
            SidArr: value
        })
    }

    handlesuccessModal = () => {
        const { responseWorkflowType } = this.props

        if (this.state.successModal) {
            this.props.requestApproveWorkflow(true, "CLEAR", "", "", "");
            this.setState({
                successModal: false,
                successModalHeader: ""
            })
        } else {
            this.setState({
                successModal: true
            })
        }

        // this.props.requestWorkflowTypeInbox(true, 'CLEAR', this.state.userId, this.state.sids, this.state.searchReq)
        this.props.requestWorkflowInbox(this.state.checkRequest, "CLEAR", this.state.userId, this.state.sids, 1, '', '')
        // this.props.requestWorkflowTypeInbox(true, 'POST', this.state.userId, this.state.sids, this.state.searchReq)
        this.props.requestWorkflowInbox(true, "POST", this.state.userId, this.state.sids, 1, 100, this.state.pageTab, this.state.searchReq)

        var dataRs: any = []
        var countArr: number = 0
        for (let i = 0; i < responseWorkflowType['result_list'].length; i++) {
            countArr += parseInt(responseWorkflowType['result_list'][i].count)
        }
        for (let i = 0; i < responseWorkflowType['result_list'].length; i++) {
            var result = dataRs.filter(item => item.wftype === responseWorkflowType['result_list'][i].wftype);
            if (result.length > 0) {
                result[0].count = (parseInt(result[0].count) + parseInt(responseWorkflowType['result_list'][i].count)).toString()
            }
            else {
                dataRs.push(responseWorkflowType['result_list'][i])
            }
        }

        this.setState({
            uniqueWfType: dataRs,
            countResponse: "เอกสารรออนุมัติ (" + countArr + " รายการ)",
        })
    }

    handleSuccessApproveReject = (mode: string) => {
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
        this.setState({
            modeApproveReject: mode
        })

        this.handleSuccessApproveRejectGetData()
    }

    handleSuccessApproveRejectGetData = () => {
        const { responseWorkflowType } = this.props

        this.props.requestWorkflowInbox(true, "POST", this.state.userId, this.state.sids, 1, 100, this.state.pageTab, this.state.searchReq)
        this.props.requestWorkflowInbox(this.state.checkRequest, "CLEAR", this.state.userId, this.state.sids, 1, '', '')

        var dataRs: any = []
        var countArr: number = 0
        for (let i = 0; i < responseWorkflowType['result_list'].length; i++) {
            countArr += parseInt(responseWorkflowType['result_list'][i].count)
        }
        for (let i = 0; i < responseWorkflowType['result_list'].length; i++) {
            var result = dataRs.filter(item => item.wftype === responseWorkflowType['result_list'][i].wftype);
            if (result.length > 0) {
                result[0].count = (parseInt(result[0].count) + parseInt(responseWorkflowType['result_list'][i].count)).toString()
            }
            else {
                dataRs.push(responseWorkflowType['result_list'][i])
            }
        }
        this.setState({
            uniqueWfType: dataRs,
            countResponse: "เอกสารรออนุมัติ (" + countArr + " รายการ)",
        })
    }

    public pageSet(value: any) {
        this.setState({
            checkPage: value
        })
    }

    prepareApprovedRejectBatch = (instanceNoList: string[], docNoList: string[], wftype: string, app_id: string, mode: string) => {
        this.setState({ page: 1 });
        var header = ""
        var wfUse: Array<batchModel> = []
        var wfins: batchModel = {
            app_id: app_id,
            user_id: this.state.userId,
            wf_type: wftype,
            doc_no_list: docNoList,
            instance_no_list: instanceNoList
        }
        wfUse.push(wfins)
        if (mode == "approved") {
            header = 'Approved Status'
        } else {
            header = 'Rejected Status'
        }
        this.setState({
            docNoList: wfUse,
            messageModal: true,
            modeApproveReject: mode,
            successModalHeader: header

        })
    }

    ApprovedRejectBatchCallback = () => {
        this.setState({
            ApprovedRejectBatch: false
        })
    }

    resetCheckResponseTable = (key: boolean) => {
        if (key) {
            this.setState({
                checkResponseTable: true
            })
        } else {
            this.setState({
                checkResponseTable: false
            })
        }
    }

    startCountdown() {
        this.intervalId = setInterval(() => {
            if (this.state.seconds > 0) {
                this.setState(prevState => ({
                    seconds: prevState.seconds - 1,
                }));
            } else {
                this.stopCountdown();
            }
        }, 1000);
    }

    stopCountdown() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    handleChangePage = (value: number) => {
        this.setState({ page: value });
    }

    handleChangeApproveRejectState = (mode: string) => {
        console.log(mode)
        this.skipModal = true
        this.setState({
            modeApproveReject: mode
        })
    }

    render() {
        const { responseWorkflowInbox, statusWorkflowInbox, statusResponseApproveReject } = this.props
        return (
            <div>
                <div className="master-data-content">
                    <div className='trapezoid-head' style={{ width: '200px' }}>
                        <span>
                            {this.state.headerText}
                        </span>
                    </div>
                </div>
                <div className="mat-box">
                    <Row style={{ border: 'solid 1px #E7E7E7', marginLeft: '1px', marginRight: '1px' }}>
                        <WorkflowSearchIdTab blindSidArr={this.blindSidArr} handleSearch={this.handleSearch} notSetDefaultFiscalYear={true} />
                    </Row>
                    {this.state.checkOnloadWorkflowinboxadmin &&
                        <div>
                            <Row>
                                <Col style={{ textAlign: 'left', paddingTop: '10px' }}>
                                    <div className="container">
                                        <Button variant="outline-primary" size="sm" onClick={() => this.refresh()} disabled={this.state.checkResponseTable}>
                                            <FaSync />
                                            &nbsp;Refresh
                                        </Button>
                                    </div>
                                </Col>
                                <Col style={{ textAlign: 'right', paddingTop: '10px' }}>
                                    <div className="container">
                                        <Button variant="outline-success" size="sm" onClick={() => this.handleApprove()} disabled={this.state.checkResponseTable}>
                                            <FaCheck />
                                            &nbsp;Approve
                                        </Button>
                                        <Button variant="outline-danger" size="sm" className="mx-1" onClick={() => this.handleReject()} disabled={this.state.checkResponseTable}>
                                            <FaTimesCircle />
                                            &nbsp;Reject
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row >
                                <Col>
                                    <div className="container">
                                        {/*                                <div className='row mt-2'>
                                    <div className='horizon-tab scrollbar custom-scrollbar'>
                                        <ul className="nav nav-pills mb-3">
                                            <tr style={{ width: '100%' }} className='text-left'>
                                                <th style={{ width: '5%' }}>
                                                    <li className='nav-item' onClick={() => this.handdleChangeWftype(this.state.initialTab, null)}>
                                                        <a className='pointer'>
                                                            <div className='trapezoid trapezoid-purple active' id='trapezoidTapAll' style={{ width: '230px' }}>
                                                                <span>
                                                                    <i className="fa fa-search" aria-hidden="true"></i>
                                                                    {this.state.checkRequest ? 'เอกสาร (0 รายการ)' : this.state.countResponse}
                                                                </span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </th>

                                                {this.state.uniqueWfType.length != 0 && this.state.uniqueWfType.map((data, index) => {
                                                    return (
                                                        <th key={index} style={{ width: '5%' }}>
                                                            <li className='nav-item' onClick={() => this.handdleChangeWftype(data, index)}>
                                                                <a className='pointer'>
                                                                    <div className='trapezoid' id={'trapezoidTap' + index} style={{ width: '230px', borderBottom: "32px solid " + this.state.wfTypeColour[index] + "" }}>
                                                                        <span>
                                                                            <i className="fa fa-search" aria-hidden="true"></i>
                                                                            {this.state.checkRequest ? 'เอกสาร (0 รายการ)' : data.wfname + ' (' + data.count + ' รายการ)'}
                                                                        </span>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                        </th>
                                                    )
                                                })}
                                                <td style={{ width: 100 - (5 * this.state.uniqueWfType.length) + '%' }}></td>
                                                {*//*                                                <div className='trapezoid trapezoid-purple' style={{ width: '230px' }}>
                                                    <span>
                                                        <i className="fa fa-search" aria-hidden="true"></i>
                                                        {this.state.checkRequest ? 'เอกสาร (0 รายการ)' : this.state.countResponse}
                                                    </span>
                                                </div>
                                                <div className='trapezoid trapezoid-blue' style={{ width: '230px' }}>
                                                    <span>
                                                        <i className="fa fa-search" aria-hidden="true"></i>
                                                        {this.state.checkRequest ? 'เอกสาร (0 รายการ)' : this.state.countResponse}
                                                    </span>
                                                </div>*//*}
                                            </tr>
                                        </ul>
                                    </div>

                                </div>*/}
                                        <div>
                                            <div className="mt-2">
                                                <Row className={this.state.checkResponseTable ? 'disable-div' : ''} style={{ zIndex: 900 }}>
                                                    {this.state.uniqueWfType.length != 0 ?
                                                        <AppBar position="static" color="inherit" style={{ boxShadow: 'none', zIndex: 0 }}>
                                                            <Tabs
                                                                indicatorColor="primary"
                                                                textColor="primary"
                                                                variant="scrollable"
                                                                scrollButtons="auto"
                                                                aria-label="scrollable auto tabs example"
                                                                value={this.state.currentPage}
                                                                onChange={this.blindTabs}
                                                                style={{ zIndex: 10 }}
                                                            >
                                                                <div className='nav-item' onClick={() => this.handdleChangeWftype(this.state.initialTab, null)}>
                                                                    <a className='pointer'>
                                                                        <div className='trapezoid trapezoid-purple active' id='trapezoidTapAll' style={{ width: '230px' }}>
                                                                            <span>
                                                                                <i className="fa fa-search" aria-hidden="true"></i>
                                                                                {this.state.checkRequest ? 'เอกสาร (0 รายการ)' : this.state.countResponse}
                                                                            </span>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                {this.state.uniqueWfType.length != 0 ? this.state.uniqueWfType.map((data, index) => {
                                                                    return (
                                                                        <div key={index} className='nav-item' onClick={() => this.handdleChangeWftype(data, index)}>
                                                                            <a className='pointer disabled'>
                                                                                <div className='trapezoid' id={'trapezoidTap' + index} style={{ width: '230px', borderBottom: "32px solid " + this.state.wfTypeColour[index] + "" }}>
                                                                                    <span>
                                                                                        <i className="fa fa-search" aria-hidden="true"></i>
                                                                                        {this.state.checkRequest ? 'เอกสาร (0 รายการ)' : (data.wftypeName != null ? data.wftypeName : data.wftype) + ' (' + data.count + ' รายการ)'}
                                                                                    </span>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    )
                                                                }) : undefined}
                                                            </Tabs>
                                                        </AppBar>
                                                        : undefined}
                                                </Row>
                                            </div>
                                            <WorkflowInboxTable
                                                checkkey={this.state.key}
                                                callbackkey={this.resetRefresh.bind(this)}
                                                pageTab={this.state.pageTab}
                                                countTab={this.state.countTab}
                                                sids={this.state.sids}
                                                sidArr={
                                                    this.props.GetMasterCompany.isLoadGetMasterCompany
                                                        ? this.props.GetMasterCompany.responseGetMasterCompany.result_list
                                                        : this.state.SidArr
                                                }
                                                handleChangeApproveReject={this.handleChangeApproveRejectState.bind(this)}
                                                handleSearch={this.handleSearch}
                                                refreshPage={this.handleSuccessApproveReject.bind(this)}
                                                searchReq={this.state.searchReq}
                                                prepareApprovedRejectBatch={this.prepareApprovedRejectBatch.bind(this)}
                                                ApprovedRejectBatchCallback={this.ApprovedRejectBatchCallback.bind(this)}
                                                ApprovedRejectBatch={this.state.ApprovedRejectBatch}
                                                checkResponseTable={this.resetCheckResponseTable.bind(this)}
                                                page={this.state.page}
                                                handleChangePage={this.handleChangePage}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    }
                </div>
                {/*<Row>
                    <Col>
                        <Card className="shadow-lg p-3 mb-5 bg-white rounded">
                            <Card.Header>
                                <Row>
                                    <Col><h4>Workflow Inbox</h4></Col>
                                </Row>
                            </Card.Header>
                                <Row>
                                <Col style={{ textAlign: 'right', paddingTop: '10px' }}>
                                    <div className="container">
                                        <Button variant="outline-success" size="sm">
                                            <FaCheck />
                                                    &nbsp;Approve
                                        </Button>
                                        <Button variant="outline-danger" size="sm" className="mx-1">
                                            <FaTimesCircle />
                                                    &nbsp;Reject
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                                <Row>
                                    <Col>
                                        <div className="container">
                                            <div className='trapezoid trapezoid-purple' style={{ width: '230px' }}>
                                                <span>
                                                    <i className="fa fa-search" aria-hidden="true"></i>
                                                    {this.state.checkRequest ? 'เอกสาร (0 รายการ)' : this.state.countResponse}
                                                </span>
                                            </div>
                                        </div>
                                        <WorkflowInboxTable />
                                       
                                    </Col>
                                </Row>
                        </Card>
                    </Col>
                </Row>*/}
                <Modal isOpen={this.state.messageModal} toggle={() => this.hamdleMessage()} size="lg" backdrop="static" keyboard={false}>
                    <ModalHeader toggle={() => this.hamdleMessage()}>Message</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Control as="textarea" rows={5} onChange={this.onchangeMessage} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {/*  <Button color="primary" onClick={() => this.handleClose(this.state.wfId, "", "", "")}>Save</Button>*/}
                        <Button className="bg-[#17a2b8]" variant="info" onClick={() => this.onsuccessApproveReject()}>Confirm</Button>
                        <Button className="bg-[#6c757d]" variant="secondary" onClick={() => this.hamdleMessage()}>Cancel</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.successModal} toggle={() => this.handlesuccessModal()} size="lg" backdrop="static" keyboard={false}>
                    {this.props.isLoadApproveReject
                        && this.props.statusResponseApproveReject == 200
                        && this.props.responseApproveReject.length != 0
                        && Array.isArray(this.props.responseApproveReject['result_list'])
                        && this.props.responseApproveReject['result_list'].map((item: ModelApproveRejectWorkflow.ApproveRejectWorkflowResponse, idx) => {
                            item.status === 'wait_otp' && window.location.replace(item.redirectLink);
                            return (
                                <React.Fragment>
                                    <ModalHeader toggle={() => this.handlesuccessModal()}>{this.state.successModalHeader}</ModalHeader>
                                    <ModalBody scrollable>
                                        <Row>
                                            <Col>

                                                <Card className="shadow-sm p-1 mb-1 bg-white rounded">
                                                    <CardBody>
                                                        <Row>
                                                            <Col lg={2}>
                                                                <div style={{ alignContent: 'center', verticalAlign: 'middle', textAlign: 'center' }}>
                                                                    {item.status == "SUCCESS"
                                                                        ? <React.Fragment><FaCheckCircle size={50} color="green" /></React.Fragment>
                                                                        : item.status === "wait_otp"
                                                                            ? <FaCircleNotch size={80} color="yellow" style={{ paddingTop: '30px' }} />
                                                                            : item.status === "wait_sign"
                                                                                ? <FaCircleNotch size={80} color="yellow" style={{ paddingTop: '30px' }} />
                                                                                : <React.Fragment><FaTimesCircle size={80} color="red" style={{ paddingTop: '30px' }} /></React.Fragment>}
                                                                </div>
                                                            </Col>
                                                            <Col lg={10}>
                                                                <Table className="table table-borderless table-hover" size="sm">
                                                                    <tr className="require-field" style={{ width: '100%' }}>
                                                                        <td className="font-weight-bold" style={{ width: '30%' }}>Document NO. :</td>
                                                                        <td style={{ width: '70%' }}>{item.docNo}</td>
                                                                    </tr>
                                                                    <tr className="require-field" style={{ width: '100%' }}>
                                                                        <td className="font-weight-bold" style={{ width: '30%' }}>Status :</td>
                                                                        <td style={{ width: '70%' }}>{item.status}</td>
                                                                    </tr>
                                                                    <tr className="require-field" style={{ width: '100%' }}>
                                                                        <td className="font-weight-bold" style={{ width: '30%' }}>Message :</td>
                                                                        <td style={{ width: '70%' }}>{item.message}</td>
                                                                    </tr>
                                                                    {/* {item.status == "wait_otp" ?
                                                                        <tr className="require-field" style={{ width: '100%' }}>
                                                                            <td className="font-weight-bold" style={{ width: '30%' }}>Redirect In :</td>
                                                                            <td style={{ width: '70%' }}>{this.state.seconds}</td>
                                                                        </tr>
                                                                        : ''} */}
                                                                    {item.status == "error" ?
                                                                        <tr className="require-field" style={{ width: '100%' }}>
                                                                            <td className="font-weight-bold" style={{ width: '30%' }}>Error Message :</td>
                                                                            <td style={{ width: '70%' }}>{item.errorMessage}</td>
                                                                        </tr>
                                                                        : ''}
                                                                </Table>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button className="bg-[#dc3545]" variant="danger" onClick={() => this.handlesuccessModal()} disabled={item.status === "wait_otp"}> Close</Button>
                                    </ModalFooter>
                                </React.Fragment>
                            )
                        })}
                </Modal>
            </div >
        );
    }
}

//export default connect((state: ApplicationState) => state.workflowInbox, WorkflowInboxStore.actionCreators) (WorkflowInbox)
export default connect((state: ApplicationState) => ({
    ...state.appState,
    ...state.MasterCompany,
    ...state.workflowInbox,
    ...state.approveRejectWorkflow,
    ...state.WorkflowTypeInbox
}), ({
    ...AppState.actionCreators,
    ...WorkflowInboxStore.actionCreators,
    ...ApproveRejectWorkflowStore.actionCreators,
    ...UniqueWorkflowTypeStore.actionCreators
}))(WorkflowInbox)