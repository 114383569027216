import * as React from "react";
import { connect } from "react-redux";
import { Button, Container, Row, Col, Table, Spinner, Form, Accordion } from "react-bootstrap";
import { FaEdit, FaTimesCircle, FaAngleDown } from 'react-icons/fa';
import Swal from 'sweetalert2'
import * as XLSX from 'xlsx';
import Pagination from '@material-ui/lab/Pagination';
import { ApplicationState } from "../../store";
import * as ModelMasterEmployee from '../../models/callbackconfig/MasterEmployee'
import * as MasterEmployeeStore from '../../store/callbackconfig/MasterEmployee'

export interface optionValue {
    value: string
    label: string
}

export interface optionSid {
    value: string
    checked: boolean
}

type Store = ModelMasterEmployee.GetMasterEmployeeState & typeof MasterEmployeeStore.actionCreators;

class MasterEmployeeTable extends React.Component<any, { isready: boolean, userId: string, openModalAdd: boolean, optionSid: Array<optionValue>, employeeListAdd: ModelMasterEmployee.employeeListAdd, sidSelected: Array<string>, formDataReq: ModelMasterEmployee.listMasterEmployee, page: number, page_size: number, empid: string, sid: string, empname: string, pageBtn: any, keyReset: boolean, totalCountRes: number }, Store> {

    constructor(props: any) {
        super(props);
        this.state = {
            isready: true,
            userId: "",
            openModalAdd: false,
            optionSid: [
                { value: '1mHUSXNKeGnm3yMz4k9vDoDBA3b', label: 'บริษัท ศิลาสานนท์ จำกัด' },
                { value: '1mHUXA5gy4XC58NNwXG5Pv5jhXs', label: 'บริษัท ศิลาไทยสงวน (2540) จำกัด' },
                { value: '1s421NbVxBwtM1jpjp7eDh5x0sR', label: 'บริษัท สยามอุตสาหกรรมวัสดุทนไฟ จำกัด' },
                { value: '1s41kgyAnv4dfqxdRXzDVIEf7XJ', label: 'บริษัท ปูนซิเมนต์ไทย จำกัด' },
                { value: '1tRwkyq7ehP3pXS6ZLHiDmyhmlG', label: 'บริษัท คิวมิกซ์ซัพพลาย จำกัด' },
                { value: '1tRwb4XQ3WeYDg4Xl1vblmGM4Fj', label: 'บริษัท อีโค่ แพลนท์ เซอร์วิสเซส จำกัด' },
                { value: '1s41rStqmc1RMtOtQTPLAdH9hMF', label: 'บริษัท เอสซีไอ อีโค่ เซอร์วิสเซส จำกัด' },
                { value: '1JeqtQlfd6fuDpsFtUjp01mOR98', label: 'FocusoneLink' },
            ],
            formDataReq: {
                id: "",
                EmployeeListAdd: []
            },
            employeeListAdd: {
                sid: "",
                empid: "",
                empname: "",
                firstname: "",
                lastname: "",
                companycode: "",
                purchaseplantcode: "",
                divisioncode: "",
                department: "",
                costcenter: "",
                email: "",
                phone: "",
                TRIAL987: ""
            },
            sidSelected: [],
            page: 1,
            page_size: 25,
            empid: "",
            sid: "",
            empname: "",
            pageBtn: [],
            keyReset: false,
            totalCountRes: 0
        }
    }

    componentDidMount() {
        var data = localStorage.getItem('WF_APPLICATION');
        if (data) {
            var msg = JSON.parse(data)
            this.setState({
                userId: msg.user_id
            })
        }

        this.props.requestGetMasterEmployee(true, 'GET', this.state.empid, this.state.sid, this.state.empname, this.state.page, this.state.page_size);
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { DeleteMasterEmployee, GetMasterEmployee } = this.props
        if (DeleteMasterEmployee.isLaoadDeleteMasterEmployee && DeleteMasterEmployee.statusDeleteMasterEmployee == 200) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'สำเร็จ!',
                text: 'บันทึกข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestDeleteMasterEmployee(true, "CLEAR", this.state.userId)
                    this.props.requestGetMasterEmployee(true, 'GET', this.state.empid, this.state.sid, this.state.empname, this.state.page, this.state.page_size);
                }
            })
        } else if (DeleteMasterEmployee.isLaoadDeleteMasterEmployee && DeleteMasterEmployee.statusDeleteMasterEmployee != 200) {
            var msg = JSON.parse(DeleteMasterEmployee.messageDeleteMasterEmployee)
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestDeleteMasterEmployee(true, "CLEAR", this.state.userId)
                    this.props.requestGetMasterEmployee(true, 'GET', this.state.empid, this.state.sid, this.state.empname, this.state.page, this.state.page_size);
                }
            })
        }

        if (GetMasterEmployee.isLaoadGetMasterEmployee && GetMasterEmployee.statusGetMasterEmployee == 200 && GetMasterEmployee.responseGetMasterEmployee['total_count'] != 0 && this.state.totalCountRes != GetMasterEmployee.responseGetMasterEmployee['total_count']) {
            this.setState({
                totalCountRes: GetMasterEmployee.responseGetMasterEmployee['total_count']
            })
            var countingPage: any = 0
            var dataArr: any = []
            countingPage = Math.ceil(GetMasterEmployee.responseGetMasterEmployee['total_count'] / this.state.page_size)
            console.log(countingPage)

            for (let i = 0; i < countingPage; i++) {
                var data: any = {}
                data['page'] = i + 1
                data['pageTab'] = this.props.pageTab
                dataArr.push(data)
            }

            this.setState({
                pageBtn: dataArr
            })
        }

        if (this.state.keyReset != this.props.keyReset) {
            if (this.props.keyReset) {
                this.props.callbackReset()
                this.setState({
                    empid: this.props.empid,
                    empname: this.props.empname,
                    sid: this.props.sid
                })
            }
            this.props.requestGetMasterEmployee(true, 'GET', this.props.empid, this.props.sid, this.props.empname, this.state.page, this.state.page_size);
        }

        if (this.props.exportTemplate == "exportTemplate") {
            this.ExportTemplate();
        }
        else if (this.props.exportTemplate == "exportEmployee") {
            this.ExportTemplateUser();
        }
    }

    ExportTemplate = () => {
        this.props.resetExportTemplate();
        var date = new Date();
        var filename = 'Template_MasterEmployee_' + date.getTime().toString() + '.xlsx';
        var Company: any = []
        this.props.TotalComapny.map((item) => {
            let data = {}
            data["sid"] = item.sid;
            data["company_name"] = item.company_name;
            Company.push(data);
        })

        fetch("/v1/configuration/CheckImportEmployee",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION')
                }
            }
        )
            .then(res => res.json())
            .then(
                (result) => {
                    var Employee: any = []
                    result.map((item) => {
                        let data = {}
                        data["sid"] = item.sid;
                        data["empid"] = item.empid;
                        data["empname"] = item.empname;
                        data["firstname"] = item.firstname;
                        data["lastname"] = item.lastname;
                        data["companycode"] = item.companycode;
                        data["purchaseplantcode"] = item.purchaseplantcode;
                        data["divisioncode"] = item.divisioncode;
                        data["department"] = item.department;
                        data["costcenter"] = item.costcenter;
                        data["email"] = item.email;
                        data["phone"] = item.phone;
                        // data["TRIAL987"] = item.TRIAL987;
                        Employee.push(data);
                    })
                    var ws_Company = XLSX.utils.json_to_sheet(Company);
                    var ws_Employee = XLSX.utils.json_to_sheet(Employee);
                    var wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws_Company, "Company");
                    XLSX.utils.book_append_sheet(wb, ws_Employee, "MasterEmployee");
                    XLSX.writeFile(wb, filename);
                    document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                },
                (error) => {
                    document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                }
            )
    }

    ExportTemplateUser = () => {
        this.props.resetExportTemplate();
        var date = new Date();
        var filename = 'MasterEmployee_' + date.getTime().toString() + '.xlsx';
        var Company: any = []
        this.props.TotalComapny.map((item) => {
            let data = {}
            data["sid"] = item.sid;
            data["company_name"] = item.company_name;
            Company.push(data);
        })

        fetch("/v1/configuration/CheckImportEmployee?sid=" + this.props.selectCompany,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION')
                }
            }
        )
            .then(res => res.json())
            .then(
                (result) => {
                    var Employee: any = []
                    result.map((item) => {
                        let data = {}
                        data["sid"] = item.sid;
                        data["empid"] = item.empid;
                        data["empname"] = item.empname;
                        data["firstname"] = item.firstname;
                        data["lastname"] = item.lastname;
                        data["companycode"] = item.companycode;
                        data["purchaseplantcode"] = item.purchaseplantcode;
                        data["divisioncode"] = item.divisioncode;
                        data["department"] = item.department;
                        data["costcenter"] = item.costcenter;
                        data["email"] = item.email;
                        data["phone"] = item.phone;
                        //data["TRIAL987"] = item.TRIAL987;
                        data["createdby"] = item.createdby;
                        data["createdon"] = item.createdon;
                        data["updateby"] = item.updateby;
                        data["updateon"] = item.updateon;
                        Employee.push(data);
                    })
                    var ws_Company = XLSX.utils.json_to_sheet(Company);
                    var ws_Employee = XLSX.utils.json_to_sheet(Employee);
                    var wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws_Company, "Company");
                    XLSX.utils.book_append_sheet(wb, ws_Employee, "MasterEmployee");
                    XLSX.writeFile(wb, filename);
                    document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                },
                (error) => {
                    document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                }
            )
    }

    ondelete = (empid: string) => {
        console.log(empid)
        Swal.fire({
            title: 'ลบข้อมูล?',
            text: 'ต้องการลบข้อมูลหรือไม่ ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ไม่',
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                this.props.requestDeleteMasterEmployee(true, "DELETE", empid)
                document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
            }
        })
    }

    BeforehandleChangePage = (event, value) => {
        console.log(value);
        this.setState({
            page: value
        })
        this.props.requestGetMasterEmployee(true, 'GET', this.state.empid, this.state.sid, this.state.empname, value, this.state.page_size);
        this.setState({
            isready: true
        })
        //return this.haddleChangePage(this.props.pageTab, value)
    }

    handlePageSize = (e) => {
        console.log(typeof e.target.value)
        var parse = parseInt(e.target.value)
        this.setState({ page_size: parse });

        this.props.requestGetMasterEmployee(true, 'GET', this.state.empid, this.state.sid, this.state.empname, this.state.page, parse);
        this.setState({
            isready: true
        })
    }

    public ResetSearch = () => {
        console.log("ResetSearch")
    }

    render() {
        const { GetMasterEmployee } = this.props
        return (
            <Container>
                <Row className="mt-1">
                    <Col md={6}>
                        <div className='d-flex justify-content-end justify-content-md-start my-2 my-lg-0'>
                            <Form inline>
                                <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
                                    Show
                                </Form.Label>
                                <Form.Control
                                    as="select"
                                    className="my-1 mr-sm-2"
                                    id="inlineFormCustomSelectPref"
                                    custom
                                    size="sm"
                                    value={this.state.page_size}
                                    onChange={this.handlePageSize}
                                >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </Form.Control>
                                <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
                                    entries
                                </Form.Label>
                            </Form>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='d-flex justify-content-end my-2 my-lg-0'>
                            <Pagination count={this.state.pageBtn.length > 0 ? this.state.pageBtn.length : undefined} shape="rounded" onChange={this.BeforehandleChangePage} defaultPage={1} showFirstButton showLastButton />
                        </div>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col style={{ overflowX: 'scroll' }}>
                        <Table className="table-bordered align-middle" size='sm'>
                            <thead>
                                <tr style={{ width: '100%' }} className="text-center table-active thead-light">
                                    <th style={{ width: '7%' }}></th>
                                    <th style={{ width: '13%' }}>Emp ID</th>
                                    {/*<th style={{ width: '20%' }}>SID</th>*/}
                                    <th style={{ width: '20%' }}>Emp name</th>
                                    <th style={{ width: '20%' }}>Frist name</th>
                                    <th style={{ width: '20%' }}>Last name</th>
                                    <th style={{ width: '20%' }}>E-mail</th>
                                    {/*<th style={{ width: '10%' }}>Phone</th>*/}
                                </tr>
                            </thead>
                            <tbody>
                                {!GetMasterEmployee.isLaoadGetMasterEmployee ? <tr><td colSpan={7} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr> : <tr></tr>}
                                {GetMasterEmployee.isLaoadGetMasterEmployee && GetMasterEmployee.isLaoadGetMasterEmployee != undefined && GetMasterEmployee.responseGetMasterEmployee['total_count'] == 0 ? <tr><td colSpan={7} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr> : <tr></tr>}
                                {GetMasterEmployee.isLaoadGetMasterEmployee && GetMasterEmployee.statusGetMasterEmployee == 200 && GetMasterEmployee.responseGetMasterEmployee['total_count'] != 0 && GetMasterEmployee.responseGetMasterEmployee['result_list'].map((item: ModelMasterEmployee.listMasterEmployee, idx) =>
                                    <tr key={idx}>
                                        <td className="text-center">
                                            <div>
                                                {/* <Link to={{ pathname: '/addauthoritygroup', state: { positioncode: item.conditionid } }}> */}
                                                <Button variant="outline-info" size="sm" onClick={() => this.props.onupdate(item.id)}><FaEdit /></Button>
                                                {/* </Link> */}
                                                <Button className="mx-1" variant="outline-danger" size="sm" onClick={() => this.ondelete(item.id)}><FaTimesCircle /></Button>
                                            </div>
                                        </td>
                                        <td className="text-center">{item.id}</td>

                                        <td>{item.empname}</td>
                                        <td>{item.firstname}</td>
                                        <td>{item.lastname}</td>
                                        <td>{item.email}</td>
                                        {/*<td>{item.phone}</td>*/}
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        )
    }
} export default connect((state: ApplicationState) => ({ ...state.appState, ...state.MasterEmployee }), ({ ...MasterEmployeeStore.actionCreators }))(MasterEmployeeTable)