import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/condition/ConditionMaster'

interface BeforeLoadConditionMaster {
    type: 'LOAD_CONDITIONMASTER'
}
interface LoadConditionMaster {
    type: 'LOADCONDITIONMASTER'
    response: Models.ResponseConditionMaster
    status: Number,
    statusMessage: String
}
interface FailLoadConditionMaster {
    type: 'LOADFAIL'
    status: number,
    statusMessage: any
}


type KnownAction = LoadConditionMaster | FailLoadConditionMaster | BeforeLoadConditionMaster

export const actionCreators = {
    requestConditionMaster: (p: boolean, method: String , company : string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.conditionMaster && p && method == 'POST') {
            console.log('call actionCreators : requestConditionMaster')
            fetch(`/v2/Condition/Master?app_id=`+ company, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('errorrrr')
                            dispatch({ type: 'LOADFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            console.log('dispatch : LOADCONDITIONMASTER')
                            dispatch({ type: 'LOADCONDITIONMASTER', response: data as Models.ResponseConditionMaster, status: 200, statusMessage: '' });
                        })
                    }
                })

            dispatch({ type: 'LOAD_CONDITIONMASTER' });
        }
    }
}

const unloadedState: Models.GetConditionMasterState = { isLoadConditionMaster: false };

export const reducer: Reducer<Models.GetConditionMasterState> = (state: Models.GetConditionMasterState | undefined, incomingAction: Action): Models.GetConditionMasterState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_CONDITIONMASTER':
            return {
                isLoadConditionMaster: false,
            }

        case 'LOADCONDITIONMASTER':
            return {
                isLoadConditionMaster: true,
                responseMaster: action.response,
                statusMaster: action.status
            }

        case 'LOADFAIL':
            return {
                isLoadConditionMaster: true,
                message: action.statusMessage,
                statusMaster: action.status
            }
        default: return state;
    }
}